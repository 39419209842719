import {useEffect} from "react";


const usePaginationScroll = (page: number) => {

    useEffect(() => {
        if (!page || page === 0) {
            return
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [page]);
}

export default usePaginationScroll
