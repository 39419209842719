import './QuestionaryPage.sass'
import React, {useEffect, useState, useRef} from "react";
import {ExpandableBlock} from './ExpandableBlock';

import {parseQuestionaryInfoRequest, updateQuestionaryInfoRequest} from "../../api/questionary";
import {
    changeUserRoleForQuestionaryBlock,
    getSessionUserRole,
    checkUserRole,
    setSessionUserRole, createNewRole
} from "../../api/sessionManagement"
import useMessage from "../../hooks/UseMessage";
import {COUNTRIES, MESSAGE_VARIANT} from "../../constants/consts";
import Message from "../../components/message/Message";


export const QuestionaryPage = () => {


    const [isBlocked, setIsBlocked] = useState(false);
    const [, updateState] = React.useState();
    const questionaryData = useRef<any>()
    const [acceptAgreement, setAcceptAgreement] = useState(false);
    const [agreementError, setAgreementError] = useState(false);

    const {message, hideMessage, showMessage} = useMessage()


    const [openItems, setOpenItems] = useState<any>({
        basicInfo: true,
        beneficialOwner: false,
        legalAddress: false,
        officeAddress: false,
        companyBoard:false,
        bankDetails: false,
        shippingAddress: false,
        purchasingDepartment: false,
        accountingDepartment: false,
        companyOrientation: false,
    });


    const toggleItem = (index: any) => {
        setOpenItems({...openItems, [index]: !openItems[index]});
    };

    const parseValueForExtendedBlocks = (dataKey: any) => {
        let extendedBlocksScheme: {}[] = []
        let extendedBlocks = document.querySelectorAll(`div.${dataKey}`)
        extendedBlocks.forEach((element: any, key: any) => {
            let extendedBlocksObject:any = {}
            if (dataKey === "extended_bank") {
                let scheme = {
                    "record_id": element.dataset.recordId === 'null' ? null : parseInt(element.dataset.recordId),
                    "name": element.querySelector('input[data-key=bank-name]').value,
                    "account_number": element.querySelector('input[data-key=bank-account-number]').value,
                    "swift": element.querySelector('input[data-key=bank-swift]').value
                }
                extendedBlocksObject =  isObjectEmpty(scheme) ? null : scheme
            } else if (dataKey === "extended_beneficial") {
                let scheme = {
                    "record_id": element.dataset.recordId === 'null' ? null : parseInt(element.dataset.recordId),
                    "name": element.querySelector('input[data-key=beneficial_owner-name]').value,
                    "nationality": element.querySelector('input[data-key=beneficial_owner-nationality]').value,
                    "identity": element.querySelector('input[data-key=beneficial_owner-identity]').value
                }
                extendedBlocksObject = isObjectEmpty(scheme) ? null : scheme
            }
            else if (dataKey === "company_board") {
                let scheme = {
                    "record_id": element.dataset.recordId === 'null' ? null : parseInt(element.dataset.recordId),
                    "name": element.querySelector('input[data-key=company-board-name]').value,
                }
                extendedBlocksObject = isObjectEmpty(scheme) ? null : scheme
            }
            else {
                let scheme = {
                    "record_id": element.dataset.recordId === 'null' ? null : parseInt(element.dataset.recordId),
                    "name": element.querySelector('input[data-key=shipping-name]').value,
                    "street": element.querySelector('input[data-key=shipping-address]').value,
                    "city": element.querySelector('input[data-key=shipping-city]').value,
                    "postal_code": element.querySelector('input[data-key=shipping-postal-code]').value,
                    "phone": element.querySelector('input[data-key=shipping-phone]').value,
                    "email": element.querySelector('input[data-key=shipping-email]').value,
                    "region": element.querySelector('select[data-key=shipping-region]').value,
                }
                extendedBlocksObject = isObjectEmpty(scheme) ? null : scheme
            }
            extendedBlocksScheme.push(
                extendedBlocksObject
            )
        })
        return extendedBlocksScheme.every(isObjectEmpty) ? [] : extendedBlocksScheme;
    }

    const parseCheckboxesValue = (htmlClass:any) =>{
        let checkedLabels = document.querySelectorAll(`.${htmlClass} input:checked+label`)
        let listOfTextLabels: (string | null)[] = []
        checkedLabels.forEach((label,key)=>{
            listOfTextLabels.push(label.textContent);
        })
        return listOfTextLabels
    }

    const handleClickSubmit = async (event: any) => {
        event.preventDefault();
        if (getSessionUserRole() === "confirmed" && !acceptAgreement) {
            setAgreementError(true);
            showMessage({
                variant: MESSAGE_VARIANT.ERROR,
                text: 'Вы должны принять условия Соглашения перед отправкой формы.'
            });
            return;
        }
        let legalAddress = {
            "record_id": null,
            "street": parseValue("legal-address-street"),
            "postal_code": parseValue("legal-address-postal-code"),
            "city": parseValue("legal-address-city"),
            "region": parseValueSelect("legal-address-region"),
            "phone": parseValue("legal-address-phone"),
            "email": parseValue("legal-address-email"),
        }
        let officeAddress = {
            "record_id": null,
            "street": parseValue("office-address-postal-street"),
            "postal_code": parseValue("office-address-postal-code"),
            "city": parseValue("office-address-city"),
            "region": parseValueSelect("office-address-region"),
            "phone": parseValue("office-address-phone"),
            "email": parseValue("office-address-email"),
        }
        let purchase = {
            "record_id": null,
            "responsible_user": parseValue("purchase-responsible-user"),
            "position": parseValue("purchase-position"),
            "phone": parseValue("purchase-phone-number"),
            "fax": parseValue("purchase-fax-number"),
            "email": parseValue("purchase-email-address"),
        }
        let accountingDepartment = {
            "record_id": null,
            "responsible_user": parseValue("accounting-department-responsible"),
            "phone": parseValue("accounting-department-phone-number"),
            "email": parseValue("accounting-department-email-address"),
        }
        let scheme = {
            "name": parseValue("company-legal-name"),
            "registration_number": parseValue("company-registration-number"),
            "vat": parseValue("company-vat-number"),
            "registration_year": parseValue("company-registration-year"),
            "turnover_last_year": parseValue("company-turnover"),
            "phone": parseValue("company-phone-number"),
            "email": parseValue("company-mail-address"),
            "website": parseValue("company-website"),
            "quantity_empl": parseValue("company-quantity"),
            "legal_address": isObjectEmpty(legalAddress) ? null : legalAddress,
            "office_address": isObjectEmpty(officeAddress) ? null : officeAddress,
            "beneficial_owners": parseValueForExtendedBlocks("extended_beneficial"),
            "boards": parseValueForExtendedBlocks("company_board"),
            "banks": parseValueForExtendedBlocks("extended_bank"),
            "shippings": parseValueForExtendedBlocks("extended_shipping"),
            "purchase": isObjectEmpty(purchase) ? null : purchase,
            "buisnes_type": parseCheckboxesValue('company_business'),
            "assortements": parseCheckboxesValue('assortment_range'),
            "accounting_department": isObjectEmpty(accountingDepartment) ? null : accountingDepartment
        }
        let result = await updateQuestionaryInfoRequest(scheme)
        if (!result.error) {
            if (getSessionUserRole() === "confirmed") {
                await createNewRole()
                await changeUserRoleForQuestionaryBlock()
                await handleCheckUserRoleForBlock()
                showMessage({
                    variant: MESSAGE_VARIANT.SUCCESS,
                    text: 'Ваши данные были отправлены! Ваша учетная запись заблокирована до подтверждения.'
                })
            }
            else {
                showMessage({
                    variant: MESSAGE_VARIANT.SUCCESS,
                    text: 'Ваши данные были изменены!'
                })
            }
            await parseQuestionaryInfo();
        }
        else {
            showMessage({
                variant: MESSAGE_VARIANT.ERROR,
                text: 'При обновлении данных произошла ошибка. Обратитесь в техподдержку.'
            })
        }

    }

    const isObjectEmpty = (obj:any) => {
        for (let key in obj) {
            if (obj[key] !== null && obj[key] !== '' && obj[key] !== undefined) {
                return false;
            }
        }
        return true;
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault()
        await handleClickSubmit(event)
    }
    const handleCheckUserRoleForBlock = async () => {
        let result = await checkUserRole()
        if (!result.error) {
            let userRole = result.data.data.role
            setSessionUserRole(userRole);
        }
        if (getSessionUserRole() === "wait-approve") {
            setIsBlocked(true);
        } else {
            setIsBlocked(false);
        }
    }


    useEffect(() => {
        (async () => {
            await parseQuestionaryInfo();
        })();
    }, []);

    const parseValue = (dataKey: any) => {
        let element: any = document.querySelector(`input[data-key='${dataKey}']`)
        return element?.value
    }

    const parseValueSelect = (dataKey: any) => {
        let element: any = document.querySelector(`select[data-key='${dataKey}']`)
        return element?.value
    }

    async function parseQuestionaryInfo() {
        let result = await parseQuestionaryInfoRequest();
        if (!result.error) {
            questionaryData.current = result.data.data
            forceUpdate();
        }
        else {
            showMessage({
                variant: MESSAGE_VARIANT.ERROR,
                text: 'При обновлении данных произошла ошибка. Обратитесь в техподдержку.'
            })
        }
    }

    // @ts-ignore
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const handleInputChange = (event: any) => {
        questionaryData.current = event.target.value;
        forceUpdate();
    };
    const emptyBankDetail = {name: '', account_number: '', swift: '', record_id: 'null'};
    const emptyBeneficialOwner = {name: '', nationality: '', identity: '', record_id: 'null'};
    const emptyShippingAddress = {
        name: '',
        street: '',
        city: '',
        postal_code: '',
        region: '',
        phone: '',
        email: '',
        record_id: 'null'
    };
    const emptyCompanyBoard = {name:'',record_id:'null'}

    const renderBankDetailContent = (block: any, index: any, handleInputChange: any) => (
        <div className="extended_general_block_content extended_bank" data-record-id={block.record_id}>
            <div className="questionary__field">
                <label>Name of bank</label>
                <input
                    disabled={isBlocked}
                    data-key="bank-name"
                    type="text"
                    name="name"
                    onChange={(e) => handleInputChange(block.id, 'name', e.target.value)}
                    value={block.name}
                />
            </div>
            <div className="questionary__field">
                <label>Account Number (IBAN)</label>
                <input
                    disabled={isBlocked}
                    data-key="bank-account-number"
                    type="text"
                    name="account_number"
                    onChange={(e) => handleInputChange(block.id, 'account_number', e.target.value)}
                    value={block.account_number}
                />
            </div>
            <div className="questionary__field">
                <label>SWIFT</label>
                <input
                    disabled={isBlocked}
                    data-key="bank-swift"
                    type="text"
                    name="swift"
                    onChange={(e) => handleInputChange(block.id, 'swift', e.target.value)}
                    value={block.swift}
                />
            </div>
        </div>
    );

    const renderCompanyBoardContent = (block: any, index: any, handleInputChange: any) => (
        <div className="extended_general_block_content company_board" data-record-id={block.record_id}>
            <div className="questionary__field">
                <label>Name</label>
                <input
                    disabled={isBlocked}
                    data-key="company-board-name"
                    type="text"
                    name="name"
                    onChange={(e) => handleInputChange(block.id, 'name', e.target.value)}
                    value={block.name}
                />
            </div>
        </div>
    );

    const renderBeneficialOwnerContent = (block: any, index: any, handleInputChange: any) => (
        <div className="extended_general_block_content extended_beneficial" data-record-id={block.record_id}>
            <div className="questionary__field">
                <label>Name</label>
                <input
                    disabled={isBlocked}
                    data-key="beneficial_owner-name"
                    type="text"
                    name="name"
                    onChange={(e) => handleInputChange(block.id, 'name', e.target.value)}
                    value={block.name}/>
            </div>
            <div className="questionary__field">
                <label>Nationality</label>
                <input
                    disabled={isBlocked}
                    type="text"
                    name="nationality"
                    data-key="beneficial_owner-nationality"
                    onChange={(e) => handleInputChange(block.id, 'nationality', e.target.value)}
                    value={block.nationality}/>
            </div>
            <div className="questionary__field">
                <label>Identity number or date of birth</label>
                <input
                    disabled={isBlocked}
                    data-key="beneficial_owner-identity"
                    type="text"
                    name="identity"
                    onChange={(e) => handleInputChange(block.id, 'identity', e.target.value)}
                    value={block.identity}/>
            </div>
        </div>
    )

    const renderShippingAddressContent = (block: any, index: any, handleInputChange: any) => (
        <div className="extended_general_block_content extended_shipping" data-record-id={block.record_id}>
            <div className="questionary__field">
                <label>Warehouse name</label>
                <input
                    disabled={isBlocked}
                    data-key="shipping-name"
                    type="text"
                    name="shipping-name"
                    onChange={(e) => handleInputChange(block.id, 'name', e.target.value)}
                    value={block.name}/>
            </div>
            <div className="questionary__field">
                <label>Shipping Address</label>
                <input
                    disabled={isBlocked}
                    data-key="shipping-address"
                    type="text"
                    name="shipping-address"
                    onChange={(e) => handleInputChange(block.id, 'street', e.target.value)}
                    value={block.street}/>
            </div>
            <div className="questionary__field">
                <label>City</label>
                <input
                    disabled={isBlocked}
                    data-key="shipping-city"
                    type="text"
                    name="shipping-city"
                    onChange={(e) => handleInputChange(block.id, 'city', e.target.value)}
                    value={block.city}/>
            </div>
            <div className="questionary__field">
                <label>Postal code</label>
                <input
                    disabled={isBlocked}
                    data-key="shipping-postal-code"
                    type="text"
                    name="shipping-postal-code"
                    onChange={(e) => handleInputChange(block.id, 'postal_code', e.target.value)}
                    value={block.postal_code}/>
            </div>
            <div className="questionary__field">
                <label>Country</label>
                <select disabled={isBlocked} data-key="shipping-region" name="shipping-region" onChange={(e) => handleInputChange(block.id, 'region', e.target.value)}>
                    <option defaultValue={block.region}>{block.region}</option>
                    {Object.entries(COUNTRIES).map(([code, name]) => (
                        <option key={code} value={code}>{name}</option>
                    ))}
                </select>
            </div>
            <div className="questionary__field">
                <label>Phone of warehouse</label>
                <input
                    disabled={isBlocked}
                    data-key="shipping-phone"
                    type="text"
                    name="shipping-phone"
                    onChange={(e) => handleInputChange(block.id, 'phone', e.target.value)}
                    value={block.phone}
                />
            </div>
            <div className="questionary__field">
                <label>E-mail address of warehouse</label>
                <input
                    disabled={isBlocked}
                    data-key="shipping-email"
                    type="text"
                    name="shipping-email"
                    onChange={(e) => handleInputChange(block.id, 'email', e.target.value)}
                    value={block.email}
                />
            </div>
        </div>
    );



    const inputPropsStyles = {
        width: "100%",
        fontSize: "12px",
        '& .MuiFormLabel-root': {
            fontSize: '0.8rem',
            width: '100%',
        },
        '& .MuiOutlinedInput-input': {
            fontSize: '0.8rem',
            width: '100%',
        },
    };


    return <>
        <form className="questionary" onSubmit={handleSubmit}>
            <div className="questionary__wrapper">
                <div className={`questionary__item ${openItems.basicInfo ? 'open' : 'closed'}`}>
                    <div className="questionary__name" onClick={() => toggleItem('basicInfo')}>Company basic
                        information
                    </div>
                    <div className={`questionary__content  ${openItems.basicInfo ? "" : "d-none"}`}>
                        <div className="questionary__field">
                            <label>Company legal name</label>
                            <input disabled={isBlocked} data-key="company-legal-name" type="text" name="name"

                                   defaultValue={questionaryData.current?.name}/>
                        </div>
                        <div className="questionary__field">
                            <label>Registration number</label>
                            <input disabled={isBlocked} type="text" name="name" data-key="company-registration-number"

                                   defaultValue={questionaryData.current?.registration_number}/>
                        </div>
                        <div className="questionary__field">
                            <label>VAT number</label>
                            <input disabled={isBlocked} type="text" name="name" data-key="company-vat-number"

                                   defaultValue={questionaryData.current?.vat}/>
                        </div>
                        <div className="questionary__field">
                            <label>Company registration year</label>
                            <input disabled={isBlocked} type="text" name="name" data-key="company-registration-year"

                                   defaultValue={questionaryData.current?.registration_year}/>
                        </div>
                        <div className="questionary__field">
                            <label>Turnover in last year</label>
                            <input disabled={isBlocked} type="text" name="name" data-key="company-turnover"

                                   defaultValue={questionaryData.current?.turnover_last_year}/>
                        </div>
                        <div className="questionary__field">
                            <label>Phone Number</label>
                            <input disabled={isBlocked} type="text" name="name" data-key="company-phone-number"

                                   defaultValue={questionaryData.current?.phone}/>
                        </div>

                        <div className="questionary__field">
                            <label>E-mail address</label>
                            <input disabled={isBlocked} type="text" name="name" data-key="company-mail-address"

                                   defaultValue={questionaryData.current?.email}/>
                        </div>
                        <div className="questionary__field">
                            <label>Website</label>
                            <input disabled={isBlocked} type="text" name="name" data-key="company-website"

                                   defaultValue={questionaryData.current?.website}/>
                        </div>
                        <div className="questionary__field">
                            <label>Quantity of employees</label>
                            <input disabled={isBlocked} type="text" name="name" data-key="company-quantity"

                                   defaultValue={questionaryData.current?.quantity_empl}/>
                        </div>
                    </div>
                </div>
                <div className={`questionary__item ${openItems.companyBoard ? 'open' : 'closed'}`}>
                    <div className="questionary__name" onClick={() => toggleItem('companyBoard')}>Company board
                    </div>
                    <div className={`questionary__content ${openItems.companyBoard ? "" : "d-none"}`}>
                        {questionaryData.current?.boards.length === 0 &&
                            <ExpandableBlock renderContent={renderCompanyBoardContent}
                                             initialData={[emptyCompanyBoard]}
                                             emptyBlock={emptyCompanyBoard}/>}
                        {questionaryData.current?.boards.length >= 1 &&
                            <ExpandableBlock renderContent={renderCompanyBoardContent}
                                             initialData={questionaryData.current?.boards}
                                             emptyBlock={emptyCompanyBoard}/>}
                    </div>
                </div>
                <div className={`questionary__item ${openItems.beneficialOwner ? 'open' : 'closed'}`}>
                    <div className="questionary__name" onClick={() => toggleItem('beneficialOwner')}>Details of the
                        beneficial owner
                    </div>
                    <div className={`questionary__content ${openItems.beneficialOwner ? "" : "d-none"}`}>
                        {questionaryData.current?.beneficial_owners.length === 0 &&
                            <ExpandableBlock renderContent={renderBeneficialOwnerContent}
                                             initialData={[emptyBeneficialOwner]} emptyBlock={emptyBeneficialOwner}/>}
                        {questionaryData.current?.beneficial_owners.length >= 1 &&
                            <ExpandableBlock renderContent={renderBeneficialOwnerContent}
                                             initialData={questionaryData.current?.beneficial_owners}
                                             emptyBlock={emptyBeneficialOwner}/>}
                    </div>
                </div>
                <div className={`questionary__item ${openItems.legalAddress ? 'open' : 'closed'}`}>
                    <div className="questionary__name" onClick={() => toggleItem('legalAddress')}>Legal address</div>
                    <div className={`questionary__content ${openItems.legalAddress ? "" : "d-none"}`}>
                        <div className="questionary__field">
                            <label>Legal address</label>
                            <input disabled={isBlocked} data-key="legal-address-street" type="text" name="name"

                                   defaultValue={questionaryData.current?.legal_address?.street}/>
                        </div>
                        <div className="questionary__field">
                            <label>City</label>
                            <input disabled={isBlocked} data-key="legal-address-city" type="text" name="name"

                                   defaultValue={questionaryData.current?.legal_address?.city}/>
                        </div>
                        <div className="questionary__field">
                            <label>Postal code</label>
                            <input disabled={isBlocked} data-key="legal-address-postal-code" type="text" name="name"

                                   defaultValue={questionaryData.current?.legal_address?.postal_code}/>
                        </div>
                        <div className="questionary__field">
                            <label>Country</label>
                            <select disabled={isBlocked} data-key="legal-address-region" name="name">
                                <option
                                    defaultValue={questionaryData.current?.legal_address?.region}>{questionaryData.current?.legal_address?.region}</option>
                                {Object.entries(COUNTRIES).map(([code, name]) => (
                                    <option key={code} value={code}>{name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="questionary__field">
                            <label>Phone Number</label>
                            <input disabled={isBlocked} data-key="legal-address-phone" type="text" name="name"

                                   defaultValue={questionaryData.current?.legal_address?.phone}/>
                        </div>
                        <div className="questionary__field">
                            <label>E-mail address</label>
                            <input disabled={isBlocked} data-key="legal-address-email" type="text" name="name"

                                   defaultValue={questionaryData.current?.legal_address?.email}/>
                        </div>
                    </div>
                </div>
                <div className={`questionary__item ${openItems.officeAddress ? 'open' : 'closed'}`}>
                    <div className="questionary__name" onClick={() => toggleItem('officeAddress')}>Office address</div>
                    <div className={`questionary__content ${openItems.officeAddress ? "" : "d-none"}`}>
                        <div className="questionary__field">
                            <label>Office address</label>
                            <input disabled={isBlocked} data-key="office-address-postal-street" type="text" name="name"

                                   defaultValue={questionaryData.current?.office_address?.street}/>
                        </div>
                        <div className="questionary__field">
                            <label>City</label>
                            <input disabled={isBlocked} data-key="office-address-city" type="text" name="name"

                                   defaultValue={questionaryData.current?.office_address?.city}/>
                        </div>
                        <div className="questionary__field">
                            <label>Postal code</label>
                            <input disabled={isBlocked} data-key="office-address-postal-code" type="text" name="name"

                                   defaultValue={questionaryData.current?.office_address?.postal_code}/>
                        </div>
                        <div className="questionary__field">
                            <label>Country</label>
                            <select disabled={isBlocked} data-key="office-address-region" name="name">
                                <option
                                    defaultValue={questionaryData.current?.office_address?.region}>{questionaryData.current?.office_address?.region}</option>
                                {Object.entries(COUNTRIES).map(([code, name]) => (
                                    <option key={code} value={code}>{name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="questionary__field">
                            <label>Phone Number</label>
                            <input disabled={isBlocked} data-key="office-address-phone" type="text" name="name"

                                   defaultValue={questionaryData.current?.office_address?.phone}/>
                        </div>
                        <div className="questionary__field">
                            <label>E-mail address</label>
                            <input disabled={isBlocked} data-key="office-address-email" type="text" name="name"

                                   defaultValue={questionaryData.current?.office_address?.email}/>
                        </div>
                    </div>
                </div>
                <div className={`questionary__item ${openItems.bankDetails ? 'open' : 'closed'}`}>
                    <div className="questionary__name" onClick={() => toggleItem('bankDetails')}>Bank details</div>
                    <div className={`questionary__content ${openItems.bankDetails ? "" : "d-none"}`}>
                        {questionaryData.current?.banks.length === 0 &&
                            <ExpandableBlock renderContent={renderBankDetailContent} initialData={[emptyBankDetail]}
                                             emptyBlock={emptyBankDetail}/>}
                        {questionaryData.current?.banks.length >= 1 &&
                            <ExpandableBlock renderContent={renderBankDetailContent}
                                             initialData={questionaryData.current?.banks}
                                             emptyBlock={emptyBankDetail}/>}
                    </div>
                </div>
                <div className={`questionary__item ${openItems.shippingAddress ? 'open' : 'closed'}`}>
                    <div className="questionary__name" onClick={() => toggleItem('shippingAddress')}>Shipping address
                    </div>
                    <div className={`questionary__content ${openItems.shippingAddress ? "" : "d-none"}`}>
                        {questionaryData.current?.shippings.length === 0 &&
                            <ExpandableBlock renderContent={renderShippingAddressContent}
                                             initialData={[emptyShippingAddress]}
                                             emptyBlock={emptyShippingAddress}/>}
                        {questionaryData.current?.shippings.length >= 1 &&
                            <ExpandableBlock renderContent={renderShippingAddressContent}
                                             initialData={questionaryData.current?.shippings}
                                             emptyBlock={emptyShippingAddress}/>}
                    </div>
                </div>
                <div className={`questionary__item ${openItems.purchasingDepartment ? 'open' : 'closed'}`}>
                    <div className="questionary__name" onClick={() => toggleItem('purchasingDepartment')}>Purchasing
                        Department
                    </div>
                    <div className={`questionary__content ${openItems.purchasingDepartment ? "" : "d-none"}`}>
                        <div className="questionary__field">
                            <label>Person responsible for ordering</label>
                            <input disabled={isBlocked} data-key="purchase-responsible-user" type="text" name="name"

                                   defaultValue={questionaryData.current?.purchase?.responsible_user}/>
                        </div>
                        <div className="questionary__field">
                            <label>Position</label>
                            <input disabled={isBlocked} data-key="purchase-position" type="text" name="name"

                                   defaultValue={questionaryData.current?.purchase?.position}/>
                        </div>
                        <div className="questionary__field">
                            <label>Phone Number</label>
                            <input disabled={isBlocked} data-key="purchase-phone-number" type="text" name="name"

                                   defaultValue={questionaryData.current?.purchase?.phone}/>
                        </div>
                        <div className="questionary__field">
                            <label>Fax Number</label>
                            <input disabled={isBlocked} data-key="purchase-fax-number" type="text" name="name"

                                   defaultValue={questionaryData.current?.purchase?.fax}/>
                        </div>
                        <div className="questionary__field">
                            <label>E-mail address</label>
                            <input disabled={isBlocked} data-key="purchase-email-address" type="text" name="name"

                                   defaultValue={questionaryData.current?.purchase?.email}/>
                        </div>
                    </div>
                </div>
                <div className={`questionary__item ${openItems.companyOrientation ? 'open' : 'closed'}`}>
                    <div className="questionary__name" onClick={() => toggleItem('companyOrientation')}>Company business
                        orientation (Please put X in category you
                        are
                        interested in)
                    </div>
                    <div className={`questionary__content ${openItems.companyOrientation ? "" : "d-none"}`}>
                        <div className="questionary__checkboxes company_business">
                            <span className="checkbox-span">Company Business type </span>
                            <div className="questionary__field questionary--checkbox">
                                <input

                                    defaultChecked={questionaryData.current?.buisnes_type.includes("Wholesale")}
                                    disabled={isBlocked}
                                    type="checkbox"
                                    id="whosale"/>
                                <label htmlFor="whosale">Wholesale</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input defaultChecked={questionaryData.current?.buisnes_type.includes("Retail")}
                                       disabled={isBlocked} type="checkbox" id="retail"/>
                                <label htmlFor="retail">Retail</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input defaultChecked={questionaryData.current?.buisnes_type.includes("Distributor")}
                                       disabled={isBlocked} type="checkbox" id="distributor"/>
                                <label htmlFor="distributor">Distributor</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input defaultChecked={questionaryData.current?.buisnes_type.includes("Trader")}
                                       disabled={isBlocked} type="checkbox" id="trader"/>
                                <label htmlFor="trader">Trader</label>
                            </div>
                        </div>
                        <div className="questionary__checkboxes assortment_range">
                            <span className="checkbox-span">Assortment (range)</span>
                            <div className="questionary__field questionary--checkbox">
                                <input defaultChecked={questionaryData.current?.assortements.includes("CE")}
                                       disabled={isBlocked} type="checkbox" id="ce"/>
                                <label htmlFor="ce">CE</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input defaultChecked={questionaryData.current?.assortements.includes("FS")}
                                       disabled={isBlocked} type="checkbox" id="fs"/>
                                <label htmlFor="fs">FS</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input defaultChecked={questionaryData.current?.assortements.includes("PHOTO")}
                                       disabled={isBlocked} type="checkbox" id="photo"/>
                                <label htmlFor="photo">PHOTO</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input defaultChecked={questionaryData.current?.assortements.includes("GSM")}
                                       disabled={isBlocked} type="checkbox" id="gsm"/>
                                <label htmlFor="gsm">GSM</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input defaultChecked={questionaryData.current?.assortements.includes("BI")}
                                       disabled={isBlocked} type="checkbox" id="bi"/>
                                <label htmlFor="bi">BI</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input defaultChecked={questionaryData.current?.assortements.includes("SDA")}
                                       disabled={isBlocked} type="checkbox" id="sda"/>
                                <label htmlFor="sda">SDA</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input defaultChecked={questionaryData.current?.assortements.includes("IT")}
                                       disabled={isBlocked} type="checkbox" id="it"/>
                                <label htmlFor="it">IT</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input defaultChecked={questionaryData.current?.assortements.includes("NAVI")}
                                       disabled={isBlocked} type="checkbox" id="navi"/>
                                <label htmlFor="navi">NAVI</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`questionary__item ${openItems.accountingDepartment ? 'open' : 'closed'}`}>
                    <div className="questionary__name" onClick={() => toggleItem('accountingDepartment')}>Information
                        about Accounting Department
                    </div>
                    <div className={`questionary__content ${openItems.accountingDepartment ? "" : "d-none"}`}>
                        <div className="questionary__field">
                            <label>Person responsible for payment / accounting information</label>
                            <input disabled={isBlocked} data-key="accounting-department-responsible" type="text"
                                   name="name"

                                   defaultValue={questionaryData.current?.accounting_department?.responsible_user}/>
                        </div>
                        <div className="questionary__field">
                            <label>Phone Number</label>
                            <input disabled={isBlocked} data-key="accounting-department-phone-number" type="text"
                                   name="name"

                                   defaultValue={questionaryData.current?.accounting_department?.phone}/>
                        </div>
                        <div className="questionary__field">
                            <label>E-mail address</label>
                            <input disabled={isBlocked} data-key="accounting-department-email-address" type="text"
                                   name="name"

                                   defaultValue={questionaryData.current?.accounting_department?.email}/>
                        </div>
                    </div>
                </div>
                {getSessionUserRole() === 'confirmed' && (
                    <div className="agreement-checkbox">
                        <input
                            type="checkbox"
                            id="agreement-checkbox"
                            checked={acceptAgreement}
                            onChange={() => {
                                setAcceptAgreement(!acceptAgreement);
                                setAgreementError(false);
                            }}
                            style={{borderColor: agreementError ? 'red' : undefined}}
                        />
                        <label htmlFor="agreement-checkbox" style={{color: agreementError ? 'red' : undefined}}>
                            Принимаю условия <a href="/terms-service" target="_blank">Соглашения</a>
                        </label>
                    </div>
                )}
                <button type="submit" className="btn-submit btn-questionary" disabled={isBlocked}>
                    {isBlocked ? 'Заблокировано' : 'Отправить'}
                </button>
            </div>
        </form>
        {message && <Message children={message.text} variant={message.variant} hideMessage={hideMessage}/>}
    </>
}