// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product__wrapper .product__image-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
}
.product__wrapper .product__image {
  object-fit: scale-down;
  object-position: center;
  height: 500px;
  width: 500px;
  margin-bottom: 5px;
}
.product__wrapper .prductSwiper {
  width: 100%;
  height: 100%;
}
.product__wrapper .prductSwiper .swiper-slide {
  height: 50px;
  width: 50px;
  padding: 2px;
}
.product__wrapper .prductSwiper .swiper-slide img {
  object-fit: scale-down;
  width: 100%;
  height: 100%;
}
.product__wrapper .prductSwiper .swiper-slide-active {
  border: 1px solid #a9a9a9;
  border-radius: 10px;
}
.product__wrapper .prductSwiper .swiper-wrapper {
  width: auto;
  padding: 0 40px;
}
.product__wrapper .prductSwiper .swiper-button-prev:after, .product__wrapper .prductSwiper .swiper-button-next:after {
  font-size: 10px !important;
  color: black;
  background-color: white;
  border-radius: 50%;
  padding: 10px 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/swiperProduct/SiwperProduct.sass"],"names":[],"mappings":"AAEE;EACE,aAAA;EACA,uBAAA;EACA,qBAAA;AADJ;AAGE;EACE,sBAAA;EACA,uBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;AADJ;AAGE;EACE,WAAA;EACA,YAAA;AADJ;AAGI;EACE,YAAA;EACA,WAAA;EACA,YAAA;AADN;AAGM;EACE,sBAAA;EACA,WAAA;EACA,YAAA;AADR;AAGI;EACE,yBAAA;EACA,mBAAA;AADN;AAGI;EACE,WAAA;EACA,eAAA;AADN;AAGI;EACE,0BAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;AADN","sourcesContent":[".product__wrapper\n\n  .product__image-wrapper\n    display: flex\n    justify-content: center\n    align-content: center\n\n  .product__image\n    object-fit: scale-down\n    object-position: center\n    height: 500px\n    width: 500px\n    margin-bottom: 5px\n\n  .prductSwiper\n    width: 100%\n    height: 100%\n\n    .swiper-slide\n      height: 50px\n      width: 50px\n      padding: 2px\n\n      img\n        object-fit: scale-down\n        width: 100%\n        height: 100%\n\n    .swiper-slide-active\n      border: 1px solid #a9a9a9\n      border-radius: 10px\n\n    .swiper-wrapper\n      width: auto\n      padding: 0 40px\n\n    .swiper-button-prev:after, .swiper-button-next:after\n      font-size: 10px!important\n      color: black\n      background-color: white\n      border-radius: 50%\n      padding: 10px 12px\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
