import {ControlButton} from "../../controlButton/controlButton";
import React from "react";
import {NewWarrantyRequestSubmitForm} from "../newWarrantyRequestSubmitForm/NewWarrantyRequestSubmitForm";
import {NewWarrantyRequestSearchForm} from "../newWarrantyRequestSearchForm/NewWarrantyRequestSearchForm";
import {Trans} from "react-i18next";


export const NewWarrantyRequest = () => {


    return (
        <>
            <section className={"new_request__section"}>
                <div className="new_request__wrapper">
                    <ControlButton className="favIcon text-colored RMA__btn btn btn-sm"
                                   icon="bi bi-download RMA__icon"
                                   label={
                                       <div className="RMA__label">
                                           <Trans i18nKey="rma_packaging_instructions"/>
                                       </div>

                                   }
                                   controlButtonHandler={() => {
                                   }}/>

                </div>
            </section>
            <section className={"new_request__section"}>
                <NewWarrantyRequestSubmitForm/>
            </section>
            <section className={"new_request__section"}>
                <NewWarrantyRequestSearchForm/>
            </section>
        </>
    )
}