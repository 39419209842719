import {useAppSelector} from "./ReduxHooks";

export const useGetProductImages = () => {

    const {product} = useAppSelector(state => state.product)
    if (!product) return []
    const productImages: string[] = [product.image_1920];
    product.product_template_image_ids.forEach((item: { image_1920: string; }) => {
        productImages.push(item.image_1920);
    });
    product.product_variant_image_ids.forEach((item: { image_1920: string; }) => {
        productImages.push(item.image_1920);
    });

    return productImages
};