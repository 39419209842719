import {useState} from "react";

interface DetailedDescriptionPropsValues {
    isCollapsed?: boolean;
    value?: string
}

export const DetailedDescription = (props: DetailedDescriptionPropsValues) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const ToggleColalapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className="buttonText d-flex justify-content-between">
            <p style={{flex: "1 0 90%"}} className={`small-text ${isCollapsed ? "hidden-text" : "open-text"}`}>
                {props.value || ""}
            </p>
            <button type="button" className="btn" onClick={ToggleColalapse}><i
                className={`bi ${isCollapsed ? "bi-plus-circle" : "bi-dash-circle"}`}></i>
            </button>
        </div>
    )
}