import { useState, ChangeEvent, useEffect } from 'react';

interface FormValues {
    pickupAddress: string;
    deliveryAddress: string;
    totalLDM: string;
    totalWeight: string;
    shippingDate: string;
    deliveryDate: string;
    cargo: {
        [key: string]: {
            quantity: string;
            dimensions: string;
        };
    };
}

interface UseDeliveryFormReturnValues {
    values: FormValues;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: VoidFunction;
    resetForm: VoidFunction;
}

export const useDeliveryForm = (): UseDeliveryFormReturnValues => {
    const [form, setForm] = useState<FormValues>({
        pickupAddress: 'Emīlijas Benjamiņas iela 11, Rīga, 1050, Latvija',
        deliveryAddress: 'Türi 7, Tallinn, 11314, Igaunija',
        totalLDM: '1,74',
        totalWeight: '4 300,00',
        shippingDate: 'с 19.03.2025 по 20.03.2025',
        deliveryDate: '25.03.2025',
        cargo: {
            '0': { quantity: '3', dimensions: '1,2 X 0,8 X 2,65' },
            '1': { quantity: '1', dimensions: '1,7 X 0,8 X 2,65' }
        }
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (name.startsWith('cargo.')) {
            const [, index, field] = name.split('.');
            setForm(prev => ({
                ...prev,
                cargo: {
                    ...prev.cargo,
                    [index]: {
                        ...prev.cargo[index],
                        [field]: value
                    }
                }
            }));
        } else {
            setForm(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleSubmit = () => {
        console.log('📤 Отправка:', form);
    };

    const resetForm = () => {
        setForm({
            pickupAddress: '',
            deliveryAddress: '',
            totalLDM: '',
            totalWeight: '',
            shippingDate: '',
            deliveryDate: '',
            cargo: {
                '0': { quantity: '', dimensions: '' },
                '1': { quantity: '', dimensions: '' }
            }
        });
    };

    return {
        values: form,
        handleChange,
        handleSubmit,
        resetForm
    };
};
