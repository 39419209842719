import {Navigation, Pagination, Scrollbar, A11y, Autoplay} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";


import "./Swiper.sass"
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {useEffect, useState} from "react";
import {getHomeBanners} from "../../api/homeBanners";

export const HomeSwiper = () => {

    const [banners,setBanners] = useState<any>([]);

    useEffect(() => {
        const fetchBanners = async () => {
            const result = await getHomeBanners();
            if (!result.error) {
                setBanners(result.data.data.data);
            }
        };
        fetchBanners();
    }, []);

    return (
        <>
            {banners.length !==0 && <div className="wrapper">
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={50}
                    slidesPerView={1}
                    effect="fade"
                    loop={true}
                    navigation={true}
                    autoplay={{delay: 3000, disableOnInteraction: false}}
                    pagination={{clickable: true}}
                    className={"homePageSwiper"}
                >
                    {banners.map((i: any, el: any) => {
                        return <SwiperSlide key={el}>
                            <picture>
                                <img
                                    src={i.image_url}
                                    alt={i.name}
                                />
                            </picture>
                        </SwiperSlide>
                    })}
                </Swiper>
            </div>}
        </>

    );
};