export const systemBlue = '#006AFF';
export const systemWhite = '#FFFFFF';
export const systemBlack = '#333333';
export const systemGreyLight = '#EDEEF0';

export const DEFAULT_HEADER_HEIGHT = '68px';

export const MAX_ROWS = 3;
export const MOBILE_MAX_ROWS = 4;
export const MOBILE_CATEGORIES_MAX_ROWS = 7;
export const WIDTH_DIFFERENCE = 80;
export const MOBILE_WIDTH_DIFFERENCE = 32;
