import {useState} from "react";

interface DetailedDescriptionPropsValues {
    isCollapsed?: boolean;
}

export const DetailedDescription = (props: DetailedDescriptionPropsValues) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const ToggleColalapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className="buttonText d-flex justify-content-between">
            <p style={{flex: "1 0 90%"}} className={`small-text ${isCollapsed ? "hidden-text" : "open-text"}`}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                been the industry"s standard dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book. It has survived not only five
                centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                passages, and more recently with desktop publishing software like Aldus PageMaker including
                versions of Lorem Ipsum.
                Why do we use it
                It is a long established fact that a reader will be distracted by the readable content of a
                p
            </p>
            <button type="button" className="btn" onClick={ToggleColalapse}><i
                className={`bi ${isCollapsed ? "bi-plus-circle" : "bi-dash-circle"}`}></i>
            </button>
        </div>
    )
}