import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface OrderDeliveryState {
    customerName: string;
    phone: string;
    email: string;
    city: string;
    postalCode: string;
    address: string;
    comment: string;
    selectedCountry?: number;
    selectedAddress?: number;
    isDropshipping: boolean;
    selectedType?: string;
}

const initialState: OrderDeliveryState = {
    customerName: '',
    phone: '',
    email: '',
    city: '',
    postalCode: '',
    address: '',
    comment: '',
    selectedCountry: undefined,
    selectedAddress: undefined,
    isDropshipping: false,
    selectedType: 'company',
};

const orderShippingSlice = createSlice({
    name: 'orderShipping',
    initialState,
    reducers: {
        setOrderData(state, action: PayloadAction<{ is_dropshipping: boolean | undefined }>) {
            if (action.payload.is_dropshipping)
                state.isDropshipping = action.payload.is_dropshipping;
        },
        setCustomerInfo(state, action: PayloadAction<Partial<OrderDeliveryState>>) {
            return {...state, ...action.payload};
        },
        toggleDropshipping(state, action: PayloadAction<boolean>) {
            state.isDropshipping = action.payload;
        },
        resetCustomerInfo(state) {
            return {
                ...state,
                customerName: '',
                phone: '',
                email: '',
                city: '',
                postalCode: '',
                address: '',
                comment: '',
                selectedCountry: undefined,
                selectedAddress: undefined,
                selectedType: 'company',
            };
        },
        resetOrderShipping(state) {
            return initialState;
        }
    },
});

export const {resetOrderShipping, setCustomerInfo, toggleDropshipping, resetCustomerInfo, setOrderData} = orderShippingSlice.actions;
export default orderShippingSlice.reducer;
