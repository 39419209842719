import styled from "styled-components";
import { motion } from "framer-motion";
import { systemBlack, systemBlue, systemWhite } from "../../../constants";

export const Container = styled.header<{ $showButtons: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: ${({ $showButtons }) => ($showButtons ? 'center' : 'space-between;')};
    align-items: center;
    padding: 16px;
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
`;

export const Image = styled.img`
    width: 167px;
    height: 40px;
`;

export const MenuButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`

export const MenuOverlay = styled(motion.div)`
    position: fixed;
    top: 0;
    right: 0;
    width: 80%;
    height: 100%;
    background: white;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const CloseButton = styled.button`
    background: none;
    border: none;
    align-self: flex-end;
    cursor: pointer;
`;

export const NavLinks = styled.nav`
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 18px;
`;

export const NavItem = styled.div`
    cursor: pointer;
    padding: 8px 0;
    font: 600 18px/22px "Roboto", sans-serif;
    color: ${systemBlack};
`;

export const LangSwitcher = styled.div`
    margin-top: auto;
    display: flex;
`;

export const Lang = styled.div<{ $isActive: boolean }>`
    cursor: pointer;
    padding: 10px;
    border-radius: 4px;
    color: ${({ $isActive }) => ($isActive ? systemBlack : '#90989F')};
    background-color: ${({ $isActive }) => ($isActive ? '#F5F7FA' : systemWhite)};
    font: 400 16px/12px "Roboto", sans-serif;
    text-align: center;

`;

export const Icon = styled.i`
    font-size: 24px;
    color: ${systemBlue};
`;
