import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {ButtonCustom} from "../auth/styles";
import TextField from "@mui/material/TextField";
import {Trans} from "react-i18next";

import "./RecoverPassword.sass"
import {ROUTE_URLS} from "../../constants/consts";
import Swal from "sweetalert2";
import {t} from "i18next";
import {initRecoverPassword} from "../../api/userPasswordReset";

const RecoverPassword: React.FC = () => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const optionsForAlertSuccess: any = {
        title: "Проверьте ваш e-mail",
        text: "На указанную вами почту отправлено письмо с инструкцией по восстановлению пароля. Если вы не получили письмо, то проверьте спам.",
        icon: "success",
        confirmButtonText: t("confirmButton"),
        confirmButtonColor: "#0ebfff",
    };

    const optionsForAlertWithInvalidCred: any = {
        title: 'Такой пользователь не зарегистрирован',
        text: 'Попробуйте изменить почту или зарегистрироваться',
        icon: 'error',
        confirmButtonText: 'ОК',
        confirmButtonColor: '#0ebfff'
    }


    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            const data = {
               "email": code,
            }
            const response = await initRecoverPassword(JSON.stringify(data));

            if (response.data.data) {
                navigate(ROUTE_URLS.SIGN_IN);
                Swal.fire(optionsForAlertSuccess).then(() => {
                });

            } else {
                Swal.fire(optionsForAlertWithInvalidCred).then(() => {
                });
            }
            navigate(ROUTE_URLS.SIGN_IN);
            Swal.fire(optionsForAlertSuccess).then(() => {
            });
        } catch (error) {
            Swal.fire(optionsForAlertWithInvalidCred).then(() => {
            });
        }
    };

    const textFieldStyles = {
        transition: 'width 0.3s ease-in-out',
        width: '100%',
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            backgroundColor: '#ebebeb',
            padding: "0 10px",
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                border: 'none',
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        }
    }

    const inputPropsStyles = {
        '&::placeholder': {
            color: '#757575',
            fontWeight: 'bold',
        },
        '& .MuiInputBase-input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px #ebebeb inset',
            WebkitTextFillColor: '#000000',
            transition: 'background-color 5000s ease-in-out 0s',
        },
        '& .MuiInputBase-input': {
            color: '#000000',
            fontWeight: 'bold',
        },
    };

    const inputLabelPropsStyles = {
        color: '#757575',
        fontWeight: 'bold',
        '&.Mui-focused': {
            color: '#757575',
        },
    };

    return (
        <div>
            <form className={"recover_form"} onSubmit={handleSubmit}>
                <p className={"help_text"}>
                    Для восстановления доступа укажите e-mail привязанный к вашему профилю.
                    После нажатия на кнопку отправить вам на почту придут инструкции по восстановлению пароля
                </p>
                <TextField
                    value={code}
                    required
                    type="code"
                    className={"recover_input"}
                    label="Почта для восстановления"
                    onChange={(e) => setCode(e.target.value)}
                    InputProps={{
                        sx: inputPropsStyles,
                    }}
                    InputLabelProps={{
                        sx: inputLabelPropsStyles,
                    }}
                    sx={textFieldStyles}
                    autoComplete="off"
                    style={{marginBottom: "15px"}}
                />
                <ButtonCustom type="submit">
                    Отправить
                </ButtonCustom>
                <div className={"go_home_block"}>
                    <Link to="/">
                        <Trans i18nKey="go_to_home"/>
                    </Link>
                </div>
            </form>
            {error && <p>{error}</p>}
        </div>
    );
};

export default RecoverPassword;
