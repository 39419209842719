import "./NotificationAlert.sass"

export const NotificationAlert = () => {
    return (
        <div className="notification-alert alert alert-warning">
            <div className="notification-alert__icon">
                <i className="bi bi-exclamation-triangle"></i>
            </div>
            <div className="notification-alert__message">
                <span>Содержимое корзины удаляется каждый день в 24:00</span>
                <small>Чтобы сохранить список продуктов воспользуйтесь опцией "Сохранить".</small>
            </div>
        </div>
    )

}