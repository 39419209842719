import axios from "axios";
import { resolve } from "./resolve.js";
import {API_BACKEND_URL, DATE_RANGE} from "../constants/consts";
import {getSession} from "./sessionManagement";
import {Dayjs} from "dayjs";
import {getExchangeHeaders} from "./helpers";
import {fetchOrdersLinesParamsType} from "../store/slices/ReservationLinesReducerSlice";

type fetchOrdersRequestsProps = {
    pageNum: number,
    searchValue?: string,
    activeFilters?: string[],
    isReserve?: boolean,
    isDaily?: boolean,
    isOwn?: boolean,
    isActive?: boolean,
    isPreOrder?: boolean
}

export async function fetchOrdersRequests(vals:fetchOrdersRequestsProps ) {
    const {
        pageNum,
        searchValue,
        activeFilters,
        isReserve = false,
        isDaily = false,
        isActive,
        isPreOrder,
        isOwn,
    } = vals
    const filters = {
        fields: activeFilters ? activeFilters.join(",") : "",
        term: searchValue,
    };

    const searchUrlPart = searchValue ? `&filters=[${encodeURIComponent(JSON.stringify(filters))}]` : "";

    let additionalParams = `&is_reserve=${isReserve || false}&is_daily=${isDaily || false}`
    if (isActive !== undefined) {
        additionalParams += `&is_active=${isActive || false}`
    }
    if (isOwn !== undefined) {
        additionalParams += `&is_own=${isOwn || false}`
    }
    if (isPreOrder !== undefined) {
        additionalParams += `&is_pre_order=${isPreOrder || false}`
    }
    const URL = `${API_BACKEND_URL}/sales/page/${pageNum}?${searchUrlPart || ""}${additionalParams}`
    return await resolve(axios.get(URL,
            {
                headers: getExchangeHeaders(),
            }
        ).then(res => res)
    );
}

export async function fetchOrdersHistory(params: { groupby: DATE_RANGE; dateFrom: Dayjs | null; dateTo: Dayjs | null; }) {
    const {groupby, dateFrom, dateTo} = params
    const sessionId = getSession()
    let data = {
        groupby: groupby || "day",
    }
    if (dateFrom && dateTo && dateFrom.isValid() && dateTo.isValid()) {
        Object.assign(data, {
            date_from: dateFrom.format('DD/MM/YYYY'),
            date_to: dateTo.format('DD/MM/YYYY'),
        })
    }
    const URL = `${API_BACKEND_URL}/sales/get_sale_history`
    return await resolve(axios.post(URL,
        data,
        {
            headers: getExchangeHeaders(),
        }
    ).then(res => res)
    );
}

export async function fetchTopSaleItems() {
    const URL = `${API_BACKEND_URL}/sales/get_top_sale_items`
    return await resolve(axios.post(URL,
        {},
        {
            headers: getExchangeHeaders(),
        }
    ).then(res => res)
    );
}

export async function updateOrderLines(data: { id_order: number; line_data: { product_id: number; quantity: any;requested_qty?:any}[] | { product_id: any; quantity: any; }[] | { id: number; quantity: number; requested_qty?: number | undefined; }[]; }){
    if (!data) return
    return await resolve(axios.post(`${API_BACKEND_URL}/sales/update_lines`,
        data,{
            headers: getExchangeHeaders(),
        }

    ).then(res => res));
}

export async function deleteOrderLines(data: { ids: any[] | number[]; }){
    if (!data) return
    return await resolve(axios.post(`${API_BACKEND_URL}/sales/delete_lines`,
        data,{
            headers: getExchangeHeaders(),
        }

    ).then(res => res));
}

export async function fetchUpdateOrder(saleId: number, data: {
    state?: string;
    is_b2b_active?: boolean;
    reservation_name?: any;
    customer_name?: string;
    phone?: string;
    email?: string;
    city?: string;
    postal_code?: string;
    address?: string;
    countryId?: number;
    addressId?: number;
    isDropshipping?: boolean;
    comment?: string;
    company_type?: string;
}){
    if (!data) return
    return await resolve(axios.patch(`${API_BACKEND_URL}/sales/update_order?sale_id=${saleId}`,
        data,{
            headers: getExchangeHeaders(),
        }

    ).then(res => res));
}


export async function fetchSingleOrderRequests(saleId: number | undefined) {
    const URL = `${API_BACKEND_URL}/sales/${saleId}`
    return await resolve(axios.get(URL,
            {
                headers: getExchangeHeaders(),
            }
        ).then(res => res)
    );
}

export async function fetchReturnOrderLines(data: any) {
    const URL = `${API_BACKEND_URL}/sales/get_lines`
    return await resolve(axios.post(URL,
            data,{
                headers: getExchangeHeaders(),
            }
        ).then(res => res)
    );
}

export async function fetchOrderLinesPaginated(values: fetchOrdersLinesParamsType) {
    const {
                pageNum,
                isReserve,
                isPreOrder = false,
                isOwn,
                searchValue,
                activeFilters,
                operator,
            } = values
    let params: {
        is_reserve: boolean,
        is_pre_order: boolean,
        is_own?: boolean,
        filters?: string,
    } = {
        "is_reserve": isReserve,
        "is_pre_order": isPreOrder,
    }
    if (isOwn) {
        params["is_own"] = isOwn
    }
    if (searchValue && activeFilters) {
        let filter: any = {
            fields: activeFilters ? activeFilters.join(",") : "",
            term: searchValue,
        };
        if (operator) {
            filter["operator"] = operator
        }
        params["filters"] = JSON.stringify([filter])
    }

    const URL = `${API_BACKEND_URL}/sales/sale_lines/page/${pageNum}`
    return await resolve(axios.get(URL,
        {
            headers: getExchangeHeaders(),
            params: params,
        },
    ).then(res => res)
    );
}
