import {FC} from "react";
import { TabItemProps } from "./TabsProps";

export const TabItem: FC<TabItemProps> = ({label, children, className = ""}) => {
    return (
        <div
            className={`tab-panel ${className}`}
            role="tabpanel"
            aria-labelledby={`tab-${(label)}`}
            id={`panel-${(label)}`}
        >
            {children}
        </div>
    )

}

