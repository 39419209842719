import {configureStore} from "@reduxjs/toolkit";
import userReducer from "./slices/UserSlice";
import breadcrumbsReducer from "./slices/BreadcrumbSlice";
import previewProductReducer from "./slices/PreviewProductSlice";
import productItemSliceReducer from "./slices/ProductReducerSlice"
import catalogProductsSliceReducer from "./slices/CatalogProductsReducerSlice"
import {thunk} from "redux-thunk";
import pagePaginationReducer from "./slices/PagePaginagionSlice";
import ordersReducer from "./slices/OrdersReducerSlice";
import orderReducer from "./slices/OrderReducerSlice";
import activeCartReducer from "./slices/ActiveCartReducerSlice";
import finTermsReducer from "./slices/FinTermsSlice";
import newReturnReducer from "./slices/NewReturnSlice";
import newWarrantyReducer from "./slices/NewWarrantySlice";
import returnsReducer from "./slices/ReturnsReducerSlice";
import returnReducer from "./slices/ReturnReducerSlice";
import creditLimitReducer from "./slices/CreditLimitRedicerSlice";
import creditLimitsReducer from "./slices/CreditLimitsReducerSlice";
import reservationLinesReducer from "./slices/ReservationLinesReducerSlice";
import hierarchyCategoryReducer from "./slices/MenuCategoriesSlice"
import orderShippingReducer from "./slices/OrderShippingSlice";

export const store = configureStore({
    reducer: {
        user: userReducer,
        breadcrumb: breadcrumbsReducer,
        page: pagePaginationReducer,
        product: productItemSliceReducer,
        orders: ordersReducer,
        reservationLines: reservationLinesReducer,
        order: orderReducer,
        activeCart: activeCartReducer,
        catalogProducts: catalogProductsSliceReducer,
        previewProduct: previewProductReducer,
        finTerms: finTermsReducer,
        newReturn: newReturnReducer,
        newWarranty:newWarrantyReducer,
        returns: returnsReducer,
        return: returnReducer,
        creditLimits: creditLimitsReducer,
        creditLimit: creditLimitReducer,
        hierarchyCategory: hierarchyCategoryReducer,
        orderShipping: orderShippingReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunk)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
