import styled from 'styled-components';

export const CatalogGridWrapper = styled.div`
    
    display: grid;
    grid-template-columns: repeat(auto-fit, 250px);
    justify-content: space-between;
    gap: 10px;
`;

export const GridControl = styled.div`
    margin-bottom: 15px;
`;


export const ButtonShowMore = styled.button`
    background-color: white;
    color: rgb(110, 209, 243);
    border: 1px solid rgb(110, 209, 243);
    width: 100%;

    &:hover {
        background-color: rgb(139, 217, 243);
        color: white;
    }

    &:focus {
        background-color: rgb(139, 217, 243);
        color: white;
    }


`;
