import React, {useEffect} from "react";
import "./NewWarrantyRequestTable.sass"
import {NewWarrantyRequestDialog} from "../newWarrantyRequestDialog/NewWarrantyRequestDialog";
import {useAppSelector} from "../../../hooks/ReduxHooks";
import {getLinesData} from "../../../store/slices/NewWarrantySlice";

interface TableRowProps {
    setOpen: (value: boolean) => void,
    handleClose: () => void,
    lineData: any,
}

export const SearchTableRow = (props: TableRowProps) => {

    const {setOpen, lineData} = props

    const handleAddRow = (lineData: any) => {
        setOpen(lineData)
    }

    return (
        <tr>
            <td scope="text-start">{lineData.order_name}</td>
            <td scope="text-start">{lineData.default_code}</td>
            <td scope="text-start">{lineData.barcode}</td>
            <td scope="text-start">{lineData.product_name}</td>
            <td scope="text-start">{lineData.waybill_number}</td>
            <td scope="text-start">{lineData.waybill_date}</td>
            <td scope="text-start">{lineData.quantity}</td>
            <td scope="text-start">{lineData.guarantee}</td>
            <td scope="text-start">{lineData.warranty_service}</td>
            <td scope="col" className="text-end newRequestTable__btns">
                <button type="button" className="btn btn-sm btn-search"
                        onClick={() => handleAddRow(lineData)}>Добавить
                </button>
            </td>
        </tr>
    )

}


export const NewWarrantySearchTable = () => {


    const [openedLine, setOpenedLine] = React.useState<any>(undefined);

    const {warrantyLinesData} = useAppSelector(state => state.newWarranty)
    const {linesToWarranty} = useAppSelector(state => state.newWarranty)

    const [lines, setLines] = React.useState<getLinesData[]>();

    useEffect(() => {
        //TODO for future cases in modal
        // if (warrantyLinesData && linesToWarranty) {
        //     const result = warrantyLinesData.map(line2process => {
        //         const addedItems = linesToWarranty.filter(item => item.lineId === line2process.id);
        //         const addedQty = addedItems.reduce((n, {quantity}) => n + quantity, 0)
        //         return {...line2process, quantity: (addedQty > 0) ? (line2process.quantity - addedQty) : line2process.quantity}
        //     }).filter(item => item.quantity > 0);
        //     setlines(result)
        // } else {
        setLines(warrantyLinesData)
        // }
    }, [linesToWarranty, warrantyLinesData]);


    const handleClose = () => {
        setOpenedLine(undefined);
    };


    return (
        <>
            <div style={{height: "20px"}}>
                {warrantyLinesData &&
                    <div className="d-flex search-banner w-100">
                        Найден {warrantyLinesData.length} продукт:
                    </div>
                }
            </div>
            <>
                <table className="table newRequestTable">
                    <thead className="newRequestTable">
                    <tr>
                        <th scope="col" className="text-secondary text-start newRequestTable__number">Номер заказа</th>
                        <th scope="col" className="text-secondary text-start newRequestTable__code">Код</th>
                        <th scope="col" className="text-secondary text-start newRequestTable__man_code">Код
                            производителя
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__name">Название продукта
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__delivery">Накладная</th>
                        <th scope="col" className="text-secondary text-start newRequestTable__bill_date">Дата
                            накладной
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__qty">Количество</th>
                        <th scope="col"
                            className="text-secondary text-start newRequestTable__price">Период<br/> гарантии
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__total">гарантийное
                            обслуживание<br/> в силе до
                        </th>
                        <th className="newRequestTable__btns"></th>


                    </tr>
                    </thead>
                    <tbody className="newRequestTable__body">
                    {lines && lines.map((el) => {
                            return (
                                <SearchTableRow setOpen={setOpenedLine}
                                                handleClose={handleClose}
                                                lineData={el}/>
                            )
                        }
                    )}

                    </tbody>
                </table>
                <NewWarrantyRequestDialog
                    openedLine={openedLine}
                    handleClose={handleClose}
                />
            </>

        </>
    )
}