import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {fetchOrders, resetOrders} from "../../store/slices/OrdersReducerSlice";
import {Loader} from "../../components/loader/Loader";
import {OrdersContent} from "../../components/orders/ordersContent/OrdersContent";
import {useLocation} from "react-router-dom";
import {ROUTE_URLS} from "../../constants/consts";


export const OrdersPage = () => {


    const {isOrdersLoading, ordersData} = useAppSelector(state => state.orders)
    const {page, searchValue} = useAppSelector((state: any) => state.page)

    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const location = useLocation()
    const isReserve = location.pathname === ROUTE_URLS.RESERVES
    const requestData = {
        pageNum: page,
        searchValue: searchValue,
        isReserve: isReserve,
    };


    useEffect(() => {
        if (page === 0) return
        dispatch(fetchOrders(requestData))
    }, [page, searchValue, location]);

    useEffect(() => {
        if (!isOrdersLoading)
            setIsLoading(isOrdersLoading)
    }, [isOrdersLoading]);


    useEffect(() => {
        return () => {
            dispatch(resetOrders())
        }
    }, []);



    return (
        <>
            {isLoading ? (
                <Loader/>
            ) : (
                <>
                    <div className="wrapper" style={{height: "100%"}}>
                        <OrdersContent/>
                    </div>
                    </>
            )}
        </>
    )
}