// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: rgb(110, 209, 243) !important;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/loader/Loader.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,OAAA;EACA,MAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AACA;EACE,oCAAA;EACA,gDAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kCAAA;AAEF;;AACA;EACE;IACE,yBAAA;EAEF;AACF","sourcesContent":[".loading-container\n  position: relative\n  width: 100%\n  height: 100%\n  left: 0\n  top: 0\n  display: flex\n  justify-content: center\n  align-items: center\n\n.spinner\n  border: 4px solid rgba(0, 0, 0, 0.1)\n  border-left-color: rgb(110, 209, 243) !important\n  border-radius: 50%\n  width: 40px\n  height: 40px\n  animation: spin 1s linear infinite\n\n\n@keyframes spin\n  to\n    transform: rotate(360deg)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
