import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import {useEffect, useState} from "react";
import {Loader} from "../../loader/Loader";
import "./ReservesHomeTable.sass"
import {Column, HomeTableContent} from "../../homeTableContent/HomeTableContent";
import {Link} from "react-router-dom";
import {ROUTE_URLS} from "../../../constants/consts";
import {fetchLines, resetLines} from "../../../store/slices/ReservationLinesReducerSlice";
import {t} from "i18next";
import {Trans} from "react-i18next";


const columns: Column[] = [
    {id: "id", label: "№", maxWidth: 30},
    {id: "name", label: "name", maxWidth: 70},
    {id: "product", label: "product", maxWidth: 100},
    {id: "code", label: "code", maxWidth: 50},
    {
        id: "date",
        label: "date_creation",
        maxWidth: 70,
        align: "right",
    },
    {
        id: "reservation_date",
        label: "reserve_period",
        maxWidth: 70,
        align: "right",
    },
    {
        id: "qty",
        label: "quantity",
        maxWidth: 50,
        align: "right",
    },
    {
        id: "amount",
        label: "amount",
        maxWidth: 170,
        align: "right",
    },
];


export const ReservesHomeTable = () => {


    const {isLinesLoading, linesData} = useAppSelector(state => state.reservationLines)
    const [page, setPage] = useState<number>(0)


    const dispatch = useAppDispatch()

    const requestData = {
        pageNum: page + 1,
        isReserve: true,
        activeFilters: ["order_id.state"],
        operator: "not like",
        searchValue: "draft",
    };

    const handleSetPage = (pageNum: number) => {
        setPage(pageNum)
    }

    useEffect(() => {
        dispatch(fetchLines(requestData))
    }, [page]);


    useEffect(() => {
        return () => {
            dispatch(resetLines())
        }
    }, []);


    return (
        <>
            {!linesData && isLinesLoading ? (
                <Loader/>
            ) : (linesData ?
                    <div className="orders_home_table__wrapper">
                        <
                            HomeTableContent
                            label={`${t("reserved_products")} (${linesData && (linesData.max_lines_count || 0)})`}
                            columns={columns}
                            setPage={handleSetPage}
                            page={page}
                            data_key="reserve"
                            isLoading={isLinesLoading}
                            rowsPerPage={linesData.limit || linesData?.lines?.length}
                            rowsCount={linesData.max_lines_count}
                            rows={linesData.lines.map(el => {
                                return {
                                    id: <Link
                                        to={`${ROUTE_URLS.ORDERS}/${el.order_id?.id}`}>{el.order_id?.name}</Link>,
                                    data_key: el.id,
                                    name: el.order_id?.reservation_name,
                                    product: el.product_id?.name,
                                    code: el.product_id?.manufacture_code,
                                    date: el.reservation_date,
                                    reservation_date: el.reservation_deadline,
                                    qty: el.product_uom_qty,
                                    amount: el.price_total,
                                }
                            })}
                            sx={{width: "100%"}}
                            dataLink={
                                <div className="orders_home_table__link">
                                    <Link to={ROUTE_URLS.ORDERS}>
                                        <Trans i18nKey="all_reserved_orders"/>
                                        <i style={{paddingLeft: "5px"}} className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            }
                        />
                    </div> : <></>
            )}
        </>
    )
}