import React, {useEffect} from "react";
import {TabItem} from "../../components/tabs/TabItem";
import {TabList} from "../../components/tabs/TabList";
import "./WarrantyRequest.sass"
import {NewWarrantyRequest} from "../../components/warrantyRequest/newWarrantyRequest/NewWarrantyRequest";
import {
    WarrantyRequestsHistoryTable
} from "../../components/warranty/warrantyRequestsHistoryTable/WarrantyRequestsHistoryTable";
import {useAppDispatch} from "../../hooks/ReduxHooks";
import {resetWarrantyRequests} from "../../store/slices/WarrantyRequestsReducerSlice";
import {t} from "i18next";

export const inputStyle = {
    width: "100%",

}

export const inputStyleSM = {
    ...inputStyle,
    maxWidth: "130px",
    '& .MuiFormLabel-root': {
        fontSize: '0.8rem',
    },
    '& .MuiOutlinedInput-input': {
        fontSize: '0.8rem',
    }
}

export const inputAddressStyleSM = {
    ...inputStyleSM,
    maxWidth: "200px",
}

export const inputProps = {
    style: {fontSize: "12px"}
}

export const WarrantyRequest = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        return () => {
            dispatch(resetWarrantyRequests())
        }
    }, []);


    return (
        <>
            <div className="pt-3 pb-5 h-100">
                <TabList className="h-100" activeTabIndex={0}>
                    <TabItem label={t('new_warranty_request_label')}>
                        <NewWarrantyRequest/>
                    </TabItem>
                    <TabItem className="h-100" label={t('current_requests_label')}>
                        <WarrantyRequestsHistoryTable isActive/>
                    </TabItem>
                    <TabItem className="h-100" label={t('request_history_label')}>
                        <WarrantyRequestsHistoryTable/>
                    </TabItem>
                </TabList>
            </div>
        </>
    )
}