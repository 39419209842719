import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hooks/ReduxHooks";
import {useLocation} from "react-router-dom";
import {BreadcrumbsDataProps} from "../../../breadcrumbs/Breadcrumbs";
import {updateBreadcrumb} from "../../../../store/slices/BreadcrumbSlice";
import {t} from "i18next";

export const WarrantyIntroduction = () => {

    const {warrantyRequestData} = useAppSelector(state => state.warrantyRequest)
    const location = useLocation()
    const dispatch = useAppDispatch()

    if (warrantyRequestData) {
        let pr: BreadcrumbsDataProps = {}
        pr[location.pathname] = warrantyRequestData?.name || ""
        dispatch(updateBreadcrumb(pr))

    }

    return (
        <>
            <div className="border-bottom-0 pt-0 pb-3">
                <div className="d-flex bold-text justify-content-between gap-2">
                        <>
                            <h3>
                                {`${t("request")}`}: <span className="text-colored">{warrantyRequestData?.name}</span>
                            </h3>
                            <div>
                                {`${t("status")}`}: <span className="text-success">{warrantyRequestData?.guarantee_state}</span>
                            </div>
                        </>
                </div>
            </div>
        </>
    )
}