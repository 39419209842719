// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_table {
  font-weight: 400;
  font-size: 11pt;
}
.home_table__row a {
  text-decoration: none;
  color: rgb(94, 208, 246);
  font-weight: 700;
  font-size: 11pt;
}`, "",{"version":3,"sources":["webpack://./src/components/homeTableContent/HomeTableContent.sass"],"names":[],"mappings":"AAAA;EAEE,gBAAA;EACA,eAAA;AAAF;AAGI;EACE,qBAAA;EACA,wBAAA;EACA,gBAAA;EACA,eAAA;AADN","sourcesContent":[".home_table\n\n  font-weight: 400\n  font-size: 11pt\n\n  &__row\n    a\n      text-decoration: none\n      color: rgb(94, 208, 246)\n      font-weight: 700\n      font-size: 11pt"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
