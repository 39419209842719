import React, {useEffect, useState, useContext, useImperativeHandle, forwardRef} from "react";
import "./ProductCardControl.sass"
import {
    addProductToUserActiveCart,
    changeValueInProduct, createBackendSaleOrder,
    deleteProductInUserActiveCart,
} from "../../api/productCart";
import {NavbarContext} from "../../hooks/UseNavbarContext";
import useDebounce from "../../hooks/UseDebounce";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {DISPLAY_TYPE, MESSAGE_VARIANT, ROUTE_URLS} from "../../constants/consts";
import {InputLoader} from "../inputLoader/inputLoader";
import {MessageContext} from "../../hooks/UseMessage";
import {ReserveModal} from "../reserveModal/ReserveModal";
import {useNavigate} from "react-router-dom";
import {updateOrderLines} from "../../api/orders";
import {ProcessOrderModal} from "../processOrderModal/ProcessOrderModal";


interface ProductCardControlProps {

    productData: {
        id: number,
        balance_no_more: number,
        stock_quantity_b2b: number,
        is_display_stock_balance_on_portal: boolean,
        price: number
        currencyId: number
        is_for_order: boolean
    };
    isListView?: boolean;
    isCanLock?: boolean;
    isCanInput?: boolean;
    isExtended?: boolean;
    isMainPage?: boolean;
}

interface ProductCardControlRef {
    setIsInCard: (newState: any) => void;
    setValue: (newState: any) => void;
}

export const ProductCardControl = forwardRef<ProductCardControlRef, ProductCardControlProps>((props, ref) => {

    const {activeCartData} = useAppSelector(state => state.activeCart)
    let cartElement = activeCartData && activeCartData.cart_lines.find(el => el.product_id === props.productData.id)
    const {setNavbarBadgeTrigger, navbarBadgeTrigger} = useContext(NavbarContext);
    const [value, setValue] = useState(cartElement ? cartElement.quantity : 0);

    const [isLock, setIsLock] = useState(false);
    const [isHasOrders, setIsHasOrders] = useState(false);
    const [isLoadingCard, setIsLoadingCard] = useState<boolean>(false);
    const [isInCard, setIsInCard] = useState(!!cartElement);
    const [triggerEffect, setTriggerEffect] = useState<number>(0);
    const debouncedValue = useDebounce(triggerEffect, 1000)
    const {displayType} = useAppSelector(state => state.page)
    const {showMessage} = useContext(MessageContext)
    const navigate = useNavigate()

    const [isModalOpen, setModalOpen] = useState(false);
    const [isOrderModalOpen, setOrderModalOpen] = useState(false);


    const getRealValue = (realValue: number) => {
        if (!props.isMainPage && displayType === DISPLAY_TYPE.TABLE && activeCartData?.cart_lines) {
            realValue += activeCartData.cart_lines.filter(
                e => e.product_id === props.productData.id
            ).reduce((n, {quantity}) => n + quantity, 0)
        }
        return realValue
    }

    const checkValue = (value: number) => {
        const realValue = getRealValue(value)
        if (props.productData.is_display_stock_balance_on_portal &&
            (props.productData.stock_quantity_b2b <= props.productData.balance_no_more) &&
            (realValue > props.productData.stock_quantity_b2b) &&
            realValue > 0
        ) {
            showMessage({
                variant: MESSAGE_VARIANT.WARNING,
                text: `Превышено доступное количество: ${props.productData.stock_quantity_b2b}!`
            })
            return false
        }
        return true
    }

    const ToggleAddToCard = async () => {
        let productPrams = {
            product_id: props.productData.id,
            quantity: value,
            price: props.productData.price,
            currency_id: props.productData.currencyId
        }
        if (value) {
            setIsLoadingCard(true)
            let result = await addProductToUserActiveCart(productPrams, true);
            if (!result.error) {
                setNavbarBadgeTrigger(!navbarBadgeTrigger)
                setValue(value)
                setIsInCard(true)
            }
        }


    };

    useEffect(() => {
        let qty = (!props.isMainPage && displayType === DISPLAY_TYPE.TABLE) ? 0 : 1
        if (cartElement) {
            if (displayType === "grid" || props.isMainPage) {
                qty = cartElement.quantity
            }
        }
        setValue(qty)
        setIsInCard(!!cartElement);
        setIsLoadingCard(false)
    }, [cartElement, displayType]);

    useImperativeHandle(ref, () => ({
        setIsInCard: (newState: any) => setIsInCard(newState),
        setValue: (newState: any) => setValue(newState)
    }));


    useEffect(() => {
        (async () => {
            if (value > 0 && isInCard) {
                let productId = props.productData.id;
                let productParams = {
                    "quantity": value,
                }
                setIsLoadingCard(true)
                let result = await changeValueInProduct(productId, productParams);
                setNavbarBadgeTrigger(!navbarBadgeTrigger)
                if (!result.error) {
                    setIsLoadingCard(false)
                }
            }
        })();
    }, [debouncedValue]);

    const ToggleLock = (isOrder: boolean = false) => {
        if (!value || value === 0 ) {
            showMessage({
                variant: MESSAGE_VARIANT.WARNING,
                text: `Неверно указано колличество: ${value}!`
            })
            return
        }
        if (isOrder) {
            isHasOrders ? setOrderModalOpen(true) : ToggleAddToCard()
        } else {
            setModalOpen(true);
        }
    };

    const addToReservation = async (reserveId: number) => {
        if (!value || value === 0 ) {
            return
        }
        const orderData = {
            "id_order": reserveId,
            "line_data": [{
                product_id: props.productData.id,
                quantity: value,
            }]
        }
        setIsLoadingCard(true)
        let result = await updateOrderLines(orderData)
        setIsLoadingCard(false)
        if (result?.error) return
        setValue(0)
        showMessage({
            variant: MESSAGE_VARIANT.SUCCESS,
            text: `Добавлено в резерв!`
        })
    }

    const createNewReservation = async () => {
        if (!value || value === 0 ) {
            return
        }
        const orderData = {
            "is_reserve": true,
            "order_line": [{
                product_id: props.productData.id,
                product_uom_qty: value,
                price_unit: props.productData.price,
            }]
        }
        setIsLoadingCard(true)
        let result = await createBackendSaleOrder(orderData)
        setIsLoadingCard(false)
        if (result?.error) return
        if (result?.data?.data?.id) navigate(ROUTE_URLS.RESERVES + `/${result?.data?.data?.id}`)
    }

    const createNew = (processOder: boolean) => {
        if (processOder) return ToggleAddToCard()
        return createNewReservation()
    }


    const handleApplyModal = async (option: 'new' | 'existing', reserveId?: number, processOder: boolean = false) => {
        if (option === 'new') {
            createNew(processOder)
        } else if (option === 'existing' && reserveId) {
            addToReservation(reserveId);
        }
    };

    const handleCloseModal = (isOrder: boolean = false) => {
        if (isOrder) {
            setOrderModalOpen(false);
        } else {
            setModalOpen(false);
        }
    };


    const handleChangeValue = async (event: any, fromListView: boolean = false) => {
        if (event.target.value === "") {
            setValue(event.target.value)
            return
        }
        let fieldValue = Number(event.target.value.replace(/\D/g, ''));
        const check = handleSetValue(fieldValue >= 1 ? fieldValue : 0)
        if (!check) return
        if (!fromListView && fieldValue === 0 && isInCard) {
            setIsInCard(!isInCard);
            await deleteProductFromCart()
        }
        if (!fromListView) {
            setTriggerEffect(triggerEffect + 1);
        }
    }

    const handleSetValue = (fieldValue: number) => {
        const isCheck = checkValue(Number(fieldValue))
        if (!isCheck) {
            return false
        }
        setValue(fieldValue)
        return true
    }

    const handleIncrease = () => {
        const isUpdated = handleSetValue(value + 1)
        if (!isUpdated) return
        setTriggerEffect(triggerEffect + 1);
    }

    const deleteProductFromCart = async () => {
        let deleteParams = {
            "product_ids": [props.productData.id]
        }
        await deleteProductInUserActiveCart(deleteParams)
        setNavbarBadgeTrigger(!navbarBadgeTrigger)
    }
    const handleDecrease = async () => {
        if (value === 1 && isInCard) {
            setIsInCard(!isInCard);
            await deleteProductFromCart()
        }
        const fieldValue = value <= 1 ? 1 : value - 1
        const isUpdated = handleSetValue(fieldValue)
        if (!isUpdated) return
        setTriggerEffect(triggerEffect + 1);
    }

    if (props.isExtended) {
        return (
            <>
                <ReserveModal
                    isOpen={isModalOpen}
                    onClose={() => handleCloseModal()}
                    onApply={handleApplyModal}
                />
                <ProcessOrderModal
                    isOpen={isOrderModalOpen}
                    onClose={() => handleCloseModal(true)}
                    onApply={(...args) => handleApplyModal(...args, true)}
                />

                <div
                    className={`product__cart-btn-control kanban d-flex gap-1 justify-content-center ${isInCard ? "" : "d-none"}`}
                > {isLoadingCard ? (<InputLoader/>) : (
                    <>
                        <button className="btn border text-colored number-minus" type="button"
                                onClick={handleDecrease}>-
                        </button>
                        <input className="product__input in_card"
                               min="0"
                               value={value}
                               onChange={handleChangeValue}
                        />
                        <button className="btn border text-colored number-plus" type="button" onClick={handleIncrease}>+
                        </button>
                    </>
                )}

                </div>
                <div className={`product__cart-btn-control ${isInCard ? "d-none" : ""}`}>
                    {props.isCanInput && <input className={"product__input"} min="0" value={value}
                                                onChange={handleChangeValue}/>}
                    <button
                        className="btn cart_btn favIcon text-colored"
                        onClick={() => ToggleLock(true)}
                        disabled={isLoadingCard}
                    >
                        {isLoadingCard ? (
                            <InputLoader/>) : (
                            <i className={`bi bi-cart${isInCard ? "-fill" : ""}`}>
                            {props.isListView ? "" : " Добавить"}
                            </i>)}
                    </button>
                    {((props.isCanLock && !props.productData.is_for_order) || (!props.productData.is_display_stock_balance_on_portal) ) && (
                        <button className="btn favIcon text-colored" onClick={() => ToggleLock()}>
                            <i className={`bi bi-lock${isLock ? "-fill" : ""}`}></i>
                        </button>
                    )}
                </div>


            </>
        )
    }


    return (
        <>
            <ReserveModal
                isOpen={isModalOpen}
                onClose={() => handleCloseModal()}
                onApply={handleApplyModal}
            />
            <ProcessOrderModal
                    isOpen={isOrderModalOpen}
                    onClose={() => handleCloseModal(true)}
                    onApply={(...args) => handleApplyModal(...args, true)}
                    setIsHasOrders={setIsHasOrders}
            />

            {<div
                className={`product__cart-btn-control list-view d-flex ${isInCard ? "" : "d-none"}`}>
                <button className="btn product_list_btn-control border text-colored number-minus" type="button"
                        onClick={handleDecrease}>-
                </button>
                <input className="product__input in_card"
                       min="0"
                       value={value}
                       onChange={handleChangeValue}
                />
                <button className="btn product_list_btn-control border text-colored number-plus" type="button"
                        onClick={handleIncrease}>+
                </button>
            </div> && !props.isListView}
            <div className="d-flex">
                <div
                    className={`product__cart-btn-control list-view ${props.isListView ? "" : "d-none"}`}>
                    <input className="product__input in_card"
                           min="0"
                           value={value}
                           onWheel={(e) => e.currentTarget.blur()}
                           onChange={event => handleChangeValue(event, true)}
                    />
                </div>
                <div className={`product__cart-btn-control d-flex justify-content-start list-view${isInCard && !props.isListView ? "d-none" : ""}`}>
                    <button className="btn cart_btn_icon favIcon text-colored" onClick={() => ToggleLock(true)}>
                        {isLoadingCard ? (<InputLoader/>) : (
                            <i className={`bi ${!isLoadingCard ? "bi-cart" : ""}${isInCard ? "-fill" : ""}`}>{props.isListView ? "" : " Добавить"}</i>
                        )}
                    </button>
                    {((props.isCanLock && !props.productData.is_for_order) || !props.productData.is_display_stock_balance_on_portal) && (
                        <button className="btn favIcon text-colored" onClick={() => ToggleLock()}>
                            <i className={`bi bi-lock${isLock ? "-fill" : ""}`}></i>
                        </button>
                    )}
                </div>
            </div>


        </>
    );
})
