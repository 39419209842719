import React, {useContext, useEffect, useState} from "react";
import {OrderTableTr} from "./OrderTableTr"
import {useAppSelector} from "../../../../hooks/ReduxHooks";
import {OrderLinesContext} from "../../../../hooks/UseOrderLinesSelectContext";
import {checkboxStyleSM} from "../../../catalog/catalogControl/style";
import Checkbox from "@mui/material/Checkbox";

export const OrderTable = () => {
    const [isAllChecked, setIsAllChecked] = useState(false)
    const { selectedLines,setSelectedLines, lines } = useContext(OrderLinesContext);
    const {orderData} = useAppSelector(state => state.order);



    useEffect(() => {
        if (lines && lines.length > 0) {
            const allChecked = lines.every(line => selectedLines.includes(line.id));
            setIsAllChecked(allChecked);
        }
    }, [lines, selectedLines]);


    const handleSelectAll = (e: { target: { checked: any; }; }) => {
        if (!orderData) return;
        const checked = e.target.checked;
        setIsAllChecked(checked);

        if (checked) {
            setSelectedLines(orderData.order_line.map(el => el.id));
        } else {
            setSelectedLines([]);
        }
    };

    const handleChildCheckboxChange = (id:number, checked:any) => {
        const updatedSelectedLines = checked
            ? [...selectedLines, id]
            : selectedLines.filter((lineId) => lineId !== id);

        setSelectedLines(updatedSelectedLines);

        const allChecked = updatedSelectedLines.length === lines.length;
        setIsAllChecked(allChecked);
    };




    return (orderData ?
        <>
            <div>
                <table className="table table-sm order__table" id="sales_order_table">
                    <thead className="align-top">
                    <tr>
                        <th scope="col">
                            <Checkbox
                                checked={isAllChecked}
                                sx={checkboxStyleSM}
                                value={""}
                                id="AllCartProductsCheck"
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th className="text-start text-colored" id="product_name_header">Продукт
                        </th>
                        <th className="text-start text-colored" id="product_name_header">Код<br/>поставщика
                        </th>
                        <th className="text-start text-colored" id="product_qty_header">Количество<br/>заказанное</th>
                        <th className="text-start text-colored" id="product_qty_header">Количество<br/>подтвержденное
                        </th>
                        {orderData.is_reserve && (
                            <th className="text-center text-colored" id="product_qty_header">К заказу
                            </th>
                        )}

                        <th className="text-center text-colored d-sm-table-cell">
                            Цена
                        </th>
                        <th className="text-center text-colored d-sm-table-cell">
                            НДС
                        </th>
                        <th className="text-start text-colored">
                            <span>Стоимость<br/> без НДС</span>
                        </th>
                        <th className="text-start text-colored">
                            <span>Стоимость<br/> с НДС</span>
                        </th>
                        {!orderData.is_reserve && (
                            <>
                                <th className="text-start text-colored" id="product_discount">Скидка<br/>%</th>
                                <th className="text-start text-colored" id="product_discount_amount">Скидка<br/>сумма</th>
                            </>
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    {lines && lines.map((child) =>
                        <OrderTableTr
                            orderLine={child}
                            orderLines={lines}
                            currency={orderData.currency}
                            isReserve={orderData.is_reserve}
                            onCheckboxChange={handleChildCheckboxChange}
                        />
                    )}
                    </tbody>
                </table>
            </div>
        </> : <></>
    )
}