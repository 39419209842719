import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ReturnsType} from "../../components/catalog/types";
import {fetchReturnsRequests} from "../../api/returns";

export type initialStateType = {
    isReturnsLoading: boolean;
    returnsData: ReturnsType | undefined
}

const initialState: initialStateType = {
    returnsData: undefined,
    isReturnsLoading: true,
}

export const fetchReturns =
    createAsyncThunk("slice/fetchReturns",
        async (values: {pageNum: number, isActive?: boolean}) => {
            const {pageNum, isActive} = values
            const result = await fetchReturnsRequests(pageNum, isActive)
            return result.data?.data
        })
export const resetReturns =
    createAsyncThunk("slice/resetReturns",
        async () => initialState
    )

const returnsSliceReducer = createSlice({
    name: "returns",
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchReturns.fulfilled, (state, action) => {
            state.returnsData = action.payload
            state.isReturnsLoading = false
        }).addCase(resetReturns.fulfilled, (state, action) => {
            Object.assign(state, initialState);
            state.isReturnsLoading = false;
        })
    }
})

export default returnsSliceReducer.reducer
