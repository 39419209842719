import styled from "styled-components";
import { motion } from "framer-motion";
import { systemWhite } from "../../../constants";

export const CardContainer = styled(motion.div)`
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    overflow: hidden;
    box-shadow: 0 8px 24px 0 #000C2814;
    padding: 40px 24px 5px 24px;
    gap: 20px;
    border-radius: 16px;
    background-color: ${systemWhite};
    animation: fadeInUp 1s forwards;

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(50px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media (max-width: 768px) {
        width: 163px;
        height: 216px;
    }
`;

export const CardText = styled.span`
    font: 400 16px/20px "Roboto", sans-serif;
    text-align: center;
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: normal;
    hyphens: auto;
`;

export const CardIcon = styled.img`
    width: 40px;
`;

