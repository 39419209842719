import {useEffect, useState} from "react";
import {fetchProductsRequests} from "../api/catalogProducts";
import {fetchWarrantyDefectTypes} from "../api/warranty";

export type warrantyDefectType = {
    id: number,
    name: string,
}

export const useWarrantyDefectTypes = () => {
    const [defectTypes, setDeffectTypes] = useState<warrantyDefectType[]>([])

    useEffect(() => {
        (async () => {

            let result = await fetchWarrantyDefectTypes()
            if (!result.error) {
                setDeffectTypes(result?.data?.data || []);
            }

        })();
    }, []);

    return defectTypes
}