import * as React from "react"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import {inputProps, inputStyleSM} from "../../productReturns/ProductReturns";
import {BaseDatePicker} from "../../date/DatePicker";
import {NewWarrantySearchTable} from "../newWarrantyRequestTable/NewWarrantySearchTable";
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import {fetchOrderLines, warrantyFilterEnum, setActiveFilter} from "../../../store/slices/NewWarrantySlice";
import {Dayjs} from "dayjs";
import {Blured} from "../../blured/Blured";


export const NewWarrantyRequestSearchForm = () => {

    const [dateFrom, setDateFrom] = React.useState<Dayjs | null>(null);
    const [dateTo, setDateTo] = React.useState<Dayjs | null>(null);
    const {activeFilter, isWarrantyLinesLoading} = useAppSelector(state => state.newWarranty)
    const dispatch = useAppDispatch()

    const handleSubmit = () => {
        if (!activeFilter) return
        dispatch(fetchOrderLines(activeFilter))
    }

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13) {
            handleSubmit()
        }
    }

    const handleSetFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: warrantyFilterEnum) => {
        const value = event.target.value;
        if (!value) return;
        const newFilter = {
            ...activeFilter,
            [field]: value,
        };
        dispatch(setActiveFilter(newFilter));
    }



    return (
        <>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': {m: 1, width: '25ch'},
                }}
                noValidate
                autoComplete="off"
            >
                <h5>Укажите критерии поиска</h5>
                <TextField id="outlined-basic"
                           size="small"
                           label="Название продукта"
                           variant="outlined"
                           inputProps={inputProps}
                           required
                           InputProps={{
                               sx: {
                                   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                       borderColor: 'rgb(110, 209, 243)',
                                       outline: 'none',
                                   },
                               },
                           }}
                           onChange={(event) => handleSetFilter(event, warrantyFilterEnum.PRODUCT_NAME)}
                           sx={inputStyleSM}
                           onKeyDown={handleKeyPress}

                />
                <TextField id="outlined-basic"
                           size="small"
                           label="Код"
                           variant="outlined"
                           inputProps={inputProps}
                           required
                           InputProps={{
                               sx: {
                                   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                       borderColor: 'rgb(110, 209, 243)',
                                       outline: 'none',
                                   },
                               },
                           }}
                           onChange={(event) => handleSetFilter(event, warrantyFilterEnum.CODE)}
                           sx={inputStyleSM}
                           onKeyDown={handleKeyPress}
                />
                <TextField id="outlined-basic"
                           size="small"
                           label="EAN"
                           variant="outlined"
                           inputProps={inputProps}
                           required
                           InputProps={{
                               sx: {
                                   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                       borderColor: 'rgb(110, 209, 243)',
                                       outline: 'none',
                                   },
                               },
                           }}
                           onChange={(event) => handleSetFilter(event, warrantyFilterEnum.EAN)}
                           sx={inputStyleSM}
                           onKeyDown={handleKeyPress}
                />
                <TextField id="outlined-basic"
                           size="small"
                           label="Серийный номер"
                           variant="outlined"
                           inputProps={inputProps}
                           required
                           InputProps={{
                               sx: {
                                   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                       borderColor: 'rgb(110, 209, 243)',
                                       outline: 'none',
                                   },
                               },
                           }}
                           sx={inputStyleSM}
                           onKeyDown={handleKeyPress}
                />
                <TextField id="outlined-basic"
                           size="small"
                           label="Накладная"
                           variant="outlined"
                           inputProps={inputProps}
                           required
                           InputProps={{
                               sx: {
                                   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                       borderColor: 'rgb(110, 209, 243)',
                                       outline: 'none',
                                   },
                               },
                           }}
                           onChange={(event) => handleSetFilter(event, warrantyFilterEnum.WAYBILL_NUMBER)}
                           sx={inputStyleSM}
                           onKeyDown={handleKeyPress}
                />
                <BaseDatePicker sx={inputStyleSM}
                                slotProps={{textField: {size: 'small', fontSize: "12px"}}}
                                label="Дата накладной с"
                                value={dateFrom}
                                setValue={setDateFrom}
                />
                <BaseDatePicker sx={inputStyleSM}
                                slotProps={{textField: {size: 'small'}}}
                                label="по/"
                                value={dateTo}
                                setValue={setDateTo}
                />
                <button type="button" className="btn-search btn" onClick={handleSubmit}>Поиск</button>
            </Box>
            <Blured
                element={<NewWarrantySearchTable/>}
                isLoading={isWarrantyLinesLoading}
            />
        </>
    )

}