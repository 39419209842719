import { SignIn } from "../../components/auth/SignIn";
import {AuthContainer, AuthContainerChild} from "../../components/auth/styles";
export const SignInPage = () => {





    return (
        <AuthContainer>
            <AuthContainerChild>
                <h1>Вход</h1>
                <SignIn/>
            </AuthContainerChild>
        </AuthContainer>
    )
}
