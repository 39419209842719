import "./SortSelection.sass"
import {Trans} from "react-i18next";


export const SortSelection = () => {
    return (
        <select role="button" defaultValue={'code'} className="sort-select" aria-label="Default select example">
            <option value="code">
                <Trans i18nKey="code"/>
            </option>
            <option value="manufacture">
                <Trans i18nKey="manufacturer_code"/>
            </option>
            <option value="brand">
                <Trans i18nKey="brand"/>
            </option>
            <option value="price">
                <Trans i18nKey="price"/>
            </option>
        </select>
    )
}
