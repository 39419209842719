import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import ru from './locales/ru.json';
import et from './locales/et.json';
import lt from './locales/lt.json';
import lv from './locales/lv.json';
import pl from './locales/pl.json';

const resources = {
    en: {
        translation: en,
    },
    ru: {
        translation: ru,
    },
    et: {
        translation: et,
    },
    lt: {
        translation: lt,
    },
    lv: {
        translation: lv,
    },
    pl: {
        translation: pl,
    },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en-UK",
        debug: true,
        interpolation: {
            escapeValue: false,
        },
    });

export const getCurrentLanguage = () => i18n.language;
