import {FC} from "react";
import {ButtonSearch} from "./styles";

interface ButtonSearchSubmit {
    name: string;
    className?: string;
}

export const ButtonSearchSubmit: FC<ButtonSearchSubmit> = ({name, className}) => {
    return (
        <ButtonSearch type="button" className={className}>
            {name}
        </ButtonSearch>
    )
}
