import {useLocation, useParams} from "react-router-dom";
import {OrderContent} from "../../components/orders/orderContent/OrderContent";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import React, {useEffect, useState} from "react";
import {Loader} from "../../components/loader/Loader";
import {updateBreadcrumb} from "../../store/slices/BreadcrumbSlice";
import {OrderLinesContext} from "../../hooks/UseOrderLinesSelectContext";
import {ReturnLineType, SaleOrderLineType} from "../../components/catalog/types";
import {fetchSingleReturn, resetReturn} from "../../store/slices/ReturnReducerSlice";
import { ReturnIntroduction } from "../../components/returns/returnContent/returnIntroduction/ReturnIntroduction";
import { ReturnSidebar } from "../../components/returns/returnContent/returnSidebar/ReturnSidebar";
import {ReturnDeliveryInfo} from "../../components/returns/returnContent/returnDeliveryInfo/ReturnDeliveryInfo";
import {ReturnTable} from "../../components/returns/returnContent/returnTable/ReturnTable";
import {ReturnTotal} from "../../components/returns/returnContent/returnTotal/ReturnTotal";
import {fetchFinTerms} from "../../store/slices/FinTermsSlice";
import {resetOrders} from "../../store/slices/OrdersReducerSlice";


export const ReturnPage = () => {

    const {returnId} = useParams()

    const {isReturnLoading, returnData} = useAppSelector(state => state.return)

    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedLines, setSelectedLines] = useState<number[]>([])
    const [lines, setLines] = useState<ReturnLineType[]>([])
    const location = useLocation()


    useEffect(() => {
        if (!isReturnLoading)
            setIsLoading(isReturnLoading)
    }, [isReturnLoading]);

    useEffect(() => {
        dispatch(fetchSingleReturn(Number(returnId)))
        return () => {
            dispatch(resetReturn())
        }
    }, [location]);

    useEffect(() => {
        if (!returnData) return
        setLines(returnData.lines)
    }, [returnData]);

    useEffect(() => {
        dispatch(fetchFinTerms())
    }, []);



    if (returnData) {
        dispatch(updateBreadcrumb({key: location.pathname, value: returnData.name}))
    }

    return (
        <OrderLinesContext.Provider value={{selectedLines, setSelectedLines, lines, setLines, setIsLoading}}>
            {isLoading ? (
                <Loader/>
            ) : (returnData ?
                <>
                    <div className="wrapper">
                        <OrderContent
                            sidebar={<ReturnSidebar/>}
                            introduction={<ReturnIntroduction/>}
                            info={<ReturnDeliveryInfo/>}
                            table={<ReturnTable/>}
                            total={<ReturnTotal/>}
                            recordId={Number(returnId)}
                            model="b2b.return"
                        />
                    </div>
                </> : <></>
            )}
        </OrderLinesContext.Provider>
    )
}