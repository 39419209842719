import React from "react";
import {
    CategoryItem,
    CategoryImage,
    CategoryTitle,
    Chip,
} from "../styles";
import { useIsMobile } from "../../../../../hooks/useIsMobile.hook";

interface CategoryProps {
    title: string;
    image: string;
}

export const Category = ({ title, image }: CategoryProps) => {
    const isMobile = useIsMobile();

    return isMobile ? <Chip>{title}</Chip> : (
        <CategoryItem>
            <CategoryImage src={image} alt="category-image"/>
            <CategoryTitle>{title}</CategoryTitle>
        </CategoryItem>
    );
}
