// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message_alert {
  position: fixed !important;
  max-width: 500px;
  width: 100%;
  top: 10px;
  right: 10px;
  z-index: 10000 !important;
}
.message_alert .close_btn {
  position: relative;
  top: 0;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/message/Message.sass"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,gBAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,yBAAA;AACF;AACE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;AACJ","sourcesContent":[".message_alert\n  position: fixed !important\n  max-width: 500px\n  width: 100%\n  top: 10px\n  right: 10px\n  z-index: 10000 !important\n\n  .close_btn\n    position: relative\n    top: 0\n    left: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
