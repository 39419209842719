// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-alert {
  display: flex;
  flex: 0 0 45%;
  gap: 7px;
  margin: initial !important;
}

.notification-alert__message {
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/components/notificationAlert/NotificationAlert.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,QAAA;EACA,0BAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;AAEF","sourcesContent":[".notification-alert\n  display: flex\n  flex: 0 0 45%\n  gap: 7px\n  margin: initial !important\n\n.notification-alert__message\n  display: flex\n  flex-direction: column\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
