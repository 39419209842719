import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchReturnOrderLines} from "../../api/orders";
import {SaleOrderLineType} from "../../components/catalog/types";

export type CustomerDataType = {
    name: string,
    phone: string,
    deliveryAddress: number | undefined
}

export enum returnFilterEnum {
    ORDER_NUMBER = "order_number",
    CODE = "code",
    MANUFACTURE_CODE = "manufacture_code",
    EAN = "barcode",
    PRODUCT_NAME = "name",
    SERIAL_NUMBER = "serial_number",
    INVOICE_NUMBER = "invoice_number",
    DATE_FROM = "date_from",
    DATE_TO = "date_to",
}

export type linesToReturnType = {
    id: number,
    lineId: number | undefined,
    lineData: SaleOrderLineType,
    filesData: File[],
    quantity: number,
    description?: string,
    condition?: string | undefined
}

export type getLinesData = {
    id: number,
    product_name: string,
    product_id: number,
    product_code: string,
    order_id: number,
    order_name: string,
    price_unit: number,
    price_subtotal: number,
    currency: string,
    quantity: number
}

export type initialStateType = {
    isLinesLoading: boolean;
    orderLinesData: getLinesData[] | undefined
    activeFilter: {[key in returnFilterEnum]: string;} | undefined
    linesToReturn: linesToReturnType[]
    customerData: CustomerDataType | undefined

}

const initialState: initialStateType = {
    orderLinesData: undefined,
    isLinesLoading: false,
    activeFilter: {
        order_number: "",
        code: "",
        barcode:"",
        name:"",
        manufacture_code: "",
        serial_number: "",
        invoice_number: "",
        date_from: "",
        date_to: ""
 
    },
    linesToReturn: [],
    customerData: undefined
}



export const fetchOrderLines =
    createAsyncThunk("slice/fetchOrderLines",
        async (filterData: any) => {
            const result = await fetchReturnOrderLines(filterData)
            return result.data?.data
        })

const newReturnSliceReducer = createSlice({
    name: "newReturn",
    initialState,
    reducers: {
        setActiveFilter: (state, action) => {
            state.activeFilter = action.payload
        },
        setLineToReturn: (state, action) => {
            state.linesToReturn = action.payload
        },
        createReturn: (state, action) => {
        },
        resetReturnData: (state) => {
            Object.assign(state, initialState)
            state.isLinesLoading = false
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchOrderLines.pending, (state, action) => {
            state.isLinesLoading = true
        }).addCase(fetchOrderLines.fulfilled, (state, action) => {
            state.orderLinesData = action.payload?.lines_data || []
            state.isLinesLoading = false
        })
    }
})

export const {
    setActiveFilter,
    setLineToReturn,
    createReturn,
    resetReturnData,
} = newReturnSliceReducer.actions;

export default newReturnSliceReducer.reducer