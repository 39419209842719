import {ControlButton} from "../../controlButton/controlButton";
import React from "react";
import {NewWarrantyRequestSubmitForm} from "../newWarrantyRequestSubmitForm/NewWarrantyRequestSubmitForm";
import {NewWarrantyRequestSearchForm} from "../newWarrantyRequestSearchForm/NewWarrantyRequestSearchForm";


export const NewWarrantyRequest = () => {


    return (
        <>
            <section className={"new_request__section"}>
                <div className="new_request__wrapper">
                    <ControlButton className="favIcon text-colored RMA__btn btn btn-sm"
                                   icon="bi bi-download RMA__icon"
                                   label={
                                       <div className="RMA__label">
                                           RMA указания по упаковке
                                       </div>
                                   }
                                   controlButtonHandler={() => {}}/>

                </div>
            </section>
            <section className={"new_request__section"}>
                <NewWarrantyRequestSubmitForm/>
            </section>
            <section className={"new_request__section"}>
                <NewWarrantyRequestSearchForm/>
            </section>
        </>
    )
}