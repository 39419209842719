import {FilterItemsType} from "../components/catalog/types";
import {GuaranteeSubmenu} from "../components/subSidebar/GuaranteeSubmenu";

export const EMPTY_STRING = "";
export const EMPTY_NUMBER = 0
export const VISIBLE_MESSAGE_COUNT = 25;

export enum SUBMENU_TYPES {
    CATALOG = "Каталог",
    ORDERS = "Заказы",
    FINANCIAL = "Финансы",
    QUESTIONARY = "Информация",
    GUARANTEE = "Гарантии"
}

export enum ROUTE_URLS {
    HOME = "/",
    CATALOG = "/products",
    CATALOG_CATEGORY = "/products/:categoryId",
    PRODUCT_FROM_CATEGORY = "/products/:categoryId/product-card/:id",
    PRODUCT = "/products/product-card/:id",
    CART = "/cart",
    SIGN_IN = "/sign-in",
    SIGN_UP = "/sign-up",
    ORDERS = "/orders",
    ORDER = "/orders/:orderId",
    RESERVES = "/reserves",
    RESERVE = "/reserves/:orderId",
    RETURNS= "/returns",
    RETURN = "/returns/:returnId",
    QUESTIONARY = "/questionary",
    FIN_TERMS = "/fin_terms",
    CREDIT_LIMIT_HISTORY = "/credit-line",
    NEW_CREDIT_LIMIT = "/credit-line/new",
    CREDIT_LIMIT = "/credit-line/:limitId",
    CONTACT_INFORMATION = "/contact-information",
    TERM_SERVICE = "/terms-service",
    TWO_FACTOR_AUTH = "/2fa",
    WARRANTY_TERMS = "/warranty-terms",
    WARRANTY_REQUEST = "/warranty-request",
    WARRANTY_PERIODS = "/warranty-periods",
    WARRANTY_CASE = "/warranty-case",
    PRICE_LIST = "/price-list",
}

export const HIDE_ROUTES: ROUTE_URLS[] = [ROUTE_URLS.SIGN_IN, ROUTE_URLS.SIGN_UP, ROUTE_URLS.TWO_FACTOR_AUTH];
export const HIDE_SEARCH_NAV_BAR_ROUTES: ROUTE_URLS[] = [
    ROUTE_URLS.CATALOG_CATEGORY,
    ROUTE_URLS.CATALOG,
    ROUTE_URLS.ORDERS,
    ROUTE_URLS.ORDER,
    ROUTE_URLS.RESERVES,
    ROUTE_URLS.RESERVE,
];

export enum USER_ROLES {
    UNVERIFIED = "unverified",
    CONFIRMED = "confirmed",
    WAITING = "wait-approve",
    ACTIVE = "active",
}

export const breadcrumbNameMap = [
    { path: ROUTE_URLS.HOME, breadcrumb: "Главная" },
    { path: ROUTE_URLS.CATALOG, breadcrumb: "Каталог" },
    { path: ROUTE_URLS.CATALOG_CATEGORY, breadcrumb: "" },
    { path: ROUTE_URLS.PRODUCT, breadcrumb:""},
    { path: ROUTE_URLS.PRODUCT_FROM_CATEGORY, breadcrumb:""},
    { path: ROUTE_URLS.CART, breadcrumb:"Корзина"},
    { path: ROUTE_URLS.ORDERS, breadcrumb:"Заказы"},
    { path: ROUTE_URLS.ORDER, breadcrumb:""},
    { path: ROUTE_URLS.RESERVES, breadcrumb:"Резервации"},
    { path: ROUTE_URLS.RESERVE, breadcrumb:""},
    { path: ROUTE_URLS.FIN_TERMS, breadcrumb:"Финансы"},
    { path: ROUTE_URLS.RETURNS, breadcrumb:"Возврат продукта"},
    { path: ROUTE_URLS.RETURN, breadcrumb:""},
];

export enum DISPLAY_TYPE {
    TABLE = "table",
    GRID = "grid",
}

export const ENDPOINT = "https://rde-crm.icodelab.eu"

export const breadcrumbNameMapExclude = ["/products/:categoryId/product-card", "/products/product-card"]

export const API_BACKEND_URL = "https://rde-service-core-exchange.icodelab.eu/api/v1"
export const API_AUTH_URL = "https://rde-service-auth.icodelab.eu/api/v1"
export const SEARCH_PARAMS: FilterItemsType = {
    name: "Название продукта",
    default_code: "Код",
    barcode: "Штрих код",
    manufacturer_id: "Брэнд",
}

export const DISPLAY_TABLE_FIELDS: FilterItemsType = {
    brand: "Бренд",
    product_code: "Код Производителя",
    default_code: "Код",
    image: "Фото",
}

export const PRODUCT_CONDITION: FilterItemsType = {
    not_opened: "Внешняя упаковка закрыта",
    ext_opened: "Внешняя упаковка открыта",
    ext_int_damaged: "Внешняя / Внутренняя упаковка отсутствует или повреждена",
    damaged: "Повреждения продукта (царапины, другое)",
    after_repair: "Продукт после гарантийного ремонта",
    half_equipped: "Неполная комплектация (отсутствуют провода, диск, документация, другое)",
}

export const LocalStorageSearchBy = "searchBy"
export const LocalStorageDisplayFields = "displayFields"

export const COUNTRIES = {
    "Austria": "Austria",
    "Belgium": "Belgium",
    "Bulgaria": "Bulgaria",
    "Croatia": "Croatia",
    "Cyprus": "Cyprus",
    "Czechia": "Czechia",
    "Denmark": "Denmark",
    "Estonia": "Estonia",
    "Finland": "Finland",
    "France": "France",
    "Germany": "Germany",
    "Greece": "Greece",
    "Hungary": "Hungary",
    "Ireland": "Ireland",
    "Italy": "Italy",
    "Latvia": "Latvia",
    "Lithuania": "Lithuania",
    "Luxembourg": "Luxembourg",
    "Malta": "Malta",
    "Netherlands": "Netherlands",
    "Poland": "Poland",
    "Portugal": "Portugal",
    "Romania": "Romania",
    "Slovakia": "Slovakia",
    "Slovenia": "Slovenia",
    "Spain": "Spain",
    "Sweden": "Sweden",
    "International": "International"
};

export enum MESSAGE_VARIANT {
    SUCCESS = "success",
    INFO = "info",
    WARNING = "warning",
    ERROR = "error",
}

export enum DATE_RANGE {
    DAY = "day",
    MONTH = "month",
    YEAR = "year",
    PERIOD = "period",
}

export enum RETURN_STATE {
    CANCEL = "cancel",
}

export const CREDIT_LIMIT_TYPES: FilterItemsType = {
    credit_limit_days: "Кредитный лимит, дни",
    credit_limit: "Кредитный Лимит",
}