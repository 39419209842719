import React from "react";
import {useAppSelector} from "../../../../hooks/ReduxHooks";

export const ReturnDeliveryInfo = () => {
    const {returnData} = useAppSelector(state => state.return)

    return ( returnData ?
        <>
            <div id="informations" className="row">
                <div id="return_info" className="col-12 col-lg-6 mb-4">
                    <table className="table table-borderless table-sm">
                        <tbody>
                        <tr>
                            <th className="w-100 ps-0 pb-0">Дата запроса:</th>
                            <td className="w-100 pb-0 text-nowrap">
                                <span>{returnData.create_date.split(" ")[0]}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="w-100 ps-0 pb-0">Дата подтверждения:</th>
                            <td className="w-100 pb-0 text-nowrap">
                                <span>{returnData.approve_date}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="w-100 ps-0 pb-0">Дата отгрузки:</th>
                            <td className="w-100 pb-0 text-nowrap">
                                <span></span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div id="return_info" className="col-12 col-lg-6 mb-4">
                    <table className="table table-borderless table-sm">
                        <tbody>
                        <tr>
                            <th className="w-100 ps-0 pb-0">Создал:</th>
                            <td className="w-100 pb-0 text-nowrap">
                                <span>{returnData.responsible_partner}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="w-100 ps-0 pb-0">Адрес доставки:</th>
                            <td className="w-100 pb-0 text-nowrap">
                                <span></span>
                            </td>
                        </tr>
                        <tr>
                            <th className="w-100 ps-0 pb-0">Контактная персона:</th>
                            <td className="w-100 pb-0 text-nowrap">
                                <span>{returnData.contact_person}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="w-100 ps-0 pb-0">Телефон:</th>
                            <td className="w-100 pb-0 text-nowrap">
                                <span></span>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </> : <></>
)
}