// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  scrollbar-width: thin;
  scrollbar-color: lightgray transparent;
}

._container {
  max-width: 1320px;
  padding: 0 15px 0 0;
  margin: 0 auto;
}

._container {
  max-width: 400px;
}

._container {
  max-width: initial;
}

._container {
  max-width: initial;
  max-width: 705px;
}

._container {
  padding: 0 30px 0 0;
  max-width: initial;
}

._container {
  padding: 0 30px 0 0;
  max-width: initial;
}

._container {
  padding: 0 15px 0 0;
  max-width: initial;
}

._container {
  max-width: initial;
}

.wrapper {
  margin-bottom: 20px;
}

.wrapper {
  margin-bottom: 40px;
}

.trade_container {
  display: flex;
  justify-content: space-between;
}

.trade_section {
  display: flex;
  flex-direction: column;
  width: 85%;
  padding-left: 15px;
  flex: 1 0;
}

.home_page_container aside {
  max-width: 100%;
}
.home_page_container .textInfoMenu {
  display: block;
  transform: translateY(0);
  overflow: visible;
  animation: sidebar-text-animation 0.3s forwards;
}`, "",{"version":3,"sources":["webpack://./src/assets/common.sass"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,sCAAA;AACF;;AACA;EACE,iBAAA;EACA,mBAAA;EACA,cAAA;AAEF;;AAEA;EACE,gBAAA;AACF;;AAGA;EACE,kBAAA;AAAF;;AAIA;EACE,kBAAA;EACA,gBAAA;AADF;;AAKA;EACE,mBAAA;EACA,kBAAA;AAFF;;AAMA;EACE,mBAAA;EACA,kBAAA;AAHF;;AAMA;EACE,mBAAA;EACA,kBAAA;AAHF;;AAMA;EACE,kBAAA;AAHF;;AAKA;EACE,mBAAA;AAFF;;AAKA;EACE,mBAAA;AAFF;;AAKA;EACE,aAAA;EACA,8BAAA;AAFF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,UAAA;EACA,kBAAA;EACA,SAAA;AADF;;AAME;EACE,eAAA;AAHJ;AAIE;EACE,cAAA;EACA,wBAAA;EACA,iBAAA;EACA,+CAAA;AAFJ","sourcesContent":["body\n  scrollbar-width: thin\n  scrollbar-color: lightgray transparent\n\n._container\n  max-width: 1320px\n  padding: 0 15px 0 0\n  margin: 0 auto\n\n\n@media screen and (min-width: 480px)\n._container\n  max-width: 400px\n\n\n@media screen and (min-width: 575.98px)\n._container\n  max-width: initial\n\n\n@media screen and (min-width: 767.98px)\n._container\n  max-width: initial\n  max-width: 705px\n\n\n@media screen and (min-width: 991.98px)\n._container\n  padding: 0 30px 0 0\n  max-width: initial\n\n\n@media screen and (min-width: 1199.98px)\n._container\n  padding: 0 30px 0 0\n  max-width: initial\n\n@media screen and (min-width: 1439.98px)\n._container\n  padding: 0 15px 0 0\n  max-width: initial\n\n@media screen and (min-width: 1639.98px)\n._container\n  max-width: initial\n\n.wrapper\n  margin-bottom: 20px\n\n@media screen and (min-width: 991.98px)\n.wrapper\n  margin-bottom: 40px\n\n\n.trade_container\n  display: flex\n  justify-content: space-between\n\n.trade_section\n  display: flex\n  flex-direction: column\n  width: 85%\n  padding-left: 15px\n  flex: 1 0\n\n\n\n.home_page_container\n  aside\n    max-width: 100%\n  .textInfoMenu\n    display: block\n    transform: translateY(0)\n    overflow: visible\n    animation: sidebar-text-animation 0.3s forwards\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
