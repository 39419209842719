import {CatalogControl} from "../catalogControl/CatalogControl"
import {SortSelection} from "./sortSelection/SortSelection"
import {CatalogCard} from "./catalogCard/CatalogCard"
import {ButtonShowMore, CatalogGridWrapper, GridControl} from "./styles";
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import * as React from "react";
import {updatePage} from "../../../store/slices/PagePaginagionSlice";
import {useParams} from "react-router-dom";
import {Trans} from "react-i18next";


export const CatalogGrid = () => {

    const {
        productInfo,
        pageNumber,
        pagesCount
    } = useAppSelector(state => state.catalogProducts)
    const dispatch = useAppDispatch()

    const {categoryId} = useParams();

    const handleOnClickShowMore = () => {
        dispatch(updatePage(pageNumber + 1))

    }

    return (
        <div>
            <GridControl className={"catalog_grid__control d-flex flex-row justify-content-between align-items-center"}>
                <div className={"sort text-secondary text-start text-nowrap fs-6 d-flex flex-row"}>
                    <span className={"align-middle"} style={{padding: "5px"}}><Trans i18nKey="sort_by"/>:</span><SortSelection/>
                </div>
                <div className={"d-flex flex-row justify-content-around"}>
                    <CatalogControl/>
                </div>
            </GridControl>
            <CatalogGridWrapper>
                {productInfo && productInfo.map((child) =>
                    <CatalogCard key={child.id} categoryID={categoryId} productData={child}/>
                )}
            </CatalogGridWrapper>
            {
                pageNumber < pagesCount && (
                    <div>
                        <ButtonShowMore type="button" className="btn btn__shwo_more" onClick={handleOnClickShowMore}>
                            <Trans i18nKey="show_more"/>
                        </ButtonShowMore>
                    </div>
                )
            }

        </div>
    )
}