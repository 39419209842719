import Checkbox from "@mui/material/Checkbox";
import {checkboxStyle} from "../style";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hooks/ReduxHooks";
import {
    removeActiveDisplayFields,
    setActiveDisplayFields,
} from "../../../../store/slices/PagePaginagionSlice";
import {useDidUpdate} from "../../../../hooks/useDidUpdate";
import {DISPLAY_TABLE_FIELDS, SEARCH_PARAMS} from "../../../../constants/consts";

interface displayFieldCheckboxProps {
    displayItem: string
}

export const DisplayFieldCheckbox = (props: displayFieldCheckboxProps) => {

    const {displayItem} = props
    const {activeDisplayFields} = useAppSelector(state => state.page)
    const isInDisplayList = activeDisplayFields.includes(displayItem)
    const [isChecked, setIsChecked] = useState(isInDisplayList)

    const dispatch = useAppDispatch()

    const handleOnChange = () => {
        setIsChecked(!isChecked)
    }

    useDidUpdate(() => {
        if (isChecked) {
            dispatch(setActiveDisplayFields(displayItem))
        }
        else {
            dispatch(removeActiveDisplayFields(displayItem))
        }
    },[isChecked]);


    return (
        <>
            <FormControlLabel value={"manufacture_code"}
                              checked={isChecked}
                              control={ <Checkbox sx={checkboxStyle}/>}
                              label={DISPLAY_TABLE_FIELDS[displayItem]}
                              onChange={handleOnChange}
            />
        </>
    )
}