import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    CategoriesSectionContent,
    CategoriesContainer,
    ITEM_WIDTH,
    MOBILE_ITEM_WIDTH,
} from "./styles";
import FirstCategory from "../../../../images/icons/first-category.png";
import { SectionTitle } from "../../styles";
import {
    MAX_ROWS,
    MOBILE_CATEGORIES_MAX_ROWS,
    MOBILE_WIDTH_DIFFERENCE,
    WIDTH_DIFFERENCE
} from "../../constants";
import { Category } from "./components/category";
import { HiddenCount } from "./components/hiddenCount";
import {t} from "i18next";
import useSWR from "swr";
import { getLandingCategories } from "../../../../api/categories";
import {useIsMobile} from "../../../../hooks/useIsMobile.hook";
import {IMAGE_SIZE, useGetImage} from "../../../../hooks/useIsImage.hook";


type CategoryType = {
    name: string;
    image_url?: string;
}

export const CategoriesSection = () => {
    const isMobile = useIsMobile();
    const widthDifference = isMobile ? MOBILE_WIDTH_DIFFERENCE : WIDTH_DIFFERENCE;
    const maxRows = isMobile ? MOBILE_CATEGORIES_MAX_ROWS : MAX_ROWS;
    const itemWidth = isMobile ? MOBILE_ITEM_WIDTH : ITEM_WIDTH;
    const categoriesPerRowRef = useRef(Math.floor((window.innerWidth - widthDifference) / itemWidth));
    const [categoriesPerRow, setCategoriesPerRow] = useState(categoriesPerRowRef.current);
    const getImage = useGetImage;

    const { data } = useSWR("landingCategory", getLandingCategories, { revalidateOnFocus: false });

    const categories = useMemo(() => data?.data?.data || [], [data]);
    const maxItems = useMemo(() => categoriesPerRow * maxRows - 1, [categoriesPerRow, maxRows]);
    const visibleCategories = useMemo(() => categories.slice(0, maxItems), [categories, maxItems]);
    const hiddenCount = useMemo(() => Math.max(categories.length - maxItems, 0), [categories.length, maxItems]);

    const updateCategoriesPerRow = useCallback(() => {
        requestAnimationFrame(() => {
            const newCategoriesPerRow = Math.floor((window.innerWidth - widthDifference) / itemWidth);
            if (categoriesPerRowRef.current !== newCategoriesPerRow) {
                categoriesPerRowRef.current = newCategoriesPerRow;
                setCategoriesPerRow(newCategoriesPerRow);
            }
        });
    }, [itemWidth, widthDifference]);

    useEffect(() => {
        window.addEventListener("resize", updateCategoriesPerRow);
        return () => window.removeEventListener("resize", updateCategoriesPerRow);
    }, [updateCategoriesPerRow]);

    return (
        <CategoriesSectionContent
            initial={{opacity: 0, scale: 0.9}}
            whileInView={{opacity: 1, scale: 1}}
            transition={{duration: 0.8}}
        >
            <SectionTitle>{t("categories_section_content")}</SectionTitle>
            <CategoriesContainer>
                {visibleCategories.map((category: CategoryType, index: number) => (
                    <Category
                        key={index}
                        title={category.name}
                        image={getImage(category.image_url, IMAGE_SIZE.XS)}
                    />
                ))}
                {hiddenCount > 0 && <HiddenCount title={`vēl +${hiddenCount}`} />}
            </CategoriesContainer>
        </CategoriesSectionContent>
    );
}
