import React, {useState, useRef, ChangeEvent} from 'react';
import {
    AttachFile as AttachFileIcon,
    PictureAsPdf as PdfIcon,
    InsertDriveFile as FileIcon,
    Image as ImageIcon,
    Close as CloseIcon
} from '@mui/icons-material';
import './CreditLineForm.sass';
import {Divider, IconButton} from '@mui/material';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {CREDIT_LIMIT_TYPES, ROUTE_URLS} from "../../../constants/consts";
import {createBackendCreditLimitRequest} from "../../../api/creditLimits";
import {useNavigate, useParams} from "react-router-dom";
import ChatBlock from "../../chat/ChatBlock";
import {Trans} from "react-i18next";

interface CreditLineFormProps {
    requestDate?: string,
    state?: string,
    note?: string,
    isNew?: boolean,
    linesData: any[] | undefined
    recordId?: number;
}

export const CreditLineForm = (props: CreditLineFormProps) => {
    const {
        requestDate,
        state,
        isNew,
        linesData,
        note,
        recordId
    } = props

    const [limitLinesData, setLimitLinesData] = useState(linesData)
    const [limitNote, setLimitNote] = useState<string>(note || "")
    const [attachedFiles, setAttachedFiles] = useState<any>([]);
    const [isDragging, setIsDragging] = useState<any>(false);
    const fileInputRef = useRef<any>(null);
    const navigate = useNavigate()

    const handleFileChange = (e: any) => {
        setAttachedFiles([...attachedFiles, ...e.target.files]);
        setIsDragging(false);
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        setAttachedFiles([...attachedFiles, ...files]);
        setIsDragging(false);
    };

    const handleFileClick = (file: any) => {
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleRemoveFile = (index: any, e: any) => {
        e.stopPropagation()
        const newFiles = attachedFiles.filter((_: any, i: any) => i !== index);
        setAttachedFiles(newFiles);
    };


    const getFileIcon = (file: any) => {
        const fileType = file.type.split('/')[0];
        if (fileType === 'image') {
            return <ImageIcon style={{color: '#6ed1f3', marginRight: '10px'}}/>;
        } else if (file.type.includes('pdf')) {
            return <PdfIcon style={{color: '#6ed1f3', marginRight: '10px'}}/>;
        } else {
            return <FileIcon style={{color: '#6ed1f3', marginRight: '10px'}}/>;
        }
    };

    const handleSetLimit = (value: string, type: any) => {
        if (!limitLinesData || !type) return
        let newData = limitLinesData.map(line => (line.credit_limit_type === type ? {
            ...line,
            requested_limit: Number(value)
        } : line))
        setLimitLinesData(newData)

    }

    const handleChangeNote = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setLimitNote(event.target.value)
    }

    const handleSubmitLimit = async () => {
        if (!limitLinesData) return
        const requestData = {
            note: limitNote,
            lines: limitLinesData.map(el => {
                return {
                    credit_limit_type: el.credit_limit_type,
                    requested_limit: el.requested_limit
                }
            })
        }
        const result = await createBackendCreditLimitRequest(requestData)
        if (!result.error && !!result?.data?.data?.id) {
            navigate(`${ROUTE_URLS.CREDIT_LIMIT_HISTORY}/${result.data.data.id}`)
        }
    }

    return (
        <div className="form-container">
            <div className="form-content">
                <div className="form-row">
                    <label>
                        <Trans i18nKey="request_date"/>
                    </label>
                    <span>{requestDate && requestDate.split(" ")[0]}</span>
                </div>
                <div className="form-row">
                    <label>
                        <Trans i18nKey="status"/>
                    </label>
                    <span>{state}</span>
                </div>
                <div className="form-row">
                    <label>
                        <Trans i18nKey="responsible"/>
                    </label>
                    <input type="text" disabled={!isNew}/>
                </div>
                <div className="form-row">
                    <label>
                        <Trans i18nKey="comment"/>
                    </label>
                    <textarea
                        disabled={!isNew}
                        value={note}
                        onChange={(event) => handleChangeNote(event)}
                    ></textarea>
                </div>
                <div className="form-row">
                    <label>
                        <Trans i18nKey="limit_type"/>
                    </label>
                    <table className="form-table">
                        <thead>
                        <tr>
                            <th>
                                <Trans i18nKey="limit_type"/>
                            </th>
                            <th>
                                <Trans i18nKey="current"/>
                            </th>
                            <th>
                                <Trans i18nKey="desired"/>
                            </th>
                            <th>
                                <Trans i18nKey="approved"/>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {limitLinesData &&
                            limitLinesData.map((line) => (
                                <tr>
                                    <td><Trans i18nKey={CREDIT_LIMIT_TYPES[line.credit_limit_type]} /></td>
                                    <td>
                                        <input
                                            type="number"
                                            disabled
                                            defaultValue={line.current_limit}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            disabled={!isNew}
                                            defaultValue={line.requested_limit}
                                            onChange={(e) =>
                                                handleSetLimit(e.target.value, line.credit_limit_type)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            defaultValue={line.to_approve_limit}
                                            disabled
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {isNew && (
                    <>
                        <div
                            className={`file-list ${isDragging ? "dragging" : ""}`}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            onClick={handleClick}
                        >
                            {attachedFiles.length > 0 ? (
                                <ul>
                                    {attachedFiles.map((file: any, index: any) => (
                                        <li
                                            className="file-item"
                                            key={index}
                                            onClick={() => handleFileClick(file)}
                                        >
                                            <Box display="flex" alignItems="center" width="100%">
                                                {getFileIcon(file)}
                                                <Typography variant="body2">{file.name}</Typography>
                                            </Box>
                                            <IconButton onClick={(e) => handleRemoveFile(index, e)}>
                                                <CloseIcon/>
                                            </IconButton>
                                            <Divider/>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <span className="empty_content_text">
              <Trans i18nKey="drag_files"/>
            </span>
                            )}
                        </div>
                        <div className="form-row file-upload d-none">
                            <label htmlFor="file-upload" className="file-upload-label">
                                <Trans i18nKey="add_files"/>
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                className="file-upload-input"
                                multiple
                                ref={fileInputRef}
                                onChange={handleFileChange}
                            />
                        </div>
                        <button
                            type="submit"
                            className="submit-button"
                            onClick={handleSubmitLimit}
                        >
                            <Trans i18nKey="submit"/>
                        </button>
                    </>
                )}
                <div style={{marginTop: "30px", marginBottom: "40px"}}>
                    <section>
                        <ChatBlock model="rde.credit.limit" recordId={Number(recordId)}/>
                    </section>
                </div>
            </div>
        </div>

    );
};
