// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  margin-bottom: 30px;
}
.card .card__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: zoom-in;
}

.product_card__footer {
  background-color: unset !important;
  border: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/components/catalog/catalogGrid/catalogCard/CatalogCard.sass"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;AACJ;;AAEE;EACE,kCAAA;EACA,wBAAA;AACJ","sourcesContent":[".card\n  margin-bottom: 30px\n\n  .card__image\n    width: 100%\n    height: 100%\n    object-fit: contain\n    cursor: zoom-in\n\n.product_card\n  &__footer\n    background-color: unset !important\n    border: unset !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
