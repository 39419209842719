import {Dispatch, FC, SetStateAction} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import {StyledDatePicker} from "./styles";

interface BaseDatePickerProps {
    label: string;
    sx?: any;
    slotProps?: any
    value: any
    renderInput?: any
    setValue: Dispatch<SetStateAction<any>>
}


export const BaseDatePicker: FC<BaseDatePickerProps> = ({label, sx, slotProps, value, setValue}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <StyledDatePicker
                slotProps={slotProps}
                value={value}
                onChange={(newValue) => setValue(newValue)}
                sx={sx}
                label={label}
            />
        </LocalizationProvider>
    )
}

