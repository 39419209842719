// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.returnHistoryTable__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.returnHistoryTable th {
  font-size: 12px;
  vertical-align: baseline;
}
.returnHistoryTable td {
  font-size: 12px;
  vertical-align: baseline;
}
.returnHistoryTable__number {
  width: 7%;
}
.returnHistoryTable__date {
  width: 7%;
}
.returnHistoryTable__created {
  width: 14%;
}
.returnHistoryTable__contact {
  width: 14%;
}
.returnHistoryTable__phone {
  width: 7%;
}
.returnHistoryTable__address {
  width: 10%;
}
.returnHistoryTable__state {
  width: 3%;
}
.returnHistoryTable__btns {
  width: 5%;
}
.returnHistoryTable__link {
  text-decoration: unset;
  color: rgb(20, 189, 243);
}`, "",{"version":3,"sources":["webpack://./src/components/warranty/warrantyRequestsHistoryTable/WarrantyRequestsHistoryTable.sass"],"names":[],"mappings":"AAEE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AADJ;AAIE;EACE,eAAA;EACA,wBAAA;AAFJ;AAIE;EACE,eAAA;EACA,wBAAA;AAFJ;AAIE;EACE,SAAA;AAFJ;AAGE;EACE,SAAA;AADJ;AAEE;EACE,UAAA;AAAJ;AACE;EACE,UAAA;AACJ;AAAE;EACE,SAAA;AAEJ;AADE;EACE,UAAA;AAGJ;AAFE;EACE,SAAA;AAIJ;AAHE;EACE,SAAA;AAKJ;AAHE;EACE,sBAAA;EACA,wBAAA;AAKJ","sourcesContent":[".returnHistoryTable\n\n  &__wrapper\n    height: 100%\n    display: flex\n    flex-direction: column\n    justify-content: space-between\n\n\n  th\n    font-size: 12px\n    vertical-align: baseline\n\n  td\n    font-size: 12px\n    vertical-align: baseline\n\n  &__number\n    width: 7%\n  &__date\n    width: 7%\n  &__created\n    width: 14%\n  &__contact\n    width: 14%\n  &__phone\n    width: 7%\n  &__address\n    width: 10%\n  &__state\n    width: 3%\n  &__btns\n    width: 5%\n\n  &__link\n    text-decoration: unset\n    color: rgb(20, 189, 243)\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
