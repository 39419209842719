import styled from "styled-components";
import { systemWhite } from "../../constants";

export const FooterContainer = styled.div`
    width: 100%;
    padding: 25px 40px;
    background-color: #306BF5;
    border-top: 1px solid #447FFF;

    @media (max-width: 768px) {
        padding: 25px 16px;
    }
`;

export const FooterContent = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;

    @media (max-width: 768px) {
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 15px;
    }
`;

export const FooterTitle = styled.div`
    font: 400 12px/16px "Roboto", sans-serif;
    color: #ADC6FF;
`;

export const FooterLink = styled.a`
    font: 400 12px/16px "Roboto", sans-serif;
    text-decoration: none;
    color: ${systemWhite};
`;
