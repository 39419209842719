import axios from "axios";
import { resolve } from "./resolve.js";
import {API_BACKEND_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";


export async function fetchProductRequests(productID) {
    const sessionId = getSession()
    return await resolve(
        axios.get(`${API_BACKEND_URL}/products/${productID}`,
        {
            headers: {
                "Content-Type": "application/json",
                "session_id": sessionId,
            }
        }
    ).then(res => res?.data));
}
