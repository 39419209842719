import React, {useContext, useEffect, useState} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {MESSAGE_VARIANT} from "../../constants/consts";
import {MessageContext} from "../../hooks/UseMessage";
import {fetchOrdersRequests} from "../../api/orders";
import useSWR from "swr";
import {t} from "i18next";

interface ReserveModalProps {
    isOpen: boolean;
    onClose: () => void;
    onApply: (option: 'new' | 'existing', reserveId?: number) => void;
}

export const ReserveModal: React.FC<ReserveModalProps> = (props) => {

    const {isOpen, onClose, onApply} = props
    const [selectedOption, setSelectedOption] = React.useState<'new' | 'existing'>('new');
    const [selectedReserveId, setSelectedReserveId] = React.useState<number | undefined>(undefined);
    const [reserves, setReserves] = useState<any[]>([])
    const {showMessage} = useContext(MessageContext)
    const {data} = useSWR(
        "daily_reserves",
        () => fetchOrdersRequests(
            {
                pageNum: 1,
                isReserve: true,
                isDaily: false,
            }
    ))


    useEffect(() => {
        setReserves((data?.data?.data?.sale_info || []).map((el: { id: any; name: any; reservation_name: any; }) => {
            return {
                id: el.id,
                number: el.name,
                name: el.reservation_name
            }
        }))
    }, [data]);

    const handleApply = () => {
        if (selectedOption === 'existing' && !selectedReserveId) {
            showMessage({
                variant: MESSAGE_VARIANT.WARNING,
                text: t("invalidReserveNumber")
            })
            return
        }
        onApply(selectedOption, selectedReserveId);
        onClose();
    };


    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    borderRadius: "10px",
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t("choose_action")}</FormLabel>
                    <RadioGroup
                        aria-label="reserve"
                        name="reserve"
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value as "new" | "existing")}
                    >
                        <FormControlLabel
                            value="new"
                            control={
                                <Radio
                                    sx={{
                                        color: "rgb(110, 209, 243)",
                                        "&.Mui-checked": {color: "rgb(110, 209, 243)"},
                                    }}
                                />
                            }
                            label={t("create_new_reserve")}
                        />
                        <FormControlLabel
                            value="existing"
                            control={
                                <Radio
                                    sx={{
                                        color: "rgb(110, 209, 243)",
                                        "&.Mui-checked": {color: "rgb(110, 209, 243)"},
                                    }}
                                />
                            }
                            label={t("by_default")}
                        />
                    </RadioGroup>
                    {selectedOption === "existing" && (
                        <Select
                            value={selectedReserveId}
                            onChange={(e) => setSelectedReserveId(Number(e.target.value))}
                            displayEmpty
                            fullWidth
                            sx={{mt: 2}}
                        >
                            <MenuItem value="" disabled>
                                {t("select_reserve")}
                            </MenuItem>
                            {reserves.map((reserve) => (
                                <MenuItem key={reserve.id} value={reserve.id}>
                                    {reserve.number}
                                    {reserve.name && `- ${reserve.name}`}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                    <Box sx={{mt: 3, display: "flex", justifyContent: "space-between"}}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "rgb(110, 209, 243)",
                                "&:hover": {backgroundColor: "rgb(90, 180, 210)"},
                            }}
                            onClick={handleApply}
                        >
                            {t("apply")}
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                borderColor: "rgb(110, 209, 243)",
                                color: "rgb(110, 209, 243)",
                                "&:hover": {
                                    borderColor: "rgb(90, 180, 210)",
                                    color: "rgb(90, 180, 210)",
                                },
                            }}
                            onClick={onClose}
                        >
                            {t("cancel")}
                        </Button>
                    </Box>
                </FormControl>
            </Box>
        </Modal>

    );
};