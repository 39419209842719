import React, {FC, ReactElement, useEffect, useState} from "react";
import {TabsProps} from "./TabsProps";
import {TabItemProps} from "./TabsProps";
import {TabItem} from "./TabItem";

import "./Tabs.sass";

export const TabList: FC<TabsProps> = ({children, activeTabIndex = 0, className = ""}) => {

    const [activeTab, setActiveTab] = useState(activeTabIndex);

    useEffect(() => {
        setActiveTab(activeTabIndex)
    }, [activeTabIndex])

    const handleTabClick = (index: number) => {
        setActiveTab(index);
    };

    const tabs = React.Children.toArray(children).filter(
        (child): child is ReactElement<TabItemProps> =>
            React.isValidElement(child) && child.type === TabItem
    );

    return (
        <div className={`tabs ${className}`}>
            <nav className="tab-list-wrapper">
                <ul className="tab-list" role="tablist" aria-orientation="horizontal">
                    {tabs.map((tab, index) => (
                        <li key={`tab-${index}`}>
                            <button
                                key={`tab-btn-${index}`}
                                role="tab"
                                id={`tab-${tab.props.label}`}
                                aria-controls={`panel-${tab.props.label}`}
                                aria-selected={activeTab === index}
                                onClick={() => handleTabClick(index)}
                                className={`tab-btn ${
                                    activeTab === index && "tab-btn--active"
                                }`}
                            >{tab.props.label}</button>
                        </li>
                    ))}
                </ul>
            </nav>
            {tabs[activeTab]}
        </div>
    );

};