import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchSingleReturnRequests} from "../../api/returns";
import {ReturnType} from "../../components/catalog/types";

export type initialStateType = {
    isReturnLoading: boolean;
    returnData: ReturnType | undefined

}
const initialState: initialStateType = {
    returnData: undefined,
    isReturnLoading: true
}

export const fetchSingleReturn =
    createAsyncThunk("slice/fetchSingleReturn",
        async (saleId: number) => {
            const result = await fetchSingleReturnRequests(saleId)
            return result.data?.data
        })
export const resetReturn =
    createAsyncThunk("slice/resetSingleReturn",
        async () => initialState
    )

const returnSliceReducer = createSlice({
    name: "return",
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchSingleReturn.fulfilled, (state, action) => {
            state.returnData = action.payload
            state.isReturnLoading = false
        }).addCase(resetReturn.fulfilled, (state, action) => {
            Object.assign(state, initialState);
            state.isReturnLoading = false;
        })
    }
})

export default returnSliceReducer.reducer