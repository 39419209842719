import axios from 'axios';
import { resolve } from './resolve.js';
import {API_BACKEND_URL, ENDPOINT} from "../constants/consts";
import {getSession} from "./sessionManagement";
import {getExchangeHeaders} from "./helpers";



export async function downloadExcelRequest(productIds) {
    return await resolve(axios.post(`${API_BACKEND_URL}/sales/download_pricelist`,
    {
        product_ids: productIds,
        product_count: 0,
        filename: "Pricelist"
    }, {
            headers: getExchangeHeaders(),
        }
    ).then(res => res));
}
