import {Link, useLocation, useNavigate, useParams} from "react-router-dom";

import React, {useEffect, useState} from "react";

import TextField from "@mui/material/TextField";
import {ButtonCustom} from "../../components/auth/styles";
import {Trans} from "react-i18next";
import "./RecoverPasswordForm.sass"
import {t} from "i18next";
import {ROUTE_URLS} from "../../constants/consts";
import Swal from "sweetalert2";
import {recoverPassword, validateToken} from "../../api/userPasswordReset";


export const RecoverPasswordForm = () => {

    const {recoverToken} = useParams()
    const [email, setEmail] = useState("")
    const [newPassword, setNewPassword] = useState('');

    const location = useLocation()

    const navigate = useNavigate();

    const optionsForAlertSuccess: any = {
        title: "Пароль был успешно изменён",
        text: "",
        icon: "success",
        confirmButtonText: t("confirmButton"),
        confirmButtonColor: "#0ebfff",
    };

    const optionsForAlertWithInvalidToken: any = {
        title: 'Отказано в доступе',
        text: 'Отказано в доступе',
        icon: 'error',
        confirmButtonText: 'ОК',
        confirmButtonColor: '#0ebfff'
    }

    const optionsForAlertWithInvalidCred: any = {
        title: 'Такой пользователь не зарегистрирован',
        text: 'Попробуйте изменить почту или зарегистрироваться',
        icon: 'error',
        confirmButtonText: 'ОК',
        confirmButtonColor: '#0ebfff'
    }

    const validateAuthToken = async (recoverToken: string) => {
            const validate_response = await validateToken(recoverToken);
            if (validate_response.error) {
                navigate(ROUTE_URLS.SIGN_IN);
                Swal.fire(optionsForAlertWithInvalidToken).then(() => {
                });
            }
            if (validate_response?.data?.data) {
                setEmail(validate_response?.data?.data?.email)
            }
    }

    useEffect(() => {
        if (!recoverToken) return
        validateAuthToken(recoverToken)
    }, [location]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const data = {
                email: email,
                password: newPassword,
            }
            if (!recoverToken || !newPassword) return
            const response = await recoverPassword(JSON.stringify(data), recoverToken);

            if (response.data.data) {
                        navigate(ROUTE_URLS.HOME);
                Swal.fire(optionsForAlertSuccess).then(() => {
                });

            } else {
                Swal.fire(optionsForAlertWithInvalidCred).then(() => {
                });
            }
            navigate(ROUTE_URLS.SIGN_IN);
            Swal.fire(optionsForAlertSuccess).then(() => {
        });
        } catch (error) {
            Swal.fire(optionsForAlertWithInvalidCred).then(() => {
            });
        }
    };


    const textFieldStyles = {
        transition: 'width 0.3s ease-in-out',
        width: '100%',
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            backgroundColor: '#ebebeb',
            padding: "0 10px",
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                border: 'none',
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        }
    }

    const inputPropsStyles = {
        '&::placeholder': {
            color: '#757575',
            fontWeight: 'bold',
        },
        '& .MuiInputBase-input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px #ebebeb inset',
            WebkitTextFillColor: '#000000',
            transition: 'background-color 5000s ease-in-out 0s',
        },
        '& .MuiInputBase-input': {
            color: '#000000',
            fontWeight: 'bold',
        },
    };

    const inputLabelPropsStyles = {
        color: '#757575',
        fontWeight: 'bold',
        '&.Mui-focused': {
            color: '#757575',
        },
    };

    return (
        <div>
            <form className={"recover_form"} onSubmit={handleSubmit}>
                <p className={"help_text"}>
                    Для восстановления доступа к почте {email} введите новый пароль.
                </p>
                <TextField
                    required
                    type="code"
                    className={"recover_input"}
                    label="Новый пароль"
                    autoComplete="off"
                    InputProps={{
                        sx: inputPropsStyles,
                    }}
                    InputLabelProps={{
                        sx: inputLabelPropsStyles,
                    }}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    sx={textFieldStyles}
                    style={{marginBottom: "15px"}}
                />
                <ButtonCustom type="submit">
                    Обновить пароль
                </ButtonCustom>
            </form>
        </div>
    )
}