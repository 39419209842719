// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.creditLineTableWrapper {
  padding: 0 25px;
  max-width: 1200px;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.creditLineTableWrapper .creditLineTable {
  width: 100%;
}
.creditLineTableWrapper .creditLineTable__header tr {
  font-size: 12px;
}
.creditLineTableWrapper .creditLineTable__body tr {
  font-size: 12px;
  vertical-align: top;
}
.creditLineTableWrapper .creditLineTable__body tr:hover {
  cursor: pointer;
}
.creditLineTableWrapper .creditLineTable__body tr p {
  margin: initial;
}
.creditLineTableWrapper .creditLineTable__body td span {
  color: black;
  font-weight: bold !important;
}`, "",{"version":3,"sources":["webpack://./src/components/creditLine/creditLineHistory/CreditLineHistory.sass"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AACF;AAEE;EACE,WAAA;AAAJ;AAGM;EACE,eAAA;AADR;AAIM;EACE,eAAA;EACA,mBAAA;AAFR;AAIQ;EACE,eAAA;AAFV;AAIQ;EACE,eAAA;AAFV;AAKQ;EACE,YAAA;EACA,4BAAA;AAHV","sourcesContent":[".creditLineTableWrapper\n  padding: 0 25px\n  max-width: 1200px\n  overflow: auto\n  height: 100%\n  display: flex\n  flex-direction: column\n  justify-content: space-between\n\n\n  .creditLineTable\n    width: 100%\n\n    &__header\n      tr\n        font-size: 12px\n\n    &__body\n      tr\n        font-size: 12px\n        vertical-align: top\n\n        &:hover\n          cursor: pointer\n\n        p\n          margin: initial\n\n      td\n        span\n          color: black\n          font-weight: bold!important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
