import React, {useState, useEffect} from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {ControlButton} from "../../controlButton/controlButton";
import {getProductCategories} from "../../../api/catalogFilter";
import {useParams} from "react-router-dom";
import {setCatalogFilterValue, removeCatalogFilterValue} from "../../../store/slices/PagePaginagionSlice";
import {useAppDispatch} from "../../../hooks/ReduxHooks";
import {useTranslation} from "react-i18next";

const MAX_VISIBLE_ITEMS = 5;

interface FilterValue {
    id: number;
    name: string;
    count: number;
}

interface FilterAttribute {
    id: number;
    name: string;
    type_props: string;
    values: FilterValue[];
}

interface FilterData {
    attributes: FilterAttribute[];
}

export const CatalogFilter = () => {
    const {categoryId} = useParams<{ categoryId: string }>();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [visibleItems, setVisibleItems] = useState<{ [key: string]: number }>({});
    const [filterData, setFilterData] = useState<FilterData>({attributes: []});
    const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({});
    const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: { id: number, type: string }[] }>({});
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setVisibleItems({});
    };

    useEffect(() => {
        getProductCategories(categoryId)
            .then(response => {
                setFilterData(response.data.data);
            })
            .catch(() => {
                setFilterData({attributes: []});
            });
    }, [categoryId]);

    const toggleSectionOpen = (id: number) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleCheckboxChange = (sectionId: number, itemId: number, sectionType: string) => {
        setSelectedFilters((prevState) => {
            const sectionFilters = prevState[sectionId] || [];
            const existingFilter = sectionFilters.find((filter) => filter.id === itemId);

            const updatedFilters = existingFilter
                ? sectionFilters.filter((filter) => filter.id !== itemId)
                : [...sectionFilters, {id: itemId, type: sectionType}];

            return {...prevState, [sectionId]: updatedFilters};
        });
    };

    const applyFilters = (event: React.FormEvent) => {
        const filtersToSave: { [key: string]: { id: number, type: string }[] } = {};
        Object.keys(selectedFilters).forEach(sectionId => {
            filtersToSave[sectionId] = selectedFilters[sectionId].map(filter => ({
                id: filter.id,
                type: filter.type,
            }));
        });
        const filterIdsByType = Object.values(selectedFilters)
            .flat()
            .reduce((acc: { [key: string]: string }, filter) => {
                const filterType = filter.type;
                const filterId = String(filter.id);
                if (acc[filterType]) {
                    acc[filterType] += `,${filterId}`;
                } else {
                    acc[filterType] = filterId;
                }
                return acc;
            }, {});
        dispatch(setCatalogFilterValue(filterIdsByType));
    };

    const resetFilters = () => {
        setSelectedFilters({});
        dispatch(removeCatalogFilterValue({}));
    };

    const handleShowMore = (id: number, itemCount: number) => {
        setVisibleItems((prevState) => ({
            ...prevState,
            [id]: itemCount,
        }));
    };

    const open = Boolean(anchorEl);
    const id = open ? "filter-popover" : undefined;

    return (
        <div>
            <ControlButton
                className="btn btn-sm text-colored"
                icon="bi bi-funnel"
                controlButtonHandler={handleClick}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Typography sx={{p: 2}}>
                    <h2 className="fs-3">{t("filterSettings")}</h2>
                    <FormControl component="fieldset">
                        {filterData.attributes.map((section) => {
                            const isOpen = openSections[section.id] ?? false;
                            const visibleCount = visibleItems[section.id] || MAX_VISIBLE_ITEMS;
                            const selectedSectionFilters = selectedFilters[section.id] || [];

                            return (
                                <div key={`section-${section.id}`}>
                                    <FormLabel
                                        component="legend"
                                        onClick={() => toggleSectionOpen(section.id)}
                                        style={{cursor: "pointer", color: "black"}}
                                    >
                                        <i className={isOpen ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} ></i> {section.name}
                                    </FormLabel>
                                    {isOpen && (
                                        <FormGroup>
                                            {section.values.slice(0, visibleCount).map((item) => (
                                                <FormControlLabel
                                                    key={item.id}
                                                    control={
                                                        <Checkbox
                                                            className="text-colored"
                                                            checked={selectedSectionFilters.some(
                                                                (filter) => filter.id === item.id
                                                            )}
                                                            onChange={() =>
                                                                handleCheckboxChange(
                                                                    section.id,
                                                                    item.id,
                                                                    section.type_props
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={`${item.name} (${item.count})`}
                                                />
                                            ))}
                                            {visibleCount < section.values.length && (
                                                <button
                                                    style={{
                                                        color: "rgba(0, 0, 0, 0.6)",
                                                        textAlign: "left",
                                                        fontSize: "11pt",
                                                        paddingLeft: 0,
                                                        paddingTop: 0,
                                                    }}
                                                    className="btn"
                                                    onClick={() =>
                                                        handleShowMore(section.id, section.values.length)
                                                    }
                                                >
                                                    {t("showMore")} {section.values.length - visibleCount}
                                                </button>
                                            )}
                                        </FormGroup>
                                    )}
                                </div>
                            );
                        })}
                    </FormControl>
                    <div className="d-flex justify-content-between mt-3" style={{gap: "10pt"}}>
                        <button
                            className="btn btn-primary"
                            style={{backgroundColor: "rgb(20, 189, 243)", borderColor: "rgb(20, 189, 243)"}}
                            onClick={applyFilters}
                        >
                            {t("applyFilters")}
                        </button>
                        <button className="btn btn-secondary" onClick={resetFilters}>
                            {t("resetFilters")}
                        </button>
                    </div>
                </Typography>
            </Popover>
        </div>
    );
};
