// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RMA__label {
  max-width: 200px;
  text-transform: uppercase;
  font-weight: 500;
}
.RMA__btn {
  display: flex !important;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  row-gap: 10px;
  padding: 10px;
  margin: 8px;
  border: 2px solid #6ed1f3 !important;
}
.RMA__btn:hover {
  box-shadow: 1px 1px 1px rgba(110, 209, 243, 0.3);
}
.RMA__btn:active {
  box-shadow: 2px 2px 1px rgba(110, 209, 243, 0.6);
}
.new_request__section {
  margin-bottom: 25px;
}

.contact_address {
  background-color: unset;
  color: black;
}

.btn-search {
  max-width: 100px;
  width: 100%;
  border-color: rgb(110, 209, 243) !important;
  background-color: rgb(110, 209, 243) !important;
  color: white !important;
}

.upload_files__title {
  font-size: 16px;
  font-weight: 400;
}
.upload_files ul {
  list-style: none;
}
.upload_files .file_item {
  display: flex;
  justify-content: space-between;
  width: 100px;
}
.upload_files .file_delete {
  opacity: 0;
  transition: opacity 10ms ease;
}
.upload_files .file_delete:hover {
  opacity: 0.7;
  background-color: rgb(110, 209, 243);
}
.upload_files .file_delete:active {
  opacity: 1;
}

.delivery_address {
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/pages/warrantyRequest/WarrantyRequest.sass"],"names":[],"mappings":"AAKE;EACE,gBAAA;EACA,yBAAA;EACA,gBAAA;AAJJ;AAME;EACE,wBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,6BAAA;EACA,aAAA;EACA,aAAA;EACA,WAAA;EAEA,oCAAA;AALJ;AAOI;EACE,gDAAA;AALN;AAOI;EACE,gDAAA;AALN;AAUA;EACE,mBAAA;AARF;;AAUA;EACE,uBAAA;EACA,YAAA;AAPF;;AASA;EACE,gBAAA;EACA,WAAA;EACA,2CAAA;EACA,+CAAA;EACA,uBAAA;AANF;;AAUE;EACE,eAAA;EACA,gBAAA;AAPJ;AASE;EACE,gBAAA;AAPJ;AASE;EACE,aAAA;EACA,8BAAA;EACA,YAAA;AAPJ;AASE;EACE,UAAA;EACA,6BAAA;AAPJ;AAUI;EACE,YAAA;EACA,oCAAA;AARN;AAUI;EACE,UAAA;AARN;;AAUA;EACE,YAAA;AAPF","sourcesContent":[".new_request\n  &__wrapper\n\n\n.RMA\n  &__label\n    max-width: 200px\n    text-transform: uppercase\n    font-weight: 500\n\n  &__btn\n    display: flex !important\n    align-items: center\n    flex-wrap: nowrap\n    flex-direction: row\n    justify-content: space-around\n    row-gap: 10px\n    padding: 10px\n    margin: 8px\n\n    border: 2px solid #6ed1f3 !important\n\n    &:hover\n      box-shadow: 1px 1px 1px rgba(110, 209, 243, 0.3)\n\n    &:active\n      box-shadow: 2px 2px 1px rgba(110, 209, 243, 0.6)\n\n  &__icon\n\n\n.new_request__section\n  margin-bottom: 25px\n\n.contact_address\n  background-color: unset\n  color: black\n\n.btn-search\n  max-width: 100px\n  width: 100%\n  border-color: rgb(110, 209, 243) !important\n  background-color: rgb(110, 209, 243) !important\n  color: white !important\n\n\n.upload_files\n  &__title\n    font-size: 16px\n    font-weight: 400\n\n  ul\n    list-style: none\n\n  .file_item\n    display: flex\n    justify-content: space-between\n    width: 100px\n\n  .file_delete\n    opacity: 0\n    transition: opacity 10ms ease\n\n\n    &:hover\n      opacity: 0.7\n      background-color: rgb(110, 209, 243)\n\n    &:active\n      opacity: 1\n\n.delivery_address\n  width: 200px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
