import {Navigation, Pagination, Scrollbar, A11y} from "swiper/modules";

import {Swiper, SwiperSlide, useSwiper} from "swiper/react";


import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import 'swiper/css/pagination';
import {useState} from "react";
import {SwiperImage} from "./styles";

import "./SwiperSearchCategories.sass"

interface SwiperCategoryProps {
    categories?: any[]
    categId?: number
    setCategId: (newCategId: any) => void;
}


export const SwiperSearchCategories = (props: SwiperCategoryProps) => {
    const [swiperRef, setSwiperRef] = useState<typeof Swiper | any>({});


    const handleSlideClick = (id: number, index: number) => {
        if (!swiperRef) return
        props.setCategId(id)
        swiperRef.slideTo(index)
    };

    return (
        <div className="d-flex justify-content-center w-100">
            <Swiper
                onSwiper={setSwiperRef}
                preventInteractionOnTransition={true}
                slidesPerView="auto"
                slideToClickedSlide={false}
                centeredSlides={false}
                spaceBetween={10}
                mousewheel={true}
                navigation={true}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                className="category__wrapper"
            >
                {props.categories && props.categories.map((el, index) => {
                    return <SwiperSlide
                            key={`$categ_slide_${el.id}`}
                            draggable={false}
                            className={`category__swiper_slide text-center ${props.categId === el.id && "active"}`}
                            onClick={() => handleSlideClick(el.id, index)}
                        >
                        <SwiperImage
                            className={"category__swiper_img"}
                            loading={"lazy"}
                            src={el.image_1920}
                        />
                        <h6 className="text-center">{el.name}</h6>
                    </SwiperSlide>
                })}
            </Swiper>

        </div>
    );
};