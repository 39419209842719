import styled from "styled-components";
import {systemBlack} from "../../constants";
import {motion} from "framer-motion";

export const ContactTitle = styled.span`
    font: 700 16px/18px "Roboto", sans-serif;
    color: ${systemBlack};
`;

export const ContactsSectionContent = styled(motion.section)`
    padding: 50px 40px;
    background-color: #FAFBFF;

    @media (max-width: 768px) {
        padding: 30px 16px;
    }
`;

export const ContactsSectionContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 50px;
`;

export const ContactsContent = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    gap: 50px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
    }
`;

export const ContactsBlock = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;

    @media (max-width: 768px) {
        gap: 10px;
    }
`;

export const Pane = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;

    @media (max-width: 768px) {
        min-width: 150px;
    }
`;
