import axios from 'axios';
import {resolve} from './resolve.js';
import {API_BACKEND_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";

export async function getProductCategories(categoryId) {
    const sessionId = getSession()
    return await resolve(
        axios.get(`${API_BACKEND_URL}/categories/category/attribute/${categoryId}`,
        {
            headers: {
                "Content-Type": "application/json",
                "session_id": sessionId,
            }
        }
    ).then(res => res));
}
