import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../../hooks/ReduxHooks";
import {Trans} from "react-i18next";

export const WarrantyDeliveryInfo = () => {
    const {warrantyRequestData, isWarrantyRequestLoading} = useAppSelector(state => state.warrantyRequest)


    return ( warrantyRequestData ?
        <>
            <div id="informations" className="row">
                <div id="return_info" className="col-12 col-lg-6 mb-4">
                    <table className="table table-borderless table-sm">
                        <tbody>
                        <tr>
                            <th className="w-40 ps-0 pb-0"><Trans i18nKey="created_date"/>:</th>
                            <td className="w-60 pb-0 text-nowrap text-end">
                                <span>{warrantyRequestData?.create_date.split(" ")[0]}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="w-40 ps-0 pb-0"><Trans i18nKey="approved_date"/>:</th>
                            <td className="w-60 pb-0 text-nowrap text-end">
                                <span>{warrantyRequestData?.approve_date}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="w-40 ps-0 pb-0"><Trans i18nKey="shipment_date"/>:</th>
                            <td className="w-60 pb-0 text-nowrap text-end">
                                <span></span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div id="return_info" className="col-12 col-lg-6 mb-4">
                    <table className="table table-borderless table-sm">
                        <tbody>
                        <tr>
                            <th className="w-40 ps-0 pb-0"><Trans i18nKey="created_by"/>:</th>
                            <td className="w-60 pb-0 text-nowrap  text-end">
                                <span>{warrantyRequestData.responsible_partner}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="w-40 ps-0 pb-0"><Trans i18nKey="delivery_address_label"/>:</th>
                            <td className="w-60 pb-0 text-nowrap  text-end">
                                <span></span>
                            </td>
                        </tr>
                        <tr>
                            <th className="w-40 ps-0 pb-0"><Trans i18nKey="phone"/>:</th>
                            <td className="w-60 pb-0 text-nowrap  text-end">
                                <span></span>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </> : <></>
    )
}