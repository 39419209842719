import {createContext, Dispatch, SetStateAction} from "react";

interface NavbarContextType {
    navbarBadgeTrigger: boolean;
    setNavbarBadgeTrigger: Dispatch<SetStateAction<boolean>>;
}

const NavbarContextValue: NavbarContextType = {
    navbarBadgeTrigger: false,
    setNavbarBadgeTrigger: () => {},
};

export const NavbarContext = createContext<NavbarContextType>(NavbarContextValue)
