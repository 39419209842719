// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.return_sidebar .btn {
  flex: 0 0 40%;
  border-color: rgb(110, 209, 243) !important;
  background-color: rgb(110, 209, 243) !important;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/warranty/warrantyContent/warrantySidebar/WarrantySidebar.sass"],"names":[],"mappings":"AAEE;EACE,aAAA;EACA,2CAAA;EACA,+CAAA;EACA,YAAA;AADJ","sourcesContent":[".return_sidebar\n\n  .btn\n    flex: 0 0 40%\n    border-color: rgb(110, 209, 243) !important\n    background-color: rgb(110, 209, 243) !important\n    color: white"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
