import React from "react";
import {
    AdvantageItemContent,
    AdvantageIcon,
    AdvantageInfo,
    Title,
    Subtitle,
} from "./styles";

interface AdvantageItemProps {
    icon: string;
    title: string;
    subtitle?: string;
}


export const AdvantageItem = ({ icon, subtitle, title }: AdvantageItemProps) => {
    return (
        <AdvantageItemContent>
            <AdvantageIcon src={icon} alt="advantage-icon"/>
            <AdvantageInfo>
                <Title>{title}</Title>
                {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </AdvantageInfo>
        </AdvantageItemContent>
    );
}
