import styled from "styled-components";
import { systemWhite, systemBlack, DEFAULT_HEADER_HEIGHT } from "../../constants";
import {motion} from "framer-motion";

export const HeaderContainer = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: ${systemWhite};
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${DEFAULT_HEADER_HEIGHT};
    padding: 10px 60px;
    z-index: 10;
`;

export const Image = styled.img`
    width: 191px;
    height: 40px;
`;

export const HeaderText = styled.span`
    font: 400 14px/24px "Roboto", sans-serif;
    color: ${systemBlack};
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;

export const RightPane = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
`;

export const Dropdown = styled.div<{ $isLanguageMenuOpen: boolean }>`
    position: relative;
    cursor: pointer;
    font: 400 14px/24px "Roboto", sans-serif;
    color: ${systemBlack};
    padding: 12px;
    border-radius: 4px;
    background-color: ${({ $isLanguageMenuOpen }) => ($isLanguageMenuOpen ? '#F5F7FA' : systemWhite)};
    
    i {
        margin-left: 2px;
    }
`;

export const DropdownMenu = styled(motion.ul)`
    position: absolute;
    top: 100%;
    left: 0;
    background-color: ${systemWhite};
    box-shadow: 0 10px 50px 0 #122A6033;
    list-style: none;
    padding: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 100;
`;

export const DropdownMenuItem = styled.li`
    padding: 8px 20px;
    cursor: pointer;
    transition: background 0.3s;
    font: 400 14px/24px "Roboto", sans-serif;
    color: ${systemBlack};
    margin: 0;

    &:hover {
        background-color: #F5F7FA;
    }
`;

export const HeaderButtonsContainer = styled(motion.div)`
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;
`;
