import { createSlice } from '@reduxjs/toolkit';

interface BreadcrumbSliceState {
    crumbs : any
}

const initialState: BreadcrumbSliceState = {
    crumbs: {
        "/": "Главная"
    }
};

const breadcrumbsSlice = createSlice({
    name: 'breadcrumbs',
    initialState,
    reducers: {

        updateBreadcrumb: (state, action) => {
            const { key, value } = action.payload;
            if (!state.crumbs.hasOwnProperty(key)) {
                state.crumbs[key] = value;
            }
            else  {
                if (value && state.crumbs[key] !== value){
                    state.crumbs[key] = value;
                }
            }
        },

    }
});

export const { updateBreadcrumb} = breadcrumbsSlice.actions;
export default breadcrumbsSlice.reducer;
