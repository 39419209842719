import React, {useCallback, useState} from "react";
import {
    AdditionalSectionContent,
    AdditionalSectionContainer,
    AdditionalSectionTitle,
} from "./styles";
import { ButtonsContainer, SectionSecondaryButton, SectionPrimaryButton } from "../../styles";
import {t} from "i18next";
import { useNavigate } from "react-router-dom";
import { SubmitFormModal } from "../submitFormModal";

export const AdditionalSection = () => {
    const navigate = useNavigate();
    const [isSubmitModalOpen, setOpenSubmitModal] = useState(false);

    const handleNavigateToSignIn = () => {
        navigate("/sign-in");
    };

    const toggleModal = useCallback(() => {
        setOpenSubmitModal(prev => !prev);
    }, []);

    return (
        <AdditionalSectionContent
            initial={{opacity: 0, y: 50}}
            whileInView={{opacity: 1, y: 0}}
            transition={{duration: 0.8}}
        >
            <AdditionalSectionContainer>
                <AdditionalSectionTitle>{t("additional_section_title")}</AdditionalSectionTitle>
                <ButtonsContainer $shouldApplyColumnStyles>
                    <SectionPrimaryButton onClick={handleNavigateToSignIn}>{t("section_primary_button")}</SectionPrimaryButton>
                    <SectionSecondaryButton onClick={toggleModal} $withBorder>{t("section_secondary_button")}</SectionSecondaryButton>
                    <SubmitFormModal isSubmitModalOpen={isSubmitModalOpen} toggleModal={toggleModal} />
                </ButtonsContainer>
            </AdditionalSectionContainer>
        </AdditionalSectionContent>
    );
}
