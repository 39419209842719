import {createContext} from "react";
import {ReturnLineType, SaleOrderLineType} from "../components/catalog/types";



interface orderLinesContextType {
    selectedLines: number[];
    lines: any[]
    setSelectedLines: (selectedLines: number[]) => void;
    setLines: (lines: any) => void;
    setIsLoading: (isLoading: boolean) => void;
}


const orderLinesContextValue: orderLinesContextType = {
    selectedLines: [],
    lines: [],
    setSelectedLines: () => {},
    setLines: () => {},
    setIsLoading: () => {},
};

export const OrderLinesContext = createContext<orderLinesContextType>(orderLinesContextValue)
