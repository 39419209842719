// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-dialog {
  font-size: 12px;
}
.add-dialog__section {
  margin-bottom: 25px;
}
.add-dialog__section .file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 5px 0;
  background-color: white;
  border: 1px solid rgba(110, 209, 243, 0.3);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  width: 200px;
  max-width: 200px;
  height: 50px;
  white-space: nowrap;
}
.add-dialog__section .upload_files ul {
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/productReturns/addLineDialog/AddLineDialog.sass"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,mBAAA;AACJ;AACI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,0CAAA;EACA,kBAAA;EACA,wCAAA;EACA,sCAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;AACN;AAEM;EACE,SAAA;AAAR","sourcesContent":[".add-dialog\n  font-size: 12px\n\n  &__section\n    margin-bottom: 25px\n\n    .file-item\n      display: flex\n      align-items: center\n      justify-content: space-between\n      padding: 10px\n      margin: 5px 0\n      background-color: white\n      border: 1px solid rgba(110, 209, 243, 0.3)\n      border-radius: 4px\n      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1)\n      transition: background-color 0.3s ease\n      width: 200px\n      max-width: 200px\n      height: 50px\n      white-space: nowrap\n\n    .upload_files\n      ul\n        gap: 10px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
