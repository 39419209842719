import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import {inputProps, inputStyleSM} from "../ProductReturns";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import {formGroupStyle} from "../../catalog/catalogControl/style";
import {MESSAGE_VARIANT, PRODUCT_CONDITION} from "../../../constants/consts";
import {ConditionCheckbox} from "./ConditionCheckbox";
import "./AddLineDialog.sass"
import {UploadFile} from "./UploadFile";
import {useContext, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import {linesToReturnType, setLineToReturn} from "../../../store/slices/NewReturnSlice";
import {MessageContext} from "../../../hooks/UseMessage";
import {PRODUCT_CONDITION_KEYS} from "../../catalog/types";

interface AddLineDialogProps {
    openedLine: any,
    handleClose: () => void,

}

export const AddLineDialog = (props: AddLineDialogProps) => {

    const {openedLine, handleClose} = props
    const [quantity, setQuantity] = useState<number | "">("")
    const [files, setFiles] = useState<File[]>([])
    const [isOpened, setIsOpened] = useState<boolean>(false)
    const [description, setDescription] = useState<string>("")
    const [productState, setProductState] = useState<string | undefined>(PRODUCT_CONDITION_KEYS.not_opened)

    const {showMessage} = useContext(MessageContext)
    const {linesToReturn} = useAppSelector(state => state.newReturn)

    const dispatch = useAppDispatch()


    if (!openedLine) return <></>
    const maxQuantity = openedLine.quantity || 0

    const handleSubmit = () => {
        if (!openedLine) return
        if (quantity && quantity > 0) {
            let newLineData: linesToReturnType = {
                id: linesToReturn.length + 1,
                lineId: openedLine.id,
                lineData: openedLine,
                filesData: files,
                quantity: quantity,
                condition: productState,
                description: "",
            }
            if (isOpened) {
                Object.assign(newLineData, {
                    description: description,
                })
            }
            dispatch(setLineToReturn([...linesToReturn, newLineData]))
            handleDialogClose()
        } else {
            showMessage({
                variant: MESSAGE_VARIANT.ERROR,
                text: `Кол-во должно быть больше 0`
            })
        }

    }

    const handleDialogClose = () => {
        setQuantity(0)
        setFiles([])
        setIsOpened(false)
        setDescription("")
        setProductState(PRODUCT_CONDITION_KEYS.not_opened)
        handleClose()
    }

    const handleSetQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        if (!value) {
            setQuantity("")
            return;
        }
        const numValue = Number(value)
        if (isNaN(numValue)) return
        if (numValue >= 0 && numValue <= maxQuantity) {
            setQuantity(numValue)
        } else {
            showMessage({
                variant: MESSAGE_VARIANT.WARNING,
                text: `Недопустимое количество ${numValue}! Максимально допустимое значение ${maxQuantity}`
            })
        }
        return;
    }

    const handleChangeOpened = () => {
        setProductState(isOpened ? PRODUCT_CONDITION_KEYS.not_opened : undefined)
        setIsOpened(!isOpened)
    }

    return (openedLine &&

        <div className="position-relative">
            <Dialog
                open={!!openedLine}
                onClose={handleDialogClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleDialogClose();
                    },
                }}
            >
                <DialogTitle>Информация о продукте</DialogTitle>
                <DialogContent className="add-dialog">
                    <section className="add-dialog__section">
                        <RadioGroup
                            aria-labelledby="radio-buttons-group-open-label"
                            defaultValue={isOpened}
                            name="radio-buttons-group"
                            onChange={handleChangeOpened}
                        >
                            <FormControlLabel value={false} control={<Radio/>} label="Не открытый продукт"/>
                            <FormControlLabel value={true} control={<Radio/>}
                                              label="Открытый продукт/ поврежденный продукт"/>
                        </RadioGroup>
                    </section>
                    <section className="add-dialog__section">
                        <TextField id="outlined-basic"
                                   size="medium"
                                   label="Количество"
                                   variant="outlined"
                                   inputProps={inputProps}
                                   required
                                   sx={inputStyleSM}
                                   value={quantity}
                                   onChange={handleSetQuantity}
                        />
                        <p>Количество, доступное для возврата: <span>{maxQuantity}</span></p>
                    </section>
                    {isOpened && (
                        <section className="add-dialog__section">
                            <TextField
                                className={`add-file__dialog`}
                                label="Примечания"
                                value={description}
                                onChange={(event) => {
                                    setDescription(event.target.value)
                                }}
                                multiline
                                minRows={5}
                                maxRows={5}
                                sx={{width: "100%"}}
                            />
                            <FormLabel component="legend">Состояние продукта и комплектация<span
                                className={"text-danger"}>*</span></FormLabel>
                            <FormGroup sx={formGroupStyle}>
                                {Object.keys(PRODUCT_CONDITION).map((key) => (
                                    <div key={"display" + key}>
                                        <ConditionCheckbox
                                            displayItem={key}
                                            productState={productState}
                                            onChange={() => setProductState(key)}
                                        />
                                    </div>
                                ))}
                            </FormGroup>
                        </section>
                    )}

                    <section className="add-dialog__section">
                        <UploadFile
                            files={files}
                            setFiles={setFiles}
                        />
                    </section>
                    <DialogContentText>
                        Добавь каждый продукт отдельно, если возвращаешь несколько
                        продуктов с одинаковым кодом, но в разном состоянии или комплектации
                    </DialogContentText>
                </DialogContent>

                <DialogActions
                    style={{
                        justifyContent: "start"
                    }}
                >
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className="btn btn-search">Добавить
                    </button>
                    <button
                        onClick={handleDialogClose}
                        className="btn btn-search"
                    >Отменить
                    </button>
                </DialogActions>
            </Dialog>

        </div>
    );
}