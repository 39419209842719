import styled from "styled-components";

export const SearchContainer = styled.div`
    width: 100%;
    background-color: #f4faf0;
    padding: 25px;
`;

export const ButtonSearch = styled.button`
    background-color: #83c144;
    color: white;
`;
