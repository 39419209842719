import axios from "axios";
import { resolve } from "./resolve.js";
import {API_BACKEND_URL, DATE_RANGE} from "../constants/consts";
import {getSession} from "./sessionManagement";
import {Dayjs} from "dayjs";
import {getExchangeHeaders} from "./helpers";


export async function fetchOrdersRequests(pageNum: number, searchValue: string | undefined, isReserve = false, isDaily = false, isActive: boolean | undefined = undefined, isPreOrder = false) {
    const sessionId = getSession()
    const searchUrlPart = searchValue ? `?filter=["name", "ilike", "${searchValue}"]` : ""
    let additionalParams = `?is_reserve=${isReserve}&is_daily=${isDaily}`
    if (isActive !== undefined) {
        additionalParams += `&is_active=${isActive}`
    }
    if (isPreOrder !== undefined) {
        additionalParams += `&is_pre_order=${isPreOrder}`
    }
    const URL = `${API_BACKEND_URL}/sales/page/${pageNum}${searchUrlPart}${additionalParams}`
    return await resolve(axios.get(URL,
            {
                headers: getExchangeHeaders(),
            }
        ).then(res => res)
    );
}

export async function fetchOrdersHistory(params: { groupby: DATE_RANGE; dateFrom: Dayjs | null; dateTo: Dayjs | null; }) {
    const {groupby, dateFrom, dateTo} = params
    const sessionId = getSession()
    let data = {
        groupby: groupby || "day",
    }
    if (dateFrom && dateTo && dateFrom.isValid() && dateTo.isValid()) {
        Object.assign(data, {
            date_from: dateFrom.format('DD/MM/YYYY'),
            date_to: dateTo.format('DD/MM/YYYY'),
        })
    }
    const URL = `${API_BACKEND_URL}/sales/get_sale_history`
    return await resolve(axios.post(URL,
        data,
        {
            headers: getExchangeHeaders(),
        }
    ).then(res => res)
    );
}

export async function fetchTopSaleItems() {
    const URL = `${API_BACKEND_URL}/sales/get_top_sale_items`
    return await resolve(axios.post(URL,
        {},
        {
            headers: getExchangeHeaders(),
        }
    ).then(res => res)
    );
}

export async function updateOrderLines(data: { id_order: number; line_data: { product_id: number; quantity: any; }[] | { product_id: any; quantity: any; }[] | { id: number; quantity: number; requested_qty?: number | undefined; }[]; }){
    if (!data) return
    return await resolve(axios.post(`${API_BACKEND_URL}/sales/update_lines`,
        data,{
            headers: getExchangeHeaders(),
        }

    ).then(res => res));
}

export async function deleteOrderLines(data: { ids: any[] | number[]; }){
    if (!data) return
    return await resolve(axios.post(`${API_BACKEND_URL}/sales/delete_lines`,
        data,{
            headers: getExchangeHeaders(),
        }

    ).then(res => res));
}

export async function fetchUpdateOrder(saleId: number, data: {
    state?: string;
    is_b2b_active?: boolean;
    reservation_name?: any;
    customer_name?: string;
    phone?: string;
    email?: string;
    city?: string;
    postal_code?: string;
    address?: string;
    countryId?: number;
    addressId?: number;
    isDropshipping?: boolean;
    comment?: string;
    company_type?: string;
}){
    if (!data) return
    return await resolve(axios.patch(`${API_BACKEND_URL}/sales/update_order?sale_id=${saleId}`,
        data,{
            headers: getExchangeHeaders(),
        }

    ).then(res => res));
}


export async function fetchSingleOrderRequests(saleId: number | undefined) {
    const URL = `${API_BACKEND_URL}/sales/${saleId}`
    return await resolve(axios.get(URL,
            {
                headers: getExchangeHeaders(),
            }
        ).then(res => res)
    );
}

export async function fetchReturnOrderLines(data: any) {
    const URL = `${API_BACKEND_URL}/sales/get_lines`
    return await resolve(axios.post(URL,
            data,{
                headers: getExchangeHeaders(),
            }
        ).then(res => res)
    );
}

export async function fetchOrderLinesPaginated(pageNum: number, isReserve=false) {
    const additionalParams = `?is_reserve=${isReserve}`
    const URL = `${API_BACKEND_URL}/sales/sale_lines/page/${pageNum}${additionalParams}`
    return await resolve(axios.get(URL,
        {
            headers: getExchangeHeaders(),
        }
    ).then(res => res)
    );
}
