import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchProductRequests} from "../../api/catalogProduct";
import {ProductType} from "../../components/catalog/types";


export type ProductItemSliceStateType = {
    product: ProductType | undefined,
    isProductLoading: boolean,
}

const initialState:  ProductItemSliceStateType = {
    product: undefined,
    isProductLoading: true,
}

export const fetchProduct =
    createAsyncThunk("slice/productAdd",
        async (productId: number) => {
            const data = await fetchProductRequests(productId)
            return data?.data
        }
    )

export const resetProduct =
    createAsyncThunk("slice/productReset",
        async () => initialState.product
    )

const productItemSliceReducer = createSlice({
    name: 'product',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchProduct.fulfilled, (state, action) => {
            state.product = action.payload
            state.isProductLoading = false
        }).addCase(resetProduct.fulfilled, (state, action) => {
            state.product = action.payload
        })
    }
})

export default productItemSliceReducer.reducer