import {useState, useEffect, memo} from "react";
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem} from "react-pro-sidebar";
import RecommendIcon from "@mui/icons-material/Recommend";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import WalletRoundedIcon from "@mui/icons-material/WalletRounded";
import ShieldRoundedIcon from "@mui/icons-material/ShieldRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import {SubSidebar} from "../subSidebar/SubSidebar";
import Logo from '../../images/rde.png';
import {SUBMENU_TYPES} from "../../constants/consts";
import useSWR from "swr";
import { setHierarchyCategoryData, setHierarchyCategoryError } from "../../store/slices/MenuCategoriesSlice";
import { getCategories } from "../../api/categories";
import "./SideBar.sass"
import {useDispatch} from "react-redux";





export const MainSidebar = memo(() => {
    const dispatch = useDispatch();
    const [submenuType, setSubmenuType] = useState<SUBMENU_TYPES | string>("")
    const [activeOverlayClass, setActiveOverlayClass] = useState("");
    const [subSidebarClassName, setSubSidebarClassName] = useState("");
    const [isChildSubSidebarClose, setIsChildSubSidebarClose] = useState(false);
    const [isMainCategoryVisible, setIsMainCategoryVisible] = useState(false);
    const [isChildCategoryVisible, setIsChildCategoryVisible] = useState(false);

    const { data, error: swrError } = useSWR(
        "category",
        () => getCategories(),
        {
            revalidateOnFocus: false,
        }
    );

    useEffect(() => {
        if (data) {
            dispatch(setHierarchyCategoryData(data.data.data));
        } else if (swrError) {
            dispatch(setHierarchyCategoryError(swrError.message));
        }
    }, [data, swrError, dispatch]);

    const closeAllCategory = () => {
        setIsChildCategoryVisible(false);
        setTimeout(() => {
            setIsMainCategoryVisible(false);
            document.body.style.overflow = "initial";
            setActiveOverlayClass("");
        }, 100);

    };

    const openSubCategory = (type: SUBMENU_TYPES) => {
        if (submenuType === type && isMainCategoryVisible) {
            closeAllCategory();
        } else {
            setSubmenuType(type);
            setIsMainCategoryVisible(true);
            setIsChildCategoryVisible(false);
            document.body.style.overflow = "hidden";
            setActiveOverlayClass("isActiveOverlay");
        }
    }





    return (
        <>
            <Sidebar className="mainSidebar"
                     width={"220px"}
                     defaultCollapsed={false}
                     collapsed={false}>
                <Menu>
                    <MenuItem
                        component={<Link to="/" className="link"/>}
                        className="menuItem"
                        onClick={() => closeAllCategory()}
                    >
                        <img alt="" src={Logo} style={{width: "165px"}}/>
                    </MenuItem>
                    <MenuItem
                        className="menuItem"
                        icon={<GridViewRoundedIcon/>}
                        onClick={() => openSubCategory(SUBMENU_TYPES.CATALOG)}
                    >
                        Каталог
                    </MenuItem>
                    <MenuItem
                        className="menuItem"
                        onClick={() => openSubCategory(SUBMENU_TYPES.ORDERS)}
                        icon={<ReceiptRoundedIcon/>}
                    >
                        Заказы
                    </MenuItem>
                    <MenuItem
                        className="menuItem"
                        icon={<WalletRoundedIcon/>}
                        onClick={() => openSubCategory(SUBMENU_TYPES.FINANCIAL)}
                    >
                        Финансы
                    </MenuItem>
                    <MenuItem
                        className="menuItem"
                        icon={<ShieldRoundedIcon/>}
                        onClick={() => openSubCategory(SUBMENU_TYPES.GUARANTEE)}
                    >
                        Гарантии
                    </MenuItem>
                    <MenuItem
                        className="menuItem"
                        icon={<HandshakeIcon/>}
                        onClick={() => closeAllCategory()}
                        component={<Link to="/" className="link"/>}
                    >
                        Услуги
                    </MenuItem>
                    <MenuItem
                        className="menuItem"
                        icon={<RecommendIcon/>}
                        onClick={() => closeAllCategory()}
                        component={<Link to="/" className="link"/>}
                    > Рекомендуем
                    </MenuItem>
                    <MenuItem
                        className="menuItem"
                        onClick={() => openSubCategory(SUBMENU_TYPES.QUESTIONARY)}
                        icon={<NotificationsRoundedIcon/>}
                    >
                        Информация
                    </MenuItem>
                </Menu>
                <div className="textInfoMenu">
                    <div className="textInfoMenu__wrapper">
                        <div className="textInfoSmall">Ваш менеджер</div>
                        <div className="textInfo">Петров Александр</div>
                        <div className="textInfo">277729221</div>
                        <div className="textInfo">еtestt123@gmail.com</div>
                    </div>
                </div>
            </Sidebar>
                <SubSidebar
                    closeAllCategory={closeAllCategory}
                    setIsChildSubSidebarClose={setIsChildSubSidebarClose}
                    isChildSubSidebarClose={isChildSubSidebarClose}
                    submenuType={submenuType as SUBMENU_TYPES}
                    className={subSidebarClassName}
                    isMainCategoryVisible={isMainCategoryVisible}
                    isChildCategoryVisible={isChildCategoryVisible}
                    setIsChildCategoryVisible={setIsChildCategoryVisible}
                />
            <div onClick={closeAllCategory} className={`overlay ${activeOverlayClass}`}></div>
        </>
    )
})