// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.totalCartBill {
  display: flex;
  gap: 5px;
}
.totalCartBill__checkout {
  display: grid;
  gap: 30px;
  border: 1px solid lightgray;
  border-radius: 10px;
  flex: 1 1;
  padding: 20px;
}
.totalCartBill__checkout__row {
  display: grid;
  gap: 5px;
}
.totalCartBill__checkout__row .bold-text {
  font-size: 14pt;
  font-weight: bold !important;
}
.totalCartBill__checkout__row .small-text {
  color: gray;
  font-size: 10pt;
}
.totalCartBill__checkout__row__item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.totalCartBill__checkout__row__item:first-child {
  font-weight: 500;
  flex: 1 0 60%;
}
.totalCartBill__checkout__row__item:last-child {
  font-weight: 500;
  flex: 0 0 35%;
}
.totalCartBill__order {
  margin-top: 30px;
}
.totalCartBill__order__privacy {
  font-size: 10pt;
}
.totalCartBill__order__privacy input {
  vertical-align: middle;
  margin-right: 10px;
}
.totalCartBill__order__privacy a {
  text-decoration: none;
  color: rgb(110, 209, 243);
}
.totalCartBill__order__buttons {
  display: flex;
  margin-top: 10px;
  gap: 20px;
}
.totalCartBill__order__buttons button {
  flex: 0 0 40%;
  border-color: rgb(110, 209, 243) !important;
}
.totalCartBill__order__buttons button.order {
  background-color: rgb(110, 209, 243);
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/totalCartBill/TotalCartBill.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;AACF;AACE;EACE,aAAA;EACA,SAAA;EACA,2BAAA;EACA,mBAAA;EACA,SAAA;EACA,aAAA;AACJ;AACI;EACE,aAAA;EACA,QAAA;AACN;AACM;EACE,eAAA;EACA,4BAAA;AACR;AACM;EACE,WAAA;EACA,eAAA;AACR;AACM;EACE,aAAA;EACA,uBAAA;EACA,8BAAA;AACR;AAEQ;EACE,gBAAA;EACA,aAAA;AAAV;AAEQ;EACE,gBAAA;EACA,aAAA;AAAV;AAGE;EAEE,gBAAA;AAFJ;AAII;EACE,eAAA;AAFN;AAIM;EACE,sBAAA;EACA,kBAAA;AAFR;AAIM;EACE,qBAAA;EACA,yBAAA;AAFR;AAII;EACE,aAAA;EACA,gBAAA;EACA,SAAA;AAFN;AAIM;EACE,aAAA;EACA,2CAAA;AAFR;AAIM;EAEE,oCAAA;EACA,YAAA;AAHR","sourcesContent":[".totalCartBill\n  display: flex\n  gap: 5px\n\n  &__checkout\n    display: grid\n    gap: 30px\n    border: 1px solid lightgray\n    border-radius: 10px\n    flex: 1\n    padding: 20px\n\n    &__row\n      display: grid\n      gap: 5px\n\n      .bold-text\n        font-size: 14pt\n        font-weight: bold !important\n\n      .small-text\n        color: gray\n        font-size: 10pt\n\n      &__item\n        display: flex\n        align-items: flex-start\n        justify-content: space-between\n\n\n        &:first-child\n          font-weight: 500\n          flex: 1 0 60%\n\n        &:last-child\n          font-weight: 500\n          flex: 0 0 35%\n\n\n  &__order\n\n    margin-top: 30px\n\n    &__privacy\n      font-size: 10pt\n\n      input\n        vertical-align: middle\n        margin-right: 10px\n\n      a\n        text-decoration: none\n        color: rgb(110, 209, 243)\n\n    &__buttons\n      display: flex\n      margin-top: 10px\n      gap: 20px\n\n      button\n        flex: 0 0 40%\n        border-color: rgb(110, 209, 243) !important\n\n      button.order\n\n        background-color: rgb(110, 209, 243)\n        color: white\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
