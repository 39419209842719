// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders_home_table__link a {
  text-decoration: none;
  color: rgb(94, 208, 246);
  font-weight: 700;
  font-size: 11pt;
}
.orders_home_table__title {
  font-weight: 700;
  font-size: 11pt;
}
.orders_home_table a {
  text-decoration: none;
  color: rgb(94, 208, 246);
  font-weight: 700;
  font-size: 11pt;
}`, "",{"version":3,"sources":["webpack://./src/components/orders/ordersHomeTable/OrdersHomeTable.sass"],"names":[],"mappings":"AAIQ;EACI,qBAAA;EACA,wBAAA;EACA,gBAAA;EACA,eAAA;AAHZ;AAKI;EACI,gBAAA;EACA,eAAA;AAHR;AAMI;EACI,qBAAA;EACA,wBAAA;EACA,gBAAA;EACA,eAAA;AAJR","sourcesContent":[".orders_home_table\n    &__wrapper\n\n    &__link\n        a\n            text-decoration: none\n            color: rgb(94, 208, 246)\n            font-weight: 700\n            font-size: 11pt\n\n    &__title\n        font-weight: 700\n        font-size: 11pt\n\n\n    a\n        text-decoration: none\n        color: rgb(94, 208, 246)\n        font-weight: 700\n        font-size: 11pt"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
