import React from 'react';
import { Box, Typography, IconButton, Link } from '@mui/material';
import {
    Cancel,
    Download,
    Image,
    PictureAsPdf,
    Description,
    VideoLibrary,
    Audiotrack,
    Code,
    InsertDriveFile,
    Archive
} from '@mui/icons-material';

interface AttachmentProps {
    file?: File;
    filename?: string;
    url?: string;
    onRemove?: (file: File) => void;
    isChatFile?: boolean;
}

const getFileIcon = (fileType: string) => {
    const lowerCaseType = fileType.toLowerCase();
    if (lowerCaseType.includes('png') || lowerCaseType.includes('jpeg') || lowerCaseType.includes('jpg') || lowerCaseType.includes('gif') || lowerCaseType.includes('svg')) {
        return <Image/>;
    }
    if (lowerCaseType.includes('pdf')) return <PictureAsPdf/>;
    if (lowerCaseType.includes('doc') || lowerCaseType.includes('docx')) return <Description/>;
    if (lowerCaseType.includes('xls') || lowerCaseType.includes('xlsx')) return <Description/>;
    if (lowerCaseType.includes('ppt') || lowerCaseType.includes('pptx')) return <Description/>;
    if (lowerCaseType.includes('mp4') || lowerCaseType.includes('avi') || lowerCaseType.includes('mkv')) return <VideoLibrary/>;
    if (lowerCaseType.includes('mp3') || lowerCaseType.includes('wav')) return <Audiotrack/>;
    if (lowerCaseType.includes('py') || lowerCaseType.includes('js') || lowerCaseType.includes('ts')
        || lowerCaseType.includes('html') || lowerCaseType.includes('css') || lowerCaseType.includes('json')
        || lowerCaseType.includes('sql')) {
        return <Code/>;
    }
    if (lowerCaseType.includes('zip') || lowerCaseType.includes('rar') || lowerCaseType.includes('7z')) return <Archive/>;
    return <InsertDriveFile/>;
};

const Attachment: React.FC<AttachmentProps> = ({
                                                   file,
                                                   filename,
                                                   url,
                                                   onRemove,
                                                   isChatFile
                                               }) => {
    const isUploadedFile = !!url && !!filename;
    const fileType = file?.type || (filename ? filename.split('.').pop() || '' : '');

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px',
            bgcolor: isChatFile ? '#4791db' : 'grey.200',
            borderRadius: '5px',
            marginBottom: '5px'
        }}>
            {fileType && (
                <Box sx={{ marginRight: '8px' }}>
                    {getFileIcon(fileType)}
                </Box>
            )}

            <Typography variant="body2" sx={{ marginRight: '8px' }}>
                {file ? file.name : filename}
            </Typography>

            {file && onRemove && (
                <IconButton onClick={() => onRemove(file)}>
                    <Cancel />
                </IconButton>
            )}

            {isUploadedFile && (
                <IconButton component={Link} href={url} download={filename} target="_blank">
                    <Download />
                </IconButton>
            )}
        </Box>
    );
};

export default Attachment;
