import {Form} from "./Form";
import {useSignIn} from "../../hooks/UseSignIn.hook";

export const SignIn = () => {
    const { handleSignIn } = useSignIn()

    return (
        <Form title="Войти" handleClick={handleSignIn} />
    )
}
