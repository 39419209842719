import React, {useEffect} from 'react';
import {Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useSession} from "./PrvateRouteHelperHook";
import {getSession, getSessionUserRole} from "../../api/sessionManagement";




export const PrivateRoute: React.FC = () => {
    const {isAuthenticated, checkUserSession} = useSession();
    const userRole:any = getSessionUserRole();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        checkUserSession();
    }, [checkUserSession]);

    if (userRole === "confirmed" && ["/terms-service"].includes(pathname)) {
        return <Outlet />
    }
    if (userRole === "confirmed" || userRole === "wait-approve"){
        return <Navigate to="/landing" />
    }
    if (isAuthenticated === null) {
        return <></>
    }

    return isAuthenticated ? <Outlet /> : <Navigate to="/landing" />;
};
