import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, {useEffect, useState} from "react";
import {PRODUCT_CONDITION} from "../../../constants/consts";
import {checkboxStyle} from "../../catalog/catalogControl/style";
import {PRODUCT_CONDITION_KEYS} from "../../catalog/types";

interface displayFieldCheckboxProps {
    displayItem: string,
    productState: string | undefined,
    onChange: () => void,
}

export const ConditionCheckbox = (props: displayFieldCheckboxProps) => {

    const {displayItem, onChange, productState} = props
    const [isChecked, setIsChecked] = useState(displayItem === productState)

    const handleOnChange = () => {
        onChange()
    }

    useEffect(() => {
        setIsChecked(displayItem === productState)
    }, [productState]);


    return (
        <>
            <FormControlLabel value={"manufacture_code"}
                              checked={isChecked}
                              control={ <Checkbox sx={checkboxStyle}/>}
                              label={PRODUCT_CONDITION[displayItem]}
                              onChange={handleOnChange}
            />
        </>
    )
}