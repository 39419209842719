import {resolve} from "./resolve";
import axios from "axios";
import {API_BACKEND_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";

export async function getUserPriceList(){
    return await resolve(axios.post(`${API_BACKEND_URL}/pricelist/product/generate_pricelist_xlsx`,
        {
            filename: "Pricelist"
        }, {
            headers: {
                "Content-Type": "application/json",
                "session_id": getSession(),
            }
        }
    ).then(res => res));
}