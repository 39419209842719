// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bi-star {
  color: rgb(110, 209, 243) !important;
}
.bi-starbtn:focus {
  outline: none;
}

.bi-star-fill {
  color: rgb(110, 209, 243) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/catalog/favButton/FavButton.sass"],"names":[],"mappings":"AAAA;EACE,oCAAA;AACF;AAAE;EACE,aAAA;AAEJ;;AAAA;EACE,oCAAA;AAGF","sourcesContent":[".bi-star\n  color: rgb(110, 209, 243) !important\n  &btn:focus\n    outline: none\n\n.bi-star-fill\n  color: rgb(110, 209, 243) !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
