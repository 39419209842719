// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td .product__input {
  max-width: 65px;
  text-align: center;
  padding: 8.5px 14px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}
td .product__input:focus {
  border-color: rgb(110, 209, 243);
  outline: none;
}
td .btn__wrapper {
  justify-content: center;
  gap: 10px;
}
td img {
  width: 100%;
}

.catalog-table-row-image {
  width: 70px;
  height: 70px;
  cursor: zoom-in;
}`, "",{"version":3,"sources":["webpack://./src/components/catalog/catalogTable/tableRow/TableRow.sass"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,qCAAA;AAAJ;AAEI;EACE,gCAAA;EACA,aAAA;AAAN;AAGE;EACE,uBAAA;EACA,SAAA;AADJ;AAGE;EACE,WAAA;AADJ;;AAGA;EACE,WAAA;EACA,YAAA;EACA,eAAA;AAAF","sourcesContent":["td\n  .product__input\n    max-width: 65px\n    text-align: center\n    padding: 8.5px 14px\n    border: 1px solid rgba(0, 0, 0, 0.23)\n\n    &:focus\n      border-color: rgb(110, 209, 243)\n      outline: none\n\n\n  .btn__wrapper\n    justify-content: center\n    gap: 10px\n\n  img\n    width: 100%\n\n.catalog-table-row-image\n  width: 70px\n  height: 70px\n  cursor: zoom-in\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
