import "./TotalCartBill.sass"
import {ControlButton} from "../controlButton/controlButton";
import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {ReserveModal} from "../reserveModal/ReserveModal";
import {MESSAGE_VARIANT} from "../../constants/consts";
import {MessageContext} from "../../hooks/UseMessage";
import {OrderLinesContext} from "../../hooks/UseOrderLinesSelectContext";
import {ProcessOrderModal} from "../processOrderModal/ProcessOrderModal";



interface TotalCartBillProps {
    cartBillData:any;
    tableRef:any;
    handleCreateOrder: (isReserve: boolean) => void;
    setIsLoading:  Dispatch<SetStateAction<boolean>>;
    handleAddToReservation: (reserveId: number) => void;
}

export const TotalCartBill = (props:TotalCartBillProps) => {

    const [isModalOpen, setModalOpen] = useState(false);
    const [isOrderModalOpen, setOrderModalOpen] = useState(false);
    const {showMessage} = useContext(MessageContext);
    const { setSelectedLines } = useContext(OrderLinesContext);

    const {handleCreateOrder, handleAddToReservation, tableRef, setIsLoading} = props
    const {activeCartData } = useAppSelector(state => state.activeCart)

    const addToReservation = async (reserveId: number) => {
        setIsLoading(true)
        handleAddToReservation(reserveId)
    }

    const createNewReservation = async () => {
        setIsLoading(true)
        const data = tableRef.current?.getActiveProductLines();
        setSelectedLines(data)
        handleCreateOrder(true)
    }

    const createNew = (processOder: boolean) => {
        if (processOder) return handleCreateOrder(false)
        return createNewReservation()
    }

    const addTo = (reserveId: number) => {
        return addToReservation(reserveId)
    }


    const handleApplyModal = async (option: 'new' | 'existing', reserveId?: number, processOder: boolean = false) => {
        if (option === 'new') {
            createNew(processOder)
        } else if (option === 'existing' && reserveId) {
            addTo(reserveId);
        }
    };


    const handleCloseModal = (isOrder: boolean = false) => {
        if (isOrder) {
            setOrderModalOpen(false);
        } else {
            setModalOpen(false);
        }
    };


    const ToggleLock = (isOrder: boolean = false) => {
        const data = tableRef.current?.getActiveProductLines();
        if (!data || data.length === 0) {
            showMessage({
                variant: MESSAGE_VARIANT.WARNING,
                text: `Нет выбранных позиций!`
            })
            return
        }
        const forOrder = activeCartData && activeCartData.cart_lines.filter(el => el.is_for_order && data.includes(el.id))
        if (!isOrder && forOrder && forOrder.length > 0) {
            showMessage({
                variant: MESSAGE_VARIANT.WARNING,
                text: `Вы не можете создать резерв на продукт: ${forOrder.reduce((names, el) => names + `\n - ${el.name}`, "")}`
            })
            return
        }
        if (isOrder) {
            setOrderModalOpen(true);
        } else {
            setModalOpen(true);
        }
    };


    return (

        <>
            <ReserveModal
                isOpen={isModalOpen}
                onClose={() => handleCloseModal()}
                onApply={handleApplyModal}
            />
             <ProcessOrderModal
                isOpen={isOrderModalOpen}
                isCart={true}
                onClose={() => handleCloseModal(true)}
                onApply={(...args) => handleApplyModal(...args, true)}
            />
            <div className="totalCartBill">
                <div className="totalCartBill__checkout">
                    <div className="totalCartBill__checkout__row">
                        <div className="totalCartBill__checkout__row__item bold-text">
                            <span>Сумма с НДС</span>
                            <span>{activeCartData && activeCartData.amount_with_vat.toFixed(2)} {activeCartData?.currency && activeCartData.currency["symbol"]}</span>
                        </div>
                    </div>
                    <div className="totalCartBill__checkout__row">
                        <div className="totalCartBill__checkout__row__item small-text">
                            <span>Лимит резервации</span>
                            <span>{props.cartBillData.reservation_limit  || 0} €</span>
                        </div>
                        <div className="totalCartBill__checkout__row__item small-text">
                            <span>Доступный лимит резервации</span>
                            <span>{props.cartBillData.available_reservation_limit || 0} €</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="totalCartBill__order">
                <div className="totalCartBill__order__buttons">
                    <ControlButton className="btn border favIcon text-colored"
                                   icon="bi bi-lock"
                                   label="&nbsp;Резервировать"
                                   controlButtonHandler={() => ToggleLock()}/>
                    <ControlButton className="btn border order"
                                   icon=""
                                   label="Заказать"
                                   controlButtonHandler={() => ToggleLock(true)}/>
                </div>
            </div>
        </>
    )
}