import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchGetActiveUserProductCart} from "../../api/productCart";
import {CartType} from "../../components/catalog/types";

export type initialStateType = {
    isCartLoading: boolean,
    activeCartData: CartType | undefined,
}

const initialState: initialStateType = {
    isCartLoading: true,
    activeCartData: undefined,
}

export const fetchCart =
    createAsyncThunk("slice/fetchCart",
        async () => {
            let result = await fetchGetActiveUserProductCart();
            if (!result.error) {
                return result.data.data
            }
        }
    )

const activeCartSliceReducer = createSlice({
    name: "catalogProducts",
    initialState,
    reducers: {
        resetIsLoading: (state, action) => {
            state.isCartLoading = true
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchCart.fulfilled, (state, action) => {
            state.activeCartData = action.payload
            state.isCartLoading = false
        })
    }
})

export const { resetIsLoading} = activeCartSliceReducer.actions;

export default activeCartSliceReducer.reducer