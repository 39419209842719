import {
    Subcategory, SubcategoryCount, SubcategoryCountNumber, SubcategoryCountText,
    SubcategoryImage, SubcategoryTitle
} from "../styles";
import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";

interface SubCategoryItemProps {
    category: any,
}

const DEFAULT_IMAGE_URL = 'https://rde-crm.icodelab.eu/web/image?model=product.b2b.category&id=489&field=image_1920';

export const SubCategoryItem = ({ category }: SubCategoryItemProps) => {
    const navigate = useNavigate();
    const total = category?.total_cout ?? Math.floor(Math.random() * (100000 - 100)) + 100;

    const handleSubcategoryClick = useCallback(() => {
        navigate(`/products/${category?.id}`)
    }, [category]);


    return (
       <Subcategory onClick={handleSubcategoryClick}>
          <SubcategoryImage
            loading={"lazy"}
            src={category?.image_url}
          />
           <SubcategoryTitle>
               <span className="text-center">{category?.name}</span>
           </SubcategoryTitle>
           {/*TODO commented for waiting answer from customer*/}
           {/*<SubcategoryCount>*/}
           {/*    <SubcategoryCountNumber>{total}</SubcategoryCountNumber>*/}
           {/*    <SubcategoryCountText>продуктов</SubcategoryCountText>*/}
           {/*</SubcategoryCount>*/}
       </Subcategory>
    )
}
