// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-container {
  text-align: center;
  color: #333;
  padding: 20px;
}

.no-results-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  margin-bottom: 16px;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.error-container h2 {
  font-size: 36px;
  margin-bottom: 16px;
  color: #007bff;
}

.error-container p {
  font-size: 18px;
  color: #555;
}

.error-link {
  color: #007bff !important;
  text-decoration: none !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/notFoundPage/NotFoundPage.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,aAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,wCAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;EACA,WAAA;AACF;;AACA;EACE,yBAAA;EACA,gCAAA;AAEF","sourcesContent":[".error-container\n  text-align: center\n  color: #333\n  padding: 20px\n\n\n.no-results-image\n  width: 100%\n  max-width: 500px\n  height: auto\n  margin-bottom: 16px\n  border-radius: 8px\n  object-fit: cover\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)\n\n\n.error-container h2\n  font-size: 36px\n  margin-bottom: 16px\n  color: #007bff\n\n\n.error-container p\n  font-size: 18px\n  color: #555\n\n.error-link\n  color: #007bff !important\n  text-decoration: none !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
