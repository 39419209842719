import {resolve} from "./resolve";
import axios from "axios";
import {API_BACKEND_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";
import {getExchangeHeaders} from "./helpers";

export async function getTermService(){
    return await resolve(axios.get(`${API_BACKEND_URL}/agreement/get_active_agreement`,
        {
            headers: getExchangeHeaders(),
        }
    ).then(res => res));
}