import styled from "styled-components";
import { systemBlack } from "../../../constants";

export const AdvantageItemContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
`;

export const AdvantageIcon = styled.img`
    margin-top: 5px;
`;

export const AdvantageInfo = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
`;

export const Title = styled.span`
    font: 700 24px/32px "Roboto", sans-serif;
    color: ${systemBlack};

    @media (max-width: 768px) {
        font: 700 16px/20px "Roboto", sans-serif;
    }
`;

export const Subtitle = styled.span`
    font: 400 16px/24px "Roboto", sans-serif;
    color: ${systemBlack};

    @media (max-width: 768px) {
        font: 400 16px/24px "Roboto", sans-serif;
    }
`;
