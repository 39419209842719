import React, {useContext, useEffect, useState} from "react";
import {ReturnTableTr} from "./ReturnTableTr"
import {useAppSelector} from "../../../../hooks/ReduxHooks";
import {OrderLinesContext} from "../../../../hooks/UseOrderLinesSelectContext";
import {checkboxStyleSM} from "../../../catalog/catalogControl/style";
import Checkbox from "@mui/material/Checkbox";

export const ReturnTable = () => {
    const [isAllChecked, setIsAllChecked] = useState(false)
    const {returnData} = useAppSelector(state => state.return)

    const { setSelectedLines, lines } = useContext(OrderLinesContext);


    const handleSelectAll = (e: { target: { checked: any; }; }) => {
        if (!returnData) return
        setIsAllChecked(e.target.checked)
        if (e.target.checked) {
            setSelectedLines(returnData.lines.map(el => el.id));
        } else {
            setSelectedLines([]);
        }
    };

    return (returnData ?
        <>
            <div>
                <table className="table table-sm" id="sales_order_table">
                    <thead className="align-top">
                    <tr>
                        <th scope="col">
                            <Checkbox
                                checked={isAllChecked}
                                sx={checkboxStyleSM}
                                value={""}
                                id="AllCartProductsCheck"
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th className="text-start text-colored" id="product_name_header">Продукт
                        </th>
                        <th className="text-start text-colored" id="product_code_header">Код
                        </th>
                        <th className="text-end text-colored" id="product_qty_header">Количество</th>
                        <th className="text-center text-colored" id="product_qty_header">Номер заказа</th>
                        <th className="text-center text-colored" id="product_qty_header">Номер накладной</th>
                        <th className="text-end text-colored" id="product_qty_header">Состояние</th>
                        <th className="text-end text-colored" id="product_qty_header">Статус</th>
                        <th className="text-end text-colored d-sm-table-cell">
                            Цена
                        </th>
                        <th className="text-end text-colored">
                            <span>Стоимость без НДС</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {lines && lines.map((child) =>
                        <ReturnTableTr
                            returnLine={child}
                        />
                    )}
                    </tbody>
                </table>
            </div>
        </> : <></>
    )
}