import "./Blured.sass"

interface BluredProps {
    element:  JSX.Element
    isLoading: boolean
}

export const Blured = (props: BluredProps) => {

    const {element, isLoading} = props

    return (
        <div className={`${isLoading && "blured"}`}>
            {element}
        </div>
    )
}
