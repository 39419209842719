import React, {useEffect} from "react";
import {TabItem} from "../tabs/TabItem";
import {ControlButton} from "../controlButton/controlButton";
import {TabList} from "../tabs/TabList";
import "./ProductReturns.sass"
import {SubmitForm} from "./submitForm/SubmitForm";
import {ReturnSearchForm} from "./returnSearchForm/ReturnSearchForm";
import {NewReturn} from "./newReturn/NewReturn";
import {ReturnHistoryTable} from "./returnHistoryTable/ReturnHistoryTable";
import {useAppDispatch} from "../../hooks/ReduxHooks";
import {resetReturnData} from "../../store/slices/NewReturnSlice";
import {t} from "i18next";

export const inputStyle = {
    width: "100%",

}

export const inputStyleSM = {
    ...inputStyle,
    maxWidth: "130px",
    '& .MuiFormLabel-root': {
        fontSize: '0.8rem',
    },
    '& .MuiOutlinedInput-input': {
        fontSize: '0.8rem',
    }
}

export const inputAddressStyleSM = {
    ...inputStyleSM,
    maxWidth: "200px",
}

export const inputProps = {
    style: {fontSize: "12px"}
}

export const ProductReturns = () => {


    const dispatch = useAppDispatch()

    useEffect(() => {
        return () => {
            dispatch(resetReturnData())
        }
    }, []);

    return (
        <>
            <div className="pt-3 pb-5 h-100">
                <TabList className="h-100" activeTabIndex={0}>
                    <TabItem label={t('new_product_return_request')}>
                        <NewReturn/>
                    </TabItem>
                    <TabItem className="h-100" label={t('active_requests')}>
                        <ReturnHistoryTable isActive/>
                    </TabItem>
                    <TabItem className="h-100" label={t('request_history')}>
                        <ReturnHistoryTable isActive={false}/>
                    </TabItem>
                </TabList>

            </div>
        </>
    )
}