import axios from 'axios';
import { resolve } from './resolve.js';
import { ENDPOINT, API_BACKEND_URL, VISIBLE_MESSAGE_COUNT } from "../constants/consts";
import { getSession } from "./sessionManagement";


export async function sendChatRequest(messageData) {
    return await resolve(axios.post(`${API_BACKEND_URL}/chats/send_message_to_chat`,
        messageData, {
            headers: {
                "session_id": getSession(),
            }
        }
    ).then(res => res));
}

export async function getNewMessages(offset = 0, limit = VISIBLE_MESSAGE_COUNT, messageData) {
    return await resolve(axios.post(`${API_BACKEND_URL}/chats/load_portal_messages?offset=${offset}&limit=${limit}`,
        messageData, {
            headers: {
                "Content-Type": "application/json",
                "session_id": getSession(),
            }
        }
    ).then(res => res));
}
export async function getChatMessagesCount(messageData) {
    return await resolve(axios.post(`${API_BACKEND_URL}/chats/get_chat_message_count`,
        messageData, {
            headers: {
                "Content-Type": "application/json",
                "session_id": getSession(),
            }
        }
    ).then(res => res));
}
