import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "../orders/ordersHomeTable/OrdersHomeTable.sass"
import "./HomeTableContent.sass"
import {Loader} from "../loader/Loader";

interface HomeTableContentProps {
    label: string;
    data_key: string;
    dataLink: JSX.Element;
    columns: Column[];
    setPage: (value: number) => void;
    page: number;
    rows: any[];
    rowsPerPage: number;
    rowsCount: number;
    isLoading: boolean;
    sx?: object
}

export interface Column {
    id: string;
    label?: string;
    minWidth?: number;
    maxWidth?: number;
    align?: "right";
    format?: (value: number) => string;
}


export const HomeTableContent = (props: HomeTableContentProps) => {

    const {label, dataLink, columns, page, setPage, rows, rowsPerPage, rowsCount, isLoading, sx, data_key} = props

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };


    return (
        <Paper sx={sx}>
            <TableContainer sx={{maxHeight: 240, height: "100%"}}>
                <Table stickyHeader aria-label="sticky table" className="home_table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" colSpan={columns.length - 3}>
                                <span className="orders_home_table__title">{label}</span>
                            </TableCell>
                            <TableCell align="right" colSpan={3}>
                                {dataLink}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={`${data_key}_${column.id}`}
                                    align={column.align}
                                    style={{
                                        top: 57,
                                        minWidth: column.minWidth,
                                        fontWeight: 700,
                                        fontSize: "11pt",
                                        color: "rgb(94, 208, 246)",
                                        verticalAlign: "top",
                                        textAlign: column.align || "left",
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{height: "130px"}}>
                        {isLoading ? (
                            <>
                                <TableRow hover role="checkbox" tabIndex={-1} key="loader_1">
                                </TableRow>
                                <TableRow hover role="checkbox" tabIndex={-1} key="loader_2">
                                    <TableCell align="center" colSpan={columns.length}>
                                        <Loader/>
                                    </TableCell>
                                </TableRow>
                            </>
                        ) : (
                            rows.map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={`${data_key}_row_${row.data_key}`}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={`${data_key}_cell_${column.id}`}
                                                    align={column.align}
                                                    className="home_table__row"
                                                >
                                                    {column.format && typeof value === "number"
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={rowsCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
            />

        </Paper>
    );
}