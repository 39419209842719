import * as React from "react";
import {PieChart} from "@mui/x-charts/PieChart";
import {useEffect, useState} from "react";
import {fetchTopSaleItems} from "../../../api/orders";

type TopItemsData = {
    id: number;
    amount: number;
    label: string;
    percent: number;

}

export const TopItems = () => {

    const [dataset, setDataset] = useState<TopItemsData[]>([]);


    const getTopItemsDataset = async () => {
        let result = await fetchTopSaleItems()
        if (!result.error) {
            setDataset(result.data.data.top_items_data)
        }
    }

    useEffect(() => {
        getTopItemsDataset()
    }, []);

    if (!dataset) return <></>

    return (
        <div className="chart__wrapper flex-grow-1">
            <h5 className="w-100 text-center">Топ 5 продуктов за 3 мес</h5>
            <PieChart
                series={[
                    {
                        data: dataset.map(el => {
                            return {
                                id: el.id,
                                value: el.amount,
                                label: el.label
                            }
                        }),
                        innerRadius: 30,
                        outerRadius: 120,
                        paddingAngle: 5,
                        cornerRadius: 5,
                        startAngle: 0,
                        endAngle: 360,
                    },
                ]}
                slotProps={{ legend: { hidden: true } }}
                width={350}
                height={350}
                className="m-auto"
            />
        </div>
    );
}