import {useLocation, useParams} from "react-router-dom";
import {OrderContent} from "../../components/orders/orderContent/OrderContent";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import React, {useEffect, useState} from "react";
import {fetchSingleOrder, resetOrder} from "../../store/slices/OrderReducerSlice";
import {Loader} from "../../components/loader/Loader";
import {updateBreadcrumb} from "../../store/slices/BreadcrumbSlice";
import {OrderLinesContext} from "../../hooks/UseOrderLinesSelectContext";
import {SaleOrderLineType} from "../../components/catalog/types";
import {OrderSidebar} from "../../components/orders/orderContent/orderSidebar/OrderSidebar";
import {OrderIntroduction} from "../../components/orders/orderContent/orderIntroduction/OrderIntroduction";
import {OrderDeliveryInfo} from "../../components/orders/orderContent/orderDeliveryInfo/OrderDeliveryInfo";
import {OrderTable} from "../../components/orders/orderContent/orderTable/OrderTable";
import {OrderTotal} from "../../components/orders/orderContent/orderTotal/OrderTotal";
import {fetchFinTerms} from "../../store/slices/FinTermsSlice";


export const OrderPage = () => {

    const {orderId} = useParams()

    const {isOrderLoading, orderData} = useAppSelector(state => state.order)

    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedLines, setSelectedLines] = useState<number[]>([])
    const [lines, setLines] = useState<SaleOrderLineType[]>([])
    const location = useLocation()

    useEffect(() => {
        dispatch(fetchFinTerms())
    }, []);

    useEffect(() => {
        if (!isOrderLoading)
            setIsLoading(isOrderLoading)
    }, [isOrderLoading]);

    useEffect(() => {
        dispatch(fetchSingleOrder(Number(orderId)))
        return () => {
            dispatch(resetOrder())
        }
    }, [location]);

    useEffect(() => {
        if (!orderData) return
        setLines(orderData.order_line.filter(el => el.product_uom_qty > 0))
        setSelectedLines(orderData.order_line.map(el => el.id))
        return () => {setLines([])}
    }, [orderData]);

    if (orderData) {
        dispatch(updateBreadcrumb({key: location.pathname, value: orderData.name}))
    }

    return (
        <OrderLinesContext.Provider value={{selectedLines, setSelectedLines, lines, setLines, setIsLoading}}>
            {isLoading ? (
                <Loader/>
            ) : (orderData ?
                <>
                    <div className="wrapper">
                        <OrderContent
                            sidebar={<OrderSidebar/>}
                            introduction={<OrderIntroduction/>}
                            info={<OrderDeliveryInfo/>}
                            table={<OrderTable/>}
                            total={<OrderTotal/>}
                            recordId={Number(orderId)}
                            model="sale.order"
                        />
                    </div>
                </> : <></>
            )}
        </OrderLinesContext.Provider>
    )
}