import axios from 'axios';
import {resolve} from './resolve.js';
import {API_BACKEND_URL} from "../constants/consts";
import {getExchangeHeaders} from "./helpers";

export async function getProductCategories(categoryId) {
    return await resolve(
        axios.get(`${API_BACKEND_URL}/categories/category/attribute/${categoryId}`,
        {
            headers: getExchangeHeaders()
        }
    ).then(res => res));
}
