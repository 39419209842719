import styled from "styled-components";
import { DEFAULT_HEADER_HEIGHT, systemWhite } from "../../constants";

export const VideoBackground = styled.div`
    position: absolute;
    left: auto;
    height: calc(100vh - 88px);
    width: 100%;
    z-index: -1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${systemWhite};

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #000;
        opacity: 0.32;
        pointer-events: none;
        z-index: 1;
    }

    @media (max-width: 768px) {
        top: 25px;
        height: calc(100vh - ${DEFAULT_HEADER_HEIGHT});
        position: relative;
    }
`;

export const MainContent = styled.div<{ $topOffset: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 80px;

    @media (max-width: 768px) {
        padding: 0 80px;
        position: absolute;
        top: ${({ $topOffset }) => `${$topOffset}px`};
        margin-bottom: 0;
    }
`;

export const VideoSectionContainer = styled.div`
    position: relative;
    height: calc(100vh - ${DEFAULT_HEADER_HEIGHT});
    width: 100%;
    margin-top: 48px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: 768px) {
        padding: 0;
        height: fit-content;
    }
`;

export const MainText = styled.span`
    font: 700 48px/72px "Roboto", sans-serif;
    width: 700px;
    text-align: center;
    color: ${systemWhite};

    @media (max-width: 768px) {
        width: 100%;
        font: 700 26px/36px "Roboto", sans-serif;
    }
`;

export const CardsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    gap: 20px;
    padding: 0 20px 10px 20px;

    @media (max-width: 768px) {
        padding: 20px 0;
        margin-top: 40px;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
    }
`;
