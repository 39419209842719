import React from "react";
import {
    CardContainer,
    CardText,
    CardIcon,
} from "./styles";

const DEFAULT_CARD_ANIMATION_DELAY = 0.1;

interface CardProps {
    index: number;
    icon: string;
    title: string;
}

export const Card = ({ icon, index, title }: CardProps) => {
    return (
        <CardContainer
            initial={{opacity: 0, y: 50}}
            animate={{opacity: 1, y: 0}}
            transition={{delay: (index + 1) * DEFAULT_CARD_ANIMATION_DELAY}}
        >
            <CardIcon src={icon} alt="card-icon"/>
            <CardText>{title}</CardText>
        </CardContainer>
    );
}
