import React, {useEffect} from "react";
import "./ReturnTable.sass"
import {AddLineDialog} from "../addLineDialog/AddLineDialog";
import {useAppSelector} from "../../../hooks/ReduxHooks";
import {getLinesData} from "../../../store/slices/NewReturnSlice";
import {Link} from "react-router-dom";
import {ROUTE_URLS} from "../../../constants/consts";
import {t} from "i18next";
import {Trans} from "react-i18next";

interface TableRowProps {
    setOpen: (value: boolean) => void,
    handleClose: () => void,
    lineData: any,
}

export const SearchTableRow = (props: TableRowProps) => {

    const {setOpen, lineData} = props

    const handleAddRow = (lineData: any) => {
        setOpen(lineData)
    }

    const orderURL = `${ROUTE_URLS.ORDERS}/${lineData.order_id}`

    return (
        <tr>
            <td scope="text-start">
                <Link className="returnTable__link" to={orderURL}>{lineData.order_name}
                </Link>
            </td>
            <td scope="text-start">{lineData.product_code}</td>
            <td scope="text-start"></td>
            <td scope="text-start">{lineData.product_name}</td>
            <td scope="text-start"></td>
            <td scope="text-start">{lineData.date_order}</td>
            <td scope="text-start">{lineData.quantity}</td>
            <td scope="text-start">{lineData.price_unit} {lineData.currency}</td>
            <td scope="text-start">{lineData.price_subtotal} {lineData.currency}</td>
            <td scope="col" className="text-end returnTable__btns">
                <button type="button" className="btn btn-sm btn-search"
                        onClick={() => handleAddRow(lineData)}><Trans i18nKey="add"/>
                </button>
            </td>
        </tr>
    )

}


export const SearchTable = () => {


    const [openedLine, setOpenedLine] = React.useState<any>(undefined);
    const {orderLinesData} = useAppSelector(state => state.newReturn)

    const handleClose = () => {
        setOpenedLine(undefined);
    };
    const {linesToReturn} = useAppSelector(state => state.newReturn)
    const [lines, setLines] = React.useState<getLinesData[]>();

    useEffect(() => {
        if (orderLinesData && linesToReturn) {
            const result = orderLinesData.map(line2process => {
                const addedItems = linesToReturn.filter(item => item.lineId === line2process.id);
                const addedQty = addedItems.reduce((n, {quantity}) => n + quantity, 0)
                return {
                    ...line2process,
                    quantity: (addedQty > 0) ? (line2process.quantity - addedQty) : line2process.quantity
                }
            }).filter(item => item.quantity > 0);
            setLines(result)
        } else {
            setLines(orderLinesData)
        }
    }, [linesToReturn, orderLinesData]);

    return (
        <>
            <div style={{height: "20px"}}>
                {orderLinesData && (
                    orderLinesData.length > 0
                        ? (
                            <div className="d-flex search-banner w-100">
                                {t('found')} {orderLinesData.length} {t('products')}
                            </div>
                        )
                        : (
                            <div className="d-flex search-banner w-100">
                                {t('no_items_available_for_return')}
                            </div>
                        )
                )}
            </div>

            <>
                <table className="table returnTable">
                    <thead className="returnTable">
                    <tr>
                        <th scope="col" className="text-secondary text-start returnTable__number">
                            {t('order_number')}
                        </th>
                        <th scope="col" className="text-secondary text-start returnTable__code">
                            {t('code')}
                        </th>
                        <th scope="col" className="text-secondary text-start returnTable__man_code">
                            {t('manufacturer_code')}
                        </th>
                        <th scope="col" className="text-secondary text-start returnTable__name">
                            {t('product_name')}
                        </th>
                        <th scope="col" className="text-secondary text-start returnTable__delivery">
                            {t('invoice')}
                        </th>
                        <th scope="col" className="text-secondary text-start returnTable__bill_date">
                            {t('invoice_date')}
                        </th>
                        <th scope="col" className="text-secondary text-start returnTable__qty">
                            {t('quantity_available_for_return')}
                        </th>
                        <th scope="col" className="text-secondary text-start returnTable__price">
                            {t('price_without_vat')}
                        </th>
                        <th scope="col" className="text-secondary text-start returnTable__total">
                            {t('total_without_vat')}
                        </th>
                        <th className="returnTable__btns"></th>


                    </tr>
                    </thead>
                    <tbody className="returnTable__body">
                    {lines && lines.map((el) => {
                            return (
                                <SearchTableRow
                                    setOpen={setOpenedLine}
                                    handleClose={handleClose}
                                    lineData={el}/>
                            )
                        }
                    )}
                    </tbody>
                </table>
                <AddLineDialog
                    openedLine={openedLine}
                    handleClose={handleClose}
                />
            </>
        </>
    )
}