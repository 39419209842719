import React, {memo} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {t} from "i18next";

interface ProcessOrderModalProps {
    isOpen: boolean;
    onClose: () => void;
    onApply: (option: "all" | "by_order") => void;
}

export const DeliveryOptionsOrderModal = memo((props: ProcessOrderModalProps) => {

    const {isOpen, onClose, onApply} = props
    const [selectedOption, setSelectedOption] = React.useState<"all" | "by_order">("all");

    const handleApply = () => {
        onApply(selectedOption);
        onClose();
    };


    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    borderRadius: "10px",
                    boxShadow: 24,
                    p: 4
                }}
            >
                <FormControl component="fieldset" className="w-100">
                    <FormLabel component="legend">{t("send_order")}</FormLabel>
                    <RadioGroup
                        aria-label="reserve"
                        name="reserve"
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value as "all" | "by_order")}
                    >
                        <FormControlLabel
                            value="all"
                            control={<Radio
                                sx={{color: "rgb(110, 209, 243)", "&.Mui-checked": {color: "rgb(110, 209, 243)"}}}/>}
                            label={t("all_together")}
                        />
                        <FormControlLabel
                            value="by_order"
                            control={<Radio
                                sx={{color: "rgb(110, 209, 243)", "&.Mui-checked": {color: "rgb(110, 209, 243)"}}}/>}
                            label={t("by_order")}
                        />
                    </RadioGroup>
                    <Box sx={{mt: 3, display: "flex", justifyContent: "space-between", gap: "5px"}}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "rgb(110, 209, 243)",
                                "&:hover": {backgroundColor: "rgb(90, 180, 210)"}
                            }}
                            onClick={handleApply}
                        >
                            {t("apply")}
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                borderColor: "rgb(110, 209, 243)",
                                color: "rgb(110, 209, 243)",
                                "&:hover": {
                                    borderColor: "rgb(90, 180, 210)",
                                    color: "rgb(90, 180, 210)"
                                }
                            }}
                            onClick={onClose}
                        >
                            {t("cancel")}
                        </Button>
                    </Box>
                </FormControl>
            </Box>
        </Modal>

    );
});
