import { AuthContainer, AuthContainerChild } from "../../components/auth/styles";
import { Trans } from "react-i18next";
import {RecoverPasswordForm} from "../../components/RecoverPasswordForm/RecoverPasswordForm";

export const RecoverPasswordFormPage = () => {
    return (
        <AuthContainer>
            <AuthContainerChild>
                <h1>
                    <Trans i18nKey="RecoverPasswordPage"/>
                </h1>
                <RecoverPasswordForm/>
            </AuthContainerChild>
        </AuthContainer>
    );
};
