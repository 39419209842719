import axios from 'axios';
import {resolve} from './resolve.js';
import {API_AUTH_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";

export async function getTwoFactorAuth() {
    return await resolve(axios.get(`${API_AUTH_URL}/users/two_factor_auth`,
        {
            headers: {
                'Content-Type': 'application/json',
                'session-id': `${getSession()}`
            },
        }
    ).then(res => res));
}
export async function getTwoFactorAuthUrl() {
    return await resolve(axios.get(`${API_AUTH_URL}/users/two_factor_auth_url`,
        {
            headers: {
                'Content-Type': 'application/json',
                'session-id': `${getSession()}`
            },
        }
    ).then(res => res));
}

export async function removeTwoFactorAuth() {
    return await resolve(axios.get(`${API_AUTH_URL}/users/remove_two_factor_auth`,
        {
            headers: {
                'Content-Type': 'application/json',
                'session-id': `${getSession()}`
            },
        }
    ).then(res => res));
}
export async function verifyTwoFactorCode(code) {
    return await resolve(axios.post(`${API_AUTH_URL}/users/two_factor_verify`,
        {
            code: code,
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'session-id': `${getSession()}`
            },
        }
    ).then(res => res));
}

