import React from "react";
import "./NewWarrantyRequestTable.sass"
import {ReturnTableRow} from "../../productReturns/returnTable/ReturnTable";
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import {setLineToReturn} from "../../../store/slices/NewReturnSlice";
import {setLineToWarranty} from "../../../store/slices/NewWarrantySlice";
import {PRODUCT_CONDITION} from "../../../constants/consts";
import {Trans} from "react-i18next";

interface TableRowProps {
    lineData: any,
}

export const NewWarrantyRequestTableRow = (props: TableRowProps) => {

    const {lineData} = props
    const {linesToWarranty} = useAppSelector(state => state.newWarranty)
    const dispatch = useAppDispatch()

    const handleDeleteRow = () => {
        dispatch(setLineToWarranty(linesToWarranty.filter(el => el.id !== lineData.id)))
    }

    return (
        <tr>
            <td scope="text-start">{lineData.lineData.order_name}</td>
            <td scope="text-start">{lineData.lineData.product_code}</td>
            <td scope="text-start"></td>
            <td scope="text-start">{lineData.lineData.product_name}</td>
            <td scope="text-start"></td>
            <td scope="text-start"></td>
            <td scope="text-start">{lineData.quantity}</td>
            <td scope="text-start">{lineData.lineData.price_unit} {lineData?.lineData?.currency}</td>
            <td scope="text-start">{lineData.lineData.price_subtotal} {lineData?.lineData?.currency}</td>
            <td scope="text-start">{lineData?.defectType?.name}</td>
            <td scope="text-start">{lineData?.filesData?.length}</td>
            <td scope="text-start"></td>
            <th scope="col" className="">
                <button type="button" className="btn btn-sm" onClick={handleDeleteRow}>
                    <i className="bi bi-trash"/>
                </button>
            </th>
        </tr>
    )

}

export const NewWarrantyRequestTable = () => {

    const {linesToWarranty} = useAppSelector(state => state.newWarranty)


    return (
        <>
            <>
                <table className="table newRequestTable">
                    <thead className="newRequestTable">
                    <tr>
                        <th scope="col" className="text-secondary text-start newRequestTable__number">
                            <Trans i18nKey="order_number"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__code">
                            <Trans i18nKey="code"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__man_code">
                            <Trans i18nKey="manufacturer_code"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__name">
                            <Trans i18nKey="product_name"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__delivery">
                            <Trans i18nKey="invoice"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__bill_date">
                            <Trans i18nKey="bill_date"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__qty">
                            <Trans i18nKey="quantity"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__price">
                            <Trans i18nKey="price_excl_vat"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__total">
                            <Trans i18nKey="total_excl_vat"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__state">
                            <Trans i18nKey="defect"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__desc">
                            <Trans i18nKey="notes"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__desc"></th>
                        <th></th>

                    </tr>
                    </thead>
                    <tbody className="returnTable__body">
                    {linesToWarranty && (
                        linesToWarranty.map((el) => {
                            return (<NewWarrantyRequestTableRow lineData={el}/>)
                        }))}
                    </tbody>
                </table>
            </>

        </>
    )
}