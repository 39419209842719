import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import "./FinancialData.sass"
import React, {useEffect} from "react";
import {fetchFinTerms} from "../../../store/slices/FinTermsSlice";
import {Loader} from "../../loader/Loader";
import {Trans} from "react-i18next";

export const FinancialData = () => {

    const {isTermsLoading, terms} = useAppSelector(state => state.finTerms)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchFinTerms())
    }, []);

    if (!terms) return (<></>)

    return ( isTermsLoading ? <Loader/> :
        <>
            <div className="financialData">
                <div className="financialData__row">
                    <div className="financialData__row__item title">
                        <h5><Trans i18nKey="utilized_credit"/></h5>
                    </div>
                    <div className="financialData__row__item bold-text">
                        <span>{
                            terms.partner_credit_limit ? ((terms.used_credit_limit / terms.partner_credit_limit) * 100).toFixed(0) : 0} %
                        </span>
                        <span>{terms.used_credit_limit.toFixed(2)} €</span>
                    </div>
                </div>
                <div className="financialData__row">
                    <div className="financialData__row__item small-text">
                        <span><Trans i18nKey="credit_limit_balance"/></span>
                        <span>{terms.available_credit_limit.toFixed(2)} €</span>
                    </div>
                </div>
                <div className="financialData__row">
                    <div className="financialData__row__item small-text">
                        <span><Trans i18nKey="credit_limit"/></span>
                        <span>{terms.partner_credit_limit.toFixed(2)} €</span>
                    </div>
                </div>
                <div className="financialData__row">
                    <div className="financialData__row__item small-text">
                        <span><Trans i18nKey="unpaid_invoices_amount"/></span>
                        <span>{terms.unpaid_credit_invoice.toFixed(2)} €</span>
                    </div>
                </div>
                <div className="financialData__row">
                    <div className="financialData__row__item small-text">
                        <span><Trans i18nKey="overdue_payments_amount"/></span>
                        <span>{terms.invoice_total_overdue.toFixed(2)} €</span>
                    </div>
                </div>
                {terms.upcoming_invoice_amount && (
                    <div className="financialData__row">
                        <div className="financialData__row__item small-text">
                            <span><Trans i18nKey="due_date_amount"/></span>
                        </div>
                        <div className="financialData__row__item small-text">
                            <span>{terms.upcoming_invoice_date}</span>
                            <span>{terms.upcoming_invoice_amount.toFixed(2)} €</span>
                        </div>
                    </div>
                )}

                <div className="financialData__row">
                    <div className="financialData__row__item totle">
                        <h5><Trans i18nKey="used_reserve"/></h5>
                    </div>
                    <div className="financialData__row__item bold-text">
                        <span>{terms.reservation_limit_cash ? ((terms.used_reservation_limit/terms.reservation_limit_cash)*100).toFixed(0) : 0} %</span>
                        <span>{terms.reservation_limit_cash.toFixed(2)} €</span>
                    </div>
                </div>
                <div className="financialData__row">
                    <div className="financialData__row__item small-text">
                        <span><Trans i18nKey="reserves_balance"/></span>
                        <span>{(terms.reservation_limit_cash - terms.used_reservation_limit).toFixed(2)} €</span>
                    </div>
                </div>
                <div className="financialData__row">
                    <div className="financialData__row__item small-text">
                        <span><Trans i18nKey="reserve_limit"/></span>
                        <span>{terms.reservation_limit_cash.toFixed(2)} €</span>
                    </div>
                </div>
            </div>
        </>
    )
}