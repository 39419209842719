import styled from "styled-components";
import { systemBlue, systemWhite } from "../../constants";

export const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
    flex-direction: column;
    padding: 32px;
    
    .MuiTextField-root {
        width: 100%;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 15px;
`;

const Button = styled.div`
    font: 700 12px/28px "Roboto", sans-serif;
    width: 170px;
    border-radius: 4px;
    cursor: pointer;
    padding: 9px 0;
    text-align: center;
`;

export const PrimaryButton = styled(Button)`
    color: ${systemWhite};
    background-color: ${systemBlue};

    &:hover {
        opacity: 0.9;
    }
`;

export const CloseIconContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;
    width: 100%;
    cursor: pointer;
`;


export const CloseIcon = styled.i`
    font-size: 30px;
`;

