import React, {useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import {getSession} from "../../api/sessionManagement";
import './NotFoundPage.sass';

export const NotFoundPage: React.FC = () => {

    const [isActiveSession, setIsActiveSession] = useState(false);

    useEffect(() => {
        if (getSession()){
            setIsActiveSession(true)
        }
        else {
            setIsActiveSession(false)
        }
    }, []);
    return (
        <>
            {
                isActiveSession ?
                    <div className="error-container">
                        <h2>Результатов не найдено</h2>
                        <p>Попробуйте поискать по-другому или перейти на <Link className="error-link" to="/">главную
                            страницу</Link>
                        </p>
                    </div>
                    : <div className="error-container">
                        <h2>404 - Страница не найдена</h2>
                        <p>Извините, но страница, которую вы ищете, не существует.</p>
                        <Link to="/sign-in" className="error-link">Перейти на страницу авторизации</Link>
                    </div>
            }
        </>
    );
}