import React, {useEffect} from "react";
import "./ReturnTable.sass"
import {AddLineDialog} from "../addLineDialog/AddLineDialog";
import {useAppSelector} from "../../../hooks/ReduxHooks";
import {getLinesData} from "../../../store/slices/NewReturnSlice";
import {Link} from "react-router-dom";
import {ROUTE_URLS} from "../../../constants/consts";

interface TableRowProps {
    setOpen: (value: boolean) => void ,
    handleClose: () => void ,
    lineData: any,
}

export const SearchTableRow = (props: TableRowProps) => {

    const {setOpen, lineData} = props

    const handleAddRow = (lineData: any) => {
        setOpen(lineData)
    }

    const orderURL = `${ROUTE_URLS.ORDERS}/${lineData.order_id}`

    return (
        <tr>
            <td scope="text-start">
                <Link className="returnTable__link" to={orderURL}>{lineData.order_name}
                </Link>
            </td>
            <td scope="text-start">{lineData.product_code}</td>
            <td scope="text-start"></td>
            <td scope="text-start">{lineData.product_name}</td>
            <td scope="text-start"></td>
            <td scope="text-start">{lineData.date_order}</td>
            <td scope="text-start">{lineData.quantity}</td>
            <td scope="text-start">{lineData.price_unit} {lineData.currency}</td>
            <td scope="text-start">{lineData.price_subtotal} {lineData.currency}</td>
            <td scope="col" className="text-end returnTable__btns">
                <button type="button" className="btn btn-sm btn-search" onClick={() => handleAddRow(lineData)}>Добавить</button>
            </td>
        </tr>
    )

}



export const SearchTable = () => {


    const [openedLine, setOpenedLine] = React.useState<any>(undefined);
    const {orderLinesData} = useAppSelector(state => state.newReturn)

    const handleClose = () => {
        setOpenedLine(undefined);
    };
    const {linesToReturn} = useAppSelector(state => state.newReturn)
    const [lines, setlines] = React.useState<getLinesData[]>();

    useEffect(() => {
        if (orderLinesData && linesToReturn) {
            const result = orderLinesData.map(line2process => {
            const addedItems = linesToReturn.filter(item => item.lineId === line2process.id);
            const addedQty = addedItems.reduce((n, {quantity}) => n + quantity, 0)
            return {...line2process, quantity: (addedQty > 0) ? (line2process.quantity - addedQty) : line2process.quantity}
        }).filter(item => item.quantity > 0);
            setlines(result)
        } else {
            setlines(orderLinesData)
        }
    }, [linesToReturn, orderLinesData]);

    return (
        <>
            <div style={{height: "20px"}}>
            {orderLinesData && (orderLinesData.length > 0 ?
                (<div className="d-flex search-banner w-100">
                    Найден {orderLinesData.length} продукт:
                </div>) :
                    (<div className="d-flex search-banner w-100">
                        Нет доступных для возврата позиций:
                    </div>)
            )}
            </div>
            <>
                <table className="table returnTable">
                    <thead className="returnTable">
                        <tr>
                            <th scope="col" className="text-secondary text-start returnTable__number">Номер заказа</th>
                            <th scope="col" className="text-secondary text-start returnTable__code">Код</th>
                            <th scope="col" className="text-secondary text-start returnTable__man_code">Код
                                производителя
                            </th>
                            <th scope="col" className="text-secondary text-start returnTable__name">Название продукта
                            </th>
                            <th scope="col" className="text-secondary text-start returnTable__delivery">Накладная</th>
                            <th scope="col" className="text-secondary text-start returnTable__bill_date">Дата счета</th>
                            <th scope="col" className="text-secondary text-start returnTable__qty">Кол-во, доступное для возврата</th>
                            <th scope="col" className="text-secondary text-start returnTable__price">Цена <br/>без НДС</th>
                            <th scope="col" className="text-secondary text-start returnTable__total">Сумма без<br/>HДC</th>
                            <th className="returnTable__btns"></th>


                        </tr>
                        </thead>
                        <tbody className="returnTable__body">
                        {lines && lines.map((el) => {
                            return (
                                <SearchTableRow
                                    setOpen={setOpenedLine}
                                    handleClose={handleClose}
                                    lineData={el}/>
                            )
                        }
                        )}
                        </tbody>
                    </table>
                    <AddLineDialog
                        openedLine={openedLine}
                        handleClose={handleClose}
                    />
                </>
        </>
    )
}