import {BaseBreadCrumbs} from "../../components/breadcrumbs/Breadcrumbs";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {fetchFinTerms} from "../../store/slices/FinTermsSlice";
import {Loader} from "../../components/loader/Loader";
import {FinTerms} from "../../components/finTerms/FinTerms";

export const FinTermsPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch()

    const { terms, isTermsLoading} = useAppSelector(state => state.finTerms)


    useEffect(() => {
        if (!isTermsLoading)
            setIsLoading(false)
    }, [isTermsLoading]);

    useEffect(() => {
        dispatch(fetchFinTerms())
    }, []);


    return (
        <>
            {isLoading ? (
                <Loader />
            ):(
                <>
                <BaseBreadCrumbs/>
                <div className="wrapper">
                    <FinTerms/>
                </div>
            </>
            )}
        </>
    )
}