import { useIsMobile } from "../../hooks/useIsMobile.hook";
import {Dropdown, DropdownMenu, DropdownMenuItem} from "../../pages/landingPage/components/header/styles";
import {MobileHeader} from "../../pages/landingPage/components/header/mobileHeader";
import {useLanguagePicker} from "../../pages/landingPage/components/header/hooks/useLanguagePicker.hook";


interface HeaderProps {
    showButtons: boolean;
}

export const LanguageSwitcher = ({ showButtons }: HeaderProps) => {
    const { currentLanguage, handleDropdownClick, handleChangeLanguage, isLanguageMenuOpen, languages, ref } = useLanguagePicker();
    const isMobile = useIsMobile();
    const languagePickerIcon = isLanguageMenuOpen ? <i className="bi bi-chevron-up" /> : <i className="bi bi-chevron-down" />;

    return isMobile ? <MobileHeader showButtons={showButtons} /> : (
        <Dropdown ref={ref} $isLanguageMenuOpen={isLanguageMenuOpen} onClick={handleDropdownClick}>
            {currentLanguage.label} {languagePickerIcon}
            {isLanguageMenuOpen && (
                <DropdownMenu initial={{opacity: 0}} animate={{opacity: 1}}>
                    {languages.map(lang => (
                        <DropdownMenuItem onClick={handleChangeLanguage(lang.value)}>{lang.label}</DropdownMenuItem>
                    ))}
                </DropdownMenu>
            )}
        </Dropdown>
    );
}
