import {useCallback, useEffect, useState} from "react";
import {checkSession} from "../../api/sessionCheck";

export const useSession = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

    const checkUserSession = useCallback(async () => {
        try {
            const response = await checkSession()
            if (!response.error) {
                setIsAuthenticated(true);
            } else {
                localStorage.removeItem('sessionId');
                setIsAuthenticated(false);
            }
        } catch (error) {
            localStorage.removeItem('sessionId');
            setIsAuthenticated(false);
        }
    }, []);

    useEffect(() => {
        const session = localStorage.getItem('sessionId');
        if (session) {
            setIsAuthenticated(true);
        } else {
            checkUserSession();
        }
    }, [checkUserSession]);

    return { isAuthenticated, checkUserSession };
};
