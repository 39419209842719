import { createSelector } from "reselect";
import {RootState} from "../index";

const findCategoryById = (categories: any, targetId?: string): any | null => {
    if (!targetId) {
        return null;
    }

    if (categories) {
            for (let category of categories) {
        if (category.id === +targetId) {
          return category;
        }

        if (category.child_ids && category.child_ids.length) {
          const selectedCategory = findCategoryById(category.child_ids, targetId);

          if (selectedCategory) return selectedCategory;
        }
    }


  }

  return null;
}

export const hierarchyCategorySelector = (state: RootState) => state.hierarchyCategory;

export const selectCategoryById = (id?: string) =>
    createSelector(
        hierarchyCategorySelector,
        (hierarchyCategoryState) => {
            return findCategoryById(hierarchyCategoryState.data, id);
        }
    );