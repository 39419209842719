// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help_text {
  text-align: center;
  margin: 0px 5px 41px 5px;
  color: #6a6666;
}

.recover_form {
  padding: 0px 60px;
}

.recover_input {
  margin-bottom: 25px;
}

.go_home_block {
  text-align: center;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/RecoverPassword/RecoverPassword.sass"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,wBAAA;EACA,cAAA;AACJ;;AACA;EACI,iBAAA;AAEJ;;AAAA;EACI,mBAAA;AAGJ;;AADA;EACI,kBAAA;EACA,gBAAA;AAIJ","sourcesContent":[".help_text\n    text-align: center\n    margin: 0px 5px 41px 5px\n    color: #6a6666\n\n.recover_form\n    padding: 0px 60px\n\n.recover_input\n    margin-bottom: 25px\n\n.go_home_block\n    text-align: center\n    margin-top: 10px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
