// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sort-select {
  appearance: none;
  padding: 5px;
  border: none;
  font-family: inherit;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/catalog/catalogGrid/sortSelection/SortSelection.sass"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,YAAA;EACA,oBAAA;EACA,aAAA;AACF","sourcesContent":[".sort-select\n  appearance: none\n  padding: 5px\n  border: none\n  font-family: inherit\n  outline: none\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
