import Checkbox from "@mui/material/Checkbox";
import {checkboxStyle} from "../style";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hooks/ReduxHooks";
import {removeActiveFilters, setActiveFilters} from "../../../../store/slices/PagePaginagionSlice";
import {useDidUpdate} from "../../../../hooks/useDidUpdate";
import {SEARCH_PARAMS} from "../../../../constants/consts";

interface searchByCheckboxProps {
    filterItem: string
}

export const SearchByCheckbox = (props: searchByCheckboxProps) => {

    const {filterItem} = props
    const {activeFilters} = useAppSelector(state => state.page)
    const isInFilter = activeFilters.includes(filterItem)
    const [isChecked, setIsChecked] = useState(isInFilter)

    const dispatch = useAppDispatch()

    const handleOnChange = () => {
        setIsChecked(!isChecked)
    }

    useDidUpdate(() => {
        if (isChecked) {
            dispatch(setActiveFilters(filterItem))
        }
        else {
            dispatch(removeActiveFilters(filterItem))
        }
    },[isChecked]);
    
    
    return (
        <>
            <FormControlLabel value={"manufacture_code"}
                              checked={isChecked}
                              control={ <Checkbox sx={checkboxStyle}/>}
                              label={SEARCH_PARAMS[filterItem]}
                              onChange={handleOnChange}
            />
        </>
    )
}