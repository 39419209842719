import React, { useState } from 'react';
import './QuestionaryPage.sass';

interface ExpandableBlockProps {
    renderContent: (block: any, index: number, handleInputChange: (id: number, key: string, value: any) => void) => React.ReactNode;
    initialData: any[];
    emptyBlock: any;
}

export const ExpandableBlock: React.FC<ExpandableBlockProps> = ({ renderContent, initialData,emptyBlock }) => {
    const [blocks, setBlocks] = useState<any[]>(initialData.map(item => ({ ...item, id: Date.now() + Math.random() })));

    const handleInputChange = (id: any, key: any, value: any) => {
        const updatedBlocks = blocks.map((block: { id: any }) =>
            block.id === id ? { ...block, [key]: value } : block
        );
        setBlocks(updatedBlocks);
    };

    const addBlock = () => {
        const newBlock = { ...emptyBlock, id: Date.now() + Math.random() }; // создаем новый пустой блок
        setBlocks([...blocks, newBlock]);
    };

    const removeBlock = (id: any) => {
        const updatedBlocks = blocks.filter((block: { id: any }) => block.id !== id);
        setBlocks(updatedBlocks);
    };

    return (
        <>
            {blocks.map((block: { id: React.Key | null | undefined }, index: number) => (
                <>
                <div className="extended_general_block" key={block.id}>
                    {renderContent(block, index, handleInputChange)}
                    <div className="extended_general_block_buttons">
                        {index === blocks.length - 1 && (
                            <button type="button" className="extended_buttons" onClick={addBlock}>+</button>
                        )}
                        {blocks.length > 1 && (
                            <button type="button" className="extended_buttons" onClick={() => removeBlock(block.id)}>-</button>
                        )}
                    </div>
                </div>
                    {index < blocks.length - 1 && <div className="block-separator" key={index}/>}
                </>
            ))}
            </>
    );
};