import { useCallback, useState } from "react";

interface UseAuthReturnValues {
    email: string;
    password: string;
    region?: string;
    companyName?: string;
    vat?: string;
    phone?: string;
    registration?: string;
    zip?: string;
    city?: string;
    address?: string;
    owners: string[];
    boards: string[];
    handleChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeZip: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeAddress: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeOwner: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
    handleAddOwner: VoidFunction;
    handleAddBoard: VoidFunction;
    handleRemoveOwner: (index: number) => void;
    handleRemoveBoard: (index: number) => void;
    handleChangeBoard: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeCity: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeRegion: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    handleChangeCompanyName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeVat: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangePhone: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeRegistration: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleAuth: VoidFunction;
}

interface UseAuthProps {
    handleClick: (
        email: string, password: string, region: string, companyName: string, vat: string, phone: string, registration: string, zip: string, city: string, address: string, owners: string[], boards: string[]) => void;
}

export function useAuth({ handleClick }: UseAuthProps): UseAuthReturnValues {
    const [email, setEmail] = useState<string>("");
    const [zip, setZip] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [boards, setBoards] = useState<string[]>([""]);
    const [owners, setOwners] = useState<string[]>([""]);
    const [password, setPassword] = useState<string>("");
    const [region, setRegion] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>("");
    const [vat, setVat] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [registration, setRegistration] = useState<string>("");

    const handleChangeZip = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setZip(event.target.value);
    }, []);
    const handleChangeAddress = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
    }, []);

    const handleChangeCity = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);
    }, []);

    const handleChangeOwner = useCallback((index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        setOwners(owners.map((owner, i) => (i === index ? event.target.value : owner)));
    }, [owners]);

    const handleAddOwner = useCallback(() => {
        setOwners([...owners, ""]);
    }, [owners]);

    const handleRemoveOwner = useCallback((index: number) => {
        setOwners(owners.filter((_, i) => i !== index));
    }, [owners]);

    const handleChangeBoard = useCallback((index:number,event: React.ChangeEvent<HTMLInputElement>) => {
        setBoards(boards.map((board, i) => (i === index ? event.target.value : board)));
    }, [boards]);

    const handleRemoveBoard = useCallback((index: number) => {
        setBoards(boards.filter((_, i) => i !== index));
    }, [boards]);

    const handleAddBoard = useCallback(() => {
        setBoards([...boards, ""]);
    }, [boards]);

    const handleChangeEmail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }, []);

    const handleChangePassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }, []);

    const handleChangeRegion = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        setRegion(event.target.value);
    }, []);

    const handleChangeCompanyName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyName(event.target.value);
    }, []);

    const handleChangeVat = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setVat(event.target.value);
    }, []);

    const handleChangePhone = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
    }, []);

    const handleChangeRegistration = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setRegistration(event.target.value);
    }, []);

    const handleAuth = useCallback(() => {
        handleClick(email, password, region, companyName, vat, phone, registration, zip, city, address, owners, boards);
    }, [email, password, region, companyName, vat, phone, registration, zip, city, address, owners, boards]);

    return {
        email,
        password,
        region,
        companyName,
        vat,
        phone,
        registration,
        zip,
        city,
        address,
        owners,
        boards,
        handleChangeEmail,
        handleChangeZip,
        handleChangeCity,
        handleChangeAddress,
        handleChangeOwner,
        handleAddOwner,
        handleRemoveOwner,
        handleChangeBoard,
        handleAddBoard,
        handleRemoveBoard,
        handleChangePassword,
        handleChangeRegion,
        handleChangeCompanyName,
        handleChangeVat,
        handleChangePhone,
        handleChangeRegistration,
        handleAuth,
    };
}
