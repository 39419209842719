import React from "react";
import ChatBlock from "../../chat/ChatBlock";
import "./OrderContent.sass"

interface OrderContentProps {
    sidebar: JSX.Element,
    introduction: JSX.Element,
    info: JSX.Element,
    table: JSX.Element,
    total: JSX.Element,
    recordId: number,
    model: string,
}

export const OrderContent = (props: OrderContentProps) => {

    const {
        sidebar,
        introduction,
        info,
        table,
        total,
        recordId,
        model,
    } = props

    return (
        <>
            <div className="container pt-3 pb-5">
                <div className="container o_portal_sidebar">
                    <div className="row o_portal_sale_sidebar">
                        <div className="d-flex col-lg-4 col-xxl-3 d-print-none">
                            {sidebar}
                            <div className="vr d-none d-lg-block bg-300"></div>
                        </div>

                        <div className="col-12 col-lg-8 col-xxl-9 mt-5 mt-lg-0 order__content">
                            <div className="pb-5">
                                <div>
                                    {introduction}
                                    <div id="content">
                                        {info}

                                        <section id="details">
                                            {table}
                                            {total}
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <section>
                               <ChatBlock
                                   model={model}
                                   recordId={recordId}
                               />
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}