// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product__cart-btn-control {
  width: 100%;
  max-width: unset;
}
.product__cart-btn-control.kanban {
  height: 40px;
}
.product__cart-btn-control .cart_btn {
  width: 100%;
  background-color: #0cbbfc;
  color: #ffffff !important;
  font-weight: bold;
}
.product__cart-btn-control .cart_btn:hover {
  background-color: #32c8fa;
}
.product__cart-btn-control .product__input {
  max-width: 85px;
  padding: 8.5px 14px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}
.product__cart-btn-control .product__input:focus {
  border-color: rgb(110, 209, 243);
  outline: none;
}

.list-view {
  display: flex;
  justify-content: center;
  gap: 12px;
}
.list-view .product__input {
  max-width: 60px;
  padding: 8.5px 14px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}
.list-view .product__input:focus {
  border-color: rgb(110, 209, 243);
  outline: none;
}
.list-view .product_list_btn-control {
  cursor: pointer;
  font-size: 8pt;
  padding: 3px 5px;
  border-radius: 4px;
}
.list-view .input-not-in-cart {
  max-width: 40px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/productCardControl/ProductCardControl.sass"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;AACF;AACE;EACE,YAAA;AACJ;AACE;EACE,WAAA;EACA,yBAAA;EACA,yBAAA;EACA,iBAAA;AACJ;AAAI;EACE,yBAAA;AAEN;AAAE;EACE,eAAA;EACA,mBAAA;EACA,qCAAA;AAEJ;AAAI;EACE,gCAAA;EACA,aAAA;AAEN;;AAGA;EACE,aAAA;EACA,uBAAA;EACA,SAAA;AAAF;AAEE;EACE,eAAA;EACA,mBAAA;EACA,qCAAA;AAAJ;AAEI;EACE,gCAAA;EACA,aAAA;AAAN;AAIE;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;AAFJ;AAKE;EACE,0BAAA;AAHJ","sourcesContent":[".product__cart-btn-control\n  width: 100%\n  max-width: unset\n\n  &.kanban\n    height: 40px\n\n  .cart_btn\n    width: 100%\n    background-color: #0cbbfc\n    color: #ffffff !important\n    font-weight: bold\n    &:hover\n      background-color: #32c8fa\n\n  .product__input\n    max-width: 85px\n    padding: 8.5px 14px\n    border: 1px solid rgba(0, 0, 0, 0.23)\n\n    &:focus\n      border-color: rgb(110, 209, 243)\n      outline: none\n\n\n\n\n.list-view\n  display: flex\n  justify-content: center\n  gap: 12px\n\n  .product__input\n    max-width: 60px\n    padding: 8.5px 14px\n    border: 1px solid rgba(0, 0, 0, 0.23)\n\n    &:focus\n      border-color: rgb(110, 209, 243)\n      outline: none\n\n\n\n  .product_list_btn-control\n    cursor: pointer\n    font-size: 8pt\n    padding: 3px 5px\n    border-radius: 4px\n\n\n  .input-not-in-cart\n    max-width: 40px !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
