import {getSession} from "./sessionManagement";
import {resolve} from "./resolve";
import axios from "axios";
import {API_BACKEND_URL} from "../constants/consts";
import {getExchangeHeaders} from "./helpers";


export async function fetchFinTermsRequests() {
    return await resolve(axios.get(`${API_BACKEND_URL}/contact/financial_information`,
        {
            headers: getExchangeHeaders(),
        }

    ).then(res => res));
}
