import {ControlButton} from "../../controlButton/controlButton";
import {SubmitForm} from "../submitForm/SubmitForm";
import {ReturnSearchForm} from "../returnSearchForm/ReturnSearchForm";
import React, {useEffect} from "react";
import {useAppDispatch} from "../../../hooks/ReduxHooks";
import { resetReturnData } from "../../../store/slices/NewReturnSlice";


export const NewReturn = () => {


    return (
        <>
            <section className={"new_return__section"}>
                <div className="new_return__wrapper">
                    <ControlButton className="favIcon text-colored RMA__btn btn btn-sm"
                                   icon="bi bi-download RMA__icon"
                                   label={
                                       <div className="RMA__label">
                                           RMA указания по упаковке
                                       </div>
                                   }
                                   controlButtonHandler={() => {
                                   }}/>

                </div>
            </section>
            <section className={"new_return__section"}>
                <SubmitForm/>
            </section>
            <section className={"new_return__section"}>
                <ReturnSearchForm/>
            </section>
        </>
    )
}