import React from "react";
import {ProductReturns} from "../../components/productReturns/ProductReturns"

export const ProductReturnsPage = () => {


    return (
        <>
            <ProductReturns/>
        </>
    )
}