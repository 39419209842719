import * as React from "react";
import {NavLink} from "react-router-dom";
import {breadcrumbNameMapExclude} from "../../constants/consts";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import {lastBreadcrumbStyles, SEPARATOR, breadcrumbsRoutes} from "../breadcrumbsRoutes";

interface BaseBreadcrumbProps {
    data?: BreadcrumbsDataProps
}

export interface BreadcrumbsDataProps {
    [url: string]: string;
}

export const BaseBreadCrumbs = (props: BaseBreadcrumbProps) => {
    const breadcrumbs = useBreadcrumbs(breadcrumbsRoutes, {excludePaths: breadcrumbNameMapExclude})
    const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1]

    return (
        <div>
            {
                breadcrumbs.map(({match, breadcrumb}) => (

                    <NavLink end={lastBreadcrumb.match !== match} key={match.pathname} to={match.pathname}
                             style={({isActive}) => ({
                                 color: isActive ? '#808084' : '#6ed1f3',
                                 textDecoration: 'none',
                                 cursor: isActive ? "default" : "pointer",

                             })}>
                         {breadcrumb}
                        {lastBreadcrumb.match !== match && <span style={lastBreadcrumbStyles}><b>{SEPARATOR}</b></span>}
                    </NavLink>

                ))
            }
        </div>
    )


}
