import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';


export interface Child {
    id: number;
    name: string;
    is_has_products:boolean;
    image_url: string;
    child_ids: Child[];
}

export interface Node {
    id: number;
    name: string;
    is_has_products:boolean;
    image_url: string;
    child_ids: Child[];
}

export interface initialStateType {
    isLoading: boolean;
    data: Node[] | undefined;
    error: string | null;
}

const initialState: initialStateType = {
    data: undefined,
    isLoading: true,
    error: null,
};


const hierarchyCategorySlice = createSlice({
    name: 'hierarchyCategory',
    initialState,
    reducers: {
        resetHierarchyCategoryData: (state) => {
            Object.assign(state, initialState);
        },
        setHierarchyCategoryData: (state, action: PayloadAction<Node[]>) => {
            state.data = action.payload;
        },
        setHierarchyCategoryError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
    },
});

export const {
    resetHierarchyCategoryData,
    setHierarchyCategoryData,
    setHierarchyCategoryError,
} = hierarchyCategorySlice.actions;

export default hierarchyCategorySlice.reducer;