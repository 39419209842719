import {useEffect, useState} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {SwiperProduct} from "../../swiperProduct/SwiperProduct";
import * as React from "react";
import {setPreviewProduct} from "../../../store/slices/PreviewProductSlice";
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import {fetchProduct, resetProduct} from "../../../store/slices/ProductReducerSlice";
import {useGetProductImages} from "../../../hooks/useGetProductImages";



export const ProductPreviewModal = () => {
    const [openPreview, setOpenPreview] = useState(false);
    const {previewProductId} = useAppSelector((state: any) => state.previewProduct);
    const {product} = useAppSelector(state => state.product)

    const handleOpen = () => setOpenPreview(true);
    const dispatch = useAppDispatch()
    const productImages = useGetProductImages()

    const handleClose = () => {
        dispatch(setPreviewProduct(undefined))
        setOpenPreview(false)

    };

    useEffect(() => {
        if (previewProductId) {
            handleOpen()
            dispatch(fetchProduct(previewProductId))
        } else {
            dispatch(resetProduct())
        }
    }, [previewProductId]);

    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "10px",
        p: 4,
        width:"50%"
    };

    if (!product) return <></>

    return (

            <Modal
                open={openPreview}
                onClose={handleClose}
                className="products__modal"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="product__wrapper">
                    <Box sx={style}>
                        <div className="modal__content">
                            <SwiperProduct imageList={productImages}/>
                            <div className="modal__close"
                                 onClick={handleClose}
                            >
                                <i className="bi bi-x"></i>
                            </div>
                        </div>
                    </Box>
                </div>
            </Modal>
    )

}
