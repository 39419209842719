import {resolve} from "./resolve";
import axios from "axios";
import {API_BACKEND_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";

export async function getHomeBanners(){
    return await resolve(axios.get(`${API_BACKEND_URL}/banners/get_banners`,
        {
            headers: {
                "Content-Type": "application/json",
                "session_id": getSession(),
            }
        }
    ).then(res => res));
}