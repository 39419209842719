import {useAppSelector} from "../../../hooks/ReduxHooks";
import {CatalogCard} from "../../catalog/catalogGrid/catalogCard/CatalogCard";
import {Link, useLocation} from "react-router-dom";
import * as React from "react";
import "./OrdersContent.sass"
import {BasePagination} from "../../pagination/Pagination";
import {ROUTE_URLS} from "../../../constants/consts";

export const OrdersContent = () => {
    const {ordersData} = useAppSelector(state => state.orders)

    const location = useLocation()
    const isReserve = location.pathname === ROUTE_URLS.RESERVES


    return (ordersData ?
        <>
            <div className="ordersTableWrapper">
                <table className="table ordersTable">
                    <thead className="cartTable__header">
                    <tr>
                        <th scope="col" className="text-colored text-start text-nowrap">№</th>
                        <th scope="col" className="text-colored text-start text-nowrap">Название</th>
                        <th scope="col" className="text-colored text-start text-nowrap">Дата создания</th>
                        {isReserve ? (
                            <>
                            <th scope="col" className="text-colored text-start text-nowrap">Ответственный</th>
                            <th scope="col" className="text-colored text-start text-nowrap">Срок резерва</th>
                            </>
                            ) : (
                                <>
                                    <th scope="col" className="text-colored text-start text-nowrap">Статус</th>
                                    <th scope="col" className="text-colored text-start text-nowrap">Заказ</th>
                                </>
                            )}
                        <th scope="col" className="text-colored text-end text-nowrap">Сумма</th>
                    </tr>
                    </thead>
                    <tbody className="cartTable__body">
                    {ordersData.sale_info.map((child) =>
                        <tr key={child.id}>
                            <td>
                                <Link
                                    className="catalog_product__link"
                                    to={(isReserve ? ROUTE_URLS.RESERVES : ROUTE_URLS.ORDERS) + `/${child.id}`}
                                >
                                    <p className="text-start fw-bold">{child.name}</p>
                                </Link>
                            </td>
                            <td>
                                <Link
                                    className="catalog_product__link"
                                    to={(isReserve ? ROUTE_URLS.RESERVES : ROUTE_URLS.ORDERS) + `/${child.id}`}
                                >
                                    <p className="text-start fw-bold">{child.reservation_name}</p>
                                </Link>
                            </td>
                            <td className="text-start">
                                <div><span>{child.date_order}</span></div>
                            </td>
                            {isReserve ? (
                                <>
                                    <td scope="col" className="text-start fw-bold"></td>
                                    <td scope="col" className="text-start fw-bold">{child.reservation_date}</td>
                                </>
                            ) : (
                                <>
                                    <td className="text-success bold-text">{child.delivery_status && child.delivery_status}</td>
                                    {child.is_b2b_active ? (
                                        <td className="text-success bold-text">Активный</td>
                                    ) : (
                                        <td className="text-danger bold-text">Неактивный</td>
                                    )}
                                </>
                            )}
                            <td className="text-end">
                                <div><span>{child.amount_untaxed} {child.currency && child.currency["symbol"]}</span></div>
                            </td>
                        </tr>
                    )}

                    </tbody>
                </table>
                <div>
                    {ordersData.pages_count > 1 && <BasePagination count={ordersData.pages_count}/>}
                </div>
            </div>
        </> : <></>)
}