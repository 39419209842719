import React, {useCallback, useEffect, useState} from "react";
import {
    VideoBackground,
    VideoSectionContainer,
    MainText,
    CardsContainer,
    MainContent,
} from "./styles";
import PinsIcon from "../../../../images/icons/pins.svg";
import ShieldIcon from "../../../../images/icons/shield.svg";
import VictoryIcon from "../../../../images/icons/victory.png";
import DeliveryIcon from "../../../../images/icons/delivery.svg";
import MapIcon from "../../../../images/icons/map.png";
import AmazonIcon from "../../../../images/icons/amazon.svg";
import { SectionPrimaryButton, SectionSecondaryButton, ButtonsContainer } from "../../styles";
import { Card } from "./components/card";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { SubmitFormModal } from "../submitFormModal";

interface VideoSectionProps {
    mainSectionRef: React.MutableRefObject<HTMLDivElement | null>;
}

const DIVISOR = 4;

export const VideoSection = ({ mainSectionRef }: VideoSectionProps) => {
    const navigate = useNavigate();
    const handleNavigateToSignIn = () => {
        navigate("/sign-in");
    };
    const [isSubmitModalOpen, setOpenSubmitModal] = useState(false);

    const toggleModal = useCallback(() => {
        setOpenSubmitModal(prev => !prev);
    }, []);

    const [topOffset, setTopOffset] = useState(window.innerHeight / DIVISOR);

    useEffect(() => {
        const handleResize = () => {
            setTopOffset(window.innerHeight / DIVISOR);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const cards = [
        {
            id: 1,
            icon: DeliveryIcon,
            title: t("delivery_icon"),
        },
        {
            id: 2,
            icon: ShieldIcon,
            title: t("shield_icon"),
        },
        {
            id: 3,
            icon: PinsIcon,
            title: t("pins_icon"),
        },
        {
            id: 4,
            icon: MapIcon,
            title: t("map_icon"),
        },
        {
            id: 5,
            icon: VictoryIcon,
            title: t("victory_icon"),
        },
        {
            id: 6,
            icon: AmazonIcon,
            title: t("amazon_con"),
        }
    ];

    return (
        <VideoSectionContainer>
            <VideoBackground>
                <video autoPlay loop muted playsInline>
                    <source src="https://rde-crm.icodelab.eu/videos/safarivide.mp4" type="video/mp4"/>
                </video>
            </VideoBackground>
            <MainContent ref={mainSectionRef} $topOffset={topOffset}>
                <MainText>{t("landing_main_text")}</MainText>
                <ButtonsContainer $withMargin $shouldApplyColumnStyles>
                    <SectionPrimaryButton onClick={handleNavigateToSignIn}>{t("section_primary_button")}</SectionPrimaryButton>
                    <SectionSecondaryButton
                        onClick={toggleModal}>{t("section_secondary_button")}</SectionSecondaryButton>
                    </ButtonsContainer>
                </MainContent>
            <CardsContainer>
                {cards.map((card, index) => (
                    <Card key={card.id} index={index} title={card.title} icon={card.icon} />
                ))}
            </CardsContainer>
            <SubmitFormModal isSubmitModalOpen={isSubmitModalOpen} toggleModal={toggleModal} />
        </VideoSectionContainer>
    );
}
