import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {useState} from "react";
import "./NewWarrantyRequestDialog.sass";
import {inputAddressStyleSM, inputProps} from "../../productReturns/ProductReturns";
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import {linesToReturnType, setLineToReturn} from "../../../store/slices/NewReturnSlice";
import {linesToWarrantyType, setLineToWarranty} from "../../../store/slices/NewWarrantySlice";
import {UploadFile} from "../../productReturns/addLineDialog/UploadFile";
import {warrantyDefectType} from "../../../hooks/UseWarrantyDefectTypes";
import MenuItem from "@mui/material/MenuItem";
import {Trans} from "react-i18next";

interface AddLineDialogProps {
    openedLine: any,
    handleClose: () => void,
    defectTypes: warrantyDefectType[],
}

export const NewWarrantyRequestDialog = (props: AddLineDialogProps) => {
    const {openedLine, handleClose, defectTypes} = props;
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [selectedDefect, setSelectedDefect] = useState<warrantyDefectType | undefined>(undefined);
    const [description, setDescription] = useState<string>("")

    const [files, setFiles] = useState<File[]>([])

    const {linesToWarranty} = useAppSelector(state => state.newWarranty)

    const dispatch = useAppDispatch()

    const handleDialogClose = () => {
        setIsOpened(false);
        setSelectedDefect(undefined);
        setFiles([]);
        setDescription("")
        handleClose();
    };

    const handleAddGuarantee = () => {
        if (!openedLine) return
        if (!openedLine) return
        let newLineData: linesToWarrantyType = {
            id: linesToWarranty.length + 1,
            lineId: openedLine.id,
            lineData: openedLine,
            filesData: files,
            quantity: openedLine.quantity,
            description: description,
        }
        if (selectedDefect) {
            newLineData["defectType"] = selectedDefect
        }
        dispatch(setLineToWarranty([...linesToWarranty, newLineData]))
        setIsOpened(false);
        handleDialogClose();
    }

    const handleDefectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const defectType = defectTypes.find((el) => el.id === event.target.value)
        setSelectedDefect(defectType);
    };

    return (openedLine &&

        <div className="position-relative">
            <Dialog
                open={!!openedLine}
                onClose={handleDialogClose}
                PaperProps={{
                    component: 'form',
                    style: {padding: '20px', maxWidth: '600px'},
                }}
            >
                <DialogTitle className="warranty-dialog-title">
                    <Trans i18nKey="dialog_title"/>
                </DialogTitle>
                <DialogContent className="warranty-dialog">
                    <div className="warranty-dialog-secondary">
        <span>
          <Trans i18nKey="product_info_title"/>
        </span>
                    </div>
                    <div className="product-info">
                        <div className="product-info-value">
                            <strong><Trans i18nKey="code"/>:</strong> {openedLine.default_code}
                        </div>
                        <div className="product-info-value">
                            <strong><Trans i18nKey="serial_number"/>:</strong> --------
                        </div>
                        <div className="product-info-value">
                            <strong><Trans i18nKey="warranty_period"/>:</strong> {openedLine.guarantee}
                        </div>
                        <div className="product-info-value">
                            <strong><Trans i18nKey="product_name"/>:</strong> {openedLine.product_name}
                        </div>
                        <div className="product-info-value">
                            <strong><Trans i18nKey="invoice"/>:</strong> {openedLine.waybill_number}
                        </div>
                        <div className="product-info-value">
                            <strong><Trans i18nKey="invoice_date"/>:</strong> {openedLine.waybill_date}
                        </div>
                        <div className="product-info-value">
                            <strong><Trans i18nKey="warranty_end"/>:</strong> {openedLine.warranty_service}
                        </div>
                    </div>
                    <div className="warranty-dialog-secondary">
        <span>
          <Trans i18nKey="defects_title"/>
        </span>
                    </div>
                    <div className="product-info-secondary">
                        <div className="product-info-secondary-select">
                            <div>
            <span>
              <Trans i18nKey="select_defects"/>
            </span>
                            </div>
                            <div className="product-info-select">
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    size="small"
                                    label={<Trans i18nKey="select"/>}
                                    inputProps={inputProps}
                                    required
                                    InputProps={{
                                        sx: {
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgb(110, 209, 243)',
                                                outline: 'none',
                                            },
                                        },
                                    }}
                                    onChange={handleDefectChange}
                                    value={selectedDefect?.id}
                                    sx={inputAddressStyleSM}
                                >
                                    {defectTypes.map((defectType) => (
                                        <MenuItem key={defectType.id} value={defectType.id}>
                                            {defectType.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className="product-info-text">
                            <div className="product-info-text-first">
            <span>
              <Trans i18nKey="defects_hint"/>
            </span>
                            </div>
                        </div>
                        <TextField
                            label={<Trans i18nKey="defect_description_label"/>}
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            value={description}
                            onChange={(event) => {
                                setDescription(event.target.value);
                            }}
                            margin="normal"
                            style={{marginBottom: '15px'}}
                        />
                        <section className="add-dialog__section">
                            <UploadFile files={files} setFiles={setFiles}/>
                        </section>
                    </div>
                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between'}}>
                    <Button onClick={handleDialogClose} color="primary">
                        <Trans i18nKey="cancel"/>
                    </Button>
                    <Button onClick={handleAddGuarantee} variant="contained" color="primary">
                        <Trans i18nKey="add"/>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    );
};