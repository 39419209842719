import axios from "axios";
import {resolve} from "./resolve.js";
import {API_BACKEND_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";
import {getExchangeHeaders} from "./helpers";


export async function fetchCountries() {
    return await resolve(axios.get(`${API_BACKEND_URL}/sales/countries/get_countries`,
            {
                headers: getExchangeHeaders(),
            }
        ).then(res => res)
    );
}
