import { styled } from "styled-components";
import { Box, TextField } from "@mui/material";

export const Section = styled(Box)`
  margin-top: 2rem;
`;

export const CustomTextField = styled(TextField).attrs(() => ({
    variant: 'standard',
}))`
  margin-bottom: 24px;

  & label {
    font-size: 14px;
    color: #777;
    transform: none;
    position: static;
  }

  & .MuiInputBase-root {
    padding: 0;
    margin-top: 0;
  }

  & .MuiInputBase-input {
    font-size: 16px;
    padding: 4px 0;
  }

  & .MuiInput-underline:before {
    border-bottom: 1px solid #777;
  }

  & .MuiInput-underline:hover:before {
    border-bottom: 1px solid #777;
  }

  & .MuiInput-underline:after {
    border-bottom: 1px solid #1976d2;
  }
`;
