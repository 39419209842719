import styled from "styled-components";
import { systemBlack } from "../../../constants";

export const ContactsPane = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
`;

export const ContactName = styled.span`
    font: 400 16px/21px "Roboto", sans-serif;
    color: #90989F;
`;

export const ContactText = styled.span`
    font: 400 16px/21px "Roboto", sans-serif;
    color: ${systemBlack};
`;

export const Link = styled.a`
    font: 400 16px/21px "Roboto", sans-serif;
    color: #306BF5;
    text-decoration: none;
`;

export const CloseIconContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;
    width: 100%;
    cursor: pointer;
`;


export const CloseIcon = styled.i`
    font-size: 30px;
`;

export const ContactUsButton = styled.span`
    cursor: pointer;
    font: 400 16px/21px "Roboto", sans-serif;
    color: #306BF5;
    margin-top: 25px;

    &:hover {
        opacity: 0.8;
    }
`;

export const Text = styled.span`
    font: 400 14px/18px "Roboto", sans-serif;
    color: ${systemBlack};
`;
