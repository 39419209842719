import "./StartPage.sass"
import Logo from '../../images/rde_standart.png';
import LanguageSwitcher from "../../components/languageSwitcher/languageSwitcher";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";
export const StartPage = () => {
    const navigate = useNavigate();
    const handleNavigateToSignIn = () => {
        navigate("/sign-in");
    };

    return (
        <>
            <div className="start-page-container">
                <header className="start-page-header">
                    <img alt="" className="logo" src={Logo} style={{width: "165px"}}/>
                    <nav className="nav">
                        <a href="#about-us">ABOUT US</a>
                        <LanguageSwitcher />
                    </nav>
                </header>

                <section className="start-page-login-container">
                    <div className="start-page-login-overlay">
                        <div className="start-page-login-content">
                            <button className="start-page-login-btn" onClick={handleNavigateToSignIn}>{t("become_customer")}</button>
                            <button className="start-page-login-btn">{t("become_supplier")}</button>
                        </div>
                    </div>
                </section>

                <section className="start-page-login-info-section">
                    <h2>Your key to success for the office, consumer electronics and IT-trade</h2>
                </section>
            </div>
        </>
    )
}