import axios from 'axios';
import { resolve } from './resolve.js';
import {API_BACKEND_URL, ENDPOINT} from "../constants/consts";
import {getSession} from "./sessionManagement";



export async function downloadExcelRequest(productIds) {
    return await resolve(axios.post(`${API_BACKEND_URL}/sales/download_pricelist`,
    {
        product_ids: productIds,
        product_count: 0,
        filename: "Pricelist"
    }, {
            headers: {
                "Content-Type": "application/json",
                "session_id": getSession(),
            }
        }
    ).then(res => res));
}
