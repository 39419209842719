import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "./SubSidebar.sass";

interface SubSidebarProps {
    className: string;
    isMainCategoryVisible: boolean;
    isChildCategoryVisible: boolean;
    setIsChildCategoryVisible: any;
    closeAllCategory: any;
}

export const CategoriesSubmenu = (props: SubSidebarProps) => {
    const { isMainCategoryVisible, isChildCategoryVisible, setIsChildCategoryVisible } = props;
    const [categoryStacks, setCategoryStacks] = useState<any[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [hoveredCategoryId, setHoveredCategoryId] = useState<number | null>(null);
    const [hoveredChildCategoryId, setHoveredChildCategoryId] = useState<number | null>(null);
    const [currentCategory, setCurrentCategory] = useState<any>(null);

    const categories = useSelector((state: any) => state.hierarchyCategory.data);
    const navigate = useNavigate();

    useEffect(() => {
        if (categories) {
            setCategoryStacks([categories]);
        }
    }, [categories]);

    const handleMainCategoryClick = (category: any) => {
        if (category.child_ids.length > 0) {
            setCategoryStacks([categories, category.child_ids]);
            setSelectedCategories([category.name]);
            setCurrentCategory(category);
            setIsChildCategoryVisible(true);
        } else {
            navigate(`/products/${category.id}`);
            props.closeAllCategory();
        }
    };

    const handleCategoryClick = (category: any) => {
        if (category.child_ids.length > 0) {
            const newCategoryStack = [...categoryStacks, category.child_ids];
            const newSelectedCategories = [...selectedCategories, category.name];

            setCategoryStacks(newCategoryStack);
            setSelectedCategories(newSelectedCategories);
            setCurrentCategory(category);
        } else {
            navigate(`/products/${category.id}`);
            props.closeAllCategory();
        }
    };

    const handleBackClick = () => {
        if (categoryStacks.length > 2) {
            const updatedStacks = categoryStacks.slice(0, -1);
            const updatedSelectedCategories = selectedCategories.slice(0, -1);

            setCategoryStacks(updatedStacks);
            setSelectedCategories(updatedSelectedCategories);
            setCurrentCategory(updatedStacks[updatedStacks.length - 1] || null);
        } else {
            setCategoryStacks([categories]);
            setSelectedCategories([]);
            setIsChildCategoryVisible(false);
        }
    };

    const handleMouseEnter = (id: number, category: any) => {
        setHoveredCategoryId(id);
        setCategoryStacks([categories, category.child_ids]);
        setSelectedCategories([category.name]);
        setCurrentCategory(category);
        setIsChildCategoryVisible(true);
    };

    const handleMouseLeave = () => {
        setHoveredCategoryId(null);
    };

    const handleChildMouseEnter = (id: number) => {
        setHoveredChildCategoryId(id);
    };

    const handleChildMouseLeave = () => {
        setHoveredChildCategoryId(null);
    };

    return (
        <>
            <div className={`mainCategory ${isMainCategoryVisible ? 'slideIn' : 'slideOut'}`}>
                <div className="mainCategoryWrapper">
                    <ul className="mainCategoryList">
                        {categoryStacks[0]?.filter((elem:any) => elem.is_has_products).map((category: any) => (
                            <li
                                key={category.id}
                                onClick={() => handleMainCategoryClick(category)}
                            >
                                <span className="category-text">{category.name}</span>
                                {category.child_ids.length > 0 && (
                                    <ChevronRightIcon className="childCategoryIcon"/>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {categoryStacks.slice(1).map((categories: any, index: any) => (
                <div key={index} className={`childCategory ${isChildCategoryVisible ? 'slideIn' : 'slideOut'}`}>
                    <div className="childCategoryWrapper">
                        {index == 0 && (
                            <div className="childCategorySearch" style={{cursor: 'default'}}>
                                <span>{selectedCategories[index]}</span>
                            </div>
                        )}
                        {index > 0 && (
                            <div className="childCategorySearch" onClick={handleBackClick}>
                                <ChevronLeftIcon className="backArrow"/>
                                <span>{selectedCategories[index-1]}</span>
                            </div>
                        )}
                        {index > 0 && (
                            <div className="childCategoryLabel">
                                <span>{selectedCategories[index]}</span>
                            </div>
                        )}

                        <ul className="childCategoryList">
                            {categories.filter((elem:any) => elem.is_has_products).map((category: any) => (
                                <li
                                    key={category.id}
                                    onClick={() => handleCategoryClick(category)}
                                >
                                    <span className="category-text">{category.name}</span>
                                    {category.child_ids.length > 0 && (
                                        <ChevronRightIcon className="childCategoryIcon"/>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </>
    );
};
