import React from "react";
import "./ReturnTable.sass"
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import {setLineToReturn} from "../../../store/slices/NewReturnSlice";
import {PRODUCT_CONDITION} from "../../../constants/consts";

interface TableRowProps {
    lineData: any,
}

export const ReturnTableRow = (props: TableRowProps) => {

    const {lineData} = props
    const {linesToReturn} = useAppSelector(state => state.newReturn)
    const dispatch = useAppDispatch()

    const handleDeleteRow = () => {
        dispatch(setLineToReturn(linesToReturn.filter(el => el.id !== lineData.id)))
    }

    return (
        <tr>
            <td scope="text-start">{lineData.lineData.order_name}</td>
            <td scope="text-start">{lineData.lineData.product_code}</td>
            <td scope="text-start"></td>
            <td scope="text-start">{lineData.lineData.product_name}</td>
            <td scope="text-start"></td>
            <td scope="text-start"></td>
            <td scope="text-start">{lineData.quantity}</td>
            <td scope="text-start">{lineData.lineData.price_unit} {lineData?.lineData?.currency}</td>
            <td scope="text-start">{lineData.lineData.price_subtotal} {lineData?.lineData?.currency}</td>
            <td scope="text-start">{PRODUCT_CONDITION[lineData.condition]}</td>
            <td scope="text-start">{lineData.description}</td>
            <td scope="text-start">{lineData?.filesData?.length}</td>
            <td scope="text-start"></td>
            <th scope="col" className="">
                <button type="button" className="btn btn-sm" onClick={handleDeleteRow}>
                    <i className="bi bi-trash"/>
                </button>
            </th>
        </tr>
    )

}

export const ReturnTable = () => {

    const {linesToReturn} = useAppSelector(state => state.newReturn)



    return (
        <>
                <>
                    <table className="table returnTable">
                        <thead className="returnTable">
                        <tr>
                            <th scope="col" className="text-secondary text-start returnTable__number">Номер заказа</th>
                            <th scope="col" className="text-secondary text-start returnTable__code">Код</th>
                            <th scope="col" className="text-secondary text-start returnTable__man_code">Код
                                производителя
                            </th>
                            <th scope="col" className="text-secondary text-start returnTable__name">Название продукта
                            </th>
                            <th scope="col" className="text-secondary text-start returnTable__delivery">Накладная</th>
                            <th scope="col" className="text-secondary text-start returnTable__bill_date">Дата счета</th>
                            <th scope="col" className="text-secondary text-start returnTable__qty">Количество</th>
                            <th scope="col" className="text-secondary text-start returnTable__price">Цена <br/>без НДС</th>
                            <th scope="col" className="text-secondary text-start returnTable__total">Сумма без<br/>HДC</th>
                            <th scope="col" className="text-secondary text-start returnTable__state">Состояние продукта
                                и комплектация
                            </th>
                            <th scope="col" className="text-secondary text-start returnTable__desc">Примечания</th>
                            <th scope="col" className="text-secondary text-start returnTable__desc"></th>
                            <th scope="col" className="text-secondary text-start returnTable__img">Фото</th>
                            <th></th>


                        </tr>
                        </thead>
                        {linesToReturn && (
                            linesToReturn.map((el) => {
                                return (
                                    <>
                                <tbody className="returnTable__body">
                                <ReturnTableRow
                                    lineData={el}
                                />
                                </tbody>
                            </>
                                )
                            }))}

                    </table>
                </>

        </>
    )
}