import React, {useState, useEffect, useRef, useContext} from "react";
import {TabItem} from "../../components/tabs/TabItem";
import {TabList} from "../../components/tabs/TabList";
import {NotificationAlert} from "../../components/notificationAlert/NotificationAlert";
import {ControlButton} from "../../components/controlButton/controlButton";
import {TotalCartBill} from "../../components/totalCartBill/TotalCartBill";
import {CartTable} from "../../components/cartTable/CartTable";
import {
    createBackendSaleOrder,
    deleteCartLinesInUserActiveCart,
} from "../../api/productCart";
import {NavbarContext} from "../../hooks/UseNavbarContext";
import {Loader} from "../../components/loader/Loader";

import "./CartPage.sass"
import {Link, useNavigate} from "react-router-dom";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {MESSAGE_VARIANT, ROUTE_URLS} from "../../constants/consts";
import {MessageContext} from "../../hooks/UseMessage";
import {updateOrderLines} from "../../api/orders";
import {useTranslation} from "react-i18next";

interface TableRef {
    getActiveProductLines: () => any;
    setIsAllCheckBoxChecked: (newState: any) => void;
    setCheckedItems: (newState: any) => void;
}


export const CartPage = () => {

    const {isCartLoading, activeCartData} = useAppSelector(state => state.activeCart)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {setNavbarBadgeTrigger, navbarBadgeTrigger} = useContext(NavbarContext);
    const [productCount, setProductCount] = useState(0);
    const [totalCartBillInfo, setTotalCartBillInfo] = useState<any>({});
    const [productLines, setProductLines] = useState<any>([]);
    const {showMessage} = useContext(MessageContext)

    const {t} = useTranslation(); // Инициализация перевода


    const TableRef = useRef<TableRef>(null);

    const navigate = useNavigate();

    const deleteCartLinesChecked = async () => {
        const data = TableRef.current?.getActiveProductLines();
        await deleteCartLines(data)
        TableRef.current?.setCheckedItems([])
        TableRef.current?.setIsAllCheckBoxChecked(false)
        setProductCount(0)

    }


    const getOrderLinesData = (data: string | any[]) => {
        if (!activeCartData) return
        const linesForOrder = activeCartData.cart_lines.filter((el: { id: string; }) => data.includes(el.id))
        return linesForOrder.map((el: { product_id: any; price: any; quantity: any; }) => {
            return ({
                    "product_id": el.product_id,
                    "price_unit": el.price,
                    "product_uom_qty": el.quantity,
                }
            )
        })
    }

    const createOrder = async (data: string | any[], isReserve?: boolean, isActive: boolean = true, isPreOrder: boolean = false) => {
        if (!activeCartData) return
        let orderData = {
            "is_reserve": isReserve,
            "is_pre_order": isPreOrder,
            "order_line": getOrderLinesData(data),
            "is_active": isActive
        }
        let result = await createBackendSaleOrder(orderData)
        if (result.error) return
        return result?.data?.data?.id
    }


    const handleAddToReservation = async (reserveId: number) => {
        const data = TableRef.current?.getActiveProductLines();
        if (!reserveId || !data) {
            showMessage({
                variant: MESSAGE_VARIANT.WARNING,
                text: t("reserve_invalid_number")
            });
            setIsLoading(false);
            return;
        }
        const line_data = getOrderLinesData(data);
        if (!line_data) {
            showMessage({
                variant: MESSAGE_VARIANT.WARNING,
                text: t("reserve_update_failed")
            });
            setIsLoading(false);
            return;
        }
        TableRef.current?.setCheckedItems([]);
        const orderData = {
            id_order: reserveId,
            line_data: line_data.map(el => ({
                product_id: el.product_id,
                quantity: el.product_uom_qty
            }))
        };
        let result = await updateOrderLines(orderData);
        if (result?.error) {
            setIsLoading(false);
            return;
        }
        await deleteCartLines(data);
        setIsLoading(false);
        showMessage({
            variant: MESSAGE_VARIANT.SUCCESS,
            text: t("reserve_success")
        });
    };

    const handleCreateOrder = async (isReserve: boolean = false, isPreOrder: boolean = false) => {
        setIsLoading(true)
        const data = TableRef.current?.getActiveProductLines();
        if (!data || data.length === 0) {
            setIsLoading(false)
            return
        }
        TableRef.current?.setCheckedItems([])
        setProductCount(0)
        const orderId = await createOrder(data, isReserve, false, isPreOrder)
        if (orderId) await deleteCartLines(data)
        setIsLoading(false)
        if (orderId) navigate((isReserve ? ROUTE_URLS.RESERVES : ROUTE_URLS.ORDERS) + `/${orderId}`)
    }

    const deleteCartLines = async (linesIds: Array<number>) => {
        let deleteParams = {"ids": linesIds}
        let result = await deleteCartLinesInUserActiveCart(deleteParams)
        if (activeCartData?.cart_lines.length === 1) {
            TableRef.current?.setIsAllCheckBoxChecked(false)
            setProductCount(0)
        }
        if (!result.error) setNavbarBadgeTrigger(!navbarBadgeTrigger)

    }


    return (
        <>
            {(isCartLoading || isLoading) ? (
                <Loader/>
            ) : (
                <div className="tradeCartContainer">
                    <TabList activeTabIndex={0}>
                        <TabItem label={t("my_cart")}>
                            {(activeCartData && activeCartData?.cart_lines.length === 0) ? (
                                <>
                                    <div className="empty-cart">
                                        <h2>{t("empty_cart_header")}</h2>
                                        <p>{t("empty_cart_text")}</p>
                                        <Link className="head-link" to="/">{t("go_to_home")}</Link>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="tradeCartControlPanel">
                                        <NotificationAlert/>
                                        <div className="tradeCartControlPanel__buttons">
                                            <ControlButton
                                                className="btn btn-sm border favIcon text-colored"
                                                icon="bi bi-download"
                                                label={` ${t("import_xlsx")}`}
                                                controlButtonHandler={() => {
                                                }}
                                            />
                                            <ControlButton
                                                className="btn btn-sm border favIcon text-colored"
                                                icon="bi bi-upload"
                                                label={` ${t("export_xlsx")}`}
                                                controlButtonHandler={() => {
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="tradeCartFilter">
                                        <span>{t("filter_by")}</span>
                                        <select
                                            role="button"
                                            defaultValue="checked"
                                            className="sort-select"
                                            aria-label="Filter by"
                                        >
                                            <option value="checked">{t("select")}</option>
                                        </select>
                                    </div>
                                    <div className="tradeCartTable">
                                        <CartTable
                                            ref={TableRef}
                                            clearCartLines={deleteCartLines}
                                            changeProductCountCart={setProductCount}
                                            cartProductLines={productLines}
                                            setTotalCartBillInfo={setTotalCartBillInfo}
                                        />
                                    </div>
                                    <div className="tradeCartTotal">
                                        <div>
                                            <p className="tradeCartProductCount">
                                                {t("products_selected", {count: productCount})}
                                            </p>
                                            <div className="tradeCartTotal__buttons">
                                                <ControlButton
                                                    className="btn favIcon text-colored"
                                                    icon="bi bi-floppy2-fill"
                                                    label={`${t("save")}`}
                                                    controlButtonHandler={() => {
                                                    }}
                                                />
                                                <ControlButton
                                                    className="btn border favIcon text-danger border-danger"
                                                    icon="bi bi-trash"
                                                    label={`${t("delete")}`}
                                                    controlButtonHandler={deleteCartLinesChecked}
                                                />
                                            </div>
                                        </div>
                                        <div className="tradeCartTotal__bill">
                                            <TotalCartBill
                                                tableRef={TableRef}
                                                handleCreateOrder={handleCreateOrder}
                                                handleAddToReservation={handleAddToReservation}
                                                setIsLoading={setIsLoading}
                                                cartBillData={totalCartBillInfo}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </TabItem>
                        <TabItem label={t("saved_carts")}>
                            <p>{t("saved_carts_content")}</p>
                        </TabItem>
                        <TabItem label={t("user_carts")}>
                            <p>{t("user_carts_content")}</p>
                        </TabItem>
                    </TabList>
                </div>
            )}
        </>
    )
}