import "./CatalogContent.sass";
import * as React from "react";
import {CatalogTable} from "./catalogTable/CatalogTable"
import {CatalogGrid} from "./catalogGrid/CatalogGrid";
import {SwitchContent} from "../switchContent";
import {ProductPreviewModal} from "./productPreviewModal/ProductPreviewModal";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {DISPLAY_TYPE} from "../../constants/consts";


export const CatalogContent = () => {

    const { displayType } = useAppSelector(state => state.page)

    return (
        <>
            <SwitchContent value={displayType}>
                    <CatalogTable data-value={DISPLAY_TYPE.TABLE}/>
                    <CatalogGrid data-value={DISPLAY_TYPE.GRID}/>
            </SwitchContent>
            <ProductPreviewModal/>
        </>
    )

}
