import styled from "styled-components";


export const CatalogFilterWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;

export const CatalogPage = styled.div`
    height: 100%;
`;

export const CatalogItemsCount = styled.span`
    margin: auto;
    vertical-align: middle;
    text-wrap: nowrap;
    align-items: end;
`;

export const SubcategoriesContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    gap: 32px 24px;
    margin-bottom: 30px;
`;

export const Subcategory = styled.div`
    background-color: #f7f7f7;
    border: 1px solid #d5d0d0;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    max-width: 250px;
    flex: 1;
    cursor: pointer;
    max-height: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        color: #00a1de;
    }
`;

export const SubcategoryImage = styled.img`
    width: 150px;
`;

export const SubcategoryTitle = styled.h6`
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-bottom: 15px;
;`

export const SubcategoryCount = styled.span`
    font-weight: bold;
    color: #0cbbfc;
    display: flex;
    align-items: baseline;
;`

export const SubcategoryCountNumber = styled.span`
    font-size: 14px;
`
;
export const SubcategoryCountText = styled.span`
    font-size: 10px;
    margin-left: 5px;
;`