import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchSingleOrderRequests} from "../../api/orders";
import {SaleOrderType} from "../../components/catalog/types";

export type initialStateType = {
    isOrderLoading: boolean;
    orderData: SaleOrderType | undefined

}
const initialState: initialStateType = {
    orderData: undefined,
    isOrderLoading: true
}

export const fetchSingleOrder =
    createAsyncThunk("slice/fetchSingleOrder",
        async (saleId: number) => {
            const result = await fetchSingleOrderRequests(saleId)
            return result.data?.data
        })
export const resetOrder =
    createAsyncThunk("slice/resetSingleOrder",
        async () => initialState
    )

const orderSliceReducer = createSlice({
    name: "orders",
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchSingleOrder.fulfilled, (state, action) => {
            state.orderData = action.payload
            state.isOrderLoading = false
        }).addCase(resetOrder.fulfilled, (state, action) => {
            Object.assign(state, initialState);
            state.isOrderLoading = false;
        })
    }
})

export default orderSliceReducer.reducer