import {resolve} from "./resolve";
import axios from "axios";
import {API_BACKEND_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";
import {getExchangeHeaders} from "./helpers";

export async function getUserPriceList(){
    return await resolve(axios.post(`${API_BACKEND_URL}/pricelist/product/generate_pricelist_xlsx`,
        {
            filename: "Pricelist"
        }, {
            headers: getExchangeHeaders(),
        }
    ).then(res => res));
}