import axios from "axios";
import { resolve } from "./resolve.js";
import {API_BACKEND_URL} from "../constants/consts";
import {getExchangeHeaders} from "./helpers";


export async function fetchProductRequests(productID) {
    return await resolve(
        axios.get(`${API_BACKEND_URL}/products/${productID}`,
        {
            headers: getExchangeHeaders(),
        }
    ).then(res => res?.data));
}
