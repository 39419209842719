import { Routes, Route} from "react-router-dom";
import {HomePage} from "./pages/homePage/HomePage";
import {SignInPage} from "./pages/signInPage/SignInPage";
import {SignUpPage} from "./pages/signUpPage/SignUpPage";
import {ProductCatalogPage} from "./pages/productCatalogPage/productCatalogPage";
import {SearchForm} from "./components/search/SearchForm";
import {CartPage} from "./pages/cartPage/CartPage";
import {Container} from "./components/container/Container";
import { tailChase } from 'ldrs'
import "./assets/common.sass"
import {ProductPage} from "./pages/productPage/ProductPage";
import {PrivateRoute} from "./components/privateRoute/PrivateRoute";
import {ROUTE_URLS} from "./constants/consts";
import {CreditLine} from "./components/creditLine/creditLine/CreditLine";
import {OrdersPage} from "./pages/ordersPage/OrdersPage";
import {OrderPage} from "./pages/orderPage/OrderPage";
import {QuestionaryPage} from "./pages/questionaryPage/QuestionaryPage";
import {FinTermsPage} from "./pages/finTermsPage/FinTermsPage";
import {ProductReturnsPage} from "./pages/productReturnsPage/ProductReturnsPage";
import {CreditLineHistory} from "./components/creditLine/creditLineHistory/CreditLineHistory";
import {TermService} from "./pages/termService/TermService";
import {ContactInformation} from "./pages/contactInformation/ContactInformation";
import {ReturnPage} from "./pages/returnPage/ReturnPage";
import {NewCreditLine} from "./components/creditLine/creditLine/NewCreditLine";
import {WarrantyRequest} from "./pages/warrantyRequest/WarrantyRequest";
import {TwoFactorAuthPage} from "./pages/twoFactorAuthPage/TwoFactorAuthPage";
import {NotFoundPage} from "./pages/notFoundPage/NotFoundPage";
import {useAppSelector} from "./hooks/ReduxHooks";
import usePaginationScroll from "./hooks/UsePaginationScroll";
import {WarrantyRequestPage} from "./pages/warrantyRequestPage/WarrantyRequestPage";
tailChase.register()

function App() {

    const { page } = useAppSelector(state => state.page);
    usePaginationScroll(page)

    return (
            <Container>
                <Routes>
                    <Route path={ROUTE_URLS.SIGN_IN} element={<SignInPage/>}/>
                    <Route path={ROUTE_URLS.SIGN_UP} element={<SignUpPage/>}/>
                    <Route path={ROUTE_URLS.TWO_FACTOR_AUTH} element={<TwoFactorAuthPage/>} />
                    <Route path={ROUTE_URLS.HOME} element={<PrivateRoute/>}>
                        <Route path="" element={<HomePage/>} />
                    </Route>
                    <Route path={ROUTE_URLS.CART} element={<PrivateRoute/>}>
                        <Route path="" element={<CartPage/>} />
                    </Route>
                    <Route path={ROUTE_URLS.CATALOG_CATEGORY} element={<PrivateRoute/>}>
                        <Route path="" element={<ProductCatalogPage/>} />
                    </Route>
                    <Route path={ROUTE_URLS.CATALOG} element={<PrivateRoute/>}>
                        <Route path="" element={<ProductCatalogPage/>} />
                    </Route>

                    <Route path={ROUTE_URLS.PRODUCT_FROM_CATEGORY} element={<PrivateRoute/>}>
                        <Route path="" element={<ProductPage/>} />
                    </Route>
                    <Route path={ROUTE_URLS.PRODUCT} element={<PrivateRoute/>}>
                        <Route path="" element={<ProductPage/>} />
                    </Route>
                    <Route path={ROUTE_URLS.ORDERS} element={<PrivateRoute/>}>
                        <Route path="" element={<OrdersPage/>} />
                    </Route>
                    <Route path={ROUTE_URLS.ORDER} element={<PrivateRoute/>}>
                        <Route path="" element={<OrderPage/>} />
                    </Route>
                    <Route path={ROUTE_URLS.RESERVES} element={<PrivateRoute/>}>
                        <Route path="" element={<OrdersPage/>} />
                    </Route>
                    <Route path={ROUTE_URLS.RESERVE} element={<PrivateRoute/>}>
                        <Route path="" element={<OrderPage/>} />
                    </Route>
                    <Route path={ROUTE_URLS.RETURNS} element={<PrivateRoute/>}>
                        <Route path="" element={<ProductReturnsPage/>} />
                    </Route>
                    <Route path={ROUTE_URLS.RETURN} element={<PrivateRoute/>}>
                        <Route path="" element={<ReturnPage/>} />
                    </Route>
                    <Route path={ROUTE_URLS.FIN_TERMS} element={<PrivateRoute/>}>
                        <Route path="" element={<FinTermsPage/>} />
                    </Route>
                    <Route path={ROUTE_URLS.NEW_CREDIT_LIMIT} element={<PrivateRoute/>}>
                        <Route path="" element={<NewCreditLine/>} />
                    </Route>
                    <Route path={ROUTE_URLS.CREDIT_LIMIT} element={<PrivateRoute/>}>
                        <Route path="" element={<CreditLine/>} />
                    </Route>
                    <Route path={ROUTE_URLS.CREDIT_LIMIT_HISTORY} element={<PrivateRoute/>}>
                        <Route path="" element={<CreditLineHistory/>} />
                    </Route>
                    <Route path="/search" element={<PrivateRoute/>}>
                        <Route path="" element={<SearchForm/>} />
                    </Route>

                    <Route path={ROUTE_URLS.QUESTIONARY}>
                        <Route path="" element={<QuestionaryPage/>} />
                    </Route>
                    <Route path={ROUTE_URLS.TERM_SERVICE} element={<PrivateRoute/>}>
                        <Route path="" element={<TermService/>} />
                    </Route>
                    <Route path={ROUTE_URLS.CONTACT_INFORMATION} element={<PrivateRoute/>}>
                        <Route path="" element={<ContactInformation/>} />
                    </Route>


                    <Route path={ROUTE_URLS.WARRANTIES}>
                        <Route path="" element={<WarrantyRequest/>} />
                    </Route>
                    <Route path={ROUTE_URLS.WARRANTY} element={<PrivateRoute/>}>
                        <Route path="" element={<WarrantyRequestPage/>} />
                    </Route>
                    <Route path="*" element={<NotFoundPage/>} />


                </Routes>
            </Container>
    );
}

export default App;
