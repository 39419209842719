import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchNewWarrantyLines} from "../../api/warranty";
import {SaleOrderLineType} from "../../components/catalog/types";
import {warrantyDefectType} from "../../hooks/UseWarrantyDefectTypes";

export enum warrantyFilterEnum {
    PRODUCT_NAME = "product_name",
    CODE = "default_code",
    WAYBILL_NUMBER = "waybill_number",
    EAN = "barcode",
    DATE_FROM = "date_from",
    DATE_TO = "date_to",
}

export type CustomerDataType = {

}

export type getLinesData = {
    id: any,
    order_name: any,
    waybill_number: any,
    waybill_date: any,
    default_code: any,
    barcode: any,
    product_name: any,
    guarantee: any,
    warranty_service: any,
    quantity: any
}

export type linesToWarrantyType = {
    id: number,
    lineId: number,
    lineData: SaleOrderLineType,
    filesData: File[],
    quantity: number,
    description?: string,
    condition?: string | undefined
    defectType?: warrantyDefectType
}

export type initialStateType = {
    warrantyLinesData: getLinesData[] | undefined
    isWarrantyLinesLoading: boolean
    activeFilter: {[key in warrantyFilterEnum]: string;} | undefined
    linesToWarranty: linesToWarrantyType[]
    customerData: CustomerDataType | undefined

}

const initialState: initialStateType = {
    warrantyLinesData: undefined,
    isWarrantyLinesLoading: false,
    activeFilter: {
        product_name:"",
        default_code: "",
        barcode:"",
        waybill_number:"",
        date_from: "",
        date_to: ""
    },
    linesToWarranty: [],
    customerData: undefined
}

export const fetchOrderLines =
    createAsyncThunk("slice/fetchWarrantyLines",
        async (filterData: any) => {
            const result = await fetchNewWarrantyLines(filterData)
            return result.data?.data
        })

const newWarrantySliceReducer = createSlice({
    name: "newWarranty",
    initialState,
    reducers: {
        setActiveFilter: (state, action) => {
            state.activeFilter = action.payload
        },
        setLineToWarranty: (state, action) => {
            state.linesToWarranty = action.payload
        },
        resetWarrantyData: (state) => {
            Object.assign(state, initialState)
            state.isWarrantyLinesLoading = false
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchOrderLines.pending, (state, action) => {
            state.isWarrantyLinesLoading = true
        }).addCase(fetchOrderLines.fulfilled, (state, action) => {
            state.warrantyLinesData = action.payload?.lines_data || []
            state.isWarrantyLinesLoading = false
        })
    }
})

export const {
    setActiveFilter,
    setLineToWarranty,
    resetWarrantyData,
} = newWarrantySliceReducer.actions;

export default newWarrantySliceReducer.reducer