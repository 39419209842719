import {forwardRef, useImperativeHandle, useState} from "react"
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {useAppSelector} from "../../hooks/ReduxHooks";


export const NavCartButton = forwardRef((props,ref) =>{

    const {activeCartData } = useAppSelector(state => state.activeCart)


    return (
        <IconButton aria-label="cart" className="">
            <Badge badgeContent={activeCartData && activeCartData.cart_lines.length}>
                <ShoppingCartIcon />
            </Badge>
        </IconButton>
    )
})

export default NavCartButton