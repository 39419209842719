import styled from "styled-components";
import { motion } from "framer-motion";
import {MAX_ROWS, systemBlack, systemGreyLight, systemWhite} from "../../constants";

export const ITEM_WIDTH = 252;
export const MOBILE_ITEM_WIDTH = 140;
export const ITEM_HEIGHT = 220;

export const CategoriesSectionContent = styled(motion.section)`
    padding: 50px 40px;
    background-color: #F7F9FC;

    @media (max-width: 768px) {
        padding: 30px 16px;
        background-color: ${systemWhite};
    }
`;

export const CategoriesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${ITEM_WIDTH}px, 1fr));
    max-height: calc(${ITEM_HEIGHT}px * ${MAX_ROWS});
    overflow: hidden;
    gap: 0;
    margin-top: 50px;

    @media (max-width: 768px) {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
`;

export const CategoryItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font: 400 14px/20px "Roboto", sans-serif;
    background-color: ${systemWhite};
    color: ${systemBlack};
    border: 1px solid ${systemGreyLight};
    margin: 0;
    min-height: ${ITEM_HEIGHT}px;
`;

export const CategoryImage = styled.img``;

export const CategoryTitle = styled.span`
    font: 400 14px/20px "Roboto", sans-serif;
    color: ${systemBlack};
`;

export const Chip = styled.div`
    background-color: #F7F9FC;
    padding: 12px 16px;
    border-radius: 99px;
    font: 400 12px/20px "Roboto", sans-serif;
    color: ${systemBlack};
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const HiddenCategoriesChip = styled.div`
    background-color: ${systemWhite};
    padding: 12px 16px;
    border-radius: 99px;
    font: 400 12px/20px "Roboto", sans-serif;
    color: ${systemBlack};
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #BDBDBD
`;
