import React, {FC, useEffect, useState} from "react";
import {MainSidebar} from "../sidebar/SideBar";
import {Navbar} from "../navbar/Navbar";
import {useLocation} from "react-router-dom";
import {NavbarContext} from "../../hooks/UseNavbarContext";
import {HIDE_ROUTES, ROUTE_URLS, USER_ROLES} from "../../constants/consts";
import {getSession, getSessionUserRole} from "../../api/sessionManagement";
import useMessage, { MessageContext } from "../../hooks/UseMessage";
import Message from "../message/Message";


interface ContainerProps {
    children:  JSX.Element
}

export const Container: FC<ContainerProps>  = ({children}) => {

    const { pathname } = useLocation();
    const [navbarBadgeTrigger, setNavbarBadgeTrigger] = useState(false);
    const [shouldRenderMainComponents, setShouldRenderMainComponents] = useState<any>(false);
    const {message, showMessage, hideMessage} = useMessage()

    const shouldShowGeneralComponents = () => {
        return !HIDE_ROUTES.includes(pathname as ROUTE_URLS) && getSession();
    };

    const isShowMainElements = () =>{
        let checkHideRoutes = HIDE_ROUTES.includes(pathname as ROUTE_URLS);
        if (checkHideRoutes) {
            return !checkHideRoutes
        }
        else {
            let conditionForQuestionary = ["/questionary"].includes(pathname)
            if (conditionForQuestionary) {
                return getSessionUserRole() === 'active'
            }
            return true
        }
    }

    const isHomePage = () => {
        return ["/"].includes(pathname);
    };

    useEffect(() => {
        if (getSession()){
            const checkHideRoutes = HIDE_ROUTES.includes(pathname as ROUTE_URLS);
            if (checkHideRoutes) {
                setShouldRenderMainComponents(!checkHideRoutes)
            }
            else {
                let conditionForQuestionary = ["/questionary"].includes(pathname)
                if (conditionForQuestionary) {
                    setShouldRenderMainComponents(getSessionUserRole() === 'active')
                    return
                }
                let conditionForTermServices = ["/terms-service"].includes(pathname)
                if (conditionForTermServices) {
                    setShouldRenderMainComponents(getSessionUserRole() === 'active')
                    return
                }
                setShouldRenderMainComponents(true)
            }
        }

    }, [pathname]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
        });
    }, [pathname]);



    return (

        <div className="_container">
            <div className={`${!shouldShowGeneralComponents() ? "" : isHomePage() ? "home_page_container trade_container" : "trade_container"}`}>
                { shouldRenderMainComponents && <MainSidebar/> }
                <section
                    className={`${!shouldShowGeneralComponents() ? "" : isHomePage() ? "home_page trade_section" : "trade_section"}`}>
                    <MessageContext.Provider value={{message, showMessage, hideMessage}}>
                        <NavbarContext.Provider value={{navbarBadgeTrigger, setNavbarBadgeTrigger}}>
                            <>
                            </>
                            {shouldRenderMainComponents && <Navbar/>}
                            {children}
                            {message &&
                                <Message children={message.text} hideMessage={hideMessage} variant={message.variant}/>}

                        </NavbarContext.Provider>
                    </MessageContext.Provider>
                </section>
            </div>
        </div>
    )
}