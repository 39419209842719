import React from "react";
import {useAppSelector} from "../../../../hooks/ReduxHooks";
import {Trans} from "react-i18next";

export const WarrantyTotal = () => {
    const {warrantyRequestData} = useAppSelector(state => state.warrantyRequest)

    if (!warrantyRequestData) return <></>

    return (
        <div id="total">
            <div className="col-xs-7 col-md-5 ms-auto">
                <table className="table table-sm">
                    <tbody>
                    <tr className="border-black">
                        <td className="text-colored">
                            <strong><Trans i18nKey="total_sum"/></strong></td>
                        <td className="text-end">
                            <span>{warrantyRequestData.lines.length && warrantyRequestData.lines.reduce((total, {amount_total}) => total + amount_total, 0)}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}