import {Button, Divider, IconButton} from '@mui/material';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    Close as CloseIcon,
    Image as ImageIcon,
    InsertDriveFile as FileIcon,
    PictureAsPdf as PdfIcon
} from "@mui/icons-material";
import React from "react";

interface UploadProps {
    files: File[]
    setFiles: (files: File[]) => void
}

const getFileIcon = (file:any) => {
        const fileType = file.type.split('/')[0];
        if (fileType === 'image') {
            return <ImageIcon style={{ color: '#6ed1f3', marginRight: '10px' }} />;
        } else if (file.type.includes('pdf')) {
            return <PdfIcon style={{ color: '#6ed1f3', marginRight: '10px' }} />;
        } else {
            return <FileIcon style={{ color: '#6ed1f3', marginRight: '10px' }} />;
        }
    };

const handleFileClick = (file:any) => {
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
    };

export const UploadFile = (props: UploadProps) => {
    const {files, setFiles} = props


    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const newFiles = Array.from(event.target.files ?? [])
            setFiles([...files, ...newFiles])
        }
    }

    const handleFileDelete = (event: React.ChangeEvent<HTMLInputElement | EventTarget>, file: File) => {
        event.preventDefault()
        event.stopPropagation()
        if (!file) return
        setFiles(files.filter(el => el.name !== file.name))
    }

    return (
        <>
            <Button
                component="label"
                variant="outlined"
                startIcon={<i className="bi bi-upload"/>}
                sx={{
                    marginRight: "1rem",
                    color: "rgb(110, 209, 243)",
                    borderColor: "rgb(110, 209, 243)",
                }}
            >
                Добавить
                <input type="file"
                       hidden
                       multiple
                       onChange={handleFileUpload}/>
            </Button>
            {files && files.length > 0 && (
            <section className={"upload_files"}>
                <h5 className="upload_files__title">Добавленые файлы:</h5>
              <ul className="w-100 d-flex flex-wrap justify-content-start">
                  {files.map((file, index) => (
                      <li className="file-item" key={index} onClick={() => handleFileClick(file)}>
                          <Box display="flex" alignItems="center" width="100%">
                              {getFileIcon(file)}
                              <Typography sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "100px",
                              }} variant="body2">{file.name}</Typography>
                          </Box>
                          <IconButton onClick={(event) => handleFileDelete(event, file)}>
                              <CloseIcon/>
                          </IconButton>
                          <Divider/>
                      </li>
                  ))}
              </ul>
            </section>
            )}
        </>
    )
}