import { ChangeEvent, useCallback, useState, FormEvent } from "react";
import { EMPTY_STRING } from "../../../../../constants/consts";

export interface FormData {
    company_name: string;
    phone: string;
    email: string;
    brands: string;
}

interface UseModalFormReturnValues {
    handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
    formData: FormData;
}

export const useModalForm = (): UseModalFormReturnValues => {
    const [formData, setFormData] = useState<FormData>({
        company_name: EMPTY_STRING,
        email: EMPTY_STRING,
        phone: EMPTY_STRING,
        brands: EMPTY_STRING,
    });

    const handleChange = useCallback((
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    }, []);

    const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log('Form submitted:', formData);
    }, [formData]);

    return { formData, handleChange, handleSubmit };
}
