import {createContext, useState} from "react";
import {MESSAGE_VARIANT} from "../constants/consts";

export type MessageType = {
  variant: MESSAGE_VARIANT,
  text: string,
}

const useMessage = () => {
  const [message, setMessage] = useState<MessageType | undefined>(undefined);

  const showMessage = (newMessage: MessageType) => {
    setMessage(newMessage);
  };

  const hideMessage = () => {
    setMessage(undefined);
  };

  return { message, showMessage, hideMessage };
};

interface MessageContextType {
    message: MessageType | undefined;
    showMessage: (message: MessageType) => void;
    hideMessage: () => void;
}

const MessageContextValue: MessageContextType = {
    message: undefined,
    showMessage: () => {},
    hideMessage: () => {},
};

export const MessageContext = createContext(MessageContextValue)


export default useMessage;