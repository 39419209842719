import React, {useEffect} from "react";
import {AnimatePresence} from "framer-motion";
import {
    Container,
    MenuButton,
    Image,
    MenuOverlay,
    CloseButton,
    NavLinks,
    NavItem,
    LangSwitcher,
    Lang,
    Icon,
    ButtonsContainer
} from "./styles";
import Logo from "../../../../../images/rde_standart.png";
import {useMobileHeader} from "./hooks/useMobileHeader.hook";
import {HeaderButtons} from "../components/headerButtons";
import {t} from "i18next";
import {HeaderText} from "../styles";

interface MobileHeaderProps {
    showButtons: boolean;
}

export const MobileHeader = ({ showButtons }: MobileHeaderProps) => {
    const {
        handleModalVisibility,
        languages,
        currentLanguage,
        handleChangeLanguage,
        iconClassName,
        isOpen
    } = useMobileHeader();
    const body = document.body;

    useEffect(() => {
        if (isOpen) {
            body.style.overflow = "hidden";
        } else {
            body.style.overflow = "initial";
        }

        return () => {
            body.style.overflow = "initial";
        }
    }, [body, isOpen]);


    return (
        <Container $showButtons={showButtons}>
            {showButtons ? <HeaderButtons showButtons={showButtons}/> : (
                <>
                    <Image alt="logo" src={Logo}/>
                    <ButtonsContainer>
                        <HeaderText>{t("authenticate")}</HeaderText>
                        <MenuButton onClick={handleModalVisibility}>
                            <Icon className={iconClassName}/>
                        </MenuButton>
                    </ButtonsContainer>
                </>
            )}
            <AnimatePresence>
                {isOpen && (
                    <MenuOverlay
                        initial={{x: "100%"}}
                        animate={{x: 0}}
                        exit={{x: "100%"}}
                        transition={{duration: 0.3}}
                    >
                        <CloseButton onClick={handleModalVisibility}>
                            <Icon className="bi bi-x"/>
                        </CloseButton>
                        <NavLinks>
                            <NavItem>{t("section_secondary_button")}</NavItem>
                            <NavItem>{t("section_primary_button")}</NavItem>
                            <NavItem>{t("authenticate")}</NavItem>
                        </NavLinks>
                        <LangSwitcher>
                            {languages.map((language) => (
                                <Lang $isActive={currentLanguage.value === language.value}
                                      onClick={handleChangeLanguage(language.value)}>{language.label}</Lang>
                            ))}
                        </LangSwitcher>
                    </MenuOverlay>
                )}
            </AnimatePresence>
        </Container>
    );
};
