import {useParams} from "react-router-dom";
import {SwiperProduct} from "../swiperProduct/SwiperProduct";

import "./ProductCard.sass"
import {ProcudtBage} from "../catalog/catalogGrid/catalogCard/styles";
import * as React from "react";
import {ProductCardControl} from "../productCardControl/ProductCardControl";
import {useEffect, useRef, useState} from "react";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {useGetProductImages} from "../../hooks/useGetProductImages";
import {fetchGetActiveUserProductCart} from "../../api/productCart";
import {ProductTagType} from "../catalog/types";
import {ToOrder} from "../catalog/toOrder/ToOrder";

interface ProductCardProps {
}

interface ProductCardControlRef {
    setIsInCard: (newState: any) => void;
    setValue: (newState: any) => void;
}



export const ProductCard = (props: ProductCardProps) => {

    const { id } = useParams();
    const {product} = useAppSelector(state => state.product)
    const [isFixed, setIsFixed] = useState(false);
    const productCardControlRef = useRef<ProductCardControlRef>(null);

    const productImages = useGetProductImages()


    useEffect(() => {
        (async () => {
            let result = await fetchGetActiveUserProductCart();
            if (!result.error){
                let productInCart = result.data.data.cart_lines.filter((product:any)=>product.product_id === Number(id));
                productCardControlRef.current?.setIsInCard(!!productInCart.length);
                if (productInCart.length){
                    productCardControlRef.current?.setValue(productInCart[0].quantity)
                }

            }
        })();
        const handleScroll = () => {
                if (window.scrollY < 1) {
                    setIsFixed(false);
                } else {
                    setIsFixed(true);
                }
            };

            window.addEventListener("scroll", handleScroll);

            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
    }, []);



    const specification = [
        {"name": "NB features", "value": "Business/Lightweight"},
        {"name": "Product line", "value": "Commercial"},
        {"name": "NB Family name", "value": "Precision"},
        {"name": "Product model code", "value": "3490"},
        {"name": "CPU Family name", "value": "Core Ultra u7-155H"},
        {"name": "CPU Model Number", "value": "3800 MHz"},
        {"name": "Clock speed", "value": "14\""},
        {"name": "Screen size", "value": "1920x1080"},
        {"name": "Native resolution", "value": "60Hz"},
        {"name": "Screen refresh rate", "value": "16GB"},
        {"name": "Memory (RAM)", "value": "DDR5"},
        {"name": "Memory type", "value": "5600 MHz"},
        {"name": "Frequency speed", "value": "1ТВ"},
        {"name": "SSD Capacity", "value": "M.2 2230, Gen4 TLC PCle NVMe Class 3 none"},
        {"name": "SSD type", "value": "Discrete"},
        {"name": "ODD type", "value": "NVIDIA RTX 500 Ada"},
        {"name": "Graphics controller", "value": "4GB"},
        {"name": "VGA card", "value": "Gigabit"},
        {"name": "Graphics memory", "value": "ENG"},
        {"name": "Integrated LAN", "value": "Yes"}
    ]
    if (!product) return <></>
    return (

        <>
            <div className={"product__wrapper"}>
                <div className={"gallery__wrapper"}>
                    <SwiperProduct imageList={productImages}/>
                </div>
                <div className={"product__cart-control"}>
                    <div className={"product__labels"}>
                        {product.product_tag_ids.map((label_item: ProductTagType) => {
                            return <ProcudtBage className="badge" bageColor={label_item.color}>
                                {label_item.name}
                            </ProcudtBage>
                        })}
                    </div>
                    <h2 className={"product__name"}>{product.name}</h2>
                    <div className={"product__properties"}>
                        <span className={"prop prop__name"}>Код:</span><span
                        className={"prop prop__value"}>{product.default_code}</span>
                        <span className={"prop prop__name"}>Код производителя:</span><span
                        className={"prop prop__value"}>112233112233112233112233</span>

                    </div>
                        <div className={`product__cart_area ${isFixed ? "position-fixed": "position-static"}`}>
                            <h3>{product.product_price} {product.currency.symbol}</h3>
                            <div className={"btn__wrapper"}>
                                <ProductCardControl
                                    ref={productCardControlRef}
                                    isCanLock
                                    isMainPage
                                    isCanInput
                                    isExtended
                                    productData={{
                                        id: product.id,
                                        is_for_order: product.is_for_order,
                                        balance_no_more: product.balance_no_more,
                                        stock_quantity_b2b: product.stock_quantity_b2b,
                                        is_display_stock_balance_on_portal: product.is_display_stock_balance_on_portal,
                                        price: product.product_price,
                                        currencyId: product.currency.id,
                                    }}/>
                            </div>
                            {product.is_display_stock_balance_on_portal &&
                                <div className={"product__delivery_info"}>
                                    <div>Доступно
                                        {product.stock_quantity_b2b <= product.balance_no_more ? (
                                            <span> {product.stock_quantity_b2b}</span>
                                        ): (
                                            <span> более {product.balance_no_more}</span>
                                        )}
                                    </div>
                                </div>
                            }
                            <ToOrder productData={product}/>
                        </div>
                </div>
            </div>
            <div className="specification__wrapper">
                <h2>Характеристики</h2>
                <div className="specification__row">
                    {specification.map((el,index) => {
                        return <div key={index} className="specification__row__item bold-text">
                            <span className="spec spec_name">{el.name}</span>
                            <span className="spec spae_value">{el.value}</span>
                        </div>
                    })}
                </div>
            </div>
            <div className="specification__info">
                <h4>Описание</h4>
                <div className="specification__description"><p>
                    Precision 3490 Workstation. Ultra-light and mobile, this 14-inch workstation is perfect for
                    professionals who need a compact device for productivity while multi-tasking across
                    applications. - 14" FHD 1920x1080, 60Hz, 250 nits, Non-Touch, FHD HDR RGB Camera, Mic,
                    WLAN - FHD HDR RGB Camera, TNR, Camera Shutter, Microphone
                </p></div>
                <div className="specification__description-small"><p>
                    The Specification and all other information on this web site are provided "as is" with no warranties of any kind, including without limitation the implied warranties of merchantability, fitness for a particular purpose, and non-infringement, and are subject to change without notice.
                    Company does not warrant that the site and the materials, products, communications, content and services will be reliable. uninterrupted, error-free, free of viruses and other harmful elements, or that defects will be corrected. Please be aware that the features/specifications can differ from country to country. Product images, including color, may differ from actual product appearance.
                </p></div>
            </div>
        </>
    )
}