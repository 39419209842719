import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import {fetchReturns, resetReturns} from "../../../store/slices/ReturnsReducerSlice";
import {Loader} from "../../loader/Loader";
import {ReturnsLineType} from "../../catalog/types";
import {BasePagination} from "../../pagination/Pagination";
import {updatePage} from "../../../store/slices/PagePaginagionSlice";
import "./ReturHistoryTable.sass"
import {RETURN_STATE, ROUTE_URLS} from "../../../constants/consts";
import {Link} from "react-router-dom";
import {createBackendReturn, updateBackendReturn} from "../../../api/returns";
import {t} from "i18next";


interface ReturnHistoryTableProps {
    isActive: boolean
}

export const ReturnHistoryTable = (props: ReturnHistoryTableProps) => {
    const {isActive} = props
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const {returnsData, isReturnsLoading} = useAppSelector(state => state.returns)
    const {page} = useAppSelector(state => state.page)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(updatePage(1))
    }, [isActive]);

    useEffect(() => {
        dispatch(fetchReturns({pageNum: page, isActive: isActive}))
    }, [isActive, page]);

    useEffect(() => {
        setIsLoading(isReturnsLoading)
    }, [isReturnsLoading]);

    useEffect(() => {
        return () => {
            dispatch(resetReturns())
        }
    }, []);

    const handleCancel = async (line: ReturnsLineType) => {
        const data = {
            "id": line.id,
            "return_state": RETURN_STATE.CANCEL
        }
        let result = await updateBackendReturn(data)
        if (result.error) return
        dispatch(fetchReturns({pageNum: page, isActive: isActive}))

    }

    return <>
        {isLoading ? (
            <Loader/>
        ) : (returnsData &&
            <>
                <div className="returnHistoryTable__wrapper">
                    <table className="table returnHistoryTable">
                        <thead className="returnHistoryTable">
                        <tr>
                            <th scope="col" className="text-colored text-start returnHistoryTable__number">
                                {t('request_number')}
                            </th>
                            <th scope="col" className=" text-colored text-start returnHistoryTable__date">
                                {t('application_date')}
                            </th>
                            <th scope="col" className=" text-colored text-start returnHistoryTable__created">
                                {t('created_by')}
                            </th>
                            <th scope="col" className=" text-colored text-start returnHistoryTable__contact">
                                {t('contact_person')}
                            </th>
                            <th scope="col" className=" text-colored text-start returnHistoryTable__phone">
                                {t('phone')}
                            </th>
                            <th scope="col" className=" text-colored text-start returnHistoryTable__address">
                                {t('delivery_address')}
                            </th>
                            <th scope="col" className=" text-colored text-start returnHistoryTable__state">
                                {t('status')}
                            </th>
                            {isActive && (
                                <th scope="col" className=" text-colored text-start returnHistoryTable__btns"></th>
                            )}
                        </tr>

                        </thead>
                        <tbody className="returnTable__body">
                        {
                            returnsData.lines_data.map((line) => (
                                <tr>
                                    <td scope="text-start">
                                        <Link
                                            className="returnHistoryTable__link"
                                            to={ROUTE_URLS.RETURNS + `/${line.id}`}
                                        >
                                            <p className="text-start fw-bold">{line.name}</p>
                                        </Link>
                                    </td>
                                    <td scope="text-start">{line.create_date.split(" ")[0]}</td>
                                    <td scope="text-start">{line.responsible_user}</td>
                                    <td scope="text-start">{line.contact_person}</td>
                                    <td scope="text-start"></td>
                                    <td scope="text-start"></td>
                                    <td scope="text-start">{line.return_state}</td>
                                    {isActive && (
                                        <td scope="text-start">
                                            <button type="button" className="btn btn-sm"
                                                    onClick={() => handleCancel(line)}>
                                                <i className="bi bi-trash"/>
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            ))
                        }

                        </tbody>
                    </table>
                    <div>
                        {returnsData.pages_count > 1 && <BasePagination count={returnsData.pages_count}/>}
                    </div>
                </div>

            </>
        )}

    </>
}