// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms__wrapper {
  padding: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1400px;
  width: 100%;
}
.terms__section {
  flex: 1 0 30%;
  max-width: 700px;
  min-width: 230px;
  width: 100%;
  margin-bottom: 40px;
}
.terms__option {
  display: flex;
  justify-content: space-between;
  color: #94A3B8;
}
.terms__name {
  font-size: 24px;
  line-height: 124%;
  font-weight: 700;
  color: #000;
  margin-bottom: 20px;
}
.terms__field {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.05em;
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/components/finTerms/FinTerms.sass"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,aAAA;EACA,8BAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;AAAJ;AAEE;EACE,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AAAJ;AAEE;EACE,aAAA;EACA,8BAAA;EACA,cAAA;AAAJ;AAEE;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AAAJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,WAAA;AAAJ","sourcesContent":[".terms\n  &__wrapper\n    padding: 50px\n    display: flex\n    justify-content: space-between\n    flex-wrap: wrap\n    max-width: 1400px\n    width: 100%\n\n  &__section\n    flex: 1 0 30%\n    max-width: 700px\n    min-width: 230px\n    width: 100%\n    margin-bottom: 40px\n\n  &__option\n    display: flex\n    justify-content: space-between\n    color: #94A3B8\n\n  &__name\n    font-size: 24px\n    line-height: 124%\n    font-weight: 700\n    color: #000\n    margin-bottom: 20px\n\n  &__field\n    font-size: 14px\n    font-weight: 400\n    line-height: 14px\n    letter-spacing: -0.05em\n    color: #000\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
