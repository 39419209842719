import React from "react";
import {useAppSelector} from "../../../../hooks/ReduxHooks";

export const ReturnTotal = () => {
    const {returnData} = useAppSelector(state => state.return)

    if (!returnData) return <></>

    return (
        <div id="total">
            <div className="col-xs-7 col-md-5 ms-auto">
                <table className="table table-sm">
                    <tbody>
                    <tr className="border-black">
                        <td className="text-colored">
                            <strong>Итого</strong></td>
                        <td className="text-end">
                            <span>{returnData.lines.length && returnData.lines.reduce((total, {amount_total}) => total + amount_total, 0)}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}