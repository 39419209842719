import styled from "styled-components";
import { motion } from "framer-motion";
import { systemBlack, systemWhite } from "../../constants";

export const AdditionalSectionContent = styled(motion.section)`
    background-color: ${systemWhite};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 40px;

    @media (max-width: 768px) {
        padding: 0;
    }
`;

export const AdditionalSectionContainer = styled.div`
    padding: 48px 10px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #D9D9D933;
    background-color: #FAFBFF;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 25px;
    }
`;

export const AdditionalSectionTitle = styled.span`
    font: 700 30px/42px "Roboto", sans-serif;
    color: ${systemBlack};

    @media (max-width: 768px) {
        font: 700 24px/28px "Roboto", sans-serif;
    }
`;
