import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Button,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Popper,
    TextField,
    Typography,
} from '@mui/material';
import {format} from 'date-fns';
import {ChatBubble, EmojiEmotions, Send} from '@mui/icons-material';
import AttachFile from '@mui/icons-material/AttachFile';
import AttachmentArea from './AttachmentArea';
import Attachment from './Attachment';
import {AnimatePresence, motion} from 'framer-motion';
import {getChatMessagesCount, getNewMessages, sendChatRequest} from "../../api/chat";
import {getUserEmail, getUserId} from "../../api/sessionManagement";
import {VISIBLE_MESSAGE_COUNT} from "../../constants/consts";
import {t} from "i18next";

interface Message {
    text: string;
    sender?: string;
    senderId?: number;
    id?: string;
    typeOfSend: string;
    message_datetime: string;
    attachments?: File[];
    attachmentUrls?: { file_name: string; url: string }[];
}

interface ChatBlockModel {
    model: string;
    recordId: number;
}

const ChatBlock: React.FC<ChatBlockModel> = ({model, recordId}) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
    const [isChatVisible, setIsChatVisible] = useState<boolean>(false);
    const [offset, setOffset] = useState<number>(0);
    const [messagesCount, setMessagesCount] = useState<number>(0);
    const [shouldScrollToBottom, setShouldScrollToBottom] = useState<boolean>(true);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const emojiPickerRef = useRef<HTMLButtonElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const emojiList = ['😀', '😂', '😍', '😎', '😊', '😢', '👍', '👏'];

    const handleSendMessage = async () => {
        if (inputValue.trim() === "") return;
        let userId = Number(getUserId());
        let userEmail = getUserEmail() || "";
        const formData = new FormData();
        selectedFiles.forEach((file, index) => {
            formData.append(`attachments`, file);
        });
        formData.append("message_text", inputValue);
        formData.append("sender", userEmail);
        formData.append("model", model);
        formData.append("record_id", recordId.toString());
        let result = await sendChatRequest(formData);
        const newMessage: Message = {
            text: inputValue,
            sender: userEmail,
            senderId: userId,
            message_datetime: result?.data?.data?.message_datetime,
            id: result?.data?.data?.message_id,
            typeOfSend: "send_to",
            attachmentUrls: result?.data?.data?.attachment_urls || [],
        };
        setMessages([...messages, newMessage]);
        setInputValue('');
        setSelectedFiles([]);
        setShouldScrollToBottom(true);
        setMessagesCount((prevCount) => prevCount + 1);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            setSelectedFiles((prevFiles) => [...prevFiles, ...Array.from(files)]);
        }
    };

    const handleEmojiSelect = (emoji: string) => {
        setInputValue(inputValue + emoji);
        setShowEmojiPicker(false);
    };

    const scrollToBottom = () => {
        if (shouldScrollToBottom && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const fetchNewMessages = async (currentOffset: number = 0, currentLimit: number = VISIBLE_MESSAGE_COUNT, isPrevious: boolean = false) => {
        let newMessages = await getNewMessages(currentOffset, currentLimit,{
            model,
            record_id: recordId,
        });
        if (newMessages?.data?.data?.length > 0) {
            setMessages((prevMessages) => {
                const existingMessagesSet = new Set(
                    prevMessages.map((msg) => msg.id)
                );
                const filteredMessages = newMessages.data.data.filter((newMsg: any) => {
                    const uniqueId = newMsg.id;
                    return !existingMessagesSet.has(uniqueId);
                });
                const newFormattedMessages = filteredMessages.map((msg: any) => ({
                    text: msg.message_text,
                    sender: msg.partner_name,
                    senderId: msg.partner_id || msg.portal_user_id,
                    id: msg.id,
                    message_datetime: msg.message_datetime,
                    typeOfSend: msg.type_of_send,
                    attachments: msg.attachments || [],
                    attachmentUrls: msg.attachment_urls || [],
                }));
                if (filteredMessages.length > 0) {
                    return isPrevious ? [...newFormattedMessages, ...prevMessages] : [...prevMessages, ...newFormattedMessages];
                } else {
                    return prevMessages;
                }
            });
            if (isChatVisible && !isPrevious) {
                setShouldScrollToBottom(true);
            }
        }
    };

    useEffect(() => {
        const fetchMessagesOnLoad = async () => {
            await fetchNewMessages(0);
        };
        const fetchChatMessagesCount = async () => {
            const result = await getChatMessagesCount({
                model: model,
                record_id: recordId,
            });
            setMessagesCount(result?.data?.data?.total_messages);
        };
        const initChat = async () => {
            await fetchMessagesOnLoad();
            await fetchChatMessagesCount();
        };
        initChat();
        const messagesIntervalId = setInterval(() => {
            fetchNewMessages(0);
        }, 10000);

        const chatCountIntervalId = setInterval(() => {
            fetchChatMessagesCount();
        }, 10000);

        return () => {
            clearInterval(messagesIntervalId);
            clearInterval(chatCountIntervalId);
        };
    }, [offset]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleLoadMoreMessages = async () => {
        const newOffset = offset + VISIBLE_MESSAGE_COUNT;
        setOffset(newOffset);
        await fetchNewMessages(newOffset, VISIBLE_MESSAGE_COUNT, true);
        setShouldScrollToBottom(false);
    };

    const handleShowAllMessages = async () => {
        const newOffset = 1000 + VISIBLE_MESSAGE_COUNT;
        setOffset(newOffset);
        await fetchNewMessages(0, 1000, true);
        setShouldScrollToBottom(false);
    };

    return (
        <Box sx={{position: 'relative', width: '100%'}}>
            <AnimatePresence>
                {isChatVisible && (
                    <motion.div
                        initial={{opacity: 0, height: 0}}
                        animate={{opacity: 1, height: '500px'}}
                        exit={{opacity: 0, height: 0}}
                        transition={{duration: 0.5, ease: 'easeInOut'}}
                        style={{overflow: 'hidden'}}
                    >
                        <Box
                            sx={{
                                margin: '0 auto',
                                padding: '20px',
                                borderRadius: '10px',
                                boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.15)',
                                bgcolor: 'background.paper',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    overflowY: 'auto',
                                    marginBottom: '20px',
                                    paddingRight: '10px',
                                    position: 'relative',
                                }}
                                ref={messagesContainerRef}
                            >
                                <List>
                                    {offset + VISIBLE_MESSAGE_COUNT < messagesCount && (
                                        <Box sx={{textAlign: 'center', marginBottom: '10px'}}>
                                            <Button onClick={handleLoadMoreMessages}>Загрузить ещё</Button>
                                            <Button onClick={handleShowAllMessages}>Показать все</Button>
                                        </Box>
                                    )}

                                    {messages.map((msg, index) => (
                                        <motion.div
                                            key={index}
                                            initial={{opacity: 0, x: msg.typeOfSend === 'send_to' && msg.senderId === Number(getUserId()) ? 50 : -50}}
                                            animate={{opacity: 1, x: 0}}
                                            transition={{duration: 0.3, ease: 'easeInOut'}}
                                        >
                                            <ListItem sx={{
                                                display: 'flex',
                                                justifyContent: msg.typeOfSend === 'send_to' && msg.senderId === Number(getUserId()) ? 'flex-end' : 'flex-start'
                                            }}>
                                                <Box
                                                    sx={{
                                                        maxWidth: '75%',
                                                        padding: '10px',
                                                        borderRadius: '10px',
                                                        bgcolor: msg.typeOfSend === 'send_to' && msg.senderId === Number(getUserId()) ? 'primary.main' : 'grey.300',
                                                        color: msg.typeOfSend === 'send_to' && msg.senderId === Number(getUserId()) ? 'white' : 'black',
                                                    }}
                                                >
                                                    <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                                                        {msg.typeOfSend === 'send_to' && msg.senderId === Number(getUserId()) ? 'You' : msg.sender}
                                                    </Typography>
                                                    <ListItemText primary={msg.text}/>
                                                    {msg.attachmentUrls && msg.attachmentUrls.length > 0 && (
                                                        <Box sx={{marginTop: '10px'}}>
                                                            {msg.attachmentUrls.map((attachment,  index) => (
                                                                <Attachment
                                                                    key={index}
                                                                    filename={attachment.file_name}
                                                                    url={attachment.url}
                                                                    isChatFile={msg.typeOfSend === 'send_to' && msg.senderId === Number(getUserId())}
                                                                />
                                                            ))}
                                                        </Box>
                                                    )}
                                                    <Typography variant="caption"
                                                                sx={{display: 'block', marginTop: '5px'}}>
                                                        {format(new Date(msg.message_datetime), 'dd.MM.yyyy HH:mm')}
                                                    </Typography>
                                                </Box>
                                            </ListItem>
                                        </motion.div>
                                    ))}
                                    <div ref={messagesEndRef}/>
                                </List>
                            </Box>

                            <Popper
                                open={showEmojiPicker}
                                anchorEl={emojiPickerRef.current}
                                placement="top"
                                sx={{zIndex: 1}}
                            >
                                <Grid container spacing={1} sx={{
                                    marginBottom: '10px',
                                    padding: '10px',
                                    bgcolor: 'background.paper',
                                    borderRadius: '10px',
                                    boxShadow: 3
                                }}>
                                    {emojiList.map((emoji, index) => (
                                        <Grid item key={index}>
                                            <IconButton onClick={() => handleEmojiSelect(emoji)}>
                                                <Typography sx={{fontSize: '1.5rem'}}>{emoji}</Typography>
                                            </IconButton>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Popper>

                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <input
                                    type="file"
                                    multiple
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    style={{display: 'none'}}
                                />
                                <IconButton
                                    onClick={() => fileInputRef.current?.click()}
                                    sx={{marginBottom: '10px'}}
                                >
                                    <AttachFile/>
                                </IconButton>
                                <TextField
                                    fullWidth
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSendMessage();
                                        }
                                    }}
                                    placeholder={`${t("type_message")}`}
                                    sx={{marginBottom: '10px'}}
                                />
                                <IconButton
                                    ref={emojiPickerRef}
                                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                    sx={{marginBottom: '10px'}}
                                >
                                    <EmojiEmotions/>
                                </IconButton>
                                <IconButton
                                    color="primary"
                                    onClick={handleSendMessage}
                                    sx={{marginBottom: '10px'}}
                                >
                                    <Send/>
                                </IconButton>
                            </Box>

                            <Box sx={{ width: '100%' }}>
                                <AttachmentArea selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
                            </Box>
                        </Box>
                    </motion.div>
                )}
            </AnimatePresence>

            <Button
                variant="contained"
                startIcon={<ChatBubble/>}
                onClick={() => setIsChatVisible(!isChatVisible)}
                sx={{position: 'absolute', top: '-20px', right: '20px', zIndex: 10}}
            >
                {isChatVisible ? t("close_chat") : t("open_chat")}
                {messagesCount > 0 && <span style={{ marginLeft: '8px' }}>({messagesCount})</span>}
            </Button>
        </Box>
    );
};

export default ChatBlock;
