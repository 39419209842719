// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiPagination-ul {
  justify-content: center;
}

.catalog__pagination {
  position: relative;
  bottom: 0;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/pagination/Pagination.sass"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AACA;EACE,kBAAA;EACA,SAAA;EACA,mBAAA;AAEF","sourcesContent":[".MuiPagination-ul\n  justify-content: center\n\n.catalog__pagination\n  position: relative\n  bottom: 0\n  margin-bottom: 15px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
