import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import {SearchTable} from "../returnTable/SearchTable";
import {inputProps, inputStyleSM} from "../ProductReturns";
import {BaseDatePicker} from "../../date/DatePicker";
import * as React from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import {fetchOrderLines, returnFilterEnum, setActiveFilter} from "../../../store/slices/NewReturnSlice";
import {Dayjs} from "dayjs";
import {useEffect} from "react";
import {Blured} from "../../blured/Blured";
import {Trans} from "react-i18next";
import {t} from "i18next";


export const ReturnSearchForm = () => {

    const {activeFilter, isLinesLoading} = useAppSelector(state => state.newReturn)
    const dispatch = useAppDispatch()

    const [dateFrom, setDateFrom] = React.useState<Dayjs | null>(null);
    const [dateTo, setDateTo] = React.useState<Dayjs | null>(null);


    const handleSubmit = () => {
        if (!activeFilter) return
        dispatch(fetchOrderLines(activeFilter))
    }

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13) {
            handleSubmit()
        }
    }

    const handleSetFilter = (value: string, field: returnFilterEnum) => {
        const newFilter = {
            ...activeFilter,
            [field]: value,
        };
        dispatch(setActiveFilter(newFilter));
    }

    useEffect(() => {
        if (!dateFrom) return
        handleSetFilter(dateFrom.format('DD/MM/YYYY'), returnFilterEnum.DATE_FROM)
    }, [dateFrom]);

    useEffect(() => {
        if (!dateTo) return
        handleSetFilter(dateTo.format('DD/MM/YYYY'), returnFilterEnum.DATE_TO)
    }, [dateTo]);

    return (
        <>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': {m: 1, width: '25ch'},
                }}
                noValidate
                autoComplete="off"
            >
                <h5><Trans i18nKey="search_criteria_label"/></h5>
                <TextField
                    id="outlined-basic"
                    size="small"
                    label={t('order_number')}
                    variant="outlined"
                    inputProps={inputProps}
                    required
                    InputProps={{
                        sx: {
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgb(110, 209, 243)',
                                outline: 'none',
                            },
                        },
                    }}
                    sx={inputStyleSM}
                    onChange={(event) => handleSetFilter(event.target.value, returnFilterEnum.ORDER_NUMBER)}
                    onKeyDown={handleKeyPress}
                />

                <TextField
                    id="outlined-basic"
                    size="small"
                    label={t('code')}
                    variant="outlined"
                    inputProps={inputProps}
                    required
                    InputProps={{
                        sx: {
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgb(110, 209, 243)',
                                outline: 'none',
                            },
                        },
                    }}
                    sx={inputStyleSM}
                    onChange={(event) => handleSetFilter(event.target.value, returnFilterEnum.CODE)}
                    onKeyDown={handleKeyPress}
                />

                <TextField
                    id="outlined-basic"
                    size="small"
                    label={t('product_name')}
                    variant="outlined"
                    inputProps={inputProps}
                    required
                    InputProps={{
                        sx: {
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgb(110, 209, 243)',
                                outline: 'none',
                            },
                        },
                    }}
                    sx={inputStyleSM}
                    onChange={(event) => handleSetFilter(event.target.value, returnFilterEnum.PRODUCT_NAME)}
                    onKeyDown={handleKeyPress}
                />

                <BaseDatePicker
                    sx={inputStyleSM}
                    slotProps={{textField: {size: 'small', fontSize: "12px"}}}
                    label={t('invoice_date_from')}
                    value={dateFrom}
                    setValue={setDateFrom}
                />

                <BaseDatePicker
                    sx={inputStyleSM}
                    slotProps={{textField: {size: 'small'}}}
                    label={t('invoice_date_to')}
                    value={dateTo}
                    setValue={setDateTo}
                />

                <button type="button" className="btn-search btn" onClick={handleSubmit}>
                    {t('search')}
                </button>

            </Box>
            <Blured
                element={<SearchTable/>}
                isLoading={isLinesLoading}
            />
        </>
    )

}