import React from 'react';
import { useTranslation } from 'react-i18next';
import './languageSwitcher.sass';

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(event.target.value);
    window.location.reload()
  };

  return (
    <div className="language-switcher">
      <select
        className="language-select"
        onChange={handleChangeLanguage}
        defaultValue={i18n.language}
      >
        <option value="en-US">English</option>
        <option value="ru-RU">Русский</option>
      </select>
    </div>
  );
};

export default LanguageSwitcher;
