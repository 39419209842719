import React, {memo} from "react";


interface ControlButton {
    className: string;
    icon: string;
    label?: string | JSX.Element;
    isLoaderUsed?: boolean;
    controlButtonHandler?: any
    isDisable?: boolean
    type?: string
}

export const ControlButton = memo((props: ControlButton) => {

    const controlButtonHandler = (event?: React.MouseEvent<HTMLButtonElement>) => {
        props.controlButtonHandler(event)
    };

    return (
        <button
            className={props.className}
            onClick={controlButtonHandler}
            disabled={props.isDisable}
        >

            <i className={props.icon}>
                <span className={`${ [true, undefined].includes(props.isLoaderUsed)  ? 'd-none' : ''}`}>
                    <l-tail-chase
                        size="14"
                        speed="1.75"
                        color="#6ed1f3"
                    />
                </span>

            </i>
            <span>{props.label}</span>
        </button>
    )
})