import React, { FC } from 'react';
import { Container, Typography, Grid } from '@mui/material';
import { CustomTextField, Section } from './styles';
import { useTranslation } from "react-i18next";
import { useDeliveryForm } from "./hooks/useDeliveryForm.hook";

export const ShippingQuotePage: FC = () => {
    const { t } = useTranslation();
    const { values, handleChange } = useDeliveryForm();

    return (
        <Container maxWidth="md" sx={{ mt: 6, mb: 6 }}>
            <Typography variant="h5" gutterBottom>
                {t('shipping_quote_form.title')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {t('shipping_quote_form.description')}
            </Typography>
            <Section>
                <Typography variant="h6">{t('shipping_quote_form.pickup_delivery.title')}</Typography>
                <CustomTextField
                    fullWidth
                    label={t('shipping_quote_form.pickup_delivery.pickup_address')}
                    defaultValue={values.pickupAddress}
                    onChange={handleChange}
                    margin="normal"
                />
                <CustomTextField
                    fullWidth
                    label={t('shipping_quote_form.pickup_delivery.delivery_address')}
                    defaultValue={values.deliveryAddress}
                    onChange={handleChange}
                    margin="normal"
                />
            </Section>
            <Section>
                <Typography variant="h6">{t('shipping_quote_form.cargo_data.title')}</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <CustomTextField
                            fullWidth
                            label={t('shipping_quote_form.cargo_data.pallet_quantity')}
                            defaultValue={values.cargo[0].quantity}
                            onChange={handleChange}
                            margin="normal"
                        />
                        <CustomTextField
                            fullWidth
                            label={t('shipping_quote_form.cargo_data.dimensions')}
                            defaultValue={values.cargo[0].dimensions}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomTextField
                            fullWidth
                            label={t('shipping_quote_form.cargo_data.pallet_quantity')}
                            defaultValue={values.cargo[1].quantity}
                            onChange={handleChange}
                            margin="normal"
                        />
                        <CustomTextField
                            fullWidth
                            label={t('shipping_quote_form.cargo_data.dimensions')}
                            defaultValue={values.cargo[1].dimensions}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <CustomTextField
                            fullWidth
                            label={t('shipping_quote_form.cargo_data.total_ldm')}
                            defaultValue={values.totalLDM}
                            onChange={handleChange}
                            margin="normal"
                        />
                        <CustomTextField
                            fullWidth
                            label={t('shipping_quote_form.cargo_data.total_weight')}
                            defaultValue={values.totalWeight}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                </Grid>
            </Section>
            <Section>
                <Typography variant="h6">{t('shipping_quote_form.execution_time.title')}</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <CustomTextField
                            fullWidth
                            label={t('shipping_quote_form.execution_time.shipping_date')}
                            defaultValue={values.shippingDate}
                            onChange={handleChange}
                            margin="normal"
                        />
                        <CustomTextField
                            fullWidth
                            label={t('shipping_quote_form.execution_time.delivery_date')}
                            defaultValue={values.deliveryDate}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                </Grid>
            </Section>
        </Container>
    );
};
