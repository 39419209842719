import React from "react";
import { Form } from "./Form";
import { useSignUp } from "../../hooks/UseSignUp.hook";

export const SignUp: React.FC = () => {
    const { handleSignUp } = useSignUp();

    return (
        <Form isSignup title="Зарегистрироваться" handleClick={handleSignUp} />
    );
};
