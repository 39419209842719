import styled from "styled-components";
import { systemWhite, systemBlack, systemBlue, systemGreyLight } from "./constants";

export const SectionTitle = styled.span`
    font: 700 40px/48px "Roboto", sans-serif;
    text-align: center;
    color: ${systemBlack};

    @media (max-width: 768px) {
        font: 700 24px/29px "Roboto", sans-serif;
    }
`;

const Button = styled.div`
    font: 700 12px/28px "Roboto", sans-serif;
    width: 170px;
    border-radius: 4px;
    cursor: pointer;
    padding: 9px 0;
    text-align: center;
`;

export const PrimaryButton = styled(Button)`
    color: ${systemWhite};
    background-color: ${systemBlue};

    &:hover {
        opacity: 0.9;
    }
`;

export const SecondaryButton = styled(Button)`
    color: ${systemBlack};
    background-color: ${systemWhite};
    border: 1px solid ${systemBlue};

    &:hover {
        background-color: ${systemGreyLight};
        opacity: 0.9;
    }
`;

export const SectionPrimaryButton = styled(PrimaryButton)`
    padding: 18px 0;
    width: 225px;
    font: 700 15px/22px "Roboto", sans-serif;
    border-radius: 6px;
`;

export const SectionSecondaryButton = styled(SecondaryButton)<{ $withBorder?: boolean }>`
    border: ${({ $withBorder }) => ($withBorder ? (`1px solid ${systemBlue}`) : 'none')};
    width: 225px;
    padding: 18px 0;
    font: 700 15px/22px "Roboto", sans-serif;
    border-radius: 6px;
`;

export const ButtonsContainer = styled.div<{ $withMargin?: boolean; $shouldApplyColumnStyles?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 26px;
    margin-top: ${({ $withMargin }) => ($withMargin ? '40px' : 0)};

    @media (max-width: 768px) {
        flex-direction: ${({ $shouldApplyColumnStyles }) => ($shouldApplyColumnStyles ? 'column' : 'row')}
    }
`;

export const Link = styled.a`
    font: 400 16px/21px "Roboto", sans-serif;
    color: #306BF5;
    text-decoration: none;
`;
