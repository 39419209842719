import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchProductsRequests, fetchSearchProductsRequests} from "../../api/catalogProducts";


interface FetchProductsParams {
    pageNum: number;
    categoryId: string;
    searchValue: string;
    activeFilters: any[];
    operator: string;
    manufacturerSearchValue: string;
    sortType: string;
    onlyAvailability: boolean;
    filterCatalogValue: string;
    activeCatalogFilters: any[];
}

export type initialStateType = {
    pageNumber: number;
    pagesCount: number;
    isProductLoading: boolean;
    productsCount: number;
    maxProductCount: number;
    productList: any[];
    productInfo: any[]
    categories: any[]
}

const initialState: initialStateType = {
    productsCount: 0,
    maxProductCount: 0,
    productInfo: [],
    pageNumber: 0,
    pagesCount: 0,
    isProductLoading: true,
    productList: [],
    categories: [],
}

let currentRequestId = 0;

export const fetchCatalogProducts =
    createAsyncThunk("slice/catalogProductsAdd",
        async (values: FetchProductsParams) => {
            const requestId = ++currentRequestId;
            const result = await fetchProductsRequests(values)
            if (requestId === currentRequestId) {
                return result.data?.data
            }
        }
    )

export const fetchSearchProducts =
    createAsyncThunk("slice/catalogProductsSearch",
        async (values: any) => {
            const {pageNum, searchValue, activeFilters, categoryId, onlyAvailability,sortType} = values
            const result = await fetchSearchProductsRequests(pageNum, searchValue, activeFilters, categoryId, onlyAvailability,sortType)
            return result.data?.data
        }
    )

export const extendCatalogProducts =
    createAsyncThunk("slice/catalogProductsExtend",
        async (values: any) => {
            const result = await fetchProductsRequests(values)
            return result.data?.data
        }
    )

export const resetCatalogProducts =
    createAsyncThunk("slice/catalogProductsReset",
        async () => initialState
    )

const updateCatalogState = (state: any, payload: any, isExtend: boolean = false) => {
    state.productsCount = payload?.product_count;
    state.maxProductCount = payload?.max_product_count;
    state.productInfo = isExtend ?
        state.productInfo.concat(payload?.product_info) : payload?.product_info;
    state.pageNumber = payload?.page_number;
    state.pagesCount = payload?.pages_count;
    state.productList = payload?.product_list;
    state.isProductLoading = false;
    if (payload?.categories?.length > 0) {
        state.categories = payload?.categories;
    }
};

const catalogProductsSliceReducer = createSlice({
    name: "catalogProducts",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchCatalogProducts.fulfilled, (state, action) => {
                updateCatalogState(state, action.payload);
            })
            .addCase(fetchCatalogProducts.pending, (state, action) => {
                state.isProductLoading = true;
            })
            .addCase(extendCatalogProducts.fulfilled, (state, action) => {
                updateCatalogState(state, action.payload, true);
            })
            .addCase(extendCatalogProducts.pending, (state, action) => {
                state.isProductLoading = true;
            })
            .addCase(fetchSearchProducts.pending, (state) => {
                state.isProductLoading = true;
            })
            .addCase(fetchSearchProducts.fulfilled, (state, action) => {
                updateCatalogState(state, action.payload);
            })
            .addCase(resetCatalogProducts.fulfilled, (state) => {
                Object.assign(state, initialState);
                state.isProductLoading = false;
            });
    }
});


export default catalogProductsSliceReducer.reducer