import React from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { store } from "./store";
import './i18n/index.ts';


import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";


let ReactDOM = require("react-dom/client");
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <App/>
        </Provider>
    </BrowserRouter>
);
