import styled from "styled-components";
import { motion } from "framer-motion";

export const AdvantagesSectionContent = styled(motion.section)`
    padding: 50px 40px;

    @media (max-width: 768px) {
        padding: 30px 16px;
    }
`;

export const AdvantagesSectionContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 50px;
    gap: 100px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 50px;
    }
`;

export const Pane = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 50px;
`;
