import { useEffect, useState } from "react";

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            const userAgent = navigator.userAgent;
            const matchMediaCheck = window.matchMedia("(max-width: 768px)").matches;
            setIsMobile(/android|iphone|ipad|ipod/i.test(userAgent) || matchMediaCheck);
        };

        checkMobile();
        window.addEventListener("resize", checkMobile);

        return () => window.removeEventListener("resize", checkMobile);
    }, []);

    return isMobile;
};
