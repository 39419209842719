import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../../hooks/ReduxHooks";
import {useDidUpdate} from "../../../../hooks/UseDidUpdate";
import useDebounce from "../../../../hooks/UseDebounce";
import {updateBackendReturn} from "../../../../api/returns";
import {DeliveryOptions} from "../../../../constants/consts";
import TextField from "@mui/material/TextField";
import {fetchAddresses} from "../../../../api/addresses";
import {NativeSelect} from "@mui/material";
import {t} from "i18next";
import {Trans} from "react-i18next";

export const ReturnDeliveryInfo = () => {
    const {returnData} = useAppSelector(state => state.return)
    const [departNum, setDepartNum] = useState<string>(returnData?.departure_number || "")
    const [deliveryType, setDeliveryType] = useState<string>(returnData?.delivery_type || "")
    const [pickingId, setPickingId] = useState<number | undefined>(returnData?.pickup_address_id?.id)
    const [addresses, setAddresses] = useState<{
        name: React.ReactNode;
        id: string,
        display_name: string,
    }[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isDeliveryTypeIsLoading, setDeliveryTypeIsLoading] = useState<boolean>(false)
    const [isPickingAddressLoading, setPickingAddressLoading] = useState<boolean>(false)
    const debouncedDepartNum = useDebounce(departNum, 700)
    const debouncedDeliveryType = useDebounce(deliveryType, 700)
    const debouncedPickingId = useDebounce(pickingId, 700)

    const getAddresses = async () => {
        const result = await fetchAddresses();
        if (!result?.data?.data) return;
        setAddresses(result?.data?.data);
    };

    const handleChangeDepartNum = (event: React.FocusEvent<HTMLInputElement>) => {
        setDepartNum(event.target.value)
    }

    const handleChangeDeliveryType = (event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setDeliveryType(event.target.value)
    }

    const handleChangePickupAddress = (event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setPickingId(Number(event.target.value))
    }

    useEffect(() => {
        getAddresses();
    }, []);

    useDidUpdate(() => {
            if (!returnData || !returnData.id) return
            (async () => {
                setIsLoading(true);
                await updateBackendReturn({
                    "id": returnData.id,
                    "departure_number": debouncedDepartNum,
                    "delivery_type": deliveryType,
                });
                setIsLoading(false);
            })()

        },
        [debouncedDepartNum]);

    useDidUpdate(() => {
            if (!returnData || !returnData.id) return
            (async () => {
                setDeliveryTypeIsLoading(true);
                await updateBackendReturn({
                    "id": returnData.id,
                    "delivery_type": deliveryType,
                });
                setDeliveryTypeIsLoading(false);
            })()

        },
        [debouncedDeliveryType]);

    useDidUpdate(() => {
            if (!returnData || !returnData.id) return
            (async () => {
                setPickingAddressLoading(true);
                await updateBackendReturn({
                    "id": returnData.id,
                    "pickup_address_id": pickingId,
                });
                setPickingAddressLoading(false);
            })()

        },
        [debouncedPickingId]);

    return (returnData ?
            <>
                <div id="informations" className="row">
                    <div id="return_info" className="col-12 col-lg-6 mb-4">
                        <table className="table table-borderless table-sm">
                            <tbody>
                            <tr>
                                <th className="w-40 ps-0 pb-0"><Trans i18nKey="created_date"/>:</th>
                                <td className="w-60 pb-0 text-nowrap text-end">
                                    <span>{returnData.create_date.split(" ")[0]}</span>
                                </td>
                            </tr>
                            <tr>
                                <th className="w-40 ps-0 pb-0"><Trans i18nKey="approved_date"/>:</th>
                                <td className="w-60 pb-0 text-nowrap text-end">
                                    <span>{returnData.approve_date}</span>
                                </td>
                            </tr>
                            <tr>
                                <th className="w-40 ps-0 pb-0"><Trans i18nKey="shipment_date"/>:</th>
                                <td className="w-60 pb-0 text-nowrap text-end">
                                    <span></span>
                                </td>
                            </tr>
                            <tr>
                                <th className="w-40 ps-0 pb-0"><Trans i18nKey="pickup_address"/>:</th>
                                <td className="w-60 pb-0 text-nowrap text-end">
                                    <NativeSelect
                                        className="w-100"
                                        inputProps={{
                                            style: {
                                                textAlign: "end",
                                                fontSize: "13px"
                                            }
                                        }}
                                        disabled={isPickingAddressLoading}
                                        onChange={handleChangePickupAddress}
                                        value={pickingId}
                                    >
                                        <option></option>
                                        {addresses.map((address) => (
                                            <option
                                                key={`address_option_${address.id}`}
                                                value={address.id}>{address.name}</option>
                                        ))}
                                    </NativeSelect>
                                </td>
                            </tr>
                            <tr>
                                <th className="w-40 ps-0 pb-0"><Trans i18nKey="tracking_number"/>:</th>
                                <td className="w-60 pb-0 text-nowrap text-end">
                                    <TextField
                                        id="del_num"
                                        variant="standard"
                                        disabled={isLoading}
                                        inputProps={{
                                            style: {
                                                textAlign: "end",
                                                fontSize: "13px",
                                            }
                                        }}
                                        className="form-control text-end w-100"
                                        onBlur={handleChangeDepartNum}
                                        defaultValue={departNum}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div id="return_info" className="col-12 col-lg-6 mb-4">
                        <table className="table table-borderless table-sm">
                            <tbody>
                            <tr>
                                <th className="w-40 ps-0 pb-0">{t('created_by')}:</th>
                                <td className="w-60 pb-0 text-nowrap text-end">
                                    <span>{returnData.responsible_partner}</span>
                                </td>
                            </tr>
                            <tr>
                                <th className="w-40 ps-0 pb-0">{t('delivery_address')}:</th>
                                <td className="w-60 pb-0 text-nowrap text-end">
                                    <span></span>
                                </td>
                            </tr>
                            <tr>
                                <th className="w-40 ps-0 pb-0">{t('contact_person')}:</th>
                                <td className="w-60 pb-0 text-nowrap text-end">
                                    <span>{returnData.contact_person}</span>
                                </td>
                            </tr>
                            <tr>
                                <th className="w-40 ps-0 pb-0">{t('phone')}:</th>
                                <td className="w-60 pb-0 text-nowrap text-end">
                                    <span></span>
                                </td>
                            </tr>
                            <tr>
                                <th className="w-40 ps-0 pb-0">{t('delivery')}:</th>
                                <td className="w-60 pb-0 text-nowrap text-end">
                                    <NativeSelect
                                        className="w-100"
                                        disabled={isDeliveryTypeIsLoading}
                                        inputProps={{
                                            style: {
                                                textAlign: "end",
                                                fontSize: "13px",
                                                width: "100%",
                                            }
                                        }}
                                        value={deliveryType}
                                        onChange={handleChangeDeliveryType}
                                    >
                                        <option></option>
                                        {DeliveryOptions.map((delivery_type) => (
                                            <option
                                                key={`delivery_option_${delivery_type.value}`}
                                                value={delivery_type.value}
                                            >
                                                {delivery_type.name}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                </td>
                            </tr>
                            </tbody>
                        </table>


                    </div>
                </div>
            </> : <></>
    )
}



