import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hooks/ReduxHooks";
import {useLocation} from "react-router-dom";
import {BreadcrumbsDataProps} from "../../../breadcrumbs/Breadcrumbs";
import {updateBreadcrumb} from "../../../../store/slices/BreadcrumbSlice";
import useDebounce from "../../../../hooks/UseDebounce";
import {fetchUpdateOrder} from "../../../../api/orders";
import {useDidUpdate} from "../../../../hooks/useDidUpdate";

export const OrderIntroduction = () => {

    const {orderData} = useAppSelector(state => state.order)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [name, setName] = useState<string>(orderData?.reservation_name || "")
    const location = useLocation()
    const dispatch = useAppDispatch()
    const debouncedName = useDebounce(name, 700)

    if (orderData) {
        let pr: BreadcrumbsDataProps = {}
        pr[location.pathname] = orderData?.name || "Product"
        dispatch(updateBreadcrumb(pr))

    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }

    useDidUpdate(() => {
        if (!orderData) return

        (async () => {
            setIsLoading(true)
            await fetchUpdateOrder( orderData.id,{
                "reservation_name": debouncedName,
            })
            setIsLoading(false)
        })()

    }, [debouncedName]);

    return (
        <>
            <div className="border-bottom-0 pt-0 pb-3">
                <div className="d-flex bold-text justify-content-between gap-2">
                    {orderData?.is_reserve ? (
                        <>
                            <h3>
                                Резерв: <span className="text-colored">{orderData && orderData.name}</span>
                            </h3>
                        </>
                    ) : (
                        <>
                            <div>
                                <h3>
                                    Заказ: <span className="text-colored">{orderData && orderData.name}</span>
                                </h3>
                                {orderData?.is_b2b_active ? (
                                    <h5 className="text-success">Активный</h5>
                                ) : (
                                    <h5 className="text-danger">Неактивный</h5>
                                )}
                            </div>
                            {orderData?.delivery_status && (
                                <div>
                                    Статус: <span
                                    className="text-success">{orderData.delivery_status}</span>
                                </div>
                            )}

                        </>
                    )}
                </div>
                {orderData?.is_reserve && (
                    <div className="d-flex justify-content-start gap-2 w-50">
                        <label className="fw-bold">Название:</label>
                        <input type="text" disabled={isLoading} onBlur={handleChangeName} className="form-control"
                               defaultValue={name}/>
                    </div>
                )}
            </div>
        </>
    )
}