import "./productCatalogPage.sass"
import {
    CatalogFilterWrapper,
    CatalogItemsCount,
    SubcategoriesContainer,
} from "./styles";
import {SearchPanelBase} from "../../components/searchPanel/SearchPanelBase";
import {CatalogContent} from "../../components/catalog/CatalogContent"
import {CatalogFilter} from "../../components/catalog/catalogFilter/CatalogFilter"
import {FormGroup, FormControlLabel, Checkbox} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import React, {useEffect, useMemo, useState} from "react";
import {
    extendCatalogProducts,
    fetchCatalogProducts, fetchSearchProducts,
} from "../../store/slices/CatalogProductsReducerSlice";
import {setCatalogSearchValue, setDisplayType, updatePage} from "../../store/slices/PagePaginagionSlice";
import {useLocation, useParams} from "react-router-dom";
import {DISPLAY_TYPE, EMPTY_STRING, ROUTE_URLS} from "../../constants/consts";
import {Loader} from "../../components/loader/Loader";
import {updateBreadcrumb} from "../../store/slices/BreadcrumbSlice";
import {ProductB2BCategory} from "../../components/catalog/types";
import {fetchCategory} from "../../api/categories";
import useDebounce from "../../hooks/UseDebounce";
import {SwiperSearchCategories} from "../../components/swiperSearchCategoties/SwiperSearchCategories";
import {Blured} from "../../components/blured/Blured";
import {useSelector} from "react-redux";
import {selectCategoryById} from "../../store/selectors/CategorySelector";
import {SubCategoryItem} from "./components/subCategoryItem";

type requestDataType = {
    pageNum: number,
    categoryId?: number,
    searchValue: string,
    activeFilters: string[],
    operator: string,
    manufacturerSearchValue: string,
}

export const ProductCatalogPage = () => {
    const {categoryId} = useParams()
    const { pathname } = useLocation();
    const [activeCategId, setActiveCategId] = useState(categoryId ? Number(categoryId) : undefined)
    const {page, displayType, searchValue, activeFilters, operator, manufacturerSearchValue} = useAppSelector((state: any) => state.page)
    const dispatch = useAppDispatch()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {maxProductCount, isProductLoading, productList, categories } = useAppSelector(state => state.catalogProducts)
    const {isCartLoading} = useAppSelector(state => state.activeCart)
    const [requestData, setRequestData] = useState<any>(undefined)
    const debouncedRequestData = useDebounce(requestData, 500)
     const selectedCategory = useSelector(selectCategoryById(categoryId));
    const hierarchyCategory = useSelector((state: any) => state.hierarchyCategory.data);
    const isCatalogPage = pathname === ROUTE_URLS.CATALOG;

    const subcategories = useMemo<Array<any>>(() => {
        const dataForFiltering = isCatalogPage ? hierarchyCategory : selectedCategory?.child_ids;

        return dataForFiltering?.filter((category: any) => category?.is_has_products);
    }, [isCatalogPage, hierarchyCategory, selectedCategory]);

    const shouldShowSubcategories = Boolean(subcategories?.length) || isCatalogPage;

    if (location?.state?.searchValue && !searchValue) {
        dispatch(setCatalogSearchValue(location?.state?.searchValue))
    }

    useEffect(() => {
        dispatch(setDisplayType(DISPLAY_TYPE.TABLE))
        dispatch(setCatalogSearchValue(EMPTY_STRING))
    }, [categoryId]);

    useEffect(() => {
        if (!isProductLoading && !isCartLoading)
            setIsLoading(false)
    }, [isProductLoading, isCartLoading]);

    useEffect(() => {
        dispatch(updatePage(1))
    }, [categoryId, displayType, activeCategId]);

    useEffect( () => {
        setActiveCategId(undefined)
    }, [searchValue]);

    useEffect(() => {
        if (!debouncedRequestData) return
        if (!categoryId) {
            dispatch(fetchSearchProducts(debouncedRequestData))
        } else {
            displayType === "grid" && page !== 1
            ? dispatch(extendCatalogProducts(debouncedRequestData))
            : dispatch(fetchCatalogProducts(debouncedRequestData));
        }
    }, [debouncedRequestData]);

    useEffect(() => {
        if (page === 0) return
        let data : requestDataType = {
            pageNum: page,
            searchValue: searchValue,
            activeFilters: activeFilters,
            operator: operator,
            manufacturerSearchValue: manufacturerSearchValue,
        }
        if (categoryId || activeCategId) {
            data["categoryId"] =  Number(activeCategId || categoryId)
        }
        setRequestData(data)

    }, [categoryId, activeCategId, page, searchValue, operator, manufacturerSearchValue]);


    const getCategory = async (data: string | any[]) => {
        if (!categoryId) return
        let result = await fetchCategory(categoryId)
        if (result.error) return
        const category = result.data.data as ProductB2BCategory
        if (!category) return undefined
        return category
    }

    useEffect(() => {
        (async () => {
            if (productList && categoryId && productList.length > 0) {
                const relatedCategory = await getCategory(categoryId)
                if (!relatedCategory) return
                dispatch(updateBreadcrumb({key: location.pathname, value: relatedCategory.name}))
            }
        })();
    }, [categoryId, productList]);

    return (
        <>
            {(isLoading) ? (
                <Loader/>
            ) : (
                <>
                    {shouldShowSubcategories ?
                        <SubcategoriesContainer>
                            {subcategories.map(category => <SubCategoryItem category={category} />)}
                        </SubcategoriesContainer>
                        : (
                        <>
                        <CatalogFilterWrapper>
                        <CatalogFilter/>
                        <div className={"w-100"}>
                            <SearchPanelBase initialSearchValue={location?.state?.searchValue}/>
                        </div>
                        <FormGroup row className="flex-nowrap">
                            <FormControlLabel control={<Checkbox/>} label="Только в наличии" color="default"
                                              className={"text-nowrap"}/>
                        </FormGroup>
                        {maxProductCount ? (
                            <CatalogItemsCount>{maxProductCount}</CatalogItemsCount>
                        ) : (
                            <></>
                        )
                        }
                    </CatalogFilterWrapper>
                    {(searchValue && !categoryId) && (
                        <Blured
                            element={<h3>Вы искали "{searchValue}"</h3>}
                            isLoading={isProductLoading}
                        />
                    )}
                    {(!categoryId && categories) && (
                        <SwiperSearchCategories
                            setCategId={setActiveCategId}
                            categId={activeCategId}
                            categories={categories}
                        />
                    )}
                    <Blured element={<CatalogContent/>} isLoading={isProductLoading}/>
                </>
              )}
            </>
            )
            }
        </>
    )
}
