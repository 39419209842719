// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.language-select {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  background-color: #f8f9fa;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.language-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.language-select option {
  font-size: 16px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/components/languageSwitcher/languageSwitcher.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AACA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,cAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,wDAAA;AAEF;;AAAA;EACE,qBAAA;EACA,0CAAA;EACA,aAAA;AAGF;;AADA;EACE,eAAA;EACA,gBAAA;AAIF","sourcesContent":[".language-switcher\n  display: flex\n  justify-content: center\n  align-items: center\n  margin-bottom: 15px\n\n.language-select\n  padding: 10px 20px\n  font-size: 16px\n  font-weight: 500\n  background-color: #f8f9fa\n  color: #495057\n  border: 1px solid #ced4da\n  border-radius: 8px\n  cursor: pointer\n  transition: border-color 0.3s ease, box-shadow 0.3s ease\n\n.language-select:focus\n  border-color: #007bff\n  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5)\n  outline: none\n\n.language-select option\n  font-size: 16px\n  font-weight: 400\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
