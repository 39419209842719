import {CatalogControl} from "../../catalogControl/CatalogControl";
import {useAppSelector} from "../../../../hooks/ReduxHooks";

interface CatalogTableProps {
    isSimple?: boolean
}

export const TableHeader = (props: CatalogTableProps) => {
    const { activeDisplayFields } = useAppSelector(state => state.page)
    return (
        <tr className="align-baseline catalog-table">
            {activeDisplayFields.includes("image") && (
                <th style={{width: "7%"}} scope="col" className="text-dark text-start text-nowrap">Фото</th>
            )}
            {activeDisplayFields.includes("default_code") && (
                <th style={{width: "5%"}} scope="col" className="text-colored text-start text-nowrap">Код</th>
            )}
            {activeDisplayFields.includes("brand") && (
                <th style={{width: "5%"}} scope="col" className="text-colored text-center text-nowrap">Брэнд</th>
            )}
            {activeDisplayFields.includes("product_code") && (
                <th style={{width: "10%"}} scope="col" className="text-colored text-start text-nowrap product__code">Код производителя</th>
            )}
            <th style={{width: "3%"}} scope="col" className="text-colored text-start text-nowrap"></th>
            <th style={{width: "46%"}} scope="col" className="text-colored text-start text-nowrap product__name">Название продукта</th>
            <th style={{width: "7%"}} scope="col" className="text-colored text-center text-nowrap">Доступность</th>
            <th style={{width: "5%"}} scope="col" className="text-colored text-center text-nowrap">Цена</th>
            <th style={{width: "10%"}} scope="col" className="text-colored text-start text-nowrap">
                {!props.isSimple && (
                    <div className={"d-flex justify-content-end"}>
                        <CatalogControl/>
                    </div>
                )}
            </th>
        </tr>
    )
}
