import {useEffect} from "react";
import {DISPLAY_TYPE} from "../constants/consts";


const usePaginationScroll = (page: number, displayType: DISPLAY_TYPE) => {

    useEffect(() => {
        if (!page || page === 0 || displayType === DISPLAY_TYPE.GRID) {
            return
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [page]);
}

export default usePaginationScroll
