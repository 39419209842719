import {
    CatalogGridImageWrapper,
    FavIconWrapper,
    ProcudtBage,
    ProductCard,
    ProductTitle
} from "./styles";
import {FavButton} from "../../favButton/FavButton"
import * as React from "react";
import {Link} from "react-router-dom";

import "./CatalogCard.sass"
import {setPreviewProduct} from "../../../../store/slices/PreviewProductSlice";
import {useDispatch} from "react-redux";
import {ProductCardControl} from "../../../productCardControl/ProductCardControl";
import {ToOrder} from "../../toOrder/ToOrder";

interface CatalogCardPropsValue {
    productData: any
    categoryID?: any
    isMainPage?: boolean
}

interface ProductCardControlRef {
    setIsInCard: (newState: any) => void;
    setValue: (newState: any) => void;
}



export const CatalogCard = (props:CatalogCardPropsValue) => {

    const dispatch = useDispatch()
    const handleOnClickImage = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        if (!props.productData) return;
        dispatch(setPreviewProduct(props.productData.id))
    }


    return (
        <div className="d-flex justify-content-around" key={`catalog_cart_${props.productData.id}`}>

                <ProductCard key={`product_${props.productData.id}`} className="card position-relative">
                    <CatalogGridImageWrapper>
                            <img loading="lazy"
                                 src={props.productData.image_1920}
                                 className="card__image rounded float-left w-100"
                                 alt="..."
                                 onClick={handleOnClickImage}
                            />
                    </CatalogGridImageWrapper>
                    <FavIconWrapper><FavButton/></FavIconWrapper>
                    <div className="card-body d-flex flex-column justify-content-between">
                        <div>
                            <ProcudtBage className={"badge"}>
                                New product
                            </ProcudtBage>
                        </div>
                        <Link className="catalog_product__link" to={`/products/${props.categoryID}/product-card/${props.productData.id}`}>
                            <ProductTitle
                                className="card-title fs-6"
                            >
                                {props.productData.name}
                            </ProductTitle>
                        </Link>
                        <div className="d-flex justify-content-between"><p className="card-text text-secondary ">Доступность </p><span
                            className="text-success fw-bold">
                            {props.productData.is_display_stock_balance_on_portal && (
                                props.productData.stock_quantity_b2b <= props.productData.balance_no_more ? (
                                    <span> {props.productData.stock_quantity_b2b}</span>
                                ) : (
                                    <span> более {props.productData.balance_no_more}</span>
                                )
                            )}
                        </span></div>
                        <p className="fs-6 fw-bold">{props.productData.product_price} {props.productData.currency.symbol}
                        </p>
                        <ToOrder productData={props.productData}/>
                    </div>
                    <div className="product_card__footer card-footer ">
                        <div className={"btn__wrapper d-flex justify-content-between"}>
                            <ProductCardControl
                                isMainPage
                                isExtended
                                productData={{
                                    id: props.productData.id,
                                    is_for_order: props.productData.is_for_order,
                                    balance_no_more: props.productData.balance_no_more,
                                    stock_quantity_b2b: props.productData.stock_quantity_b2b,
                                    is_display_stock_balance_on_portal: props.productData.is_display_stock_balance_on_portal,
                                    price: props.productData.product_price,
                                    currencyId: props.productData.currency.id,
                                }}/>
                        </div>
                    </div>
                </ProductCard>

        </div>
    )
}