// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-nav {
  overflow-x: scroll;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

.tab-list::-webkit-scrollbar {
  display: none;
}

.tab-list {
  width: -moz-fit-content;
  width: fit-content;
  min-width: 100%;
  display: flex;
  gap: 0.5em;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid #eee;
  color: gray;
}

.tab-list li {
  display: block;
  margin-bottom: -2px;
}

.tab-btn {
  font: inherit;
  padding: 0.75em 1em;
  background-color: transparent;
  border: 2px solid var(--tab-border-color);
  cursor: pointer;
  color: inherit;
  white-space: nowrap;
}

.tab-btn:not(.tab-btn--active):hover {
  color: var(--site-text-color);
}

.tab-btn--active {
  color: #000;
  font-weight: 600;
  border-bottom: 2px solid rgb(110, 209, 243);
}

.tab-panel {
  margin-top: 1em;
  padding: 1em;
  background-color: var(--tab-panel-bg-color);
  border-radius: 0.5em;
}`, "",{"version":3,"sources":["webpack://./src/components/tabs/Tabs.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,iCAAA;EACA,yBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,uBAAA;EAAA,kBAAA;EACA,eAAA;EACA,aAAA;EACA,UAAA;EACA,SAAA;EACA,UAAA;EACA,6BAAA;EACA,WAAA;AACF;;AACA;EACE,cAAA;EACA,mBAAA;AAEF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,yCAAA;EACA,eAAA;EACA,cAAA;EACA,mBAAA;AAEF;;AACA;EACE,6BAAA;AAEF;;AACA;EACE,WAAA;EACA,gBAAA;EACA,2CAAA;AAEF;;AACA;EACE,eAAA;EACA,YAAA;EACA,2CAAA;EACA,oBAAA;AAEF","sourcesContent":[".tab-nav\n  overflow-x: scroll\n  scrollbar-width: none\n  -webkit-overflow-scrolling: touch\n  overflow-scrolling: touch\n\n\n.tab-list::-webkit-scrollbar\n  display: none\n\n\n.tab-list\n  width: fit-content\n  min-width: 100%\n  display: flex\n  gap: .5em\n  margin: 0\n  padding: 0\n  border-bottom: 2px solid #eee\n  color: gray\n\n.tab-list li\n  display: block\n  margin-bottom: -2px\n\n\n.tab-btn\n  font: inherit\n  padding: 0.75em 1em\n  background-color: transparent\n  border: 2px solid var(--tab-border-color)\n  cursor: pointer\n  color: inherit\n  white-space: nowrap\n\n\n.tab-btn:not(.tab-btn--active):hover\n  color: var(--site-text-color)\n\n\n.tab-btn--active\n  color: #000\n  font-weight: 600\n  border-bottom: 2px solid  rgb(110, 209, 243)\n\n\n.tab-panel\n  margin-top: 1em\n  padding: 1em\n  background-color: var(--tab-panel-bg-color)\n  border-radius: .5em\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
