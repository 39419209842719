import axios from "axios";
import {resolve} from "./resolve.js";
import {API_BACKEND_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";


export async function fetchAddresses() {
    return await resolve(axios.get(`${API_BACKEND_URL}/sales/addresses/get_addresses`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "session_id": getSession(),
                }
            }
        ).then(res => res)
    );
}
