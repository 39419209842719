// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-select {
  padding: 10px 20px;
  border: none;
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  background-size: 12px;
}

.language-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.language-select option {
  font-size: 16px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/languageSwitcher/languageSwitcher.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AACA;EACE,kBAAA;EACA,YAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,aAAA;EACA,wDAAA;EACA,qBAAA;AAEF;;AAAA;EACE,qBAAA;EACA,0CAAA;EACA,aAAA;AAGF;;AADA;EACE,eAAA;EACA,gBAAA;AAIF","sourcesContent":[".language-switcher\n  display: flex\n  justify-content: center\n  align-items: center\n\n.language-select\n  padding: 10px 20px\n  border: none\n  background: transparent\n  font-size: 16px\n  font-weight: 500\n  color: #000\n  border-radius: 8px\n  cursor: pointer\n  outline: none\n  transition: border-color 0.3s ease, box-shadow 0.3s ease\n  background-size: 12px\n\n.language-select:focus\n  border-color: #007bff\n  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5)\n  outline: none\n\n.language-select option\n  font-size: 16px\n  font-weight: 500\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
