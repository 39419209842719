import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";


export function NavButtonNotification() {

    return (
        <IconButton aria-label="bell" style={{padding:"initial",display:"block", paddingLeft:"9px",paddingRight: "9px"}}>
            <Badge badgeContent={1}>
                <NotificationsIcon/>
            </Badge>
        </IconButton>
    )
}

export default NavButtonNotification