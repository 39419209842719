// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order_sidebar .btn {
  flex: 0 0 40%;
  border-color: rgb(110, 209, 243) !important;
  background-color: rgb(110, 209, 243) !important;
  color: white;
}

.avatar__wrapper {
  width: 150px;
  height: 150px;
  margin: auto;
}
.avatar__wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/components/orders/orderContent/orderSidebar/OrderSidebar.sass"],"names":[],"mappings":"AAEE;EACE,aAAA;EACA,2CAAA;EACA,+CAAA;EACA,YAAA;AADJ;;AAKE;EACE,YAAA;EACA,aAAA;EACA,YAAA;AAFJ;AAII;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AAFN","sourcesContent":[".order_sidebar\n\n  .btn\n    flex: 0 0 40%\n    border-color: rgb(110, 209, 243) !important\n    background-color: rgb(110, 209, 243) !important\n    color: white\n\n\n.avatar\n  &__wrapper\n    width: 150px\n    height: 150px\n    margin: auto\n\n    img\n      width: 100%\n      height: 100%\n      object-fit: contain\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
