import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem';
import {inputAddressStyleSM, inputProps, inputStyle, inputStyleSM} from "../../productReturns/ProductReturns";
import React from "react";
import {NewWarrantyRequestTable} from "../newWarrantyRequestTable/NewWarrantyRequestTable";


export const NewWarrantyRequestSubmitForm = () => {

    const deliveryAddress = [
        {
            id: 1,
            name: "Address 1"
        },
        {
            id: 2,
            name: "Address 2"
        }
    ]

    return (
        <>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': {m: 1, width: '25ch'},
                }}
                noValidate
                autoComplete="off"
            >
                <h5>Оформить заявку</h5>
                <TextField id="outlined-basic"
                           size="small"
                           label="Контактная персона"
                           variant="outlined"
                           inputProps={inputProps}
                           required
                           InputProps={{
                               sx: {
                                   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                       borderColor: 'rgb(110, 209, 243)',
                                       outline: 'none',
                                   },
                               },
                           }}
                           sx={inputStyleSM}
                />
                <TextField id="outlined-basic"
                           size="small"
                           label="Телефон"
                           variant="outlined"
                           inputProps={inputProps}
                           required
                           InputProps={{
                               sx: {
                                   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                       borderColor: 'rgb(110, 209, 243)',
                                       outline: 'none',
                                   },
                               },
                           }}
                           sx={inputStyleSM}
                />
                <TextField
                    id="outlined-select-currency"
                    select
                    size="small"
                    label="Адрес доставки"
                    inputProps={inputProps}
                    required
                    InputProps={{
                        sx: {
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgb(110, 209, 243)',
                                outline: 'none',
                            },
                        },
                    }}
                    sx={inputAddressStyleSM}
                    className="delivery_address w-100"
                >
                    {deliveryAddress.map((address) => (
                        <MenuItem
                            key={`$address_option_{address.id}`}
                            value={address.name}
                        >
                            {address.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField id="outlined-basic"
                           size="small"
                           label="Название заявки (текст)"
                           variant="outlined"
                           inputProps={inputProps}
                           required
                           InputProps={{
                               sx: {
                                   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                       borderColor: 'rgb(110, 209, 243)',
                                       outline: 'none',
                                   },
                               },
                           }}
                           sx={inputStyleSM}
                />

            </Box>
            <NewWarrantyRequestTable/>
            <button type="button" className={`btn btn-search`}>Создать</button>
        </>
    )
}