// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newRequestTable {
  width: 100%;
}
.newRequestTable__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.newRequestTable th {
  font-size: 12px;
  vertical-align: baseline;
}
.newRequestTable td {
  font-size: 12px;
  vertical-align: baseline;
}
.newRequestTable__number {
  width: 7%;
}
.newRequestTable__code {
  width: 7%;
}
.newRequestTable__man_code {
  width: 4%;
}
.newRequestTable__name {
  width: 25%;
}
.newRequestTable__delivery {
  width: 7%;
}
.newRequestTable__bill_date {
  width: 7%;
}
.newRequestTable__qty {
  width: 3%;
}
.newRequestTable__price {
  width: 7%;
}
.newRequestTable__total {
  width: 7%;
}
.newRequestTable__state {
  width: 10%;
}
.newRequestTable__desc {
  width: 10%;
}
.newRequestTable__img {
  width: 6%;
}
.newRequestTable__btns {
  width: 5%;
}

.search-banner {
  background-color: #cec9c9;
  font-size: 12px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/warrantyRequest/newWarrantyRequestTable/NewWarrantyRequestTable.sass"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AACJ;AAEE;EACE,eAAA;EACA,wBAAA;AAAJ;AAEE;EACE,eAAA;EACA,wBAAA;AAAJ;AAEE;EACE,SAAA;AAAJ;AACE;EACE,SAAA;AACJ;AAAE;EACE,SAAA;AAEJ;AADE;EACE,UAAA;AAGJ;AAFE;EACE,SAAA;AAIJ;AAHE;EACE,SAAA;AAKJ;AAJE;EACE,SAAA;AAMJ;AALE;EACE,SAAA;AAOJ;AANE;EACE,SAAA;AAQJ;AAPE;EACE,UAAA;AASJ;AARE;EACE,UAAA;AAUJ;AATE;EACE,SAAA;AAWJ;AAVE;EACE,SAAA;AAYJ;;AAVA;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;AAaF","sourcesContent":[".newRequestTable\n  width: 100%\n\n  &__wrapper\n    height: 100%\n    display: flex\n    flex-direction: column\n    justify-content: space-between\n\n\n  th\n    font-size: 12px\n    vertical-align: baseline\n\n  td\n    font-size: 12px\n    vertical-align: baseline\n\n  &__number\n    width: 7%\n  &__code\n    width: 7%\n  &__man_code\n    width: 4%\n  &__name\n    width: 25%\n  &__delivery\n    width: 7%\n  &__bill_date\n    width: 7%\n  &__qty\n    width: 3%\n  &__price\n    width: 7%\n  &__total\n    width: 7%\n  &__state\n    width: 10%\n  &__desc\n    width: 10%\n  &__img\n    width: 6%\n  &__btns\n    width: 5%\n\n.search-banner\n  background-color: #cec9c9\n  font-size: 12px\n  font-weight: 700\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
