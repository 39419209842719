import {memo, useState} from "react";
import "./FavButton.sass"

interface FavButtonPropsValues {
    isActive?: boolean;
    isColored?: boolean
}

export const FavButton = memo((props: FavButtonPropsValues) => {
    const isColored = props.isColored
    const [isActive, setActive] = useState(false);

    const ToggleActive = () => {
        setActive(!isActive);
    };

    return (
        <button className={`btn favIcon ${isColored && "text-colored"}`} onClick={ToggleActive}>
            <i className={`bi bi-star${isActive ? "-fill": ""}`}></i>
        </button>
    )
})
