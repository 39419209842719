import styled from "styled-components";

export const Container = styled.form`   
    display: flex;
    width: 80%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
`;

export const AuthContainer = styled.div`   
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;

`;

export const AuthContainerChild = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2% 0%;
    width: 600px;
    padding: 75px 0px 90px 0px;
    border-radius: 20px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    
      h1, h2 {
            padding-bottom: 50px;
      }

`;

export const FormElement = styled.input`   
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #ebebeb;
    padding: 0 10px;
    transition: width 0.3s ease-in-out;
    outline: none;
    font-weight: bold;
    
    &:focus {
        width: 105%;
    }
`;

export const FormElementSelect = styled.select`   
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #ebebeb;
    padding: 0 10px;
    transition: width 0.3s ease-in-out;
    outline: none;
    font-weight: bold;
    
`;

export const ButtonCustom = styled.button`
    width: 100%;
    color: white;
    background-color: rgb(14, 191, 255);
    border: 1px solid rgb(0, 184, 252);
    border-radius: 10px;
    height: 50px;
    font-weight: bold;

    &:hover {
        background-color: rgb(40, 141, 180);
    }
`;
