import {AuthContainer, AuthContainerChild} from "../../components/auth/styles";
import TwoFactorAuth from "../../components/twoFactorAuth/TwoFactorAuth";

export const TwoFactorAuthPage = () => {


    return (
        <AuthContainer>
            <AuthContainerChild>
                <h2>Двухфакторная аунтификация</h2>
                <TwoFactorAuth/>
            </AuthContainerChild>
        </AuthContainer>
    )
}
