import {SignUp} from '../../components/auth/SignUp';
import {AuthContainer, AuthContainerChild} from "../../components/auth/styles";

export const SignUpPage = () => {


    return (
        <AuthContainer>
            <AuthContainerChild>
                <h1>Регистрация</h1>
                <SignUp/>
            </AuthContainerChild>
        </AuthContainer>
    )
}
