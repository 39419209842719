import styled from 'styled-components';

export const CatalogGridImageWrapper = styled.div`
    width: initial;
    height: 200px;
    background-color: #f6f6f6
`;

export const FavIconWrapper = styled.div`
    position: absolute;
    right: 5px;
`;

export const ProcudtBage = styled.span<{ bageColor?: string }>`
    color: ${p => (p.bageColor ? "white" : "rgb(95, 101, 87)")};
    background-color: ${p => (p.bageColor || "rgb(211, 230, 188)")}

`;

export const ProductCard = styled.div`
    width: 18rem;
    margin-top: -1px;
    margin-left: -1px;
    border: 2px solid #f6f6f6 !important;
    border-radius: 5px;
`;

export const ProductTitle= styled.h5`
    margin-bottom: 30px;
`;
