import {ProcudtBage} from "../catalogGrid/catalogCard/styles";
import * as React from "react";

export const ToOrder = (props: any) => {
    return (
        <>
            {(
                    props.productData.is_for_order &&
                    props.productData.is_display_stock_balance_on_portal
                ) && (
                    <div className="m-1 d-flex gap-1">
                        <ProcudtBage className={"badge"}>
                            Под заказ
                        </ProcudtBage>
                        {(
                            props.productData.days_qty > 0 &&
                            props.productData.days_qty <= 50
                        ) && (
                            <ProcudtBage className={"badge"}>
                                Дней: {props.productData.days_qty}
                            </ProcudtBage>
                        )
                        }
                    </div>
                )}
        </>
    )
}