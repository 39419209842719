import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {verifyTwoFactorCode} from '../../api/twoFactorAuth';
import {ROUTE_URLS} from "../../constants/consts";
import {ButtonCustom} from "../auth/styles";
import TextField from "@mui/material/TextField";
import {setIsTwoFactorAuthorized} from "../../api/sessionManagement";
import Swal from "sweetalert2";

const TwoFactorAuth: React.FC = () => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const optionsForAlertWithInvalidCode: any = {
        title: 'Упс...',
        text: 'Код неверный!',
        icon: 'error',
        confirmButtonText: 'ОК',
        confirmButtonColor: '#0ebfff'
    }
    const optionsForAlertSomethingWentWrong: any = {
        title: 'Упс...',
        text: 'Что-то пошло не так!',
        icon: 'error',
        confirmButtonText: 'ОК',
        confirmButtonColor: '#0ebfff'
    }


    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            const response = await verifyTwoFactorCode(code);

            if (response.data.data) {
                setIsTwoFactorAuthorized(true);
                navigate(ROUTE_URLS.HOME);
            } else {
                Swal.fire(optionsForAlertWithInvalidCode).then(() => {
                });
            }
        } catch (error) {
            Swal.fire(optionsForAlertSomethingWentWrong).then(() => {
            });
        }
    };

    const textFieldStyles = {
        transition: 'width 0.3s ease-in-out',
        width: '100%', // Стандартная ширина
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px', // Добавление скругленных углов для соответствия макету
            backgroundColor: '#ebebeb', // Цвет фона
            padding: "0 10px",
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                border: 'none',
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        },
        '&:focus-within': {
            width: "calc(100% + 20px)",
        }
    }

    const inputPropsStyles = {
        '&::placeholder': {
            color: '#757575',
            fontWeight: 'bold',
        },
        '& .MuiInputBase-input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px #ebebeb inset',
            WebkitTextFillColor: '#000000',
            transition: 'background-color 5000s ease-in-out 0s',
        },
        '& .MuiInputBase-input': {
            color: '#000000',
            fontWeight: 'bold',
        },
    };

    const inputLabelPropsStyles = {
        color: '#757575',
        fontWeight: 'bold',
        '&.Mui-focused': {
            color: '#757575',
        },
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>

                <TextField
                    value={code}
                    required
                    type="code"
                    className="auth-input"
                    label="Code"
                    onChange={(e) => setCode(e.target.value)}
                    InputProps={{
                        sx: inputPropsStyles,
                    }}
                    InputLabelProps={{
                        sx: inputLabelPropsStyles,
                    }}
                    sx={textFieldStyles}
                    autoComplete="off"
                    style={{marginBottom: "15px"}}
                />
                <ButtonCustom type="submit">
                    Войти
                </ButtonCustom>
            </form>
            {error && <p>{error}</p>}
        </div>
    );
};

export default TwoFactorAuth;
