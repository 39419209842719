import React, {useContext, useEffect, useState} from "react";
import {OrderTableTr} from "./OrderTableTr"
import {useAppSelector} from "../../../../hooks/ReduxHooks";
import {OrderLinesContext} from "../../../../hooks/UseOrderLinesSelectContext";
import {checkboxStyleSM} from "../../../catalog/catalogControl/style";
import Checkbox from "@mui/material/Checkbox";
import {Trans} from "react-i18next";

export const OrderTable = () => {
    const [isAllChecked, setIsAllChecked] = useState(false)
    const {selectedLines, setSelectedLines, lines} = useContext(OrderLinesContext);
    const {orderData} = useAppSelector(state => state.order);


    useEffect(() => {
        if (lines && lines.length > 0) {
            const allChecked = lines.every(line => selectedLines.includes(line.id));
            setIsAllChecked(allChecked);
        }
    }, [lines, selectedLines]);


    const handleSelectAll = (e: { target: { checked: any; }; }) => {
        if (!orderData) return;
        const checked = e.target.checked;
        setIsAllChecked(checked);

        if (checked) {
            setSelectedLines(orderData.order_line.map(el => el.id));
        } else {
            setSelectedLines([]);
        }
    };

    const handleChildCheckboxChange = (id: number, checked: any) => {
        const updatedSelectedLines = checked
            ? [...selectedLines, id]
            : selectedLines.filter((lineId) => lineId !== id);

        setSelectedLines(updatedSelectedLines);

        const allChecked = updatedSelectedLines.length === lines.length;
        setIsAllChecked(allChecked);
    };


    return (orderData ?
            <>
                <div>
                    <table className="table table-sm order__table" id="sales_order_table">
                        <thead className="align-top">
                        <tr>
                            <th scope="col">
                                <Checkbox
                                    checked={isAllChecked}
                                    sx={checkboxStyleSM}
                                    value={""}
                                    id="AllCartProductsCheck"
                                    onChange={handleSelectAll}
                                />
                            </th>
                            <th className="text-start text-colored" id="product_name_header">
                                <Trans i18nKey="product"/>
                            </th>
                            <th className="text-start text-colored" id="product_name_header">
                                <Trans i18nKey="supplier_code"/>
                            </th>
                            <th className="text-start text-colored" id="product_qty_header">
                                <Trans i18nKey="quantity_ordered" components={{br: <br/>}}/>
                            </th>
                            <th className="text-start text-colored" id="product_qty_header">
                                <Trans i18nKey="quantity_confirmed" components={{br: <br/>}}/>
                            </th>
                            {orderData.is_reserve && (
                                <th className="text-center text-colored" id="product_qty_header">
                                    <Trans i18nKey="to_order"/>
                                </th>
                            )}
                            <th className="text-center text-colored d-sm-table-cell">
                                <Trans i18nKey="price"/>
                            </th>
                            <th className="text-center text-colored d-sm-table-cell">
                                <Trans i18nKey="vat"/>
                            </th>
                            <th className="text-start text-colored">
                                <Trans i18nKey="cost_excl_vat" components={{br: <br/>}}/>
                            </th>
                            <th className="text-start text-colored">
                                <Trans i18nKey="cost_incl_vat" components={{br: <br/>}}/>
                            </th>
                            {orderData.is_reserve ? (
                                <>
                                    <th className="text-start text-colored" id="reservation_date">
                                        <Trans i18nKey="reservation_date" components={{br: <br/>}}/>
                                    </th>
                                     <th className="text-start text-colored" id="reservation_date">
                                        <Trans i18nKey="reservation_deadline" components={{br: <br/>}}/>
                                    </th>
                                </>
                                ) : (
                                <>
                                    <th className="text-start text-colored" id="product_discount">
                                        <Trans i18nKey="discount_percentage" components={{br: <br/>}}/>
                                    </th>
                                    <th className="text-start text-colored" id="product_discount_amount">
                                        <Trans i18nKey="discount_amount" components={{br: <br/>}}/>
                                    </th>
                                </>
                            )}
                            {orderData.is_pre_order && (
                                <>
                                    <th className="text-start text-colored" id="product_discount">
                                        <Trans i18nKey="order_date" components={{br: <br/>}}/>
                                    </th>
                                    <th className="text-start text-colored" id="product_discount_amount">
                                        <Trans i18nKey="dpd_to_warehouse" components={{br: <br/>}}/>
                                    </th>
                                </>
                            )}
                        </tr>

                        </thead>
                        <tbody>
                        {lines && lines.map((child) =>
                            <OrderTableTr
                                orderLine={child}
                                orderLines={lines}
                                currency={orderData.currency}
                                isReserve={orderData.is_reserve}
                                isPreOrder={orderData.is_pre_order}
                                onCheckboxChange={handleChildCheckboxChange}
                            />
                        )}
                        </tbody>
                    </table>
                </div>
            </> : <></>
    )
}