import {useCallback, useMemo, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import {useOutsideClick} from "../../../../../hooks/useOutsideClick.hook";

interface UseLanguagePickerReturnValues {
    isLanguageMenuOpen: boolean;
    languages: Array<Record<string, string>>;
    handleDropdownClick: VoidFunction;
    handleChangeLanguage: (language: string) => () => void;
    currentLanguage: Record<string, string>;
    ref: React.RefObject<HTMLDivElement> | null;
}

export const useLanguagePicker = (): UseLanguagePickerReturnValues => {
    const ref = useRef<HTMLDivElement>(null);
    const { i18n } = useTranslation();
    const [isLanguageMenuOpen, setLanguageMenuOpen] = useState<boolean>(false);
    const languages = useMemo(() => ([
        {
            label: 'LV',
            value: 'lv-LV',
        },
        {
            label: 'EN',
            value: 'en-UK',
        },
        {
            label: 'RU',
            value: 'ru-RU',
        },
        {
            label: 'LT',
            value: 'lt-LT',
        },
        {
            label: 'PL',
            value: 'pl-PL',
        },
        {
            label: 'ET',
            value: 'et-EE',
        },
    ]), []);

    const handleDropdownClick = useCallback(() => {
        setLanguageMenuOpen(prev => !prev);
    }, []);

    useOutsideClick(ref, handleDropdownClick, isLanguageMenuOpen)

    const handleChangeLanguage = (language: string) => () => {
        i18n.changeLanguage(language);
        window.location.reload()
    };

    const currentLanguage = useMemo(() => {
        const prefixMatch = languages.find(lang => i18n.language.startsWith(lang.value.split('-')[0]));
        const exactMatch = languages.find(lang => i18n.language === lang.value);

        if (prefixMatch) {
            return prefixMatch;
        }

        if (exactMatch) {
            return exactMatch;
        }

        return languages[0];
    }, [i18n.language, languages]);

    return { isLanguageMenuOpen, languages, currentLanguage, handleChangeLanguage, handleDropdownClick, ref }
}
