import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {WarrantyRequestsType} from "../../components/catalog/types";
import {fetchBackendWarrantyRequests} from "../../api/warranty";

export type initialStateType = {
    isRequestsLoading: boolean;
    requestsData: WarrantyRequestsType | undefined
}

const initialState: initialStateType = {
    requestsData: undefined,
    isRequestsLoading: true,
}

export const fetchWarrantyRequests =
    createAsyncThunk("slice/fetchWarrantyRequests",
        async (values: {pageNum: number, isActive?: boolean}) => {
            const {pageNum, isActive} = values
            const result = await fetchBackendWarrantyRequests(pageNum, isActive)
            return result.data?.data
        })
export const resetWarrantyRequests =
    createAsyncThunk("slice/resetWarrantyRequests",
        async () => initialState
    )

const warrantyRequestsSliceReducer = createSlice({
    name: "returns",
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchWarrantyRequests.fulfilled, (state, action) => {
            state.requestsData = action.payload
            state.isRequestsLoading = false
        }).addCase(resetWarrantyRequests.fulfilled, (state, action) => {
            Object.assign(state, initialState);
            state.isRequestsLoading = false;
        })
    }
})

export default warrantyRequestsSliceReducer.reducer
