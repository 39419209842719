// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.graphs__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
}
.graphs__fin_data {
  width: 400px;
}
.graphs__table_data {
  flex: 1 0;
}

.orders_info__wrapper {
  overflow: scroll;
}`, "",{"version":3,"sources":["webpack://./src/pages/homePage/HomePage.sass"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,eAAA;EACA,SAAA;EACA,8BAAA;EACA,mBAAA;AAAJ;AAEE;EACE,YAAA;AAAJ;AAEE;EACE,SAAA;AAAJ;;AAIE;EACE,gBAAA;AADJ","sourcesContent":[".graphs\n  &__wrapper\n    display: flex\n    flex-wrap: wrap\n    gap: 20px\n    justify-content: space-between\n    margin-bottom: 20px\n\n  &__fin_data\n    width: 400px\n\n  &__table_data\n    flex: 1 0\n\n\n.orders_info\n  &__wrapper\n    overflow: scroll"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
