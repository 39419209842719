import * as React from "react";
import {memo} from "react";

interface TableRowImageProps {
    src: string;
    handleOnClickImage: () => void;
}


export const TableRowImage = memo((props: TableRowImageProps) => {

    const {src, handleOnClickImage} = props
    return (
        <img loading="lazy"
             src={src}
             className="object-fit-contain rounded float-left"
             alt="..."
             onClick={handleOnClickImage}
        />
    )
})