import { createSlice } from '@reduxjs/toolkit';

interface PreviewProductSliceState {
    previewProductId : number | undefined
}

const initialState: PreviewProductSliceState = {
    previewProductId: undefined
};

const previewProductSlice = createSlice({
    name: "previewProduct",
    initialState,
    reducers: {
        setPreviewProduct: (state,action) => {
            state.previewProductId = action.payload
        }



    }
});

export const { setPreviewProduct} = previewProductSlice.actions;
export default previewProductSlice.reducer;
