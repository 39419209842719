import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchSingleReturnRequests} from "../../api/returns";
import {ReturnType, WarrantyRequestType} from "../../components/catalog/types";
import {fetchSingleWarrantyRequests} from "../../api/warranty";

export type initialStateType = {
    isWarrantyRequestLoading: boolean;
    warrantyRequestData: WarrantyRequestType | undefined

}
const initialState: initialStateType = {
    warrantyRequestData: undefined,
    isWarrantyRequestLoading: true
}

export const fetchSingleRequest =
    createAsyncThunk("slice/fetchSingleWarrantyRequest",
        async (warrantyId: number) => {
            const result = await fetchSingleWarrantyRequests(warrantyId)
            return result.data?.data
        })
export const resetRequest =
    createAsyncThunk("slice/resetSingleWarrantyRequest",
        async () => initialState
    )

const warrantyRequestSliceReducer = createSlice({
    name: "warranty",
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchSingleRequest.fulfilled, (state, action) => {
            state.warrantyRequestData = action.payload
            state.isWarrantyRequestLoading = false
        }).addCase(resetRequest.fulfilled, (state, action) => {
            Object.assign(state, initialState);
            state.isWarrantyRequestLoading = false;
        })
    }
})

export default warrantyRequestSliceReducer.reducer