import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import {fetchReturns, resetReturns} from "../../../store/slices/ReturnsReducerSlice";
import {Loader} from "../../loader/Loader";
import {WarrantyRequestsLineType} from "../../catalog/types";
import {BasePagination} from "../../pagination/Pagination";
import {updatePage} from "../../../store/slices/PagePaginagionSlice";
import "./WarrantyRequestsHistoryTable.sass"
import {RETURN_STATE, ROUTE_URLS, WARRANTEE_STATE} from "../../../constants/consts";
import {Link} from "react-router-dom";
import { updateBackendReturn} from "../../../api/returns";
import {fetchWarrantyRequests, resetWarrantyRequests} from "../../../store/slices/WarrantyRequestsReducerSlice";
import {updateBackendWarrantyRequests} from "../../../api/warranty";
import {t} from "i18next";


interface WarrantyRequestsHistoryTableProps {
    isActive?: boolean
}

export const WarrantyRequestsHistoryTable = (props: WarrantyRequestsHistoryTableProps) => {
    const {isActive} = props
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const {requestsData, isRequestsLoading} = useAppSelector(state => state.warrantyRequests)
    const {page} = useAppSelector(state => state.page)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(updatePage(1))
    }, [isActive]);

    useEffect(() => {
        dispatch(fetchWarrantyRequests({pageNum: page, isActive: isActive}))
    }, [isActive, page]);

    useEffect(() => {
        setIsLoading(isRequestsLoading)
    }, [isRequestsLoading]);

    useEffect(() => {
        return () => {
            dispatch(resetWarrantyRequests())
        }
    }, []);

    const handleCancel = async (line: WarrantyRequestsLineType) => {
        const data = {
          "id": line.id,
          "guarantee_state": WARRANTEE_STATE.CANCEL
        }
        let result = await updateBackendWarrantyRequests(data)
            if (result.error) return
            dispatch(fetchWarrantyRequests({pageNum: page, isActive: isActive}))
    }

    return <>
        {isLoading ? (
            <Loader/>
        ) : (requestsData &&
            <>
                <div className="returnHistoryTable__wrapper">
                    <table className="table returnHistoryTable">
                        <thead className="returnHistoryTable">
                        <tr>
                            <th scope="col" className="text-colored text-start returnHistoryTable__number">{t('request_number')}</th>
                            <th scope="col" className=" text-colored text-start returnHistoryTable__date">{t('application_date')}</th>
                            <th scope="col" className=" text-colored text-start returnHistoryTable__created">{t('created_by')}</th>
                            <th scope="col" className=" text-colored text-start returnHistoryTable__created">{t('product')}</th>
                            <th scope="col" className=" text-colored text-start returnHistoryTable__created">{t('defect')}</th>
                            <th scope="col" className=" text-colored text-start returnHistoryTable__contact">{t('contact_person')}
                            </th>
                            <th scope="col" className=" text-colored text-start returnHistoryTable__phone">{t('phone')}</th>
                            <th scope="col" className=" text-colored text-start returnHistoryTable__address">{t('delivery_address')}</th>
                            <th scope="col" className=" text-colored text-start returnHistoryTable__state">{t('status')}</th>
                            {isActive && (
                                <th scope="col" className=" text-colored text-start returnHistoryTable__btns"></th>
                            )}
                        </tr>
                        </thead>
                        <tbody className="returnTable__body">
                        {
                            requestsData.lines_data.map((line) => (
                                <tr>
                                    <td scope="text-start">
                                        <Link
                                            className="returnHistoryTable__link"
                                            to={ROUTE_URLS.WARRANTIES + `/${line.id}`}
                                        >
                                            <p className="text-start fw-bold">{line.name}</p>
                                        </Link>
                                    </td>
                                    <td scope="text-start">{line.create_date.split(" ")[0]}</td>
                                    <td scope="text-start">{line.responsible_user}</td>
                                    <td scope="text-start">{line.product_id.name}</td>
                                    <td scope="text-start">{line.defect_id.name}</td>
                                    <td scope="text-start">{line.contact_person}</td>
                                    <td scope="text-start">{line.contact_person}</td>
                                    <td scope="text-start"></td>
                                    <td scope="text-start">{line.guarantee_state}</td>
                                    {isActive && (
                                        <td scope="text-start">
                                            <button type="button" className="btn btn-sm"
                                                    onClick={() => handleCancel(line)}>
                                                <i className="bi bi-trash"/>
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            ))
                        }

                        </tbody>
                    </table>
                    <div>
                        {requestsData.pages_count > 1 && <BasePagination count={requestsData.pages_count}/>}
                    </div>
                </div>

            </>
        )}

    </>
}