import {ProcudtBage} from "../catalogGrid/catalogCard/styles";
import * as React from "react";
import {Trans} from "react-i18next";

export const ToOrder = (props: any) => {
    return (
        <>
            {(
                    props.productData.is_for_order &&
                    props.productData.is_display_stock_balance_on_portal
                ) && (
                    <div className="m-1 d-flex gap-1">
                        <ProcudtBage className={"badge"}>
                            <Trans i18nKey="to_order"/>
                        </ProcudtBage>
                        {(
                            props.productData.days_qty > 0 &&
                            props.productData.days_qty <= 50
                        ) && (
                            <ProcudtBage className={"badge"}>
                                <Trans i18nKey="days"/>: {props.productData.days_qty}
                            </ProcudtBage>
                        )
                        }
                    </div>
                )}
        </>
    )
}