import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchFinTermsRequests} from "../../api/financialTerms";

export type FinTermsType = {
  partner_credit_limit: number,
  partner_credit_limit_days: number,
  available_credit_limit: number,
  used_credit_limit: number,
  reservation_limit_day: number,
  reservation_limit_cash: number,
  posted_orders_sum: number,
  delayed_orders_sum: number,
  invoice_total: number,
  invoice_total_overdue: number,
  upcoming_invoice_date: string,
  upcoming_invoice_amount: number,
  used_reservation_limit: number,
  penalties: number,
  unpaid_credit_invoice: number,
  credit_invoice_total: number,
  overpayment: number,
  historical_data: 0
}

export type ProductItemSliceStateType = {
    terms: FinTermsType | undefined,
    isTermsLoading: boolean,
}

const initialState:  ProductItemSliceStateType = {
    terms: undefined,
    isTermsLoading: true,
}

export const fetchFinTerms =
    createAsyncThunk("slice/fetchTerms",
        async () => {
            const data = await fetchFinTermsRequests()
            return data?.data?.data
        }
    )

const finTermsSliceReducer = createSlice({
    name: 'finTerms',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchFinTerms.fulfilled, (state, action) => {
            state.terms = action.payload
            state.isTermsLoading = false
        })
    }
})

export default finTermsSliceReducer.reducer
