import axios from "axios";
import { resolve } from "./resolve.js";
import {API_BACKEND_URL, DATE_RANGE, RETURN_STATE} from "../constants/consts";
import {getSession} from "./sessionManagement";
import {Dayjs} from "dayjs";
import {getExchangeHeaders} from "./helpers";

export type CreateReturnDataType = {
    responsible_user_id?: number | undefined;
    lines: {
        id: number | undefined;
        quantity: number;
        state: string | undefined;
        comment: string | undefined;
    }[];
}

export type UpdateReturnDataType = {
    id: number;
    return_state?: RETURN_STATE;
    departure_number?: string;
    delivery_type?: string;
    pickup_address_id?: number;
}

export async function fetchReturnsRequests(pageNum: number, isActive: boolean = false) {
    const sessionId = getSession()
    const additionalParams = `?is_active=${isActive}`
    const URL = `${API_BACKEND_URL}/sales/returns/page/${pageNum}${additionalParams}`
    return await resolve(axios.get(URL,
            {
                headers: getExchangeHeaders(),
            }
        ).then(res => res)
    );
}

export async function fetchSingleReturnRequests(returnId: Number) {
    const sessionId = getSession()
    const URL = `${API_BACKEND_URL}/sales/returns/${returnId}`
    return await resolve(axios.get(URL,
            {
                headers: getExchangeHeaders(),
            }
        ).then(res => res)
    );
}

export async function fetchReturnHistory(params: { groupby: DATE_RANGE; dateFrom: Dayjs | null; dateTo: Dayjs | null; }) {
    const {groupby, dateFrom, dateTo} = params
    const sessionId = getSession()
    const URL = `${API_BACKEND_URL}/sales/get_return_history`
    let data = {
        groupby: groupby || "day",
    }
    if (dateFrom && dateTo && dateFrom.isValid() && dateTo.isValid()) {
        Object.assign(data, {
            date_from: dateFrom.format('DD/MM/YYYY'),
            date_to: dateTo.format('DD/MM/YYYY'),
        })
    }
    return await resolve(axios.post(URL,
        data,
        {
            headers: getExchangeHeaders(),
        }
    ).then(res => res)
    );
}

export async function createBackendReturn(data: CreateReturnDataType) {
    const URL = `${API_BACKEND_URL}/sales/create_return`
    return await resolve(axios.post(URL,
        data, {
            headers: getExchangeHeaders(),
        }
    ).then(res => res));
}

export async function updateBackendReturn(data: UpdateReturnDataType){
    const URL = `${API_BACKEND_URL}/sales/returns/update_return`
    return await resolve(axios.patch(URL,
        data,{
            headers: getExchangeHeaders(),
        }
    ).then(res => res));
}

