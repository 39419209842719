import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import * as React from "react";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {ROUTE_URLS} from "../../constants/consts";

export const SEPARATOR = " / "
export const lastBreadcrumbStyles = {color: "#6ed1f3", cursor: "default", margin: "0 5px"};
export type BreadcrumbsType = Array<{ id: string; name: string }>;
// @ts-ignore
const DynamicCategoryBreadcrumb = ({match}) => {
    const categoryId = +match.params.categoryId;
    const hierarchyCategory = useSelector((state: any) => state.hierarchyCategory.data);
    const {product} = useAppSelector(state => state.product)

    const findCategoryBreadcrumbs = (
        categories: any,
        categoryId: number,
        breadcrumbs: BreadcrumbsType = []
    ): BreadcrumbsType | null => {
        if (categories && categories.length > 0) {
            for (const category of categories) {
                const newBreadcrumbs: BreadcrumbsType = [...breadcrumbs, {id: category.id, name: category.name}];

                if (category.id === categoryId) {
                    return newBreadcrumbs;
                }

                if (category.child_ids && category.child_ids.length > 0) {
                    const result = findCategoryBreadcrumbs(category.child_ids, categoryId, newBreadcrumbs);
                    if (result) return result;
                }
            }
        }


        return null;
    };

    const categoryBreadcrumbs = findCategoryBreadcrumbs(hierarchyCategory, categoryId);
    const lastCategoryBreadcrumbId = categoryBreadcrumbs?.[categoryBreadcrumbs?.length - 1]?.id;

    return (
        <>
            {categoryBreadcrumbs?.map((category: any) => (
                <NavLink key={category?.name} to={`/products/${category?.id}`}
                         style={({isActive}) => ({
                             color: isActive && !product ? '#808084' : '#6ed1f3',
                             textDecoration: 'none',
                             cursor: isActive && !product ? "default" : "pointer",

                         })}>
                    {category?.name}
                    {lastCategoryBreadcrumbId !== category?.id &&
                        <span style={lastBreadcrumbStyles}><b>{SEPARATOR}</b></span>}
                </NavLink>
            ))}
        </>
    );
};

const DynamicCategoryProductBreadcrumb = () => {
    const {product} = useAppSelector(state => state.product)

    return (
        <NavLink key={product?.name} to=""
                         style={({isActive}) => ({
                             color: isActive ? '#808084' : '#6ed1f3',
                             textDecoration: 'none',
                             cursor: isActive ? "default" : "pointer",

                         })}>
                    {product?.name}
                </NavLink>
    );
};

export const breadcrumbsRoutes = [
        {path: ROUTE_URLS.HOME, breadcrumb: "Главная"},
        {path: ROUTE_URLS.CATALOG, breadcrumb: "Каталог"},
        {path: ROUTE_URLS.CATALOG_CATEGORY, breadcrumb: DynamicCategoryBreadcrumb},
        {path: ROUTE_URLS.PRODUCT, breadcrumb: ""},
        {path: ROUTE_URLS.PRODUCT_FROM_CATEGORY, breadcrumb: DynamicCategoryProductBreadcrumb},
        {path: ROUTE_URLS.CART, breadcrumb: "Корзина"},
        {path: ROUTE_URLS.ORDERS, breadcrumb: "Заказы"},
        {path: ROUTE_URLS.ORDER, breadcrumb: ""},
        {path: ROUTE_URLS.RESERVES, breadcrumb: "Резервации"},
        {path: ROUTE_URLS.RESERVE, breadcrumb: ""},
        {path: ROUTE_URLS.FIN_TERMS, breadcrumb: "Финансы"},
        {path: ROUTE_URLS.RETURNS, breadcrumb: "Возврат продукта"},
        {path: ROUTE_URLS.RETURN, breadcrumb: ""},
    ];