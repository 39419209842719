import React, {useContext, useEffect, useState} from "react";
import {CurrencyType, SaleOrderLineType} from "../../../catalog/types";
import {OrderLinesContext} from "../../../../hooks/UseOrderLinesSelectContext";
import {checkboxStyleSM} from "../../../catalog/catalogControl/style";
import Checkbox from "@mui/material/Checkbox";
import "./OrderTable.sass"
import {MessageContext} from "../../../../hooks/UseMessage";
import {MESSAGE_VARIANT} from "../../../../constants/consts";

export interface OrderTableProps {
    orderLine : SaleOrderLineType,
    orderLines : SaleOrderLineType[],
    currency?: CurrencyType,
    isReserve?: boolean,
    onCheckboxChange: any;
}

export const OrderTableTr = (props: OrderTableProps) => {
    const {orderLine, orderLines, currency, isReserve} = props
    const [toOrder, setToOrder] = useState<number | "">(orderLine.product_uom_qty)
    const { selectedLines, setSelectedLines, setLines} = useContext(OrderLinesContext)
    const [isChecked, setIsChecked] = useState(selectedLines.includes(orderLine.id))
    const {showMessage} = useContext(MessageContext)

    useEffect(() => {
        setIsChecked(selectedLines.includes(orderLine.id))
    }, [selectedLines]);


    const handleLineCheck = () => {
        if (selectedLines.includes(orderLine.id)) {
            setSelectedLines(selectedLines.filter((item) => item !== orderLine.id));
        } else {
            setSelectedLines([...selectedLines, orderLine.id]);
        }
        props.onCheckboxChange(orderLine.id, !isChecked);
    }

    const handleChangeToOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        if (!value) {
            setToOrder("")
            return;
        }
        const numValue = Number(value)
        if (isNaN(numValue)) return
        if (numValue >= 0 && numValue <= orderLine.product_uom_qty) {
            setToOrder(numValue)
            setLines(orderLines.map(row => (row.id === orderLine.id ? { ...row,  toOrder: numValue} : row)))
        } else {
            showMessage({
                variant: MESSAGE_VARIANT.WARNING,
                text: `Недопустимое количество ${value}!`
            })
        }
    }


    return (
        <tr className="so__row" key={`order_line_${orderLine.id}`}>
            <td scope="row">
                <Checkbox
                    checked={isChecked}
                    sx={checkboxStyleSM}
                    value={""}
                    onChange={handleLineCheck}
                />
            </td>
            <td><span className="d-block text-wrap">{orderLine?.product_id?.name}</span>
            </td>
            <td>
                <span className="d-block text-wrap"></span>
            </td>
            <td className="text-center">
                <span>{orderLine.requested_qty}</span>
            </td>
            <td className="text-center">
                <span>{orderLine.product_uom_qty}</span>
            </td>
            {isReserve && (
                <td className="text-center">
                    <input
                        className="to_order"
                        type={"text"}
                        value={toOrder}
                        onChange={handleChangeToOrder}/>
                </td>
            )}
            <td className="text-end d-none d-sm-table-cell priceCell">
                <div className=" text-end">{orderLine.price_unit} {currency && currency.symbol}
                </div>
            </td>
            <td className="text-end d-none d-sm-table-cell taxCell">
                <div className=" text-end">{orderLine.tax_id && orderLine.tax_id.name}
                </div>
            </td>
            <td className="text-end" id="subtotal">
                <span className="oe_order_line_price_subtotal"><span
                    className="oe_currency_value">{orderLine.price_total} {currency && currency.symbol}</span></span>
            </td>
            <td className="text-end" id="subtotal">
                <span className="oe_order_line_price_subtotal"><span
                    className="oe_currency_value">{orderLine.price_total} {currency && currency.symbol}</span></span>
            </td>
            {!isReserve && (
                <>
                    <td className="text-end">{orderLine.discount}</td>
                    <td className="text-end">{(orderLine.discount/100) * orderLine.price_subtotal} {currency && currency.symbol}</td>
                </>
            )}
        </tr>
    )
}