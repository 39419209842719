// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.financialData {
  display: grid;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  flex: 1 1;
  padding: 20px;
  height: 100%;
}
.financialData__row .title {
  font-size: 12pt;
  font-weight: 700 !important;
}
.financialData__row .bold-text {
  font-size: 14pt;
  font-weight: 700 !important;
}
.financialData__row .small-text {
  color: gray;
  font-size: 10pt;
}
.financialData__row__item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;
}
.financialData__row__item:first-child {
  font-weight: 500;
  flex: 1 0 60%;
}
.financialData__row__item:last-child {
  font-weight: 500;
  flex: 0 0 35%;
}`, "",{"version":3,"sources":["webpack://./src/components/graphs/financailData/FinancialData.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,yHAAA;EACA,SAAA;EACA,aAAA;EACA,YAAA;AACF;AAII;EACE,eAAA;EACA,2BAAA;AAFN;AAII;EACE,eAAA;EACA,2BAAA;AAFN;AAII;EACE,WAAA;EACA,eAAA;AAFN;AAII;EACE,aAAA;EACA,uBAAA;EACA,8BAAA;EACA,mBAAA;AAFN;AAKM;EACE,gBAAA;EACA,aAAA;AAHR;AAKM;EACE,gBAAA;EACA,aAAA;AAHR","sourcesContent":[".financialData\n  display: grid\n  border-radius: 4px\n  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)\n  flex: 1\n  padding: 20px\n  height: 100%\n\n\n  &__row\n\n    .title\n      font-size: 12pt\n      font-weight: 700 !important\n\n    .bold-text\n      font-size: 14pt\n      font-weight: 700 !important\n\n    .small-text\n      color: gray\n      font-size: 10pt\n\n    &__item\n      display: flex\n      align-items: flex-start\n      justify-content: space-between\n      margin-bottom: 15px\n\n\n      &:first-child\n        font-weight: 500\n        flex: 1 0 60%\n\n      &:last-child\n        font-weight: 500\n        flex: 0 0 35%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
