import React, {useEffect} from "react";
import "./NewWarrantyRequestTable.sass"
import {NewWarrantyRequestDialog} from "../newWarrantyRequestDialog/NewWarrantyRequestDialog";
import {useAppSelector} from "../../../hooks/ReduxHooks";
import {getLinesData} from "../../../store/slices/NewWarrantySlice";
import {useWarrantyDefectTypes} from "../../../hooks/UseWarrantyDefectTypes";
import {Trans} from "react-i18next";
import {t} from "i18next";

interface TableRowProps {
    setOpen: (value: boolean) => void,
    handleClose: () => void,
    lineData: any,
}

export const SearchTableRow = (props: TableRowProps) => {

    const {setOpen, lineData} = props

    const handleAddRow = (lineData: any) => {
        setOpen(lineData)
    }

    return (
        <tr>
            <td scope="text-start">{lineData.order_name}</td>
            <td scope="text-start">{lineData.default_code}</td>
            <td scope="text-start">{lineData.barcode}</td>
            <td scope="text-start">{lineData.product_name}</td>
            <td scope="text-start">{lineData.waybill_number}</td>
            <td scope="text-start">{lineData.waybill_date}</td>
            <td scope="text-start">{lineData.quantity}</td>
            <td scope="text-start">{lineData.guarantee}</td>
            <td scope="text-start">{lineData.warranty_service}</td>
            <td scope="col" className="text-end newRequestTable__btns">
                <button type="button" className="btn btn-sm btn-search"
                        onClick={() => handleAddRow(lineData)}><Trans i18nKey="add"/>
                </button>
            </td>
        </tr>
    )

}


export const NewWarrantySearchTable = () => {


    const [openedLine, setOpenedLine] = React.useState<any>(undefined);

    const {warrantyLinesData} = useAppSelector(state => state.newWarranty)
    const {linesToWarranty} = useAppSelector(state => state.newWarranty)

    const [lines, setLines] = React.useState<getLinesData[]>();
    const defectTypes = useWarrantyDefectTypes()


    useEffect(() => {
        if (warrantyLinesData && linesToWarranty) {
            const result = warrantyLinesData.map(line2process => {
                const addedItems = linesToWarranty.filter(item => item.lineId === line2process.id);
                const addedQty = addedItems.reduce((n, {quantity}) => n + quantity, 0)
                return {
                    ...line2process,
                    quantity: (addedQty > 0) ? (line2process.quantity - addedQty) : line2process.quantity
                }
            }).filter(item => item.quantity > 0);
            setLines(result)
        } else {
            setLines(warrantyLinesData)
        }
    }, [linesToWarranty, warrantyLinesData]);


    const handleClose = () => {
        setOpenedLine(undefined);
    };


    return (
        <>
            <div style={{height: "20px"}}>
                {warrantyLinesData &&
                    <div className="d-flex search-banner w-100">
                        {t('search_banner_found_products', {count: warrantyLinesData.length})}
                    </div>
                }
            </div>
            <>
                <table className="table newRequestTable">
                    <thead className="newRequestTable">
                    <tr>
                        <th scope="col" className="text-secondary text-start newRequestTable__number">
                            <Trans i18nKey="order_number"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__code">
                            <Trans i18nKey="code"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__man_code">
                            <Trans i18nKey="manufacturer_code"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__name">
                            <Trans i18nKey="product_name"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__delivery">
                            <Trans i18nKey="invoice"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__bill_date">
                            <Trans i18nKey="invoice_date"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__qty">
                            <Trans i18nKey="quantity"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__price">
                            <Trans i18nKey="warranty_period"/>
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__total">
                            <Trans i18nKey="warranty_valid_until"/>
                        </th>
                        <th className="newRequestTable__btns"></th>


                    </tr>
                    </thead>
                    <tbody className="newRequestTable__body">
                    {lines && lines.map((el) => {
                            return (
                                <SearchTableRow setOpen={setOpenedLine}
                                                handleClose={handleClose}
                                                lineData={el}/>
                            )
                        }
                    )}

                    </tbody>
                </table>
                <NewWarrantyRequestDialog
                    defectTypes={defectTypes}
                    openedLine={openedLine}
                    handleClose={handleClose}
                />
            </>

        </>
    )
}