import React from "react";
import {
    CarouselImage,
    CarouselSectionContent,
    CarouselImageContainer,
} from "./styles";


import slideOne from "../../../../images/landing_slider_images/slide_one.jpeg";
import slideTwo from "../../../../images/landing_slider_images/slide_two.jpeg";
import slideThree from "../../../../images/landing_slider_images/slide_three.jpeg";
import slideFour from "../../../../images/landing_slider_images/slide_four.jpeg";
import slideFive from "../../../../images/landing_slider_images/slide_five.jpeg";
import slideSix from "../../../../images/landing_slider_images/slide_six.jpeg";
import slideSeven from "../../../../images/landing_slider_images/slide_seven.jpeg";
import slideAte from "../../../../images/landing_slider_images/slide_ate.jpeg";
import slideTan from "../../../../images/landing_slider_images/slide_tan.jpeg";


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


export const CarouselSection = () => {
    const responsive = {
        XLargeDesktop: {breakpoint: {max: 3000, min: 1700}, items: 5},
        largeDesktop: {breakpoint: {max: 1700, min: 1400}, items: 4},
        desktop: {breakpoint: {max: 1400, min: 1024}, items: 3},
        tablet: {breakpoint: {max: 1024, min: 768}, items: 2},
        mobile: {breakpoint: {max: 768, min: 576}, items: 2},
        smallMobile: {breakpoint: {max: 576, min: 0}, items: 1},
    };

    return (
        <CarouselSectionContent initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 1}}>
            <Carousel responsive={responsive} infinite autoPlay>
                {
                    [slideOne, slideTwo, slideThree, slideFour, slideFive, slideSix, slideSeven, slideAte, slideTan].map((src, index) => (
                        <CarouselImageContainer key={index} style={{padding: '0 10px'}}>
                            <CarouselImage src={src} alt={`Gallery ${index + 1}`}/>
                        </CarouselImageContainer>
                    ))}
            </Carousel>
        </CarouselSectionContent>
    );
}
