import styled from "styled-components";
import { motion } from "framer-motion";
import {MAX_ROWS, MOBILE_MAX_ROWS, systemGreyLight, systemWhite} from "../../constants";

export const ITEM_WIDTH = 160;
export const ITEM_HEIGHT = 83;

export const BrandsSectionContent = styled(motion.section)`
    padding: 50px 40px;

    @media (max-width: 768px) {
        padding: 30px 16px;
    }
`;

export const BrandsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${ITEM_WIDTH}px, 1fr));
    max-height: calc(${ITEM_HEIGHT}px * ${MAX_ROWS});
    overflow: hidden;
    gap: 0;
    margin-top: 50px;

    @media (max-width: 768px) {
        max-height: calc(${ITEM_HEIGHT}px * ${MOBILE_MAX_ROWS});
    }
`;


export const BrandItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${systemWhite};
    border: 1px solid ${systemGreyLight};
    min-height: ${ITEM_HEIGHT}px;
    text-align: center;
    margin: 0;
`;

export const BrandLogo = styled.img`
    max-width: ${ITEM_WIDTH}px;
    max-height: 81px;
`;

