import {Navigation, Pagination, Scrollbar, A11y} from "swiper/modules";

import {Swiper, SwiperSlide} from "swiper/react";


import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import 'swiper/css/pagination';
import {useState} from "react";
import {SwiperImage} from "./styles";

import "./SiwperProduct.sass"

interface SwiperProductProps {
    imageList?: string[]
}


export const SwiperProduct = (props: SwiperProductProps) => {
    const [swiperRef, setSwiperRef] = useState<typeof Swiper | any>({});
    const imageList = props.imageList
    const [activeImage, setActiveImage] = useState(imageList && imageList[0]);

    const handleSlideChange = (swiper: any) => {
        setActiveImage(imageList && imageList[swiper.activeIndex]);
    };

    const handleSlideClick = (index: number) => {
        if (!swiperRef) return
        swiperRef.slideTo(index)
    };

    return (
        <>
            <div className="product__image-wrapper" draggable={false}>
                <img loading="lazy" className="product__image" draggable={false} src={activeImage}/>
            </div>
            <Swiper
                onSlideChange={handleSlideChange}
                onSwiper={setSwiperRef}
                slidesPerView={2}
                centeredSlides={true}
                spaceBetween={2}
                navigation={true}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                className="prductSwiper"
                width={"50px"}
            >
                {imageList && imageList.map((img_url, index) => {
                    return <SwiperSlide
                            key={index}
                            draggable={false}
                            className={"product__swiper_slide"}
                            onClick={() => handleSlideClick(index)}
                        >
                        <SwiperImage
                            className={"product__swiper_img"}
                            loading={"lazy"}
                            src={img_url}
                        />
                    </SwiperSlide>
                })}
            </Swiper>

        </>
    );
};