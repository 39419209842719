import {useAppSelector} from "../../hooks/ReduxHooks";
import "./FinTerms.sass"

export const FinTerms = () => {

    const { terms} = useAppSelector(state => state.finTerms)

    if (!terms) return <></>

    return (
        <div className="terms__wrapper">
            <div style={{width: "100%", maxWidth: "500px"}}>
                <section className="terms__section">
                    <h3 className="terms__name">Кредитный лимит</h3>
                    <ul>
                        <li className="terms__option"><p>Кредитный лимит:</p><p
                            className="terms__field">{terms.partner_credit_limit} <i
                            className="bi bi-currency-euro"></i></p></li>
                        <li className="terms__option"><p>Кредитный лимит, дни:</p><p
                            className="terms__field">{terms.partner_credit_limit_days} <i
                            className="bi bi-currency-euro"></i></p></li>
                        <li className="terms__option"><p>Доступный кредитный лимит:</p>
                            <p className="terms__field">{terms.available_credit_limit}<i
                                className="bi bi-currency-euro"></i></p></li>
                        <li className="terms__option"><p>Использованный кредитный лимит:</p>
                            <p className="terms__field">{terms.used_credit_limit}<i className="bi bi-currency-euro"></i>
                            </p></li>
                    </ul>
                </section>
                <section className="terms__section">
                    <h3 className="terms__name">Резервации</h3>
                    <ul>
                        <li className="terms__option"><p>Лимит резервации, дней:</p><p
                            className="terms__field">{terms.reservation_limit_day}</p></li>
                        <li className="terms__option"><p>Лимит резервации, деньги:</p><p
                            className="terms__field">{terms.reservation_limit_cash}
                            <i className="bi bi-currency-euro"></i>
                        </p></li>
                    </ul>
                </section>
                <section className="terms__section">
                    <h3 className="terms__name">Заказы</h3>
                    <ul>
                        <li className="terms__option"><p>Сумма размещенных заказов (без НДС):</p><p
                            className="terms__field">{terms.posted_orders_sum}<i className="bi bi-currency-euro"></i>
                        </p></li>
                        <li className="terms__option"><p>Сумма задержанных заказов (без НДС):</p><p
                            className="terms__field">{terms.delayed_orders_sum}<i className="bi bi-currency-euro"></i>
                        </p></li>
                    </ul>
                </section>
            </div>
            <div style={{width: "100%", maxWidth: "500px"}}>
                <section className="terms__section">
                    <h3 className="terms__name">Оплата счетов</h3>
                    <ul>
                        <li className="terms__option"><p>Оплата счетов:</p><p
                            className="terms__field">{terms.invoice_total}<i className="bi bi-currency-euro"></i></p>
                        </li>
                        <li className="terms__option"><p>Сумма просроченных платежей::</p><p
                            className="terms__field">{terms.invoice_total_overdue}<i
                            className="bi bi-currency-euro"></i></p></li>
                        <li className="terms__option"><p>Срок оплаты счетов:</p><p
                            className="terms__field">{""}</p>
                        </li>
                        <li className="terms__option"><p>Штрафные санкции:</p><p
                            className="terms__field">{terms.penalties * 100}%</p>
                        </li>
                        <li className="terms__option"><p>Сумма неоплаченных счетов:</p><p
                            className="terms__field">{terms.unpaid_credit_invoice}
                            <i className="bi bi-currency-euro"></i>
                        </p></li>
                        <li className="terms__option"><p>Сумма кредитных счетов:</p><p
                            className="terms__field">{terms.credit_invoice_total}
                            <i className="bi bi-currency-euro"></i>
                        </p></li>
                        <li className="terms__option"><p>Переплата:</p><p className="terms__field">{terms.overpayment}</p>
                        </li>
                        <li className="terms__option"><p>Исторические данные по обороту:</p><p
                            className="terms__field">{terms.historical_data}
                            <i className="bi bi-currency-euro"></i>
                        </p></li>
                    </ul>
                </section>
            </div>


        </div>
    )
}