import { ChangeEvent, useCallback, useState, FormEvent, useEffect } from "react";
import { EMPTY_STRING } from "../../../../../../constants/consts";

export interface ContactFormData {
    name: string;
    phone: string;
    email: string;
    message: string;
}

interface UseContactFormReturnValues {
    handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
    handleCaptchaChange: (token: string | null) => void;
    formData: ContactFormData;
}

interface UseContactFormProps {
    isContactModalOpen: boolean;
}

export const useContactForm = ({ isContactModalOpen }: UseContactFormProps): UseContactFormReturnValues => {
    const [formData, setFormData] = useState<ContactFormData>({
        name: EMPTY_STRING,
        phone: EMPTY_STRING,
        email: EMPTY_STRING,
        message: EMPTY_STRING,
    });
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);

    useEffect(() => {
        if (!isContactModalOpen) {
            setCaptchaToken(null);
        }
    }, [isContactModalOpen]);

    const handleCaptchaChange = useCallback((token: string | null) => {
        setCaptchaToken(token);
    }, []);

    const handleChange = useCallback((
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    }, []);

    const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!captchaToken) {
            return;
        }

        console.log('Form submitted:', formData, 'Captcha token:', captchaToken);
    }, [captchaToken, formData]);

    return { formData, handleChange, handleSubmit, handleCaptchaChange };
}
