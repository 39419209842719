import {BaseBreadCrumbs, BreadcrumbsDataProps} from "../../components/breadcrumbs/Breadcrumbs";
import {ProductCard} from "../../components/productCard/ProductCard";
import {useLocation, useParams} from "react-router-dom";
import React, {useEffect, useState, useMemo} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {fetchProduct, resetProduct} from "../../store/slices/ProductReducerSlice";
import {Loader} from "../../components/loader/Loader";

export const ProductPage = () => {
    const { categoryId , id} = useParams();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch()

    const {isCartLoading} = useAppSelector(state => state.activeCart)
    const {product, isProductLoading} = useAppSelector(state => state.product)

    useEffect(() => {
        if (!isProductLoading && !isCartLoading)
            setIsLoading(false)
    }, [isProductLoading, isCartLoading]);

    useEffect(() => {
        if (id === null) return
        dispatch(fetchProduct(Number(id)))
        return () => {
            dispatch(resetProduct())
        }
    }, [id]);


    let pr: BreadcrumbsDataProps = {}
    pr[location.pathname] = product?.name || "Product"

    if (categoryId && product) {

        let categoryCategoryUrl = `/products/${categoryId}`
        const productCategory = product.product_b2b_category_ids.find((item: { id: number; name: string }) => item.id === Number(categoryId));
        pr[categoryCategoryUrl] =  productCategory?.name || "Category Product"
    }


    return (
        <>
            {isLoading ? (
                <Loader />
            ):(
                <>
                <BaseBreadCrumbs data={pr}/>
                <div className="wrapper">
                    <ProductCard/>
                </div>
            </>
            )
            }
        </>
    )
}