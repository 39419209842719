import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import * as React from "react";
import TextField from "@mui/material/TextField";
import { CloseIcon, CloseIconContainer } from "./styles";
import { useIsMobile } from "../../../../hooks/useIsMobile.hook";
import { DEFAULT_ANIMATION_DELAY } from "../../../../constants/consts";
import { Button } from "@mui/material";
import { useModalForm } from "./hooks/useModalForm.hook";

interface SubmitFormModalProps {
    isSubmitModalOpen: boolean;
    toggleModal: VoidFunction;
}

export const SubmitFormModal = ({ isSubmitModalOpen, toggleModal }: SubmitFormModalProps) => {
    const isMobile = useIsMobile();
    const { handleSubmit, formData, handleChange } = useModalForm();
    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "10px",
        border: 'none',
        p: 0,
        width: isMobile ? "90%" : "50%",
        maxHeight: "90vh",
        overflowY: "auto"
    };

    return (

        <Modal
            open={isSubmitModalOpen}
            onClose={toggleModal}
            className="products__modal"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                outline: 'none',
                '& .MuiBox-root': {
                    outline: 'none',
                },
            }}
        >
            <Fade in={isSubmitModalOpen} timeout={DEFAULT_ANIMATION_DELAY}>
                <Box sx={style}>
                    <CloseIconContainer onClick={toggleModal}>
                        <CloseIcon className="bi bi-x"></CloseIcon>
                    </CloseIconContainer>
                    <Box>
                        <Box
                            sx={{
                                pl: isMobile ? 2 : 4,
                                pr: isMobile ? 2 : 4,
                                pb: isMobile ? 3 : 4
                            }}
                            component="form"
                            onSubmit={handleSubmit}
                        >
                            <TextField
                                required
                                fullWidth
                                label="Company name"
                                name="сompany_name"
                                margin="normal"
                                variant="outlined"
                                value={formData.company_name}
                                onChange={handleChange}
                                sx={{ borderRadius: 2, backgroundColor: '#fff' }}
                            />
                            <TextField
                                required
                                fullWidth
                                label="Email"
                                name="email"
                                margin="normal"
                                variant="outlined"
                                value={formData.email}
                                onChange={handleChange}
                                sx={{ borderRadius: 2, backgroundColor: '#fff' }}
                            />
                            <TextField
                                required
                                fullWidth
                                label="Phone number"
                                name="email"
                                margin="normal"
                                variant="outlined"
                                value={formData.phone}
                                onChange={handleChange}
                                sx={{ borderRadius: 2, backgroundColor: '#fff' }}
                            />
                            <TextField
                                required
                                fullWidth
                                label="Phone number"
                                name="email"
                                margin="normal"
                                variant="outlined"
                                value={formData.brands}
                                onChange={handleChange}
                                sx={{ borderRadius: 2, backgroundColor: '#fff' }}
                            />
                            <Box sx={{ mt: 2, mb: 2 }} />
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                sx={{
                                    mt: 1,
                                    py: 1.5,
                                    fontWeight: 500,
                                    backgroundColor: '#006AFF',
                                    borderRadius: 1,
                                    '&:hover': {
                                        backgroundColor: '#006AFF',
                                        opacity: 0.9,
                                    },
                                }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )

}
