import React, {useEffect, useRef, useState} from "react";
import { Header } from "./components/header";
import { VideoSection } from "./components/videoSection";
import { BrandsSection } from "./components/brandsSection";
import { CategoriesSection } from "./components/categoriesSection";
import { AdvantagesSection } from "./components/advantagesSection";
import { ContactsSection } from "./components/contactsSection";
import { CarouselSection } from "./components/carouselSection";
import { AdditionalSection } from "./components/additionalSection";
import { Footer } from "./components/footer";


export const LandingPage = () => {
    const [showButtons, setShowButtons] = useState(false);
    const mainSectionRef = useRef(null);

    useEffect(() => {
        const container = document.querySelector('._container') as HTMLElement | null;

        if (container) {
            container.style.margin = '0';
            container.style.padding = '0';
        }

        const observer = new IntersectionObserver(
            ([entry]) => setShowButtons(!entry.isIntersecting),
            { threshold: 0.1 }
        );

        if (mainSectionRef.current) {
            observer.observe(mainSectionRef.current);
        }

        return () => observer.disconnect();
    }, []);

    return (
        <>
            <Header showButtons={showButtons} />
            <VideoSection mainSectionRef={mainSectionRef} />
            <BrandsSection />
            <CategoriesSection />
            <AdvantagesSection />
            <ContactsSection />
            <CarouselSection />
            <AdditionalSection />
            <Footer />
        </>
    );
}
