import {createSlice} from "@reduxjs/toolkit";
import {EMPTY_NUMBER, EMPTY_STRING} from "../../constants/consts";

interface UserState {
    email: string;
    password: string;
    role: string;
    id: number,
    companyName: string,
    vat: string,
    phoneNumber: string,
    registration: string,
    region: string,

}

const initialState: UserState = {
    email: EMPTY_STRING,
    password: EMPTY_STRING,
    role: EMPTY_STRING,
    id: EMPTY_NUMBER,
    companyName: EMPTY_STRING,
    vat: EMPTY_STRING,
    phoneNumber: EMPTY_STRING,
    registration: EMPTY_STRING,
    region: EMPTY_STRING,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser(state, action) {
            state.email = action.payload.email;
            state.password = action.payload.password;
            state.role = action.payload.role;
            state.id = action.payload.id;
            state.companyName = action.payload.companyName;
            state.vat = action.payload.vat;
            state.phoneNumber = action.payload.phoneNumber;
            state.registration = action.payload.registration;
            state.region = action.payload.region;
        },
        resetUser(state) {
            state.email = EMPTY_STRING;
            state.password = EMPTY_STRING;
            state.role = EMPTY_STRING;
            state.companyName = EMPTY_STRING;
            state.vat = EMPTY_STRING;
            state.phoneNumber = EMPTY_STRING;
            state.registration = EMPTY_STRING;
            state.region = EMPTY_STRING;
        },
    },
});

export const {setUser, resetUser} = userSlice.actions;

export default userSlice.reducer;
