import React from "react";

export const InputLoader = () => {
    return (
        <span>
            <l-tail-chase
                size="14"
                speed="1.75"
                color="#6ed1f3"
            />
        </span>
    )
}