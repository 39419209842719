import React, {useContext} from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {ControlButton} from "../../controlButton/controlButton";
import {downloadExcelRequest} from "../../../api/downloadExcel";
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import {DISPLAY_TABLE_FIELDS, DISPLAY_TYPE, ENDPOINT, SEARCH_PARAMS} from "../../../constants/consts";
import {switchDisplayType} from "../../../store/slices/PagePaginagionSlice";
import {checkboxStyle, formGroupStyle} from "./style";
import {SearchByCheckbox} from "./searchByCheckbox/SearchByCheckbox";
import {DisplayFieldCheckbox} from "./displayFieldCheckbox/DisplayFieldCheckbox";
import {Trans} from "react-i18next";

interface CatalogControlProps {
    isDownload?: boolean;
    isSetConfig?: boolean;
    isSetDisplay?: boolean;
    className?: string;
    btnClassName?: string;
}

export const CatalogControl = ({
                                   isDownload = true,
                                   isSetConfig = true,
                                   isSetDisplay = true,
                                   className = "d-flex justify-content-end gap-1",
                                   btnClassName = "",
                               }: CatalogControlProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [isActiveButton, setIsActiveButton] = React.useState<boolean>(true);
    const dispatch = useAppDispatch()
    const {
        productList,
    } = useAppSelector(state => state.catalogProducts)


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const {
        page, displayType
    } = useAppSelector(state => state.page)

    const downloadExcel = async () => {
        setIsActiveButton(false)
        let result = await downloadExcelRequest(productList);
        window.location.assign(`${ENDPOINT}/sales/download-pricelist/${result.data.data.attach_id}`);
        setIsActiveButton(true)
    }

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const updateDisplay = () => {
        dispatch(switchDisplayType())
    }
    const nodeStyle = className ? {} : {width: "145px"}

    return (
        <div className={`${className} ${open ? "active" : ""}`} style={nodeStyle}>
            {isDownload && (
                <ControlButton className={btnClassName ? btnClassName : "btn btn-sm border favIcon text-colored"}
                               icon={`bi bi-${isActiveButton ? "download" : ""}`}
                               label="&nbsp;.xlxs"
                               isLoaderUsed={isActiveButton}
                               controlButtonHandler={downloadExcel}/>
            )}
            {isSetConfig && (
                <ControlButton className={btnClassName ? btnClassName : "btn btn-sm border favIcon text-colored"}
                               icon="bi bi-gear"
                               controlButtonHandler={handleClick}/>
            )}
            {isSetDisplay && (
                <ControlButton className={btnClassName ? btnClassName : "btn btn-sm border favIcon text-colored"}
                               icon={`bi bi-${displayType === "table" ? "grid" : "layout-text-window-reverse"}`}
                               controlButtonHandler={updateDisplay}/>
            )}

            <div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "right",
                    }}
                >
                    <Typography sx={{p: 4}}>
                        <h3 className="fs-3">
                            <Trans i18nKey="title"/>
                        </h3>
                        <FormControl component="fieldset">
                            {displayType === DISPLAY_TYPE.TABLE && (
                                <>
                                    <FormLabel component="legend">
                                        <Trans i18nKey="showColumns"/>
                                    </FormLabel>
                                    <FormGroup sx={formGroupStyle}>
                                        {Object.keys(DISPLAY_TABLE_FIELDS).map((key) => (
                                            <div key={"display" + key}>
                                                <DisplayFieldCheckbox displayItem={key}/>
                                            </div>
                                        ))}
                                    </FormGroup>
                                </>
                            )}
                            <FormLabel component="legend">
                                <Trans i18nKey="searchBy"/>
                            </FormLabel>
                            <FormGroup>
                                {Object.keys(SEARCH_PARAMS).map((key) => (
                                    <div key={"search_by" + key}>
                                        <SearchByCheckbox filterItem={key}/>
                                    </div>
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Typography>

                </Popover>
            </div>

        </div>

    )
}