import React, {useContext, useEffect, useState} from "react";
import {ReturnTableTr} from "./ReturnTableTr"
import {useAppSelector} from "../../../../hooks/ReduxHooks";
import {OrderLinesContext} from "../../../../hooks/UseOrderLinesSelectContext";
import {checkboxStyleSM} from "../../../catalog/catalogControl/style";
import Checkbox from "@mui/material/Checkbox";
import {t} from "i18next";

export const ReturnTable = () => {
    const [isAllChecked, setIsAllChecked] = useState(false)
    const {returnData} = useAppSelector(state => state.return)

    const {setSelectedLines, lines} = useContext(OrderLinesContext);


    const handleSelectAll = (e: { target: { checked: any; }; }) => {
        if (!returnData) return
        setIsAllChecked(e.target.checked)
        if (e.target.checked) {
            setSelectedLines(returnData.lines.map(el => el.id));
        } else {
            setSelectedLines([]);
        }
    };

    return (returnData ?
            <>
                <div>
                    <table className="table table-sm" id="sales_order_table">
                        <thead className="align-top">
                        <tr>
                            <th scope="col">
                                <Checkbox
                                    checked={isAllChecked}
                                    sx={checkboxStyleSM}
                                    value={""}
                                    id="AllCartProductsCheck"
                                    onChange={handleSelectAll}
                                />
                            </th>
                            <th className="text-start text-colored" id="product_name_header">
                                {t("product")}
                            </th>
                            <th className="text-start text-colored" id="product_code_header">
                                {t("code")}
                            </th>
                            <th className="text-end text-colored" id="product_qty_header">
                                {t("quantity")}
                            </th>
                            <th className="text-center text-colored" id="product_qty_header">
                                {t("order_number")}
                            </th>
                            <th className="text-center text-colored" id="product_qty_header">
                                {t("invoice_number")}
                            </th>
                            <th className="text-end text-colored" id="product_qty_header">
                                {t("condition")}
                            </th>
                            <th className="text-end text-colored" id="product_qty_header">
                                {t("status")}
                            </th>
                            <th className="text-end text-colored d-sm-table-cell">
                                {t("price")}
                            </th>
                            <th className="text-end text-colored">
                                <span>{t("cost_without_vat")}</span>
                            </th>
                            <th className="text-end text-colored">
                                <span>{t("delivery")}</span>
                            </th>
                        </tr>

                        </thead>
                        <tbody>
                        {lines && lines.map((child, index) =>
                            <ReturnTableTr
                                line_key={`return_line_${index}`}
                                returnLine={child}
                            />
                        )}
                        </tbody>
                    </table>
                </div>
            </> : <></>
    )
}