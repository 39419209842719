// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homePageSwiper .swiper-wrapper {
  padding-bottom: 30px;
}
.homePageSwiper .swiper-wrapper picture {
  height: 0;
  display: block;
  overflow: hidden;
  position: relative;
  padding-bottom: 36%;
}
.homePageSwiper .swiper-wrapper img {
  position: absolute;
  left: 0;
  border-radius: 10px;
  top: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  background-repeat: no-repeat;
  margin: auto;
}
.homePageSwiper .swiper-button-prev, .homePageSwiper .swiper-button-next {
  top: calc(100% - 60px) !important;
}
.homePageSwiper .swiper-button-prev:after, .homePageSwiper .swiper-button-next:after {
  font-size: 10px !important;
  color: #2a2a2a;
  background-color: white;
  border-radius: 50%;
  padding: 10px 12px;
}
.homePageSwiper .swiper-button-prev {
  left: calc(100% - 90px) !important;
}
.homePageSwiper .swiper-button-next {
  right: 20px !important;
}
.homePageSwiper .swiper-pagination-bullet {
  width: 40px !important;
  height: 3px !important;
  border-radius: 0 !important;
}
.homePageSwiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: rgb(110, 209, 243) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/swiper/Swiper.sass"],"names":[],"mappings":"AACE;EACE,oBAAA;AAAJ;AAEE;EACE,SAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,OAAA;EACA,mBAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,4BAAA;EACA,YAAA;AADJ;AAIE;EACE,iCAAA;AAFJ;AAKE;EACE,0BAAA;EACA,cAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;AAHJ;AAME;EACE,kCAAA;AAJJ;AAOE;EACE,sBAAA;AALJ;AAQE;EACE,sBAAA;EACA,sBAAA;EACA,2BAAA;AANJ;AASE;EACE,yCAAA;AAPJ","sourcesContent":[".homePageSwiper\n  .swiper-wrapper\n    padding-bottom: 30px\n\n  .swiper-wrapper picture\n    height: 0\n    display: block\n    overflow: hidden\n    position: relative\n    padding-bottom: 36%\n\n\n  .swiper-wrapper img\n    position: absolute\n    left: 0\n    border-radius: 10px\n    top: 0\n    width: 100%\n    height: 100%\n    object-position: center\n    background-repeat: no-repeat\n    margin: auto\n\n\n  .swiper-button-prev, .swiper-button-next\n    top: calc(100% - 60px)!important\n\n\n  .swiper-button-prev:after, .swiper-button-next:after\n    font-size: 10px!important\n    color: #2a2a2a\n    background-color: white\n    border-radius: 50%\n    padding: 10px 12px\n\n\n  .swiper-button-prev\n    left: calc(100% - 90px)!important\n\n\n  .swiper-button-next\n    right: 20px!important\n\n\n  .swiper-pagination-bullet\n    width: 40px !important\n    height: 3px !important\n    border-radius: 0 !important\n\n\n  .swiper-pagination-bullet.swiper-pagination-bullet-active\n    background: rgb(110, 209, 243) !important\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
