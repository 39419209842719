import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import {Link} from "react-router-dom";
import {ROUTE_URLS} from "../../constants/consts";

export function NavButtonPerson () {
    
    return (
        <IconButton aria-label="person">
            <Link to={ROUTE_URLS.QUESTIONARY} style={{color: "unset"}}>
                <PersonIcon/>
            </Link>
        </IconButton>
    )
}

export default NavButtonPerson