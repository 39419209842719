import Placeholder from "../images/placeholder.png";

export enum IMAGE_SIZE {
    XL = "&field=image_1920",
    L = "&field=image_1024",
    M = "&field=image_512",
    S = "&field=image_256",
    XS = "&field=image_128",
}

export const useGetImage = (url: string | undefined | boolean, size: IMAGE_SIZE = IMAGE_SIZE.M) => {
    if (!url) return Placeholder
    return `${url}${size}`
};
