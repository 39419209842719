import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    BrandsSectionContent,
    BrandItem,
    BrandsContainer,
    ITEM_WIDTH,
    BrandLogo,
} from "./styles";
import { SectionTitle } from "../../styles";
import {MAX_ROWS, MOBILE_MAX_ROWS, MOBILE_WIDTH_DIFFERENCE, WIDTH_DIFFERENCE} from "../../constants";
import { t } from "i18next";
import useSWR from "swr";
import { getLandingBrands } from "../../../../api/categories";
import { useIsMobile } from "../../../../hooks/useIsMobile.hook";
import {IMAGE_SIZE, useGetImage} from "../../../../hooks/useIsImage.hook";

type Brand = { image_1920: string }

export const BrandsSection = () => {
    const itemRef = useRef<HTMLDivElement>(null);
    const isMobile = useIsMobile();
    const previousBrandsPerRow = useRef<number | null>(null);
    const widthDifference = isMobile ? MOBILE_WIDTH_DIFFERENCE : WIDTH_DIFFERENCE;
    const maxRows = isMobile ? MOBILE_MAX_ROWS : MAX_ROWS;

    const brandsPerRowRef = useRef<number>(Math.floor((window.innerWidth - widthDifference) / ITEM_WIDTH));
    const [brandsPerRow, setBrandsPerRow] = useState<number>(brandsPerRowRef.current);
    const getImage = useGetImage;

    const { data } = useSWR("landingBrands", getLandingBrands, { revalidateOnFocus: false });

    const brands: Brand[] = useMemo(() => data?.data?.data || [], [data]);
    const maxItems = useMemo(() => brandsPerRow * maxRows - 1, [brandsPerRow, maxRows]);
    const visibleBrands = useMemo(() => brands.slice(0, maxItems), [brands, maxItems]);
    const hiddenCount = useMemo(() => Math.max(brands.length - maxItems, 0), [brands.length, maxItems]);

    const updateBrandsPerRow = useCallback(() => {
        if (previousBrandsPerRow.current === null) {
            previousBrandsPerRow.current = brandsPerRowRef.current;
        }

        requestAnimationFrame(() => {
            const newBrandsPerRow = Math.floor((window.innerWidth - widthDifference) / ITEM_WIDTH);

            if (previousBrandsPerRow.current !== newBrandsPerRow) {
                brandsPerRowRef.current = newBrandsPerRow;
                setBrandsPerRow(newBrandsPerRow);
                previousBrandsPerRow.current = newBrandsPerRow;
            }
        });
    }, [widthDifference]);

    useEffect(() => {
        window.addEventListener("resize", updateBrandsPerRow);
        return () => window.removeEventListener("resize", updateBrandsPerRow);
    }, [updateBrandsPerRow]);

    return (
        <BrandsSectionContent initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.8}}>
            <SectionTitle>{t("brands_section_title")}</SectionTitle>
            <BrandsContainer>
                {visibleBrands.map((brand: Brand, index: number) => (
                    <BrandItem key={index}>
                        <BrandLogo src={getImage(brand.image_1920, IMAGE_SIZE.S)} alt="brand-logo" />
                    </BrandItem>
                ))}
                {Boolean(hiddenCount) && <BrandItem ref={itemRef}>vēl +{hiddenCount}</BrandItem>}
            </BrandsContainer>
        </BrandsSectionContent>
    );
}
