import * as React from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { inputProps, inputStyleSM } from "../../productReturns/ProductReturns";
import { BaseDatePicker } from "../../date/DatePicker";
import { NewWarrantySearchTable } from "../newWarrantyRequestTable/NewWarrantySearchTable";
import { useAppDispatch, useAppSelector } from "../../../hooks/ReduxHooks";
import { fetchOrderLines, warrantyFilterEnum, setActiveFilter } from "../../../store/slices/NewWarrantySlice";
import { Dayjs } from "dayjs";
import { Blured } from "../../blured/Blured";
import { Trans, useTranslation } from "react-i18next";

export const NewWarrantyRequestSearchForm = () => {
  const [dateFrom, setDateFrom] = React.useState<Dayjs | null>(null);
  const [dateTo, setDateTo] = React.useState<Dayjs | null>(null);
  const { activeFilter, isWarrantyLinesLoading } = useAppSelector(state => state.newWarranty);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSubmit = () => {
    if (!activeFilter) return;
    dispatch(fetchOrderLines(activeFilter));
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleSetFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: warrantyFilterEnum) => {
    const value = event.target.value;
    const newFilter = {
      ...activeFilter,
      [field]: value,
    };
    dispatch(setActiveFilter(newFilter));
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <h5><Trans i18nKey="search_criteria_label" /></h5>
        <TextField
          id="outlined-basic"
          size="small"
          label={t("product_name_label")}
          variant="outlined"
          inputProps={inputProps}
          required
          InputProps={{
            sx: {
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgb(110, 209, 243)',
                outline: 'none',
              },
            },
          }}
          onChange={(event) => handleSetFilter(event, warrantyFilterEnum.PRODUCT_NAME)}
          sx={inputStyleSM}
          onKeyDown={handleKeyPress}
        />
        <TextField
          id="outlined-basic"
          size="small"
          label={t("code_label")}
          variant="outlined"
          inputProps={inputProps}
          required
          InputProps={{
            sx: {
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgb(110, 209, 243)',
                outline: 'none',
              },
            },
          }}
          onChange={(event) => handleSetFilter(event, warrantyFilterEnum.CODE)}
          sx={inputStyleSM}
          onKeyDown={handleKeyPress}
        />
        <TextField
          id="outlined-basic"
          size="small"
          label={t("ean_label")}
          variant="outlined"
          inputProps={inputProps}
          required
          InputProps={{
            sx: {
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgb(110, 209, 243)',
                outline: 'none',
              },
            },
          }}
          onChange={(event) => handleSetFilter(event, warrantyFilterEnum.EAN)}
          sx={inputStyleSM}
          onKeyDown={handleKeyPress}
        />
        <TextField
          id="outlined-basic"
          size="small"
          label={t("serial_number_label")}
          variant="outlined"
          inputProps={inputProps}
          required
          InputProps={{
            sx: {
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgb(110, 209, 243)',
                outline: 'none',
              },
            },
          }}
          sx={inputStyleSM}
          onKeyDown={handleKeyPress}
        />
        <TextField
          id="outlined-basic"
          size="small"
          label={t("waybill_label")}
          variant="outlined"
          inputProps={inputProps}
          required
          InputProps={{
            sx: {
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgb(110, 209, 243)',
                outline: 'none',
              },
            },
          }}
          onChange={(event) => handleSetFilter(event, warrantyFilterEnum.WAYBILL_NUMBER)}
          sx={inputStyleSM}
          onKeyDown={handleKeyPress}
        />
        <BaseDatePicker
          sx={inputStyleSM}
          slotProps={{ textField: { size: 'small', fontSize: "12px" } }}
          label={t("waybill_date_from_label")}
          value={dateFrom}
          setValue={setDateFrom}
        />
        <BaseDatePicker
          sx={inputStyleSM}
          slotProps={{ textField: { size: 'small' } }}
          label={t("to_label")}
          value={dateTo}
          setValue={setDateTo}
        />
        <button type="button" className="btn-search btn" onClick={handleSubmit}>
          <Trans i18nKey="search_button" />
        </button>
      </Box>
      <Blured
        element={<NewWarrantySearchTable />}
        isLoading={isWarrantyLinesLoading}
      />
    </>
  );
};
