import axios, {AxiosResponse} from 'axios';
import {resolve} from './resolve.js';
import {API_AUTH_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";

export async function userLogin(email:any, password:any, region:any, companyName:any, vat:any, phone:any, registration:any, zip:any, city:any, address:any, owner:any, boards:any):Promise<AxiosResponse["data"]> {
    return await resolve(axios.post(`${API_AUTH_URL}/users/registration`,
        {
            email: email,
            phone: phone,
            company: {
                name: companyName,
                vat: vat,
                boards: [],
                registration_number:registration,
                address: {
                    street:address,
                    postal_code:zip,
                    city:city,
                    region: region,
                },
                beneficial_owner: [],
            },
        }
    ).then(res => res));
}


export async function userSignUpSecond(owner:any, boards:any,companyName:any,email:any,phone:any,vat:any,registration_number:any,city:any,address:any,zip:any,region:any):Promise<AxiosResponse["data"]> {
    let data = {
        "beneficial_owners": owner.map((item: any) => ({ name: item ,record_id:null })),
        "boards": boards.map((item: any) => ({ name: item,record_id:null })),
        "name": companyName,
        "banks": [],
        "shippings": [],
        "email":email,
        "phone":phone,
        "vat":vat,
        "registration_number": registration_number,
        "legal_address": {
            "street": address,
            "postal_code": zip,
            "city": city,
            "region": region,
            "record_id": null
        }
    }
    return await resolve(axios.patch(`${API_AUTH_URL}/company/`,
        data,{
            headers: {
                'session-id': `${getSession()}`
            },
        }
    ).then(res => res));
}
