// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-panel__config {
  position: absolute !important;
  right: 0;
  opacity: 0;
}
.search-panel__config:hover {
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.search-panel .active {
  opacity: 1;
}
.search-panel__btn {
  border: none;
  outline: none;
}
.search-panel .active {
  opacity: 1 !important;
  transition: 0.3s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/components/searchPanel/SearchPanelBase.sass"],"names":[],"mappings":"AACE;EACE,6BAAA;EACA,QAAA;EACA,UAAA;AAAJ;AAGI;EACE,UAAA;EACA,4BAAA;AADN;AAGE;EACE,UAAA;AADJ;AAGE;EACE,YAAA;EACA,aAAA;AADJ;AAIE;EACE,qBAAA;EACA,4BAAA;AAFJ","sourcesContent":[".search-panel\n  &__config\n    position: absolute !important\n    right: 0\n    opacity: 0\n\n\n    &:hover\n      opacity: 1\n      transition: 0.3s ease-in-out\n\n  .active\n    opacity: 1\n\n  &__btn\n    border: none\n    outline: none\n\n\n  .active\n    opacity: 1 !important\n    transition: 0.3s ease-in-out"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
