import {useEffect, useState} from "react";
import {getTermService} from "../../api/termService";

export const TermService = () => {

    const [structureTermService, setStructureTermService] = useState<any>(null)
    const [nameTermService, setNameTermService] = useState<any>("")

    const getDataTermService = async () => {
        let result = await getTermService()
        if (!result.error){
            setNameTermService(result.data.data.data.name)
            setStructureTermService(result.data.data.data.editor)
        }
    }

    useEffect(() => {
        (async () => {
            await getDataTermService()
        })();
    }, [])

    return (
        <>
            <h1>{nameTermService}</h1>
            <div dangerouslySetInnerHTML={{__html: structureTermService}}/>
        </>
    )
}