import React from "react";

import "./ChildSubSidebar.sass"
import {SUBMENU_TYPES} from "../../constants/consts";
import {SwitchContent} from "../switchContent";
import {CategoriesSubmenu} from "./CategoriesSubmenu";
import {OrdersSubmenu} from "./OrdersSubmenu";
import {FinancialMenu} from "./FinancialSubmenu";
import {QuestionaryMenu} from "./QuestionarySubmenu";
import {GuaranteeSubmenu} from "./GuaranteeSubmenu";


interface SubSidebarProps {
    className: string;
    closeAllCategory: any
    isChildSubSidebarClose: boolean;
    setIsChildSubSidebarClose: any;
    submenuType: SUBMENU_TYPES;
    isMainCategoryVisible:boolean;
    isChildCategoryVisible: boolean;
    setIsChildCategoryVisible: any;
}


export const SubSidebar = (props: SubSidebarProps) => {

    return (
        <> {props.submenuType &&
            <SwitchContent value={props.submenuType}>
                <CategoriesSubmenu {...props} data-value={SUBMENU_TYPES.CATALOG}/>
                <OrdersSubmenu {...props} data-value={SUBMENU_TYPES.ORDERS}/>
                <FinancialMenu {...props} data-value={SUBMENU_TYPES.FINANCIAL}/>
                <QuestionaryMenu {...props} data-value={SUBMENU_TYPES.QUESTIONARY}/>
                <GuaranteeSubmenu  {...props} data-value={SUBMENU_TYPES.GUARANTEE}/>
            </SwitchContent>}
        </>

    )
}