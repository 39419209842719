import { useCallback, useEffect } from 'react';

export const useOutsideClick = (
    ref: React.RefObject<HTMLElement> | null,
    callback: VoidFunction,
    visible?: boolean,
    skipClasses?: string[],
): void => {
    const handleClick = useCallback((event: MouseEvent) => {
        if (!ref?.current || !visible) return;

        const target = event.target as HTMLElement;

        if (ref.current.contains(target)) return;

        if (skipClasses) {
            const skipElements = skipClasses
                .map((skipClass) => Array.from(document.getElementsByClassName(skipClass)))
                .flat();

            const isClickedOnElement = skipElements.some((element) =>
                element.contains(target),
            );

            if (isClickedOnElement) return;
        }

        callback();
    }, [callback, ref, skipClasses, visible]);

    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [ref, callback, visible, skipClasses, handleClick]);
};
