// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-colored {
  color: rgb(20, 189, 243) !important;
}

.modal__content {
  position: relative;
}

.modal__close {
  position: absolute;
  top: -20px;
  right: -20px;
  font-size: 30px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/catalog/CatalogContent.sass"],"names":[],"mappings":"AAAA;EACE,mCAAA;AACF;;AACA;EACI,kBAAA;AAEJ;;AAAA;EACE,kBAAA;EACA,UAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;AAGF","sourcesContent":[".text-colored\n  color: rgb(20, 189, 243) !important\n\n.modal__content\n    position: relative\n\n.modal__close\n  position: absolute\n  top: -20px\n  right: -20px\n  font-size: 30px\n  cursor: pointer\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
