export const checkboxStyle = {
    "& .MuiSvgIcon-root": {
        fontSize: 24,
        color: "rgb(110, 209, 243)"
    },
    "&.MuiCheckbox-root": {
        borderRadius: 0,
    },
    "&.Mui-checked": {
        backgroundColor: "white",
    },
    "& svg": {
        scale: "1",
    },
}

export const checkboxStyleSM = {
    "& .MuiSvgIcon-root": {
        fontSize: 16,
        color: "rgb(110, 209, 243)",
        padding: 0,
    },
    "&.MuiCheckbox-root": {
        borderRadius: 0,
        padding: 0,
    },
    "&.Mui-checked": {
        backgroundColor: "white",
    },
    "& svg": {
        scale: "1",
    },
}

export const formGroupStyle = {

}