import {useLocation, useParams} from "react-router-dom";
import {OrderContent} from "../../components/orders/orderContent/OrderContent";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import React, {useEffect, useState} from "react";
import {Loader} from "../../components/loader/Loader";
import {updateBreadcrumb} from "../../store/slices/BreadcrumbSlice";
import {OrderLinesContext} from "../../hooks/UseOrderLinesSelectContext";
import {ReturnLineType, SaleOrderLineType, WarrantyRequestLineType} from "../../components/catalog/types";
import {fetchSingleReturn, resetReturn} from "../../store/slices/ReturnReducerSlice";
import { ReturnIntroduction } from "../../components/returns/returnContent/returnIntroduction/ReturnIntroduction";
import { ReturnSidebar } from "../../components/returns/returnContent/returnSidebar/ReturnSidebar";
import {ReturnDeliveryInfo} from "../../components/returns/returnContent/returnDeliveryInfo/ReturnDeliveryInfo";
import {ReturnTable} from "../../components/returns/returnContent/returnTable/ReturnTable";
import {ReturnTotal} from "../../components/returns/returnContent/returnTotal/ReturnTotal";
import {fetchFinTerms} from "../../store/slices/FinTermsSlice";
import {fetchSingleRequest} from "../../store/slices/WarrantyRequestReducerSlice";
import {WarrantySidebar} from "../../components/warranty/warrantyContent/warrantySidebar/WarrantySidebar";
import {
    WarrantyIntroduction
} from "../../components/warranty/warrantyContent/warrantyIntroduction/WarrantyIntroduction";
import {
    WarrantyDeliveryInfo
} from "../../components/warranty/warrantyContent/warrantyDeliveryInfo/WarrantyDeliveryInfo";
import {WarrantyTable} from "../../components/warranty/warrantyContent/warrantyTable/WarrantyTable";
import {WarrantyTotal} from "../../components/warranty/warrantyContent/warrantyTotal/WarrantyTotal";
import {resetWarrantyData} from "../../store/slices/NewWarrantySlice";


export const WarrantyRequestPage = () => {

    const {warrantyId} = useParams()

    const {isWarrantyRequestLoading, warrantyRequestData} = useAppSelector(state => state.warrantyRequest)

    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedLines, setSelectedLines] = useState<number[]>([])
    const [lines, setLines] = useState<WarrantyRequestLineType[]>([])
    const location = useLocation()


    useEffect(() => {
        if (!isWarrantyRequestLoading)
            setIsLoading(isWarrantyRequestLoading)
    }, [isWarrantyRequestLoading]);

    useEffect(() => {
        dispatch(fetchSingleRequest(Number(warrantyId)))
        return () => {
            dispatch(resetWarrantyData())
        }
    }, [location]);

    useEffect(() => {
        if (!warrantyRequestData) return
        setLines(warrantyRequestData.lines)
    }, [warrantyRequestData]);

    useEffect(() => {
        dispatch(fetchFinTerms())
    }, []);


    if (warrantyRequestData) {
        dispatch(updateBreadcrumb({key: location.pathname, value: warrantyRequestData.name}))
    }

    return (
        <OrderLinesContext.Provider value={{selectedLines, setSelectedLines, lines, setLines, setIsLoading}}>
            {isLoading ? (
                <Loader/>
            ) : (warrantyRequestData ?
                <>
                    <div className="wrapper">
                        <OrderContent
                            sidebar={<WarrantySidebar/>}
                            introduction={<WarrantyIntroduction/>}
                            info={<WarrantyDeliveryInfo/>}
                            table={<WarrantyTable/>}
                            total={<WarrantyTotal/>}
                            recordId={Number(warrantyId)}
                            model="guarantee.request"
                        />
                    </div>
                </> : <></>
            )}
        </OrderLinesContext.Provider>
    )
}