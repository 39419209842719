import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import * as React from "react";
import TextField from "@mui/material/TextField";
import { Button, Typography } from "@mui/material";
import { useContactForm } from "./hooks/useContactForm.hook";
import { CloseIcon, CloseIconContainer, Text } from "./styles";
import { DEFAULT_ANIMATION_DELAY, RECAPTCHA_SITE_KEY } from "../../../../../constants/consts";
import { useIsMobile } from "../../../../../hooks/useIsMobile.hook";
import { t } from "i18next";
import { LocalizedReCaptcha } from "./localizedReCaptcha";

interface ContactFormModalProps {
    isContactModalOpen: boolean;
    toggleModal: VoidFunction;
}

export const ContactFormModal = ({ isContactModalOpen, toggleModal }: ContactFormModalProps) => {
    const isMobile = useIsMobile();
    const { formData, handleChange, handleSubmit, handleCaptchaChange } = useContactForm({ isContactModalOpen });
    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "10px",
        border: 'none',
        p: 0,
        width: isMobile ? "90%" : "50%",
        maxHeight: "95vh",
        overflowY: "auto"
    };

    return (
        <Modal
            open={isContactModalOpen}
            onClose={toggleModal}
            className="products__modal"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                outline: 'none',
                '& .MuiBox-root': {
                    outline: 'none',
                },
            }}
        >
            <Fade in={isContactModalOpen} timeout={DEFAULT_ANIMATION_DELAY}>
                <Box sx={style}>
                    <CloseIconContainer onClick={toggleModal}>
                        <CloseIcon className="bi bi-x"></CloseIcon>
                    </CloseIconContainer>
                    <Box>
                        <Typography
                            variant="h5"
                            gutterBottom
                            sx={{
                                fontWeight: 600,
                                px: isMobile ? 2 : 4
                            }}
                        >
                            {t("contact_form")}
                        </Typography>
                        <Box
                            sx={{
                                pl: isMobile ? 2 : 4,
                                pr: isMobile ? 2 : 4,
                                pb: isMobile ? 3 : 4
                            }}
                            component="form"
                            onSubmit={handleSubmit}
                        >
                            <Box sx={{ mt: 1, mb: 1 }}>
                                <Text>{t("helpful_text")}</Text>
                            </Box>
                            <TextField
                                required
                                fullWidth
                                label={t("name_field_label")}
                                name="name"
                                margin="normal"
                                variant="outlined"
                                value={formData.name}
                                onChange={handleChange}
                                sx={{ borderRadius: 2, backgroundColor: '#fff' }}
                            />
                            <TextField
                                required
                                fullWidth
                                label={t("phone_number_field_label")}
                                name="phone"
                                margin="normal"
                                variant="outlined"
                                value={formData.phone}
                                onChange={handleChange}
                                sx={{ borderRadius: 2, backgroundColor: '#fff' }}
                            />
                            <TextField
                                required
                                fullWidth
                                label={t("email_address_field_label")}
                                name="email"
                                type="email"
                                margin="normal"
                                variant="outlined"
                                value={formData.email}
                                onChange={handleChange}
                                sx={{ borderRadius: 2, backgroundColor: '#fff' }}
                            />
                            <TextField
                                required
                                fullWidth
                                label={t("comments_field_label")}
                                name="message"
                                multiline
                                rows={3}
                                margin="normal"
                                variant="outlined"
                                value={formData.message}
                                onChange={handleChange}
                                sx={{ borderRadius: 2, backgroundColor: '#fff' }}
                            />
                            <Box sx={{ mt: 2, mb: 2 }}>
                                <LocalizedReCaptcha
                                    siteKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || RECAPTCHA_SITE_KEY}
                                    onChange={handleCaptchaChange}
                                />
                            </Box>
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                sx={{
                                    mt: 1,
                                    py: 1.5,
                                    fontWeight: 500,
                                    backgroundColor: '#006AFF',
                                    borderRadius: 1,
                                    '&:hover': {
                                        backgroundColor: '#006AFF',
                                        opacity: 0.9,
                                    },
                                }}
                            >
                                {t("send_message")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}
