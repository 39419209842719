import axios from 'axios';
import {resolve} from './resolve.js';
import {API_BACKEND_URL} from "../constants/consts";
import {getExchangeHeaders} from "./helpers";


export async function getCategories() {
    return await resolve(axios.get(`${API_BACKEND_URL}/categories/categories`,
        {
            headers: getExchangeHeaders(),
        }
    ).then(res => res))
}


export async function getChildCategories(activeCategoryId) {
    return await resolve(axios.get(`${API_BACKEND_URL}/categories/children/${activeCategoryId}`,
        {
            headers: getExchangeHeaders(),
        }
    ).then(res => res));
}


export async function fetchCategory(categoryId){
    return await resolve(axios.get(`${API_BACKEND_URL}/categories/category/${categoryId}`,
        {
            headers: getExchangeHeaders(),
        }
    ).then(res => res));
}