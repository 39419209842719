import React, {useContext, useEffect, useState} from "react";
import {OrderLinesContext} from "../../../../hooks/UseOrderLinesSelectContext";
import {checkboxStyleSM} from "../../../catalog/catalogControl/style";
import Checkbox from "@mui/material/Checkbox";
import "./WarrantyTable.sass"
import {WarrantyRequestLineType} from "../../../catalog/types";
import {Link} from "react-router-dom";
import {ROUTE_URLS} from "../../../../constants/consts";

export interface ReturnTableProps {
    requestLine : WarrantyRequestLineType,
    line_key: string
}

export const WarrantyTableTr = (props: ReturnTableProps) => {
    const {requestLine, line_key} = props
    const { selectedLines, setSelectedLines} = useContext(OrderLinesContext)
    const [isChecked, setIsChecked] = useState(selectedLines.includes(requestLine.id))

    useEffect(() => {
        setIsChecked(selectedLines.includes(requestLine.id))
    }, [selectedLines]);

    const handleLineCheck = () => {
        if (selectedLines.includes(requestLine.id)) {
            setSelectedLines(selectedLines.filter((item) => item !== requestLine.id));
        } else {
            setSelectedLines([...selectedLines, requestLine.id]);
        }
    }

    return (
        <tr className="" key={line_key}>
            <td scope="row">
                <Checkbox
                    checked={isChecked}
                    sx={checkboxStyleSM}
                    value={""}
                    onChange={handleLineCheck}
                />
            </td>
            <td>
                <span className="d-block text-wrap">{requestLine?.product_data?.name}</span>
            </td>
            <td>
                <span className="d-block text-wrap">
                    {requestLine.product_data.default_code}
                </span>
            </td>
            <td className="text-center">
                <span>{requestLine.quantity}</span>
            </td>
            <td className="text-center">
                <span className="d-block text-wrap">
                    {requestLine.order_id && (
                        <Link className="catalog_product__link" to={`${ROUTE_URLS.ORDERS}/${requestLine.order_id.id}`}>
                            <p className="text-center">{requestLine.order_id.name}</p>
                        </Link>
                    )}
                </span>
            </td>
            <td className="text-center">
                <span className="d-block text-wrap">
                    {requestLine.waybill_number}
                </span>
            </td>
            <td>
                <span className="d-block text-wrap">
                    {requestLine.defect_id.name}
                </span>
            </td>
            <td>
                <span className="d-block text-wrap">
                    {requestLine.comment}
                </span>
            </td>
            <td className="text-end d-none d-sm-table-cell priceCell">
                <div className=" text-end">{requestLine.price_unit} {requestLine?.currency_id?.symbol}</div>
            </td>
            <td className="text-end d-none d-sm-table-cell priceCell">
                <div className=" text-end">{requestLine.amount_total} {requestLine?.currency_id?.symbol}</div>
            </td>
        </tr>
    )
}