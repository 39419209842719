import {CreditLineForm} from "../creditLineForm/CreditLineForm";
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import React, {useEffect, useState} from "react";
import {resetCreditLimit} from "../../../store/slices/CreditLimitRedicerSlice";
import {Loader} from "../../loader/Loader";
import {CREDIT_LIMIT_TYPES} from "../../../constants/consts";
import {fetchFinTerms} from "../../../store/slices/FinTermsSlice";



export const NewCreditLine = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {isTermsLoading, terms} = useAppSelector(state => state.finTerms)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchFinTerms())
        return () => {
            dispatch(resetCreditLimit())
        }
    }, [])

    useEffect(() => {
        setIsLoading(isTermsLoading)
    }, [isTermsLoading]);

    const currentDate = new Date().toJSON().slice(0, 10);

    let linesData= Object.keys(CREDIT_LIMIT_TYPES).map(key => {
            return {
                credit_limit_type : key,
                current_limit: (key === "credit_limit"  ? terms?.partner_credit_limit : terms?.partner_credit_limit_days) || 0,
                requested_limit: 0,
                to_approve_limit: 0,
            }
        })

    return (
        <>
            {isLoading ? (
                <Loader/>
            ) : (
                <CreditLineForm
                    isNew
                    requestDate={currentDate}
                    state={"Draft"}
                    linesData={linesData}
                />
            )
            }
        </>
    )
}