import axios, { AxiosResponse } from 'axios';
import { resolve } from './resolve.js';
import {API_BACKEND_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";
import {getExchangeHeaders} from "./helpers";


export type addToCardParams = {
    product_id: number;
    quantity: number;
    currency_id: number;
}


export async function fetchGetActiveUserProductCart(): Promise<AxiosResponse["data"]>  {
    return await resolve(axios.get(`${API_BACKEND_URL}/carts/active_cart`,
        {
            headers: getExchangeHeaders(),
        }
    ).then(res => res));
}

export async function addProductToUserActiveCart(productParams: addToCardParams, sum_quant=false): Promise<AxiosResponse["data"]> {
    return await resolve(axios.post(`${API_BACKEND_URL}/carts/add_or_create?sum_quant=${sum_quant}`,
            productParams, {
            headers: getExchangeHeaders(),
        }


    ).then(res => res));
}

export async function deleteCartLinesInUserActiveCart(deleteParams: { ids: number[]; }): Promise<AxiosResponse["data"]> {
    return await resolve(axios.post(`${API_BACKEND_URL}/carts/cartlines/delete_cartlines`,
        deleteParams,{
            headers: getExchangeHeaders(),
        }
    ).then(res => res));
}

export async function changeValueInProduct(productId: number, productParams: { quantity: number; }): Promise<AxiosResponse["data"]> {
    return await resolve(axios.patch(`${API_BACKEND_URL}/carts/cartline/product/${productId}`,
        productParams,{
            headers: getExchangeHeaders(),
        }


    ).then(res => res));
}

export async function createBackendSaleOrder(data: { order_line: any; }){
    return await resolve(axios.post(`${API_BACKEND_URL}/sales`,
        data,{
            headers: getExchangeHeaders(),
        }


    ).then(res => res));
}


export async function deleteProductInUserActiveCart(deleteParams: { product_ids: number[]; }): Promise<AxiosResponse["data"]> {
    return await resolve(axios.post(`${API_BACKEND_URL}/carts/cartlines/delete_cartlines_by_product_ids`,
        deleteParams,{
            headers: getExchangeHeaders(),
        }


    ).then(res => res));
}
