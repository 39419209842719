import React, {useCallback, useState} from "react";
import { HeaderButtonsContainer } from "../styles";
import { PrimaryButton, SecondaryButton } from "../../../styles";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { SubmitFormModal } from "../../submitFormModal";

interface HeaderButtonsProps {
    showButtons: boolean;
}

export const HeaderButtons = ({ showButtons }: HeaderButtonsProps) => {
    const navigate = useNavigate();
    const [isSubmitModalOpen, setOpenSubmitModal] = useState(false);

    const handleNavigateToSignIn = () => {
        navigate("/sign-in");
    };

    const toggleModal = useCallback(() => {
        setOpenSubmitModal(prev => !prev);
    }, []);

    return (
        <HeaderButtonsContainer
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: showButtons ? 1 : 0, y: showButtons ? 0 : -20 }}
            transition={{ duration: 0.3 }}
        >
            <PrimaryButton onClick={handleNavigateToSignIn}>{t("section_primary_button")}</PrimaryButton>
            <SecondaryButton onClick={toggleModal}>{t("section_secondary_button")}</SecondaryButton>
            <SubmitFormModal isSubmitModalOpen={isSubmitModalOpen} toggleModal={toggleModal} />
        </HeaderButtonsContainer>
    );
}
