import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CreditLimitsType} from "../../components/catalog/types";
import {fetchBackendCreditLimits} from "../../api/creditLimits";

export type initialStateType = {
    isCreditLimitsLoading: boolean;
    creditLimitsData: CreditLimitsType | undefined
}

const initialState: initialStateType = {
    creditLimitsData: undefined,
    isCreditLimitsLoading: true,
}

export const fetchCreditLimits =
    createAsyncThunk("slice/fetchCreditLimits",
        async (values: {pageNum: number}) => {
            const {pageNum} = values
            const result = await fetchBackendCreditLimits(pageNum)
            return result.data?.data
        })
export const resetCreditLimits =
    createAsyncThunk("slice/resetCreditLimits",
        async () => initialState
    )

const creditLimitsSliceReducer = createSlice({
    name: "creditLimits",
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchCreditLimits.fulfilled, (state, action) => {
            state.creditLimitsData = action.payload
            state.isCreditLimitsLoading = false
        }).addCase(resetCreditLimits.fulfilled, (state, action) => {
            Object.assign(state, initialState);
            state.isCreditLimitsLoading = false;
        })
    }
})

export default creditLimitsSliceReducer.reducer
