import React, { useState, useEffect } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DownloadIcon from "@mui/icons-material/Download";
import { Link, useNavigate } from "react-router-dom";
import { getUserPriceList } from "../../api/priceList";
import { ENDPOINT, ROUTE_URLS, SUBMENU_TYPES } from "../../constants/consts";
import { Trans } from 'react-i18next'; // Добавляем компонент Trans
import "./SubSidebar.sass";

interface SubSidebarProps {
    className: string;
    closeAllCategory: any;
    isChildCategoryVisible: boolean;
    setIsChildCategoryVisible: any;
    submenuType: string;
    isMainCategoryVisible: boolean;
}

interface activeCategoryType {
    name?: string;
    id: any;
    submenu?: { id: number; name: string; path: string }[];
    path?: string;
}

export const FinancialMenu = (props: SubSidebarProps) => {
    const [categoryStacks, setCategoryStacks] = useState<activeCategoryType[][]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [currentCategory, setCurrentCategory] = useState<activeCategoryType | null>(null);
    const [hoveredCategoryId, setHoveredCategoryId] = useState<number | null>(null);
    const [childCategoryVisible, setChildCategoryVisible] = useState<boolean>(false);
    const navigate = useNavigate();

    const ordersSubItems: activeCategoryType[] = [
        { name: "price_list", id: 0 },
        {
            name: "financial_terms",
            id: 1,
            path: ROUTE_URLS.FIN_TERMS,
        },
        {
            name: "financial_documents",
            id: 2,
            submenu: [
                { id: 21, name: "invoices", path: "" },
                { id: 22, name: "balance", path: "" },
            ],
        },
        {
            name: "credit_line_request",
            id: 3,
            submenu: [
                { id: 31, name: "new_request", path: ROUTE_URLS.NEW_CREDIT_LIMIT },
                { id: 32, name: "history_requests", path: ROUTE_URLS.CREDIT_LIMIT_HISTORY },
            ],
        },
    ];

    useEffect(() => {
        setCategoryStacks([ordersSubItems]);
    }, []);

    const handleMainCategoryClick = (category: activeCategoryType) => {
        if (category.submenu?.length) {
            setCategoryStacks([ordersSubItems, category.submenu]);
            setSelectedCategories([category.name || ""]);
            setCurrentCategory(category);
            props.setIsChildCategoryVisible(true);
        } else if (category.path) {
            navigate(category.path);
            props.closeAllCategory();
        }
    };

    const handleBackClick = () => {
        if (categoryStacks.length > 1) {
            const updatedStacks = categoryStacks.slice(0, -1);
            const updatedSelectedCategories = selectedCategories.slice(0, -1);

            setCategoryStacks(updatedStacks);
            setSelectedCategories(updatedSelectedCategories);
            setCurrentCategory(updatedStacks[updatedStacks.length - 1][0] || null);
        } else {
            props.setIsChildCategoryVisible(false);
        }
    };

    const handleMouseEnter = (id: number, category: activeCategoryType) => {
        setHoveredCategoryId(id);

        // Проверяем, есть ли подкатегории
        if (category.submenu?.length) {
            setCategoryStacks([ordersSubItems, category.submenu]);
            setSelectedCategories([category.name || ""]);
            setCurrentCategory(category);
            props.setIsChildCategoryVisible(true);
        } else {
            props.setIsChildCategoryVisible(false);
        }
    };

    const handleMouseLeave = () => {
        setHoveredCategoryId(null);
    };

    const downloadUserPriceList = async () => {
        const result = await getUserPriceList();
        if (!result.error) {
            window.location.assign(`${ENDPOINT}/pricelist/download-pricelist/${result.data.data.attach_id}`);
        }
    };

    return (
        <>
            <div className={`mainCategory ${props.isMainCategoryVisible ? 'slideIn' : 'slideOut'}`}>
                <div className="mainCategoryWrapper">
                    <div className="mainCategoryButtonBack" onClick={props.closeAllCategory}>
                        <ChevronLeftIcon />
                        <span><Trans i18nKey={`${props.submenuType}`} /></span>
                    </div>
                    <ul className="mainCategoryList">
                        {categoryStacks[0]?.map((category) => (
                            <li
                                key={category.id}
                                onClick={() => (category.id === 0 ? downloadUserPriceList() : handleMainCategoryClick(category))}
                            >
                                <Trans i18nKey={`submenu.${category.name}`} /> {/* Перевод имени категории */}
                                {category.submenu?.length && <ChevronRightIcon />}
                                {category.id === 0 && <DownloadIcon />}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {categoryStacks.slice(1).map((categories, index) => (
                <div key={index} className={`childCategory ${props.isChildCategoryVisible ? "slideIn" : "slideOut"}`}>
                    <div className="childCategoryWrapper">
                        <div className="childCategorySearch" onClick={handleBackClick}>
                            <ChevronLeftIcon className="backArrow" />
                            <span><Trans i18nKey={`submenu.${selectedCategories[index]}`} /></span> {/* Перевод выбранной категории */}
                        </div>
                        <ul className="childCategoryList">
                            {categories?.map((category) => (
                                <li key={category.id} onClick={() => handleMainCategoryClick(category)}>
                                    <span className="category-text"><Trans i18nKey={`submenu.${category.name}`} /></span> {/* Перевод имени категории */}
                                    {category.submenu?.length && <ChevronRightIcon />}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </>
    );
};
