import {CreditLineForm} from "../creditLineForm/CreditLineForm";
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import React, {useEffect, useState} from "react";
import {fetchCreditLimit, resetCreditLimit} from "../../../store/slices/CreditLimitRedicerSlice";
import {useParams} from "react-router-dom";
import {Loader} from "../../loader/Loader";



export const CreditLine = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {isCreditLimitLoading, creditLimitData} = useAppSelector(state => state.creditLimit)

    const dispatch = useAppDispatch()

    const {limitId} = useParams();

    useEffect(() => {
        dispatch(fetchCreditLimit(Number(limitId)))
        return () => {
            dispatch(resetCreditLimit())
        }
    }, [limitId])

    useEffect(() => {
        setIsLoading(isCreditLimitLoading)
    }, [isCreditLimitLoading]);

    return (
        <>
            {isLoading ? (
                <Loader/>
            ) : (creditLimitData && (
                <CreditLineForm
                    recordId={Number(limitId)}
                    note={creditLimitData.note}
                    requestDate={creditLimitData.create_date}
                    state={creditLimitData.state}
                    linesData={creditLimitData.lines && creditLimitData.lines.map(lineData => {
                        return {
                            credit_limit_type : lineData.credit_limit_type,
                            current_limit: lineData.current_limit,
                            requested_limit: lineData.requested_limit,
                            to_approve_limit: lineData.to_approve_limit,
                        }
                    })}

                />
                )
            )
            }
        </>
    )
}