import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchOrdersRequests} from "../../api/orders";
import {SaleOrdersType} from "../../components/catalog/types";

export type initialStateType = {
    isOrdersLoading: boolean;
    ordersData: SaleOrdersType | undefined
}

type fetchOrdersParamsType = {
    pageNum: number,
    searchValue?: string,
    isReserve?: boolean,
    isDaily?: boolean,
    isActive?: boolean,
}

const initialState: initialStateType = {
    ordersData: undefined,
    isOrdersLoading: true,
}

export const fetchOrders =
    createAsyncThunk("slice/fetchOrders",
        async (values: fetchOrdersParamsType) => {
            const {pageNum, searchValue, isReserve, isDaily, isActive} = values
            const result = await fetchOrdersRequests(pageNum, searchValue, isReserve, isDaily, isActive)
            return result.data?.data
        })
export const resetOrders =
    createAsyncThunk("slice/ordersReset",
        async () => initialState
    )

const ordersSliceReducer = createSlice({
    name: "orders",
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchOrders.fulfilled, (state, action) => {
            state.ordersData = action.payload
            state.isOrdersLoading = false
        }).addCase(resetOrders.fulfilled, (state, action) => {
            Object.assign(state, initialState);
            state.isOrdersLoading = false;
        }).addCase(fetchOrders.pending, (state, action) => {
            state.isOrdersLoading = true;
        })
    }
})

export default ordersSliceReducer.reducer