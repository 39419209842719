import {FilterItemsType} from "../components/catalog/types";
import {GuaranteeSubmenu} from "../components/subSidebar/GuaranteeSubmenu";
import { Trans } from "react-i18next";

export const EMPTY_STRING = "";
export const EMPTY_NUMBER = 0
export const VISIBLE_MESSAGE_COUNT = 25;

export enum SUBMENU_TYPES {
    CATALOG = "catalog",
    ORDERS = "orders",
    FINANCIAL = "financial",
    QUESTIONARY = "information",
    GUARANTEE = "guarantee"
}

export enum ROUTE_URLS {
    HOME = "/",
    CATALOG = "/products",
    CATALOG_CATEGORY = "/products/:categoryId",
    PRODUCT_FROM_CATEGORY = "/products/:categoryId/product-card/:id",
    PRODUCT = "/products/product-card/:id",
    CART = "/cart",
    SIGN_IN = "/sign-in",
    SIGN_UP = "/sign-up",
    ORDERS = "/orders",
    ORDER = "/orders/:orderId",
    RESERVES = "/reserves",
    RESERVE = "/reserves/:orderId",
    RETURNS= "/returns",
    RETURN = "/returns/:returnId",
    QUESTIONARY = "/questionary",
    FIN_TERMS = "/fin_terms",
    CREDIT_LIMIT_HISTORY = "/credit-line",
    NEW_CREDIT_LIMIT = "/credit-line/new",
    CREDIT_LIMIT = "/credit-line/:limitId",
    CONTACT_INFORMATION = "/contact-information",
    TERM_SERVICE = "/terms-service",
    TWO_FACTOR_AUTH = "/2fa",
    WARRANTY_TERMS = "/warranty-terms",
    WARRANTY_PERIODS = "/warranty-periods",
    WARRANTY_CASE = "/warranty-case",
    PRICE_LIST = "/price-list",
    WARRANTIES = "/warranties",
    WARRANTY = "/warranties/:warrantyId",
}

export const HIDE_ROUTES: ROUTE_URLS[] = [ROUTE_URLS.SIGN_IN, ROUTE_URLS.SIGN_UP, ROUTE_URLS.TWO_FACTOR_AUTH];
export const DISPLAY_SEARCH_NAV_BAR_ROUTES: ROUTE_URLS[] = [
    ROUTE_URLS.HOME,
];

export enum USER_ROLES {
    UNVERIFIED = "unverified",
    CONFIRMED = "confirmed",
    WAITING = "wait-approve",
    ACTIVE = "active",
}

export enum ORDER_TYPES {
    ORDER = "order",
    PER_ORDER = "pre_order",
    RESERVE = "preserve",
}

export enum DISPLAY_TYPE {
    TABLE = "table",
    GRID = "grid",
}

export const ENDPOINT = "https://rde-crm.icodelab.eu"

export const breadcrumbNameMapExclude = ["/products/:categoryId/product-card", "/products/product-card"]

export const API_BACKEND_URL = "https://rde-service-core-exchange.icodelab.eu/api/v1"
export const API_AUTH_URL = "https://rde-service-auth.icodelab.eu/api/v1"
export const SEARCH_PARAMS: FilterItemsType = {
    name: "product_name_label",
    default_code: "code",
    barcode: "barcode",
    manufacturer_id: "brand",
}

export const DISPLAY_TABLE_FIELDS: FilterItemsType = {
    brand: "brand",
    product_code: "manufacturer_code",
    default_code: "code",
    image: "image",
}

export const PRODUCT_CONDITION: FilterItemsType = {
    not_opened: "Внешняя упаковка закрыта",
    ext_opened: "Внешняя упаковка открыта",
    ext_int_damaged: "Внешняя / Внутренняя упаковка отсутствует или повреждена",
    damaged: "Повреждения продукта (царапины, другое)",
    after_repair: "Продукт после гарантийного ремонта",
    half_equipped: "Неполная комплектация (отсутствуют провода, диск, документация, другое)",
}

export const LocalStorageSearchBy = "searchBy"
export const LocalStorageDisplayFields = "displayFields"

export const COUNTRIES = {
    "Austria": "Austria",
    "Belgium": "Belgium",
    "Bulgaria": "Bulgaria",
    "Croatia": "Croatia",
    "Cyprus": "Cyprus",
    "Czechia": "Czechia",
    "Denmark": "Denmark",
    "Estonia": "Estonia",
    "Finland": "Finland",
    "France": "France",
    "Germany": "Germany",
    "Greece": "Greece",
    "Hungary": "Hungary",
    "Ireland": "Ireland",
    "Italy": "Italy",
    "Latvia": "Latvia",
    "Lithuania": "Lithuania",
    "Luxembourg": "Luxembourg",
    "Malta": "Malta",
    "Netherlands": "Netherlands",
    "Poland": "Poland",
    "Portugal": "Portugal",
    "Romania": "Romania",
    "Slovakia": "Slovakia",
    "Slovenia": "Slovenia",
    "Spain": "Spain",
    "Sweden": "Sweden",
    "International": "International"
};

export enum MESSAGE_VARIANT {
    SUCCESS = "success",
    INFO = "info",
    WARNING = "warning",
    ERROR = "error",
}

export enum DATE_RANGE {
    DAY = "day",
    MONTH = "month",
    YEAR = "year",
    PERIOD = "period",
}

export enum RETURN_STATE {
    CANCEL = "cancel",
}

export enum WARRANTEE_STATE {
    CANCEL = "cancel",
}

export const CREDIT_LIMIT_TYPES: FilterItemsType = {
    credit_limit_days: "credit_limit_days_label",
    credit_limit: "credit_limit_label",
}

export const DeliveryOptions = [
    {"name": "Свой транспорт", "value": "by_own"},
    {"name": "Запросить доставку", "value": "request_delivery"},
]

export const ReturnsDeliveryLineOptions = [
    {"name": "Платно", "value": "paid"},
    {"name": "Бесплатно", "value": "free"},
]
