import axios from "axios";
import { resolve } from "./resolve.js";
import {API_BACKEND_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";

export type CreateCreditLimitDataType = {
    note: string | undefined,
    lines: {
        credit_limit_type: string
        requested_limit: number;
    }[];
}

export async function fetchBackendCreditLimits(pageNum: number) {
    const sessionId = getSession()
    const URL = `${API_BACKEND_URL}/credit_limit/page/${pageNum}`
    return await resolve(axios.get(URL,
            {
                headers: {
                    "Content-Type": "application/json",
                    "session_id": sessionId,
                }
            }
        ).then(res => res)
    );
}

export async function fetchBackendSingleCreditLimit(limitId: Number) {
    const sessionId = getSession()
    const URL = `${API_BACKEND_URL}/credit_limit/${limitId}`
    return await resolve(axios.get(URL,
            {
                headers: {
                    "Content-Type": "application/json",
                    "session_id": sessionId,
                }
            }
        ).then(res => res)
    );
}

export async function createBackendCreditLimitRequest(data: CreateCreditLimitDataType) {
    const URL = `${API_BACKEND_URL}/credit_limit/create`
    return await resolve(axios.post(URL,
        data, {
            headers: {
                'Content-Type': 'application/json',
                'session_id': getSession(),
            }
        }
    ).then(res => res));
}
