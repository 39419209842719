import axios from 'axios';
import { resolve } from './resolve.js';
import {API_AUTH_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";

export async function checkSession() {
    return await resolve(axios.post(`${API_AUTH_URL}/users/check`,
        {
            session_id: getSession(),
        }
    ).then(res => res));
}
