import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import {Link, useLocation} from "react-router-dom";
import * as React from "react";
import "./OrdersContent.sass"
import {BasePagination} from "../../pagination/Pagination";
import {ORDER_TYPES, ROUTE_URLS} from "../../../constants/consts";
import {useEffect} from "react";
import {Blured} from "../../blured/Blured";
import {t} from "i18next";
import {setOrderType} from "../../../store/slices/PagePaginagionSlice";

interface OrdersContentProps {
    type?: ORDER_TYPES;
}

export const OrdersContent = (props: OrdersContentProps) => {

    const {type} = props
    const dispatch = useAppDispatch();
    const {ordersData, isOrdersLoading} = useAppSelector(state => state.orders)

    useEffect(() => {
        dispatch(setOrderType(ORDER_TYPES.ORDER))
    }, [dispatch, type]);


    return (ordersData ?
        <Blured
            element={
                <>
                    <div className="ordersTableWrapper">
                        <table className="table ordersTable">
                            <thead className="cartTable__header">
                            <tr>
                                <th scope="col" className="text-colored text-start text-nowrap">{t('order_number')}</th>
                                <th scope="col" className="text-colored text-start text-nowrap">{t('name')}</th>
                                <th scope="col"
                                    className="text-colored text-start text-nowrap">{t('creation_date')}</th>
                                <th scope="col"
                                    className="text-colored text-start text-nowrap">{t('responsible')}</th>
                                <th scope="col"
                                    className="text-colored text-start text-nowrap">{t('status')}</th>
                                <th scope="col"
                                    className="text-colored text-start text-nowrap">{type === ORDER_TYPES.PRE_ORDER ? t('preorder_label') : t('order_label')}</th>
                                <th scope="col" className="text-colored text-end text-nowrap">{t('amount')}</th>
                            </tr>
                            </thead>
                            <tbody className="cartTable__body">
                            {ordersData?.sale_info.map((child) =>
                                <tr key={child.id}>
                                    <td>
                                        <Link
                                            className="catalog_product__link"
                                            to={`${ROUTE_URLS.ORDERS}/${child.id}`}
                                        >
                                            <span className="text-start fw-bold">{child.name}</span>
                                        </Link>
                                    </td>
                                    <td>
                                        <Link
                                            className="catalog_product__link"
                                            to={`${ROUTE_URLS.ORDERS}/${child.id}`}
                                        >
                                            <p className="text-start fw-bold">{child.reservation_name}</p>
                                        </Link>
                                    </td>
                                    <td className="text-start">
                                        <div><span>{child.date_order}</span></div>
                                    </td>
                                    <td className="text-start fw-bold">
                                        {child?.responsible_id?.name}
                                    </td>
                                    <td className="text-success bold-text">{child.delivery_status && child.delivery_status}</td>
                                    {child.is_b2b_active ? (
                                        <td className="text-success bold-text">{t('active')}</td>
                                    ) : (
                                        <td className="text-danger bold-text">{t('inactive')}</td>
                                    )}
                                    <td className="text-end">
                                        <div>
                                            <span>{child.amount_untaxed} {child.currency && child.currency["symbol"]}</span>
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>

                        <div>
                            {ordersData.pages_count > 1 && <BasePagination count={ordersData.pages_count}/>}
                        </div>
                    </div>
                </>

            }
            isLoading={isOrdersLoading}
        />
        : <></>)
}