import axios from "axios";
import {resolve} from "./resolve.js";
import {API_BACKEND_URL} from "../constants/consts";
import {getExchangeHeaders} from "./helpers";


export async function fetchAddresses() {
    return await resolve(axios.get(`${API_BACKEND_URL}/sales/addresses/get_addresses`,
            {
                headers: getExchangeHeaders()
            }
        ).then(res => res)
    );
}
