import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface UseMobileHeaderReturnValues {
    isOpen: boolean;
    languages: Array<Record<string, string>>;
    handleModalVisibility: VoidFunction;
    handleChangeLanguage: (language: string) => () => void;
    currentLanguage: Record<string, string>;
    iconClassName: string;
}

export const useMobileHeader = (): UseMobileHeaderReturnValues => {
    const [isOpen, setIsOpen] = useState(false);
    const iconClassName = isOpen ? "bi bi-x" : "bi bi-list";

    const handleModalVisibility = useCallback(() => {
        setIsOpen(prev => !prev);
    }, []);

    const { i18n } = useTranslation();
    const languages = useMemo(() => ([
        {
            label: 'LV',
            value: 'lv-LV',
        },
        {
            label: 'EN',
            value: 'en-UK',
        },
        {
            label: 'RU',
            value: 'ru-RU',
        },
        {
            label: 'LT',
            value: 'lt-LT',
        },
        {
            label: 'PL',
            value: 'pl-PL',
        },
        {
            label: 'ET',
            value: 'et-EE',
        },
    ]), []);

    const handleChangeLanguage = (language: string) => () => {
        i18n.changeLanguage(language);
        window.location.reload()
    };


    const currentLanguage = useMemo(() => {
        const prefixMatch = languages.find(lang => i18n.language.startsWith(lang.value.split('-')[0]));
        const exactMatch = languages.find(lang => i18n.language === lang.value);

        if (prefixMatch) {
            return prefixMatch;
        }

        if (exactMatch) {
            return exactMatch;
        }

        return languages[0];
    }, [i18n.language, languages]);

    return { handleModalVisibility, languages, currentLanguage, handleChangeLanguage, iconClassName, isOpen }
}
