import React from "react";
import {TabItem} from "../../components/tabs/TabItem";
import {TabList} from "../../components/tabs/TabList";
import "./WarrantyRequest.sass"
import {NewWarrantyRequest} from "../../components/warrantyRequest/newWarrantyRequest/NewWarrantyRequest";

export const inputStyle = {
    width: "100%",

}

export const inputStyleSM = {
    ...inputStyle,
    maxWidth: "130px",
    '& .MuiFormLabel-root': {
        fontSize: '0.8rem',
    },
    '& .MuiOutlinedInput-input': {
        fontSize: '0.8rem',
    }
}

export const inputAddressStyleSM = {
    ...inputStyleSM,
    maxWidth: "200px",
}

export const inputProps = {
    style: {fontSize: "12px"}
}

export const WarrantyRequest = () => {


    return (
        <>
            <div className="pt-3 pb-5 h-100">
                <TabList className="h-100" activeTabIndex={0}>
                    <TabItem label="Новая заявка на Гарантийное обслуживание">
                        <NewWarrantyRequest/>
                    </TabItem>
                    <TabItem className="h-100"  label="Актуальные запросы">
                        <p>Актуальные запросы</p>
                    </TabItem>
                    <TabItem className="h-100"  label="История запросов">
                        <p>История запросов</p>
                    </TabItem>
                </TabList>
            </div>
        </>
    )
}