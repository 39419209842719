import axios from "axios";
import { resolve } from "./resolve.js";
import {API_BACKEND_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";
import {getExchangeHeaders} from "./helpers";


export async function fetchProductsRequests(pageNum, categoryId, searchValue, activeFilters, operator, manufacturerSearchValue) {
    const sessionId = getSession()
    let requestUrl = `${API_BACKEND_URL}/products/page/${pageNum}/${categoryId}`
    const filter = {
        fields: activeFilters.join(","),
        term: searchValue,
    };
    const manufactureFilter = {
        fields: "manufacturer_id",
        term: manufacturerSearchValue,
    }
    if (operator) {
        filter.operator = operator;
        manufactureFilter.operator = operator;
    }
    if (activeFilters && searchValue) {
        if (manufacturerSearchValue) {
            requestUrl += `?filter=${JSON.stringify([filter, manufactureFilter])}`;
        }
        else requestUrl += `?filter=${JSON.stringify([filter])}`;
    }
    else if (manufactureFilter && manufacturerSearchValue) {
        requestUrl += `?filter=${JSON.stringify([manufactureFilter])}`;
    }

    return await resolve(axios.get(requestUrl,
        {
            headers: getExchangeHeaders(),
        }
    ).then(res => res)
    );
}

export async function fetchSearchProductsRequests(pageNum, searchValue, activeFilters, categId) {
    let requestUrl = `${API_BACKEND_URL}/products/search/`
    let params = {
        "page_number": pageNum,
    }
    if (activeFilters && searchValue) {
        params["filter"] = `${JSON.stringify([{fields: activeFilters.join(","), term: searchValue}])}`
    }
    if (categId) {
        params["categ_id"] = categId
    }


    return await resolve(axios.get(requestUrl,
        {
            params: params,
            headers: getExchangeHeaders(),
        }
    ).then(res => res)
    );
}
