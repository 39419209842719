import * as React from "react";
import {fetchReturnHistory} from "../../../api/returns";
import {useEffect, useState} from "react";
import {DATE_RANGE} from "../../../constants/consts";
import {SelectChangeEvent} from "@mui/material/Select";
import {TransactionsBarChart} from "../barChart/TransactionsBarChart";
import {Loader} from "../../loader/Loader";
import dayjs, {Dayjs} from "dayjs";


export const ReturnHistory = () => {

    const [dataset, setDataset] = useState([]);
    const [groupBy, setGroupBy] = useState<DATE_RANGE>(DATE_RANGE.MONTH);
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [dateFrom, setDateFrom] = React.useState<Dayjs | null>(null);
    const [dateTo, setDateTo] = React.useState<Dayjs | null>(null);



    const getOrdersDataset = async () => {
        let result = await fetchReturnHistory({
            groupby: groupBy,
            dateFrom: dateFrom,
            dateTo: dateTo
        })
        setIsLoading(false)
        if (!result.error) {
            setDataset(result.data.data.dataset);
        }
    }

    useEffect(() => {
        setIsLoading(true)
        getOrdersDataset()
    }, [groupBy, dateFrom, dateTo]);

    const handleChangeGroupBy = (event: SelectChangeEvent) => {
        setGroupBy(event.target.value as DATE_RANGE);
    }

    if (!dataset) return <></>

    return (
        <TransactionsBarChart
            dataset={dataset}
            groupBy={groupBy}
            isLoading={isLoading}
            handleChangeGroupBy={handleChangeGroupBy}
            label="История возвратов"
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
        />)
}