import "./NotificationAlert.sass"
import {t} from "i18next";

export const NotificationAlert = () => {
    return (
        <div className="notification-alert alert alert-warning">
            <div className="notification-alert__icon">
                <i className="bi bi-exclamation-triangle"></i>
            </div>
            <div className="notification-alert__message">
                <span>{t("cart_clear_info")}</span>
                <small>{t("cart_save_hint")}</small>
            </div>
        </div>
    )

}