import React from "react";
import {useAppSelector} from "../../../../hooks/ReduxHooks";

export const OrderTotal = () => {
    const {orderData} = useAppSelector(state => state.order)

    return (
        <div id="total">
            <div className="col-xs-7 col-md-5 ms-auto">
                <table className="table table-sm">
                    <tbody>
                    <tr>
                        <td className="text-colored">
                            <strong>Итого без НДС</strong></td>
                        <td className="text-end">
                            <span>{orderData && orderData.tax_totals.formatted_amount_untaxed}</span>
                        </td>
                    </tr>
                    {(orderData && !orderData.is_reserve) && (
                        <tr>
                            <td className="text-colored">
                                <strong>Скидка</strong></td>
                            <td className="text-end">
                                <span>{orderData.order_line.reduce((n, {discount, price_subtotal}) => {
                                    return (discount/100) * price_subtotal
                                }, 0)} {orderData.currency && orderData.currency.symbol}</span>
                            </td>
                        </tr>
                    )}

                    <tr className="border-black">
                        <td className="text-colored">
                            <strong>Итого с НДС</strong></td>
                        <td className="text-end">
                            <span>{orderData && orderData.tax_totals.formatted_amount_total}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}