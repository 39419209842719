import React, {useContext, useEffect, useState} from "react";
import {OrderLinesContext} from "../../../../hooks/UseOrderLinesSelectContext";
import {checkboxStyleSM} from "../../../catalog/catalogControl/style";
import Checkbox from "@mui/material/Checkbox";
import "./ReturnTable.sass"
import {MessageContext} from "../../../../hooks/UseMessage";
import {ReturnLineType} from "../../../catalog/types";

export interface ReturnTableProps {
    returnLine : ReturnLineType,
}

export const ReturnTableTr = (props: ReturnTableProps) => {
    const {returnLine} = props
    const { selectedLines, setSelectedLines, setLines} = useContext(OrderLinesContext)
    const [isChecked, setIsChecked] = useState(selectedLines.includes(returnLine.id))

    useEffect(() => {
        setIsChecked(selectedLines.includes(returnLine.id))
    }, [selectedLines]);

    const handleLineCheck = () => {
        if (selectedLines.includes(returnLine.id)) {
            setSelectedLines(selectedLines.filter((item) => item !== returnLine.id));
        } else {
            setSelectedLines([...selectedLines, returnLine.id]);
        }
    }

    return (
        <tr className="" key={`line_${returnLine.id}`}>
            <td scope="row">
                <Checkbox
                    checked={isChecked}
                    sx={checkboxStyleSM}
                    value={""}
                    onChange={handleLineCheck}
                />
            </td>
            <td>
                <span className="d-block text-wrap">{returnLine.product_data.name}</span>
            </td>
            <td>
                <span className="d-block text-wrap">
                    {returnLine.product_data.default_code}
                </span>
            </td>
            <td className="text-center">
                <span>{returnLine.quantity}</span>
            </td>
            <td className="text-center">
                <span className="d-block text-wrap">
                    {/*{returnLine.order_id.name}*/}
                </span>
            </td>
            <td className="text-center">
                <span className="d-block text-wrap">
                    {returnLine.waybill_number}
                </span>
            </td>
            <td>
                <span className="d-block text-wrap">
                    {returnLine.return_reason}
                </span>
            </td>
            <td>
                <span className="d-block text-wrap">
                    {returnLine.status}
                </span>
            </td>
            <td className="text-end d-none d-sm-table-cell priceCell">
                <div className=" text-end">{returnLine.price_unit} {returnLine?.currency_id?.symbol}</div>
            </td>
            <td className="text-end d-none d-sm-table-cell priceCell">
                <div className=" text-end">{returnLine.amount_total} {returnLine?.currency_id?.symbol}</div>
            </td>
        </tr>
    )
}