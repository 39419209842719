import {getSession} from "./sessionManagement";
import {API_BACKEND_URL, WARRANTEE_STATE} from "../constants/consts";
import {resolve} from "./resolve";
import axios from "axios";
import {getExchangeHeaders} from "./helpers";

export type CreateWarrantyRequestDataType = {
    attachments?: any;
    responsible_user_id?: number | undefined;
    id: number | undefined;
    quantity: number;
    state: string | undefined;
    defect_id: number | undefined;
    comment: string | undefined;
}

export type UpdateWarrantyReturnDataType = {
    id: number;
    return_state?: WARRANTEE_STATE;
}

export async function fetchNewWarrantyLines(data: any) {
    const sessionId = getSession()
    const URL = `${API_BACKEND_URL}/warranty/get_warranty_lines`
    return await resolve(axios.post(URL,
            data,{
                headers: getExchangeHeaders(),
            }
        ).then(res => res)
    );
}

export async function fetchWarrantyDefectTypes() {
    const sessionId = getSession()
    const URL = `${API_BACKEND_URL}/warranty/defect_types`
    return await resolve(axios.get(
        URL,
    {
            headers: getExchangeHeaders(),
        }
        ).then(res => res)
    );
}


export async function createBackendWarrantyRequest(data: FormData) {
    const URL = `${API_BACKEND_URL}/warranty/create`
    return await resolve(axios.post(URL,
        data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'session_id': getSession(),
            }
        }
    ).then(res => res));
}

export async function fetchBackendWarrantyRequests(pageNum: number, isActive: boolean = false) {
    const sessionId = getSession()
    const additionalParams = `?is_active=${isActive}`
    const URL = `${API_BACKEND_URL}/warranty/page/${pageNum}${additionalParams}`
    return await resolve(axios.get(URL,
            {
                headers: getExchangeHeaders(),
            }
        ).then(res => res)
    );
}

export async function fetchSingleWarrantyRequests(warrantyId: Number) {
    const sessionId = getSession()
    const URL = `${API_BACKEND_URL}/warranty/${warrantyId}`
    return await resolve(axios.get(URL,
            {
                headers: getExchangeHeaders(),
            }
        ).then(res => res)
    );
}

export async function updateBackendWarrantyRequests(data: UpdateWarrantyReturnDataType){
    const URL = `${API_BACKEND_URL}/warranty/update`
    return await resolve(axios.patch(URL,
        data,{
            headers: getExchangeHeaders(),
        }
    ).then(res => res));
}
