import React from "react";
import {
    CategoryItem,
    CategoryTitle,
    HiddenCategoriesChip,
} from "../styles";
import { useIsMobile } from "../../../../../hooks/useIsMobile.hook";

interface HiddenCountProps {
    title: string;
}

export const HiddenCount = ({ title }: HiddenCountProps) => {
    const isMobile = useIsMobile();

    return isMobile ? <HiddenCategoriesChip>{title}</HiddenCategoriesChip> : (
        <CategoryItem>
            <CategoryTitle>{title}</CategoryTitle>
        </CategoryItem>
    );
}
