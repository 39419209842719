import {
    SearchPanelNavbarContainer,
    ShopCartArea,
    UserInfo,
    TopSpan,
    CurrentBalance,
    AmountTotal,
    StyledNavBar
} from "./styles";
import * as React from "react";

import {SearchPanelBase} from "../searchPanel/SearchPanelBase";
import {memo, useContext, useEffect, useRef, useState} from "react";
import NavButtonCart from "./NavButtonCart";
import NavButtonNotification from "./NavButtonNotification";
import NavButtonPerson from "./NavButtonPerson";
import {Link, matchPath, useLocation, useMatches, useParams} from "react-router-dom";
import {HIDE_ROUTES, DISPLAY_SEARCH_NAV_BAR_ROUTES} from "../../constants/consts";
import {NavbarContext} from "../../hooks/UseNavbarContext";
import {BaseBreadCrumbs} from "../breadcrumbs/Breadcrumbs";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {fetchCart} from "../../store/slices/ActiveCartReducerSlice";
import {getSession, getSessionUserCompany} from "../../api/sessionManagement";


export const Navbar = memo(() => {
    const [balance, setBalance] = useState(0);
    const [person, setPerson] = useState("Person");
    const [company, setCompany] = useState(getSession() ? getSessionUserCompany() : "Company");
    const [level, setLevel] = useState("Platina");
    const location = useLocation();
    const displayComponentSearchPanel = DISPLAY_SEARCH_NAV_BAR_ROUTES.some(element => matchPath(element, location.pathname));
    const hideComponentNavBar = HIDE_ROUTES.some(element => matchPath(element, location.pathname));
    const {navbarBadgeTrigger, setNavbarBadgeTrigger} = useContext(NavbarContext);
    const navButtonCartRef = useRef(null);
    const dispatch = useAppDispatch()
    const {activeCartData } = useAppSelector(state => state.activeCart)


    useEffect(() => {
        dispatch(fetchCart())
    }, [navbarBadgeTrigger, location]);


    return (
        <>
            {!hideComponentNavBar &&
                <StyledNavBar style={{gap:"50px"}} className="d-flex navbar navbar-light">
                    {!displayComponentSearchPanel && <BaseBreadCrumbs/>}
                    <div style={{flex:"1"}}>
                        {displayComponentSearchPanel && <SearchPanelNavbarContainer>
                            <SearchPanelBase/>
                        </SearchPanelNavbarContainer>
                        }
                    </div>
                    <div className="justify-content-end">
                        <div className="d-flex" style={{gap:"15px"}}>

                            <div className="company-label d-flex align-items-center">
                                <NavButtonPerson/>
                                <span style={{fontWeight:"500"}}>{company}</span>
                            </div>
                            <NavButtonNotification/>
                            <ShopCartArea className="d-flex">
                                <div>
                                    <Link to="/cart"><NavButtonCart ref={navButtonCartRef}/></Link>
                                </div>
                                <div>
                                    <b className="me-2">{!activeCartData && 0} {!activeCartData?.currency && "€"} {activeCartData?.amount_with_vat && activeCartData.amount_with_vat.toFixed(2)} {activeCartData?.currency && activeCartData.currency["symbol"]}</b>
                                </div>
                            </ShopCartArea>
                        </div>
                    </div>
                </StyledNavBar>
            }
        </>

    )
})