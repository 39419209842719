import axios, {AxiosResponse} from 'axios';
import { resolve } from './resolve.js';
import {API_AUTH_URL, API_BACKEND_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";


export async function initRecoverPassword(data: string ) {
    const URL = `${API_AUTH_URL}/users/recover/password/email`
    return await resolve(axios.post(URL,
        data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'session_id': getSession(),
            }
        }
    ).then(res => res));
}


export async function validateToken(recoverToken: string ) {

    const URL = `${API_AUTH_URL}/users/recover/password/check/`
    return await resolve(axios.get(URL,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'session_id': getSession(),
            },
            params: {
                'token': recoverToken
            }
        }
    ).then(res => res));
}

export async function recoverPassword(data: string, recoverToken: string ) {
    const URL = `${API_AUTH_URL}/users/recover/password/`
    return await resolve(axios.post(URL,
        data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'session_id': getSession(),
            },
            params: {
                'token': recoverToken
            }
        }
    ).then(res => res));
}
