import React from "react";
import {useAppSelector} from "../../../../hooks/ReduxHooks";
import "./ReturnSidebar.sass"
import Avatar from "../../../../images/avatar.png";


export const ReturnSidebar = () => {
    const {returnData} = useAppSelector(state => state.return)

    const handleClickDownload = () => {
    }

    if (!returnData) return <></>

    return (<>
        <div className="order_sidebar flex-grow-1 mb-4 mb-lg-0 pe-lg-4">
            <div
                className="position-relative d-flex align-items-center justify-content-md-center justify-content-lg-between flex-wrap gap-2">
                <h2 className="mb-0 text-break">
                    <span className="currency_value">

                    </span>

                </h2>
            </div>
            <div className="d-flex flex-column gap-4 mt-3">
                <div className="d-flex flex-column gap-2" id="sale_order_sidebar_button">
                    <div className="d-flex gap-2 flex-lg-column flex-xl-row flex-wrap">
                        <button
                            className="btn btn-light flex-grow-1"
                            title="Скачать"
                            role="button"
                            onClick={handleClickDownload}
                        >Скачать
                        </button>
                    </div>
                </div>
                <div>
                    <h6><small className="text-muted">{returnData.responsible_user}</small></h6>
                    <div className="d-flex flex-column gap-2">
                        <div className="avatar__wrapper d-flex gap-2">
                            <img className="avatar portal_contact_img rounded" alt="..."
                                 src={Avatar}
                                 loading="lazy"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}