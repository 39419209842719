// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category__wrapper {
  width: 100%;
}
.category__wrapper .category__image-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
}
.category__wrapper .category__image {
  object-fit: scale-down;
  object-position: center;
  height: 500px;
  width: 500px;
  margin-bottom: 5px;
}
.category__wrapper .category__swiper_img {
  height: 90px;
  width: 90px;
  object-fit: scale-down;
}
.category__wrapper .swiper-slide {
  width: 160px;
  padding: 2px;
  cursor: pointer;
}
.category__wrapper .swiper-slide:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}
.category__wrapper .swiper-slide img {
  object-fit: scale-down;
  width: 60%;
  height: 60%;
}
.category__wrapper .active {
  transform: scale(1.1);
  color: rgb(44, 193, 244);
}
.category__wrapper .swiper-wrapper {
  width: auto;
  padding: 0 140px;
}
.category__wrapper .swiper-button-prev:after, .category__wrapper .swiper-button-next:after {
  font-size: 10px !important;
  color: black;
  background-color: white;
  border-radius: 50%;
  padding: 10px 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/swiperSearchCategoties/SwiperSearchCategories.sass"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,aAAA;EACA,uBAAA;EACA,qBAAA;AACJ;AACE;EACE,sBAAA;EACA,uBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;AACJ;AACE;EACE,YAAA;EACA,WAAA;EACA,sBAAA;AACJ;AACE;EACE,YAAA;EACA,YAAA;EACA,eAAA;AACJ;AACI;EACE,+CAAA;AACN;AACI;EACE,sBAAA;EACA,UAAA;EACA,WAAA;AACN;AAEE;EACE,qBAAA;EACA,wBAAA;AAAJ;AAEE;EACE,WAAA;EACA,gBAAA;AAAJ;AAEE;EACE,0BAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;AAAJ","sourcesContent":[".category__wrapper\n  width: 100%\n\n  .category__image-wrapper\n    display: flex\n    justify-content: center\n    align-content: center\n\n  .category__image\n    object-fit: scale-down\n    object-position: center\n    height: 500px\n    width: 500px\n    margin-bottom: 5px\n\n  .category__swiper_img\n    height: 90px\n    width: 90px\n    object-fit: scale-down\n\n  .swiper-slide\n    width: 160px\n    padding: 2px\n    cursor: pointer\n\n    &:hover\n      box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px\n\n    img\n      object-fit: scale-down\n      width: 60%\n      height: 60%\n\n\n  .active\n    transform: scale(1.1)\n    color: rgb(44, 193, 244)\n\n  .swiper-wrapper\n    width: auto\n    padding: 0 140px\n\n  .swiper-button-prev:after, .swiper-button-next:after\n    font-size: 10px!important\n    color: black\n    background-color: white\n    border-radius: 50%\n    padding: 10px 12px\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
