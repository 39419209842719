// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.to_order {
  width: 70px;
  border: none;
  text-align: center;
}
.to_order:focus-visible {
  border: none !important;
}
.to_order:focus {
  border: none;
}

.priceCell {
  width: 100px;
}

.taxCell {
  width: 100px;
}

.so__row {
  font-size: 12px;
}

.order__table {
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/components/orders/orderContent/orderTable/OrderTable.sass"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AACF;AACE;EACE,uBAAA;AACJ;AACE;EACE,YAAA;AACJ;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,YAAA;AAGF;;AAAE;EACE,eAAA;AAGJ;;AAAE;EACE,eAAA;AAGJ","sourcesContent":[".to_order\n  width: 70px\n  border: none\n  text-align: center\n\n  &:focus-visible\n    border: none !important\n\n  &:focus\n    border: none\n\n.priceCell\n  width: 100px\n\n.taxCell\n  width: 100px\n\n.so\n  &__row\n    font-size: 12px\n\n.order\n  &__table\n    font-size: 13px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
