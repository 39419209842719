import {HomeSwiper} from "../../components/swiper/Swiper";
import {CatalogGridWrapper} from "../../components/catalog/catalogGrid/styles";
import {CatalogCard} from "../../components/catalog/catalogGrid/catalogCard/CatalogCard";
import {ProductPreviewModal} from "../../components/catalog/productPreviewModal/ProductPreviewModal";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {setCatalogSearchValue, setDisplayType} from "../../store/slices/PagePaginagionSlice";
import {DISPLAY_TYPE, EMPTY_STRING, ROUTE_URLS} from "../../constants/consts";
import {SalesHistory} from "../../components/graphs/salesHystory/SalesHystory";
import {ReturnHistory} from "../../components/graphs/returnHystory/ReturnHystory";
import "./HomePage.sass"
import {TopItems} from "../../components/graphs/topItems/TopItems";
import {FinancialData} from "../../components/graphs/financailData/FinancialData";
import {OrdersHomeTable} from "../../components/orders/ordersHomeTable/OrdersHomeTable";
import {ReservesHomeTable} from "../../components/orders/reservesHomeTable/ReservesHomeTable";
import {SearchResult} from "../../components/searchResult/SearchResult";
import {fetchSearchProducts, resetCatalogProducts} from "../../store/slices/CatalogProductsReducerSlice";
import {useLocation, useNavigate} from "react-router-dom";
import {useDidUpdate} from "../../hooks/useDidUpdate";

export const HomePage = () => {
    const [categId, setCategId] = useState<number | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(setCatalogSearchValue(EMPTY_STRING))
    }, [location]);

    const {
        isProductLoading,
        productInfo,
    } = useAppSelector(state => state.catalogProducts)

    const {isCartLoading} = useAppSelector(state => state.activeCart)

    const {searchValue} = useAppSelector((state: any) => state.page)

    dispatch(setDisplayType(DISPLAY_TYPE.GRID))

    useEffect(() => {
        if (!isCartLoading && !isProductLoading)
            setIsLoading(false)
    }, [isCartLoading, isProductLoading]);

    useDidUpdate(() => {
        if (!searchValue || searchValue === EMPTY_STRING) return
        navigate(ROUTE_URLS.CATALOG, { state: { searchValue: searchValue } });
    }, [searchValue]);

    useEffect(() => {
        setCategId(undefined)
    }, [searchValue]);

    return (
        <>
            <HomeSwiper/>
            <div className="graphs__wrapper">
                <SalesHistory/>
                <TopItems/>
                <ReturnHistory/>
            </div>
            <div className="graphs__wrapper">
                <div className="graphs__fin_data">
                    <FinancialData/>
                </div>
                <div className="graphs__table_data">
                    <OrdersHomeTable/>
                    <ReservesHomeTable/>
                </div>
            </div>

            <div className="titleTop">
                <h4>Новая поставка на склад Trade</h4>
            </div>
            <CatalogGridWrapper className="catalog_grid--justify">
                {(!isLoading && productInfo) && productInfo.map((child) =>
                    <CatalogCard key={child.id} categoryID={categId} productData={child} isMainPage={true}/>
                )}
            </CatalogGridWrapper>
            <ProductPreviewModal/>
        </>
    )
}
