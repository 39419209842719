import {getSession} from "./sessionManagement";
import {resolve} from "./resolve";
import axios from "axios";
import {API_BACKEND_URL} from "../constants/consts";


export async function fetchFinTermsRequests() {
    return await resolve(axios.get(`${API_BACKEND_URL}/contact/financial_information`,
        {
            headers: {
                "Content-Type": "application/json",
                "session_id": getSession(),
            }
        }

    ).then(res => res));
}
