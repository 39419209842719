import "./SortSelection.sass"


export const SortSelection = () => {
    return (
        <select role="button" defaultValue={'code'} className="sort-select" aria-label="Default select example">
            <option  value="code">код</option>
            <option value="manufacture">Код производителя</option>
            <option value="brand">Брэнд</option>
            <option value="price">Цена</option>
        </select>
    )
}
