import styled from "styled-components";
import { motion } from "framer-motion";
import { systemWhite } from "../../constants";

export const CarouselSectionContent = styled(motion.section)`
    padding-bottom: 50px;
    background-color: #FAFBFF;

    @media (max-width: 768px) {
        background-color: ${systemWhite};
    }
`;

export const CarouselImage = styled.img`
    width: 100%;
    height: 250px;
    border-radius: 8px;
`;

export const CarouselImageContainer = styled.div`
    padding: 0 10px;
`;
