import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';

type LocalizedReCaptchaProps = {
    siteKey: string;
    onChange: (value: string | null) => void;
};

const RECAPTCHA_BLOCK_HEIGHT = 74;
const RECAPTCHA_BLOCK_WIDTH = 300;

export const LocalizedReCaptcha: React.FC<LocalizedReCaptchaProps> = ({ siteKey, onChange }) => {
    const { i18n } = useTranslation();
    const [ready, setReady] = useState(false);
    const [currentLang, setCurrentLang] = useState<string | null>(null);

    useEffect(() => {
        const lang = i18n.language || 'en';

        if (currentLang === lang && (window as any).grecaptcha) {
            setReady(true);
            return;
        }

        setReady(false);
        setCurrentLang(lang);

        const scriptId = 'recaptcha-script';
        const existingScript = document.getElementById(scriptId);
        if (existingScript) {
            existingScript.remove();
            delete (window as any).grecaptcha;
        }

        const script = document.createElement('script');
        script.id = scriptId;
        script.src = `https://www.google.com/recaptcha/api.js?hl=${lang}`;
        script.async = true;
        script.defer = true;

        script.onload = () => {
            const checkGrecaptchaReady = () => {
                if ((window as any).grecaptcha && (window as any).grecaptcha.render) {
                    setReady(true);
                } else {
                    setTimeout(checkGrecaptchaReady, 50);
                }
            };
            checkGrecaptchaReady();
        };

        script.onerror = () => {
            console.error('Failed to load ReCAPTCHA script');
        };

        document.body.appendChild(script);
    }, [i18n.language]);

    if (!ready) {
        return (
            <Skeleton
                variant="rectangular"
                width={RECAPTCHA_BLOCK_WIDTH}
                height={RECAPTCHA_BLOCK_HEIGHT}
                animation="wave"
            />
        );
    }

    return (
        <ReCAPTCHA
            sitekey={siteKey}
            onChange={onChange}
        />
    );
};
