import {getSession} from "./sessionManagement";
import {API_BACKEND_URL} from "../constants/consts";
import {resolve} from "./resolve";
import axios from "axios";

export async function fetchNewWarrantyLines(data) {
    const sessionId = getSession()
    const URL = `${API_BACKEND_URL}/sales/get_warranty_lines`
    return await resolve(axios.post(URL,
            data,{
                headers: {
                    "Content-Type": "application/json",
                    "session_id": sessionId,
                }
            }
        ).then(res => res)
    );
}