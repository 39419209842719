import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchBackendSingleCreditLimit} from "../../api/creditLimits";
import {CreditLimitType} from "../../components/catalog/types";

export type initialStateType = {
    isCreditLimitLoading: boolean;
    creditLimitData: CreditLimitType | undefined

}
const initialState: initialStateType = {
    creditLimitData: undefined,
    isCreditLimitLoading: true
}

export const fetchCreditLimit =
    createAsyncThunk("slice/fetchCreditLimit",
        async (limitId: number) => {
            const result = await fetchBackendSingleCreditLimit(limitId)
            return result.data?.data
        })
export const resetCreditLimit =
    createAsyncThunk("slice/resetCreditLimit",
        async () => initialState
    )

const creditLimitSliceReducer = createSlice({
    name: "creditLimit",
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchCreditLimit.fulfilled, (state, action) => {
            state.creditLimitData = action.payload
            state.isCreditLimitLoading = false
        }).addCase(resetCreditLimit.fulfilled, (state, action) => {
            Object.assign(state, initialState);
            state.isCreditLimitLoading = false;
        })
    }
})

export default creditLimitSliceReducer.reducer