import axios, {AxiosResponse} from 'axios';
import { resolve } from './resolve.js';
import {API_AUTH_URL} from "../constants/consts";

export async function userLogin(email: any, password: any): Promise<AxiosResponse["data"]> {
    return await resolve(axios.post(`${API_AUTH_URL}/users/login`,
        {
            headers: {
                'Access-Control-Allow-Origin': '*'
            },
            email: email,
            password: password,
        }
    ).then(res => res));
}
