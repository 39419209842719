import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hooks/ReduxHooks";
import {useLocation} from "react-router-dom";
import {BreadcrumbsDataProps} from "../../../breadcrumbs/Breadcrumbs";
import {updateBreadcrumb} from "../../../../store/slices/BreadcrumbSlice";

export const ReturnIntroduction = () => {

    const {returnData} = useAppSelector(state => state.return)
    const location = useLocation()
    const dispatch = useAppDispatch()

    if (returnData) {
        let pr: BreadcrumbsDataProps = {}
        pr[location.pathname] = returnData?.name || ""
        dispatch(updateBreadcrumb(pr))

    }

    return (
        <>
            <div className="border-bottom-0 pt-0 pb-3">
                <div className="d-flex bold-text justify-content-between gap-2">
                        <>
                            <h3>
                                Запрос: <span className="text-colored">{returnData && returnData.name}</span>
                            </h3>
                            <div>
                                Статус: <span className="text-success">{returnData && returnData.return_state}</span>
                            </div>
                        </>
                </div>
            </div>
        </>
    )
}