import "./CreditLineHistory.sass"
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import React, {useEffect, useState} from "react";
import {fetchCreditLimits} from "../../../store/slices/CreditLimitsReducerSlice";
import {Loader} from "../../loader/Loader";
import {BasePagination} from "../../pagination/Pagination";
import {ROUTE_URLS} from "../../../constants/consts";
import {useNavigate} from "react-router-dom";


export const CreditLineHistory = () => {

    const {isCreditLimitsLoading, creditLimitsData} = useAppSelector(state => state.creditLimits)
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const {page} = useAppSelector(state => state.page)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchCreditLimits({pageNum: page}))
    }, [page]);

    const handleClickRow = (limitId: number) => {
        navigate(`${ROUTE_URLS.CREDIT_LIMIT_HISTORY}/${limitId}`)

    }

    useEffect(() => {
        setIsLoading(isCreditLimitsLoading)
    }, [isCreditLimitsLoading]);


    return (<>
            {isLoading ? (
                <Loader/>
            ) : (creditLimitsData && (
                <div className="creditLineTableWrapper">
                <table className="table creditLineTable">
                    <thead className="creditLineTable__header">
                    <tr>
                        <th scope="col" className="text-colored text-center text-nowrap">Дата</th>
                        <th scope="col" className="text-colored text-center text-nowrap">Ответственный</th>
                        <th scope="col" className="text-colored text-center text-nowrap">Статус</th>
                    </tr>
                    </thead>
                    <tbody className="creditLineTable__body">
                    {creditLimitsData.credit_limit_info && creditLimitsData.credit_limit_info.map(limitData => {
                        return (<tr onClick={() => handleClickRow(limitData.id)}>
                            <td className="text-center">
                                <span>{limitData.create_date.split(" ")[0]}</span>
                            </td>
                            <td className="text-center">
                                <span>{limitData.partner_id?.name}</span>
                            </td>
                            <td className="text-center">
                                <span>{limitData.state}</span>
                            </td>
                        </tr>)
                    })}
                    </tbody>
                </table>
                <div>
                    {(creditLimitsData.pages_count) > 1 && <BasePagination count={creditLimitsData.pages_count}/>}
                </div>
            </div>))
            }
        </>
    )
}