import React from "react";
import {
    AdvantagesSectionContent,
    Pane,
    AdvantagesSectionContainer,
} from "./styles";
import Box from "../../../../images/icons/box.svg";
import Computer from "../../../../images/icons/computer.svg";
import Credit from "../../../../images/icons/credit.svg";
import Discounts from "../../../../images/icons/discounts.svg";
import ShieldCheck from "../../../../images/icons/shield-check.svg";
import UserGroup from "../../../../images/icons/user-group.svg";
import { SectionTitle } from "../../styles";
import { AdvantageItem } from "./components/advantageItem";
import { t } from "i18next";



export const AdvantagesSection = () => {
    return (
        <AdvantagesSectionContent
            initial={{opacity: 0, x: -50}}
            whileInView={{opacity: 1, x: 0}}
            transition={{duration: 0.8}}
        >
            <SectionTitle>{t("advantage_section_title")}</SectionTitle>
            <AdvantagesSectionContainer>
                <Pane>
                    <AdvantageItem icon={Credit} title={`${t("advantages_section_container_credit_main")}`} subtitle={`${t("advantages_section_container_credit_title")}`} />
                    <AdvantageItem icon={Box} title={`${t("advantages_section_container_box_main")}`} subtitle={`${t("advantages_section_container_box_title")}`} />
                    <AdvantageItem icon={ShieldCheck} title={`${t("advantages_section_container_shield_check_main")}`} subtitle={`${t("advantages_section_container_shield_check_title")}`} />
                </Pane>
                <Pane>
                    <AdvantageItem icon={Discounts} title={`${t("advantages_section_container_discounts_main")}`} subtitle={`${t("advantages_section_container_discounts_title")}`} />
                    <AdvantageItem icon={Computer} title={`${t("advantages_section_container_computer_main")}`} subtitle={`${t("advantages_section_container_computer_title")}`} />
                    <AdvantageItem icon={UserGroup} title={`${t("advantages_section_container_user_group_main")}`} />
                </Pane>
            </AdvantagesSectionContainer>
        </AdvantagesSectionContent>
    );
}
