import {FC, useCallback, useEffect, useState} from "react";
import { useAuth } from "../../hooks/UseAuth";
import { ButtonCustom, Container } from "./styles";
import {COUNTRIES, ROUTE_URLS, USER_ROLES} from "../../constants/consts";
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {
    checkUserRole, clearLocalStorage, getIsTwoFactorAuthorized, getIsUserLoggedIn,
    getSession,
    getSessionUserCompany,
    getSessionUserRole, getTwoFactorEnabled, setSession, setSessionUserCompany,
    setSessionUserRole, setUserEmail
} from "../../api/sessionManagement";
import "./Form.sass";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import {Link, useNavigate} from "react-router-dom";
import {Card, CardContent,Button} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import {userLogin, userSignUpSecond} from "../../api/userRegistration";
import Swal from "sweetalert2";

interface FormProps {
    title: string;
    isSignup?: boolean;
    handleClick: (
        email: string, password: string, region: string, companyName: string, vat: string, phone: string, registration: string, zip: string, city: string, address: string, owners: string[], boards: string[]
    ) => void;
}

export const Form: FC<FormProps> = ({ title, handleClick, isSignup }) => {
    const {
        email,
        password,
        zip,
        city,
        address,
        boards,
        owners,
        region,
        companyName,
        vat,
        phone,
        registration,
        handleChangeZip,
        handleChangeBoard,
        handleAddBoard,
        handleRemoveBoard,
        handleChangeCity,
        handleChangeAddress,
        handleChangeOwner,
        handleAddOwner,
        handleRemoveOwner,
        handleChangeEmail,
        handleChangePassword,
        handleChangeRegion,
        handleChangeCompanyName,
        handleChangeVat,
        handleChangePhone,
        handleChangeRegistration,
        handleAuth,
    } = useAuth({ handleClick });
    const [isSecondStep, setIsSecondStep] = useState<boolean>(() => {
        const savedStep = localStorage.getItem('isSecondStep');
        return savedStep ? JSON.parse(savedStep) : false;
    });
    const [isActiveSession, setIsActiveSession] = useState<boolean>(false)
    const optionsForAlertSuccess:any = {title: 'Регистрация прошла успешно!', text: 'Вам придет сообщение на почту с подтверждением регистрации!', icon: 'success', confirmButtonText: 'ОК',confirmButtonColor: '#0ebfff'}

    const [errors, setErrors] = useState({
        email: "",
        password: "",
        companyName: "",
        region: "",
        registration: "",
        vat: "",
        phone: "",
        zip: "",
        city: "",
        address: "",
    });


    const handleClickSubmitAuth = async (event: any) => {
        event.preventDefault();
        if (isSignup && isSecondStep) {
            let result = await userSignUpSecond(owners, boards,companyName,email,phone,vat,registration,city,address,zip,region)
            if (result) {
                Swal.fire(optionsForAlertSuccess).then(() => {
                    localStorage.setItem('isSecondStep', JSON.stringify(false));
                    clearLocalStorage()

                });
                navigate('/sign-in')
            }

        } else if (isSignup && !isSecondStep) {
            handleAuth();
        } else {
            handleAuth();
        }
    };

    useEffect(() => {
        const handleStorageChange = () => {
            const savedStep = localStorage.getItem('isSecondStep');
            if (savedStep !== null) {
                setIsSecondStep(JSON.parse(savedStep));
            }
        };

        window.addEventListener('localStorageUpdate', handleStorageChange);
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('localStorageUpdate', handleStorageChange);
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);


    useEffect(() => {
        if (getSession() && getIsUserLoggedIn()){
            setIsActiveSession(true)
        }
        else {
            setIsActiveSession(false)
        }
    }, []);

    const navigate = useNavigate();

    const textFieldStyles = {
        transition: 'width 0.3s ease-in-out',
        width: '100%', // Стандартная ширина
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px', // Добавление скругленных углов для соответствия макету
            backgroundColor: '#ebebeb', // Цвет фона
            padding:"0 10px",
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                border: 'none',
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        },
        '&:focus-within': {
            width: "calc(100% + 20px)",
        }
    }

    const inputPropsStyles = {
        '&::placeholder': {
            color: '#757575',
            fontWeight: 'bold',
        },
        '& .MuiInputBase-input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px #ebebeb inset',
            WebkitTextFillColor: '#000000',
            transition: 'background-color 5000s ease-in-out 0s',
        },
        '& .MuiInputBase-input': {
            color: '#000000',
            fontWeight: 'bold',
        },
    };

    const inputLabelPropsStyles = {
        color: '#757575',
        fontWeight: 'bold',
        '&.Mui-focused': {
            color: '#757575',
        },
    };

    const formControlStyles = {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            backgroundColor: '#ebebeb',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                border: 'none',
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        },
    };

    const selectStyles = {
        fontWeight: 'bold',
        color: '#000000', // Цвет текста выбора
        '&:focus': {
            backgroundColor: '#ebebeb',
        },
    };

    const handleBannerClick = async () => {
        const isTwoFactorAuthorized = getIsTwoFactorAuthorized();
        const isTwoFactorEnabled = getTwoFactorEnabled();
        let result = await checkUserRole()
        if (!result.error) {
            setSession(result.data.data.session);
            setSessionUserRole(result.data.data.role);
            let userRole:any = getSessionUserRole()
            if (isTwoFactorEnabled && !isTwoFactorAuthorized && result.data.data.is_need_verify) {
                navigate(ROUTE_URLS.TWO_FACTOR_AUTH);
            } else if (userRole && [USER_ROLES.CONFIRMED,USER_ROLES.WAITING].includes(userRole)) {
                navigate(ROUTE_URLS.QUESTIONARY);
            }
            else {
                navigate(ROUTE_URLS.HOME);
            }
        }
    };

    const handleRegionChange = (event: any) => {
        handleChangeRegion(event);
    };

    const handleLogoutClick = () => {
        clearLocalStorage()
        setIsActiveSession(false)
    }


    return (
        <>
            {
                isActiveSession ? <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px', // Space between card and button
                            marginTop: '5px',
                        }}
                    >
                        <Card
                            onClick={handleBannerClick}
                            sx={{
                                display: 'flex',
                                flex:'1',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#ffffff',
                                padding: '20px',
                                borderRadius: '12px',
                                boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.35)',
                                marginTop: '5px',
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    transform: 'translateY(-5px)',
                                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.35)',
                                },
                            }}
                        >
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: 0,
                                    paddingBottom: "0 !important",
                                }}
                            >
                                <PersonIcon sx={{ fontSize: '40px', color: 'rgb(110, 209, 243)', marginRight: '10px' }} />
                                <Box>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: '#333',
                                            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
                                        }}
                                    >
                                        {getSessionUserCompany()}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: '#555',
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        У вас уже есть аккаунт
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleLogoutClick}
                            sx={{
                                height: 'auto', // Ensure button height matches card
                                flexShrink: 0, // Prevent button from shrinking
                                display: 'flex',
                                color:'white',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '5px',
                                padding: '19px 10px',
                                borderRadius: '12px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                backgroundColor: 'rgb(110, 209, 243)',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    backgroundColor: 'rgb(110, 209, 243)',
                                    transform: 'translateY(-5px)',
                                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                        >
                            <LogoutIcon sx={{ fontSize: '30px', color: '#fff' }} />
                            <Typography variant="button" sx={{ color: '#fff', fontWeight: 'bold' }}>
                                Выйти
                            </Typography>
                        </Button>
                    </Box>
                    </Container>
                    :<>
                        <Container onSubmit={handleClickSubmitAuth}>
                            {/* Поля для входа (isSignup === false) */}
                            {!isSignup && (
                                <>
                                    <TextField
                                        value={email}
                                        required
                                        type="email"
                                        label="Email"
                                        onChange={handleChangeEmail}
                                        sx={textFieldStyles}
                                        autoComplete="off"
                                        InputProps={{ sx: inputPropsStyles }}
                                        InputLabelProps={{ sx: inputLabelPropsStyles }}
                                    />
                                    <TextField
                                        value={password}
                                        required
                                        type="password"
                                        label="Password"
                                        onChange={handleChangePassword}
                                        sx={textFieldStyles}
                                        autoComplete="off"
                                        InputProps={{ sx: inputPropsStyles }}
                                        InputLabelProps={{ sx: inputLabelPropsStyles }}
                                    />
                                </>
                            )}

                            {/* Поля для регистрации (isSignup === true) */}
                            {isSignup && !isSecondStep && (
                                <>
                                    {/* Поля первого шага регистрации */}
                                    <TextField
                                        value={companyName}
                                        required
                                        label="Company legal name"
                                        onChange={handleChangeCompanyName}
                                        sx={textFieldStyles}
                                        autoComplete="off"
                                        InputProps={{ sx: inputPropsStyles }}
                                        InputLabelProps={{ sx: inputLabelPropsStyles }}
                                    />
                                    <FormControl variant="outlined" sx={formControlStyles} required>
                                        <InputLabel sx={inputLabelPropsStyles}>Select a country</InputLabel>
                                        <Select
                                            value={region}
                                            onChange={handleRegionChange}
                                            label="Select a country"
                                            sx={selectStyles}
                                            inputProps={{ sx: inputPropsStyles }}
                                        >
                                            <MenuItem value="" disabled>Select a country</MenuItem>
                                            {Object.entries(COUNTRIES).map(([code, name]) => (
                                                <MenuItem key={code} value={code}>{name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        value={registration}
                                        required
                                        label="Registration NO."
                                        onChange={handleChangeRegistration}
                                        sx={textFieldStyles}
                                        autoComplete="off"
                                        InputProps={{ sx: inputPropsStyles }}
                                        InputLabelProps={{ sx: inputLabelPropsStyles }}
                                    />
                                    <TextField
                                        value={vat}
                                        required
                                        label="VAT NO."
                                        onChange={handleChangeVat}
                                        sx={textFieldStyles}
                                        autoComplete="off"
                                        InputProps={{ sx: inputPropsStyles }}
                                        InputLabelProps={{ sx: inputLabelPropsStyles }}
                                    />
                                    <TextField
                                        value={phone}
                                        required
                                        label="Contact Phone Number"
                                        onChange={handleChangePhone}
                                        sx={textFieldStyles}
                                        autoComplete="off"
                                        InputProps={{ sx: inputPropsStyles }}
                                        InputLabelProps={{ sx: inputLabelPropsStyles }}
                                    />
                                    <TextField
                                        value={zip}
                                        required
                                        label="Zip"
                                        onChange={handleChangeZip}
                                        sx={textFieldStyles}
                                        autoComplete="off"
                                        InputProps={{ sx: inputPropsStyles }}
                                        InputLabelProps={{ sx: inputLabelPropsStyles }}
                                    />
                                    <TextField
                                        value={city}
                                        required
                                        label="City"
                                        onChange={handleChangeCity}
                                        sx={textFieldStyles}
                                        autoComplete="off"
                                        InputProps={{ sx: inputPropsStyles }}
                                        InputLabelProps={{ sx: inputLabelPropsStyles }}
                                    />
                                    <TextField
                                        value={address}
                                        required
                                        label="Address"
                                        onChange={handleChangeAddress}
                                        sx={textFieldStyles}
                                        autoComplete="off"
                                        InputProps={{ sx: inputPropsStyles }}
                                        InputLabelProps={{ sx: inputLabelPropsStyles }}
                                    />
                                    <TextField
                                        value={email}
                                        required
                                        type="email"
                                        label="Email"
                                        onChange={handleChangeEmail}
                                        sx={textFieldStyles}
                                        autoComplete="off"
                                        InputProps={{ sx: inputPropsStyles }}
                                        InputLabelProps={{ sx: inputLabelPropsStyles }}
                                    />
                                </>
                            )}

                            {isSignup && isSecondStep && (
                                <>
                                    {/* Поля второго шага регистрации */}
                                    {boards.map((board: any, index: any) => (
                                        <TextField
                                            key={index}
                                            value={board}
                                            label="Company board"
                                            onChange={(event: any) => handleChangeBoard(index, event)}
                                            sx={textFieldStyles}
                                            InputProps={{
                                                endAdornment: (
                                                    boards.length > 1 && (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => handleRemoveBoard(index)} size="small">
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                ),
                                                sx: inputPropsStyles,
                                            }}
                                            InputLabelProps={{ sx: inputLabelPropsStyles }}
                                        />
                                    ))}
                                    <button className="buttonAdd" type="button" onClick={handleAddBoard}>+ Add board</button>

                                    {owners.map((owner: any, index: any) => (
                                        <TextField
                                            key={index}
                                            value={owner}
                                            label="Beneficiary owner"
                                            onChange={(event: any) => handleChangeOwner(index, event)}
                                            sx={textFieldStyles}
                                            InputProps={{
                                                endAdornment: (
                                                    owners.length > 1 && (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => handleRemoveOwner(index)} size="small">
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                ),
                                                sx: inputPropsStyles,
                                            }}
                                            InputLabelProps={{ sx: inputLabelPropsStyles }}
                                        />
                                    ))}
                                    <button className="buttonAdd" type="button" onClick={handleAddOwner}>+ Add Owner</button>
                                </>
                            )}

                            <ButtonCustom type="submit">
                                {isSignup ? (isSecondStep ? "Зарегистрировать" : "Далее") : title}
                            </ButtonCustom>

                            {isSignup ? (
                                <p>Есть аккаунт? <Link to="/sign-in">Войти</Link></p>
                            ) : (
                                <p>Нет аккаунта? <Link to="/sign-up">Зарегистрироваться</Link></p>
                            )}
                        </Container>
                    </>
            }

        </>
    );
};
