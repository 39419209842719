import styled from "styled-components";
import {systemBlack, systemWhite} from "../../pages/landingPage/constants";

export const SearchPanelNavbarContainer = styled.div`
    justify-content: left;
    width: 100%;
`;



export const ShopCartArea = styled.div`
    border: 1px solid #DEE2E6;
    display: flex;
    border-radius: 8px;
    gap: 4px;
    align-items: center;

`;

export const TopSpan = styled.span`
`;

export const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const CompanyInfo = styled.span`
    cursor: pointer;
    font: 500 14px/24px "Roboto", sans-serif;
    color: ${systemBlack};
    padding: 12px;
    border-radius: 4px;
    background-color: ${systemWhite};
    
`

export const CurrentBalance = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const AmountTotal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const StyledNavBar = styled.nav`

`