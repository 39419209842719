import {getSession} from "./sessionManagement";
import {getCurrentLanguage} from "../i18n";

export const getExchangeHeaders = () => {
    const sessionId = getSession()
    const lang = getCurrentLanguage()
    return {
        "Content-Type": "application/json",
        "session_id": sessionId,
        "Accept-Language": `${lang},${lang.slice(0, 2)};q=0.8`,
    }
}

export default getExchangeHeaders()