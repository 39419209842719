import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {useState} from "react";
import "./NewWarrantyRequestDialog.sass";
import {inputAddressStyleSM, inputProps} from "../../productReturns/ProductReturns";

interface AddLineDialogProps {
    openedLine: any,
    handleClose: () => void,
}

export const NewWarrantyRequestDialog = (props: AddLineDialogProps) => {
    const {openedLine, handleClose} = props;
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [selectedDefect, setSelectedDefect] = useState<string>('');

    const handleDialogClose = () => {
        setIsOpened(false);
        handleClose();
    };

    const handleDefectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedDefect(event.target.value as string);
    };

    return (openedLine &&

        <div className="position-relative">
            <Dialog
                open={!!openedLine}
                onClose={handleDialogClose}
                PaperProps={{
                    component: 'form',
                    style: {padding: '20px', maxWidth: '600px'}
                }}
            >
                <DialogTitle className="warranty-dialog-title">Информация о продукте</DialogTitle>
                <DialogContent className="warranty-dialog">
                    <div className="warranty-dialog-secondary">
                        <span>ИНФОРМАЦИЯ О ПРОДУКТЕ</span>
                    </div>
                    <div className="product-info">
                        <div className="product-info-value">
                            <strong>ELKO код:</strong> 1389712
                        </div>
                        <div className="product-info-value">
                            <strong>Серийный номер:</strong> K2212N0063168
                        </div>
                        <div className="product-info-value">
                            <strong>Период гарантии:</strong> 24/12 months limited
                        </div>
                        <div className="product-info-value">
                            <strong>Название продукта:</strong> NB KATANA17 CI7-12650H 17" 16GB/1TB W11 B12VGK-013NL MSI
                        </div>
                        <div className="product-info-value">
                            <strong>Накладная:</strong> LV553322
                        </div>
                        <div className="product-info-value">
                            <strong>Дата накладной:</strong> 10.03.2023
                        </div>
                        <div className="product-info-value">
                            <strong>Конец срока гарантии:</strong> 10.03.2025
                        </div>
                    </div>
                    <div className="warranty-dialog-secondary">
                        <span>ОПИСАНИЕ ДЕФЕКТОВ ПРОДУКТА</span>
                    </div>

                    <div className="product-info-secondary">
                        <div className="product-info-secondary-select">
                            <div>
                                <span>Выбрать подходящие описания<br/>дефектов продукта:</span>
                            </div>
                            <div className="product-info-select">
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    size="small"
                                    label="Выбрать"
                                    inputProps={inputProps}
                                    required
                                    InputProps={{
                                        sx: {
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgb(110, 209, 243)',
                                                outline: 'none',
                                            },
                                        },
                                    }}
                                    sx={inputAddressStyleSM}
                                >
                                </TextField>
                            </div>

                        </div>
                        <div className="product-info-text">
                            <div className="product-info-text-first">
                                <span>Выбрать подходящие описания дефектов продукта</span>
                            </div>
                            <div className="product-info-text-second">
                                <i>Пожалуйста, по возможности, более детально укажите в чем выражается дефект. Если
                                    описать дефект недостаточно детально, гарантийный отдел может не принять заявку.</i>
                            </div>
                        </div>
                        <TextField
                            label="Описание дефекта"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            style={{marginBottom: '15px'}}
                        />

                        <div className="product-info-secondary-checkbox">
                            <input type="checkbox"/>
                            <label>Добавить заключение сервисного центра</label>
                        </div>

                        <Button
                            component="label"
                            variant="outlined"
                            startIcon={<i className="bi bi-upload"/>}
                            sx={{
                                marginRight: "1rem",
                                marginTop: "10px",
                                color: "#4caf50",
                                borderColor: "#4caf50",
                            }}
                        >
                            Добавить
                            <input type="file"
                                   hidden
                                   multiple
                            />
                        </Button>
                    </div>


                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between'}}>
                    <Button onClick={handleDialogClose} color="primary">
                        Отмена
                    </Button>
                    <Button variant="contained" color="primary">
                        Добавить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};