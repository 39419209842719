import React from "react";
import {
    HeaderContainer,
    Image,
    HeaderText,
    RightPane,
} from "./styles";
import Logo from "../../../../images/rde_standart.png";
import { useIsMobile } from "../../../../hooks/useIsMobile.hook";
import { MobileHeader } from "./mobileHeader";
import { HeaderButtons } from "./components/headerButtons";
import { t } from "i18next";
import { LanguageSwitcher } from "../../../../components/languageSwitcher/languageSwitcher";

interface HeaderProps {
    showButtons: boolean;
}

export const Header = ({ showButtons }: HeaderProps) => {
    const isMobile = useIsMobile();

    return isMobile ? <MobileHeader showButtons={showButtons} /> : (
        <HeaderContainer>
            <Image alt="logo" src={Logo}/>
            <RightPane>
                <HeaderText>{t("authenticate")}</HeaderText>
                <LanguageSwitcher showButtons={showButtons}/>
                {showButtons && <HeaderButtons showButtons={showButtons} />}
            </RightPane>
        </HeaderContainer>
    );
}
