import React, {useCallback, useState} from "react";
import {
    ContactsContent,
    ContactsBlock,
    ContactTitle,
    ContactsSectionContent,
    ContactsSectionContainer, Pane,
} from "./styles";
import { SectionTitle } from "../../styles";
import { ContactName, ContactText, Link, ContactUsButton } from "./components/styles";
import { t } from "i18next";
import { ContactFormModal } from "./components/contactFormModal";

export const ContactsSection = () => {
    const [isContactModalOpen, setOpenContactModal] = useState(false);

    const toggleModal = useCallback(() => {
        setOpenContactModal(prev => !prev);
    }, []);

    return (
        <ContactsSectionContent
            initial={{opacity: 0, y: 50}}
            whileInView={{opacity: 1, y: 0}}
            transition={{duration: 0.8}}
        >
            <SectionTitle>{t("contacts_section_content")}</SectionTitle>
            <ContactsSectionContainer>
                <ContactTitle>SIA «MK TRADE»</ContactTitle>
                <ContactsContent>
                    <ContactsBlock>
                        <Pane>
                            <ContactName>{t("contacts_section_email")}</ContactName>
                            <ContactName>{t("contacts_section_office_address")}</ContactName>
                        </Pane>
                        <Pane>
                            <Link href="mailto:export@mktrade.lv">export@mktrade.lv</Link>
                            <ContactText>Krasta iela 105A, Rīga, LV-1019</ContactText>
                        </Pane>
                    </ContactsBlock>
                    <ContactsBlock>
                        <Pane>
                            <ContactName>{t("contacts_section_reg_number")}</ContactName>
                            <ContactName>{t("contacts_section_vat")}</ContactName>
                        </Pane>
                        <Pane>
                            <ContactText>40103299982</ContactText>
                            <ContactText>LV40103299982</ContactText>
                        </Pane>
                    </ContactsBlock>
                </ContactsContent>
                <ContactUsButton onClick={toggleModal}>{t("contact_us")}</ContactUsButton>
            </ContactsSectionContainer>
            <ContactFormModal isContactModalOpen={isContactModalOpen} toggleModal={toggleModal} />
        </ContactsSectionContent>
    );
}
