import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {fetchOrders, resetOrders} from "../../store/slices/OrdersReducerSlice";
import {Loader} from "../../components/loader/Loader";
import {OrdersContent} from "../../components/orders/ordersContent/OrdersContent";
import {useLocation} from "react-router-dom";
import {ORDER_TYPES} from "../../constants/consts";
import {TabItem} from "../../components/tabs/TabItem";
import {TabList} from "../../components/tabs/TabList";
import {resetReturnData} from "../../store/slices/NewReturnSlice";
import {updatePage} from "../../store/slices/PagePaginagionSlice";
import {t} from "i18next";
import {Blured} from "../../components/blured/Blured";

export type OrderRequestParams = {
    pageNum: number;
    searchValue: any;
    isReserve?: boolean;
    isPreOrder?: boolean;
}

export const OrdersPage = () => {


    const {isOrdersLoading, ordersData} = useAppSelector(state => state.orders)
    const {page, searchValue} = useAppSelector((state: any) => state.page)

    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [orderType, setOrderType] = useState<ORDER_TYPES>(ORDER_TYPES.RESERVE);
    const location = useLocation()

    useEffect(() => {
        dispatch(updatePage(1))
        dispatch(resetReturnData())
    }, [orderType]);


    useEffect(() => {
        if (page === 0 || !orderType) return
        let requestData: OrderRequestParams = {
            pageNum: page,
            searchValue: searchValue,
        };
        if (orderType === ORDER_TYPES.RESERVE) {
            requestData["isReserve"] = true
        }
        if (orderType === ORDER_TYPES.PER_ORDER) {
            requestData["isPreOrder"] = true
        }
        dispatch(fetchOrders(requestData))
    }, [page, searchValue, location, orderType]);

    useEffect(() => {
        setIsLoading(isOrdersLoading)
    }, [isOrdersLoading]);


    useEffect(() => {
        return () => {
            dispatch(updatePage(1))
            dispatch(resetOrders())
        }
    }, []);

    const getActiveTabIndex = (orderType: ORDER_TYPES) => {
        switch (orderType) {
            case ORDER_TYPES.ORDER: return 2
            case ORDER_TYPES.RESERVE: return 0
            case ORDER_TYPES.PER_ORDER: return 1
        }
    }


    return (
        <>
            <>
                <div className="wrapper" style={{height: "100%"}}>
                <>
                    <TabList className="h-100 m-auto" activeTabIndex={getActiveTabIndex(orderType)}>
                        <TabItem label={t('reserve_label')}>
                            <Blured
                                element={<OrdersContent type={ORDER_TYPES.RESERVE} setType={setOrderType}/>}
                                isLoading={isLoading}
                            />
                        </TabItem>
                        <TabItem className="h-100"  label={t('preorder_label')}>
                            <Blured
                                element={<OrdersContent type={ORDER_TYPES.PER_ORDER} setType={setOrderType}/>}
                                isLoading={isLoading}
                            />
                        </TabItem>
                        <TabItem className="h-100"  label={t('order_label')}>
                            <Blured
                                element={<OrdersContent type={ORDER_TYPES.ORDER} setType={setOrderType}/>}
                                isLoading={isLoading}
                            />
                        </TabItem>
                    </TabList>
                </>
                </div>
                </>
        </>
    )
}