import React, {useContext, useEffect, useState} from "react";
import {WarrantyTableTr} from "./WarrantyTableTr"
import {useAppSelector} from "../../../../hooks/ReduxHooks";
import {OrderLinesContext} from "../../../../hooks/UseOrderLinesSelectContext";
import {checkboxStyleSM} from "../../../catalog/catalogControl/style";
import Checkbox from "@mui/material/Checkbox";
import {t} from "i18next";

export const WarrantyTable = () => {
    const [isAllChecked, setIsAllChecked] = useState(false)
    const {warrantyRequestData} = useAppSelector(state => state.warrantyRequest)

    const { setSelectedLines, lines } = useContext(OrderLinesContext);


    const handleSelectAll = (e: { target: { checked: any; }; }) => {
        if (!warrantyRequestData) return
        setIsAllChecked(e.target.checked)
        if (e.target.checked) {
            setSelectedLines(warrantyRequestData.lines.map(el => el.id));
        } else {
            setSelectedLines([]);
        }
    };

    return (warrantyRequestData ?
        <>
            <div>
                <table className="table table-sm" id="sales_order_table">
                    <thead className="align-top">
                    <tr>
                        <th scope="col">
                            <Checkbox
                                checked={isAllChecked}
                                sx={checkboxStyleSM}
                                value={""}
                                id="AllCartProductsCheck"
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th className="text-start text-colored" id="product_name_header">{t('product')}</th>
                        <th className="text-start text-colored" id="product_code_header">{t('product_code')}</th>
                        <th className="text-end text-colored" id="product_qty_header">{t('quantity')}</th>
                        <th className="text-center text-colored" id="product_qty_header">{t('order_number')}</th>
                        <th className="text-center text-colored" id="product_qty_header">{t('invoice_number')}</th>
                        <th className="text-end text-colored" id="product_qty_header">{t('defect')}</th>
                        <th className="text-end text-colored" id="product_qty_header">{t('note')}</th>
                        <th className="text-end text-colored d-sm-table-cell">{t('price')}</th>
                        <th className="text-end text-colored"><span>{t('cost_without_vat')}</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    {lines && lines.map((child, index) =>
                        <WarrantyTableTr
                            line_key={`return_line_${index}`}
                            requestLine={child}
                        />
                    )}
                    </tbody>
                </table>
            </div>
        </> : <></>
    )
}