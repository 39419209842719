import {useAppDispatch} from "./ReduxHooks";
import {useNavigate} from "react-router-dom";
import {useCallback, useState} from "react";
import {ROUTE_URLS, USER_ROLES} from "../constants/consts";
import {userLogin} from "../api/userLogin";
import {
    clearLocalStorage,
    getSessionUserRole,
    setSession,
    setSessionUserRole,
    setUserEmail,
    setSessionUserCompany, setTwoFactorEnabled, setUserId, setIsUserLoggedIn
} from "../api/sessionManagement";
import Swal from "sweetalert2";

interface UseSignInReturnValues {
    handleSignIn: (email: string, password: string) => void;
}

export const useSignIn = (): UseSignInReturnValues => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const optionsForAlertWithUserEmailConflict:any = {title: 'Упс...',text: 'Пользователь с таким email не найден!', icon: 'error', confirmButtonText: 'ОК',confirmButtonColor: '#0ebfff'}
    const optionsForAlertWithUserPasswordConflict:any = {title: 'Упс...',text: 'Неверный пароль!', icon: 'error', confirmButtonText: 'ОК',confirmButtonColor: '#0ebfff'}
    const optionsForAlertWithUserError:any = {title: 'Упс...',text: 'При входе в учетную запись возникли проблемы!', icon: 'error', confirmButtonText: 'ОК',confirmButtonColor: '#0ebfff'}

    const handleSignIn = useCallback(async (email: string, password: string) => {
        clearLocalStorage()
        const userSession = await userLogin(email, password)
        if (!userSession.error) {
            if (userSession.data) {
                setSession(userSession.data.data.session);
                setSessionUserCompany(userSession.data.data.company_name);
                setSessionUserRole(userSession.data.data.role);
                setUserEmail(email);
                setIsUserLoggedIn(true)
                setTwoFactorEnabled(userSession.data.data.is_enable_two_factor_auth);
                setUserId(userSession.data.data.user_id);
                let userRole:any = getSessionUserRole()
                if (userSession.data.data.is_enable_two_factor_auth && userSession.data.data.is_need_verify) {
                    navigate(ROUTE_URLS.TWO_FACTOR_AUTH);
                } else if (userRole && [USER_ROLES.CONFIRMED,USER_ROLES.WAITING].includes(userRole)) {
                    navigate(ROUTE_URLS.QUESTIONARY);
                } else {
                    navigate(ROUTE_URLS.HOME);
                }
            }
        }
        else {
            if (userSession.error.response?.status === 404) {
                Swal.fire(optionsForAlertWithUserEmailConflict).then(() => {});
            }
            else if (userSession.error.response?.status === 403) {
                Swal.fire(optionsForAlertWithUserPasswordConflict).then(() => {});
            }
            else {
                Swal.fire(optionsForAlertWithUserError).then(() => {});
            }

        }


    }, [dispatch, navigate]);

    return { handleSignIn }
};
