// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-spinner {
  -moz-appearance: textfield;
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -webkit-appearance-margin: 0;
}

.owner-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.owner-container .owner-input {
  flex: 1 1;
}
.owner-container .buttonRemove {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.buttonAdd {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  padding: 10px 0px;
  background-color: #749baa;
  color: #ffffff;
  font-weight: 500;
}
.buttonAdd:hover {
  background-color: #5b7c86;
}`, "",{"version":3,"sources":["webpack://./src/components/auth/Form.sass"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;;AACA;;EAEE,wBAAA;EACC,4BAAA;AAEH;;AAyBA;EACI,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;AAtBJ;AAwBI;EACI,SAAA;AAtBR;AAyBI;EACI,kBAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;AAvBR;;AA2BA;EACI,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;EACA,WAAA;EACA,mBAAA;EACA,+CAAA;EACA,iBAAA;EACA,yBAAA;EACA,cAAA;EACA,gBAAA;AAxBJ;AAyBI;EACE,yBAAA;AAvBN","sourcesContent":[".no-spinner\n  -moz-appearance: textfield\n\n.no-spinner::-webkit-outer-spin-button,\n.no-spinner::-webkit-inner-spin-button\n  -webkit-appearance: none\n   margin: 0\n\n//.auth-input\n//    width: 100%\n//    transition: width 0.3s ease-in-out!important\n//\n//    input\n//        width: 100%!important\n//        height: 50px!important\n//        border-radius: 10px!important\n//        border: none!important\n//        background-color: #ebebeb!important\n//        padding: 0 10px!important\n//\n//        outline: none!important\n//        font-weight: bold!important\n//\n//\n//    fieldset\n//        border-style: initial !important\n//\n//    label\n//        font-family: inherit !important\n//        font-weight: bold!important\n//        color: gray!important\n\n\n.owner-container \n    display: flex\n    align-items: center\n    position: relative\n    width: 100%\n\n    .owner-input \n        flex: 1\n    \n\n    .buttonRemove \n        position: absolute\n        right: 10px\n        top: 50%\n        transform: translateY(-50%)\n        background: none\n        border: none\n        cursor: pointer\n    \n\n\n.buttonAdd \n    position: relative\n    background: none\n    border: none\n    cursor: pointer\n    width: 100%\n    border-radius: 10px\n    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px\n    padding: 10px 0px\n    background-color: #749baa\n    color: #ffffff\n    font-weight: 500\n    &:hover\n      background-color: #5b7c86\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
