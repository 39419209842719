import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import {useEffect, useState} from "react";
import {fetchOrders, resetOrders} from "../../../store/slices/OrdersReducerSlice";
import {Loader} from "../../loader/Loader";
import "./OrdersHomeTable.sass"
import {Column, HomeTableContent} from "../../homeTableContent/HomeTableContent";
import {Link} from "react-router-dom";
import {ROUTE_URLS} from "../../../constants/consts";
import useDebounce from "../../../hooks/UseDebounce";
import {t} from "i18next";
import {Trans} from "react-i18next";


const columns: Column[] = [
  { id: "id", label: "№", minWidth: 170 },
  {
    id: "date",
    label: "date_creation",
    minWidth: 170,
    align: "right",
  },
  {
    id: "state",
    label: "status",
    minWidth: 170,
    align: "right",
  },
  {
    id: "amount",
    label: "amount",
    minWidth: 170,
    align: "right",
  },
];


export const OrdersHomeTable = () => {


    const {isOrdersLoading, ordersData} = useAppSelector(state => state.orders)
    const [page, setPage] = useState<number>(0)
    const debouncedValue = useDebounce(page, 1000)


    const dispatch = useAppDispatch()

    const requestData = {
        pageNum: page + 1,
        isReserve: false,
    };

    const handleSetPage = (pageNum: number) => {
        setPage(pageNum)
    }

    useEffect(() => {
        if (requestData.pageNum === 0) return
        dispatch(fetchOrders(requestData))
    }, [debouncedValue]);


    useEffect(() => {
        return () => {
            dispatch(resetOrders())
        }
    }, []);



    return (
        <>
            {! ordersData && isOrdersLoading ? (
                <Loader/>
            ) : (ordersData ?
                <div className="orders_home_table__wrapper">
                    <
                        HomeTableContent
                        label={`${t("orders")} (${ordersData && ordersData.max_sale_count || 0})`}
                        columns={columns}
                        setPage={handleSetPage}
                        page={page}
                        data_key="orders"
                        isLoading={isOrdersLoading}
                        rowsPerPage={ordersData.sale_limit || ordersData?.sale_info?.length}
                        rowsCount={ordersData.max_sale_count}
                        rows={ordersData.sale_info.map(el => {
                            return {
                                id: <Link to={`${ROUTE_URLS.ORDERS}/${el.id}`}>{el.name}</Link>,
                                data_key: el.id,
                                name: el.reservation_name,
                                date: el.date_order.split(" ")[0],
                                state: el.state,
                                amount: el.amount_untaxed
                            }
                        })}
                        sx={{width: "100%", marginBottom: "20px"}}
                        dataLink={
                            <div className="orders_home_table__link">
                                <Link to={ROUTE_URLS.ORDERS}>
                                    <Trans i18nKey="all_orders"/>
                                    <i style={{paddingLeft: "5px"}} className="bi bi-arrow-right"></i>
                                </Link>
                            </div>
                        }
                    />
                </div> : <></>
            )}
        </>
    )
}