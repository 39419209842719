import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Attachment from './Attachment';

interface AttachmentAreaProps {
    selectedFiles: File[];
    setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const AttachmentArea: React.FC<AttachmentAreaProps> = ({ selectedFiles, setSelectedFiles }) => {
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const newFiles = Array.from(event.dataTransfer.files);
            setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleRemoveFile = (fileToRemove: File) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    };

    return (
        <Box
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            sx={{
                border: isDragging ? '2px dashed #1976d2' : '2px dashed grey',
                padding: '16px',
                borderRadius: '8px',
                textAlign: 'center',
                bgcolor: isDragging ? 'grey.100' : 'grey.50',
                transition: 'background-color 0.3s ease',
                position: 'relative',
                maxHeight: '150px',
                overflowY: 'auto',
            }}
        >
            <Typography variant="body2" color="textSecondary">
                {isDragging ? 'Отпустите файлы, чтобы прикрепить' : 'Перетащите файлы сюда или нажмите на иконку скрепки, чтобы прикрепить'}
            </Typography>

            <Box
                sx={{
                    marginTop: '10px',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    gap: '10px',
                }}
            >
                {selectedFiles.map((file, index) => (
                    <Attachment key={index} file={file} onRemove={() => handleRemoveFile(file)} />
                ))}
            </Box>
        </Box>
    );
};

export default AttachmentArea;
