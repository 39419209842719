import React from "react";
import {
    FooterContainer,
    FooterTitle,
    FooterLink,
    FooterContent,
} from "./styles";

export const Footer = () => {
    return (
        <FooterContainer>
            <FooterContent>
                <FooterTitle>RD Electronics. © 2025 SIA «MK Trade».</FooterTitle>
                <FooterLink href="mailto:export@mktrade.lv">export@mktrade.lv</FooterLink>
            </FooterContent>
        </FooterContainer>
    );
}
