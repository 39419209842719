import React from "react";
import "./NewWarrantyRequestTable.sass"

interface TableRowProps {
    lineData: any,
}

export const NewWarrantyRequestTableRow = () => {

    return (
        <tr>
            <td scope="text-start"></td>
            <td scope="text-start"></td>
            <td scope="text-start"></td>
            <td scope="text-start"></td>
            <td scope="text-start"></td>
            <td scope="text-start"></td>
            <td scope="text-start"></td>
            <td scope="text-start"></td>
            <td scope="text-start"></td>
            <td scope="text-start"></td>
            <td scope="text-start"></td>
            <td scope="text-start"></td>
            <td scope="text-start"></td>
            <th scope="col" className="">
                <button type="button" className="btn btn-sm">
                    <i className="bi bi-trash"/>
                </button>
            </th>
        </tr>
    )

}

export const NewWarrantyRequestTable = () => {

    return (
        <>
            <>
                <table className="table newRequestTable">
                    <thead className="newRequestTable">
                    <tr>
                        <th scope="col" className="text-secondary text-start newRequestTable__number">Номер заказа</th>
                        <th scope="col" className="text-secondary text-start newRequestTable__code">Код</th>
                        <th scope="col" className="text-secondary text-start newRequestTable__man_code">Код
                            производителя
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__name">Название продукта
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__delivery">Накладная</th>
                        <th scope="col" className="text-secondary text-start newRequestTable__bill_date">Дата счета</th>
                        <th scope="col" className="text-secondary text-start newRequestTable__qty">Количество</th>
                        <th scope="col" className="text-secondary text-start newRequestTable__price">Цена <br/>без НДС
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__total">Сумма без<br/>HДC
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__state">Состояние продукта
                            и комплектация
                        </th>
                        <th scope="col" className="text-secondary text-start newRequestTable__desc">Примечания</th>
                        <th scope="col" className="text-secondary text-start newRequestTable__desc"></th>
                        <th scope="col" className="text-secondary text-start newRequestTable__img">Фото</th>
                        <th></th>

                    </tr>
                    </thead>
                    <tbody className="newRequestTable__body">

                    </tbody>
                </table>
            </>

        </>
    )
}